import customerAPI from "../../api/CustomersAPI"

export default {
    state: {
        customers: [],
        customer: {},
        status: ''
    },

    getters: {
        customers: state => state.customers,
        customer: state => state.customer,
        customerStatus: state => state.status
    },

    actions: {
        createCustomer( { commit }, payload ) {
            commit( 'setCustomerStatus', 'loading' );
            customerAPI.post( payload ).then( () => {
                commit( 'setCustomerStatus', '' );
                this.dispatch( 'getCustomers' );

            } ).catch( () => {
                commit( 'setCustomerStatus', 'error' );
            } )
        },

        getCustomers( { commit } ) {
            commit( 'setCustomerStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {

                customerAPI.get().then( data => {
                    commit( 'setCustomerStatus', '' );
                    commit( 'setCustomers', data );
                    resolve( data )
                } ).catch( () => {
                    commit( 'setCustomerStatus', 'error' );
                    reject()
                } )
            } )
        },

        getCustomer( { commit }, id ) {
            commit( 'setCustomerStatus', 'loading' );

            customerAPI.getOne( id ).then( data => {
                commit( 'setCustomerStatus', '' );
                commit( 'setCustomer', data );

            } ).catch( () => {
                commit( 'setCustomerStatus', 'error' );
            } )
        },

        updateCustomer( { commit }, payload ) {
            commit( 'setCustomerStatus', 'loading' );

            // payload is a Formdata object
            let id = payload.get( 'id' );

            customerAPI.put( id, payload ).then( data => {
                commit( 'setCustomerStatus', '' );
                commit( 'setCustomer', data );
                this.dispatch( 'getCustomers' );

            } ).catch( () => {
                commit( 'setCustomerStatus', 'error' );
            } )
        },

        deleteCustomer( { commit }, id ) {
            commit( 'setCustomerStatus', 'loading' );

            customerAPI.delete( id ).then( () => {
                commit( 'setCustomerStatus', '' );
                this.dispatch( 'getCustomers' );

            } ).catch( () => {
                commit( 'setCustomerStatus', 'error' );
            } )
        },

        resetCustomersData( { commit } ) {
            commit( 'resetData' )
        }
    },

    mutations: {
        setCustomers( state, customers ) {
            state.customers = customers;
        },

        setCustomer( state, customer ) {
            state.customer = customer;
        },

        setCustomerStatus( state, status ) {
            state.status = status;
        },

        resetData( state ) {
            state.customers = []
            state.customer = {}
            state.status = ''
        }
    }
}