<template>
  <v-dialog v-model="dialog" persistent width="850px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs8 class="pr-3" align-center justify-space-between>
              <v-text-field
                v-model="job.job_name"
                :rules="rules.required_field"
                placeholder="Job Name"
                label="Job Name"
              ></v-text-field>
            </v-flex>

            <v-flex xs4 class="pl-3" align-center justify-space-between>
              <v-text-field
                v-model="job.job_area"
                :rules="rules.required_field"
                label="Job Area"
                placeholder="Job Area"
              ></v-text-field>
            </v-flex>

            <v-flex xs9 class="mt-3 pr-3">
              <v-text-field
                v-model="job.job_description"
                :rules="rules.required_field"
                label="Job Description"
                placeholder="Job Description"
              ></v-text-field>
            </v-flex>

            <v-flex xs3 class="pl-3 mt-3" align-center justify-space-between>
              <v-select
                v-model="job.job_type"
                :rules="rules.required_field"
                label="Job Type"
                placeholder="Job Type"
                :items="job_types"
                item-text="type"
                item-value="value"
              ></v-select>
            </v-flex>

            <v-flex class="mt-3 pr-3" xs6>
              <v-text-field
                v-model="job.po_number"
                :rules="rules.required_field"
                label="P.O Number"
                placeholder="P.O Number"
              ></v-text-field>
            </v-flex>

            <v-flex class="mt-3 pl-3" xs6>
              <v-select
                v-model="job.current_state"
                :items="job_states"
                label="Current State"
                placeholder="Current State"
              ></v-select>
            </v-flex>

            <v-flex class="mt-3 pr-3" xs4>
              <v-text-field
                v-model="job.work_order_number"
                label="Work Order Number"
                placeholder="Work Order Number"
              ></v-text-field>
            </v-flex>

            <v-flex class="pl-3 mt-3" xs8>
              <v-text-field
                v-model="job.work_order_description"
                label="Work Order Description"
                placeholder="Work Order Description"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="execCreateJob"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../utils/mixins";

export default {
  name: "JobDialog",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true
    },
    editModel: {
      type: Object
    }
  },
  data: () => ({
    job_types: [
      {
        type: "FTTH JOB",
        value: 1
      },
      {
        type: "FTTB JOB",
        value: 2
      },
      {
        type: "FTTS/A JOB",
        value: 3
      }
    ],
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "New Job",
    dialog_button_text: "Create",
    valid: true,
    job_states: [
      {
        text: "Proposed",
        value: 0
      },
      {
        text: "Implementation",
        value: 1
      },
      {
        text: "Updated",
        value: 2
      },
      {
        text: "Closed",
        value: 3
      }
    ],
    rules: {
      email_field: [
        v => !!v || "Field is required",
        v =>
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Field should be a valid email"
      ],
      required_field: [v => !!v || "Field is required"]
    },
    dialog: null,
    job: {},
    upload_file: null
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.job = {};

    if (this.editModel) {
      this.is_edit_mode = true;

      Object.assign(this.job, this.editModel);
      this.dialog_title = "Edit Job";
      this.dialog_button_text = "Update";
    }
  },
  computed: {
    ...mapState({
      status: state => state.projectsModule.status
    })
  },
  methods: {
    ...mapActions({
      createJob: "createJob",
      getProjects: "getProjects",
      updateJob: "updateJob"
    }),
    execCreateJob() {
      if (this.$refs.form.validate()) {
        if (this.is_edit_mode) {
          this.updateJob(this.job);
        } else {
          this.job.project = this.$route.params.project_id;
          this.createJob(this.job).then(() => this.getProjects());
        }

        this.resetFields();
        this.closeDialog();
      }
    },
    resetFields() {
      this.job = {};
      this.is_edit_mode = false;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();

      this.$emit("closeDialog");
    }
  },
  watch: {
    dialogModel: function(val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function(val) {
      if (!val) {
        this.job = {};
        this.closeDialog();
      }
    }
  }
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>