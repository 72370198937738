<template>
  <c-submenu-layout>
    <!-- SubMenu Column -->
    <v-flex class="sub-menu" xs2>
      <c-sub-menu :items="sub_menu"></c-sub-menu>
    </v-flex>

    <!-- Main Content Column -->
    <v-flex xs10 class="main-content-border">
      <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

      <h1>Account</h1>

      <!-- v-card start -->
      <v-card flat class="content-card">
        <v-flex class="content-card-title" xs12>General Information</v-flex>
        <v-layout row wrap class="pa-3">
          <v-flex class="editable-content-form" xs3 style="text-align:center; display: block;">
            <v-flex class="pt-3">
              <v-avatar size="150px" rounded>
                <v-img :src="user.user_avatar"></v-img>
              </v-avatar>
            </v-flex>

            <v-flex class="pb-3">
              <v-btn
                :loading="updating_profile_picture"
                @click="openProfilePicker"
                outlined
                class="mt-4"
                color="secondary"
                disabled
              >
                <v-icon left>photo_camera</v-icon>Change Image
              </v-btn>
            </v-flex>

            <v-file-input
              id="profile-input"
              v-model="upload_file"
              color="success"
              class="hidden"
              label="Excel Upload"
              placeholder="Select file with historical data"
              prepend-icon="mdi-paperclip"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="success" dark label small>{{ text }}</v-chip>
                <span
                  v-else-if="index === 2"
                  class="overline grey--text text--darken-3 mx-2"
                >+{{ files.length - 2 }} File(s)</span>
              </template>
            </v-file-input>
          </v-flex>

          <v-flex xs9 class="pt-9 pb-9 mb-5">
            <v-layout class="no-padding form-layout-border">
              <v-flex class="editable-content-form" xs5>
                <span class="span-title">Name</span>
                <span class="span-subtitle">Your full name</span>
              </v-flex>
              <v-flex class="editable-content-form" xs7>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      class="form-textfield"
                      dense
                      label="Solo"
                      placeholder="Full name"
                      v-model="user.name"
                      :loading="first_name_updating"
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-layout class="no-padding form-layout-border">
              <v-flex class="editable-content-form" xs5>
                <span class="span-title">Email</span>
                <span class="span-subtitle">This is also your username, so it is not editable</span>
              </v-flex>
              <v-flex class="editable-content-form" xs7>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      class="form-textfield"
                      dense
                      label="Solo"
                      placeholder="Email"
                      v-model="user.email"
                      disabled
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-layout class="no-padding form-layout-border">
              <v-flex class="editable-content-form" xs5>
                <span class="span-title">Phone Number</span>
                <span
                  class="span-subtitle"
                >This is phone number. It may be used in 2-factor authentication, if enabled.</span>
              </v-flex>
              <v-flex class="editable-content-form" xs7>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      class="form-textfield"
                      dense
                      v-model="user.phone_number"
                      :loading="phone_number_updating"
                      label="Solo"
                      placeholder="Phone Number"
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
      <!-- v-card end -->
    </v-flex>
  </c-submenu-layout>
</template>

<script>
import appConfig from "../../config";
import _ from "lodash";
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    updating_profile_picture: false,
    first_name_updating: false,
    last_name_updating: false,
    phone_number_updating: false,
    sub_menu: [],
    user: {},
    bread_crumbs: [
      {
        text: "Account",
        href: "/profile"
      }
    ],
    upload_file: null
  }),
  created() {
    this.debouncedUpdateUser = _.debounce(this.updateUserData, 1500);
  },
  mounted() {
    this.user = this.current_user;
    this.sub_menu = appConfig.profile_page_sub_menus;
  },
  watch: {
    current_user: function(val) {
      this.user = val;
    },
    "user.name": function(old_val, new_val) {
      if (new_val != undefined && old_val != new_val) {
        if (new_val != "") {
          this.first_name_updating = true;
          this.debouncedUpdateUser();
        }
      }
    },
    "user.phone_number": function(old_val, new_val) {
      if (new_val != undefined && old_val != new_val) {
        this.phone_number_updating = true;
        this.debouncedUpdateUser();
      }
    }
  },
  computed: {
    ...mapState({
      current_user: state => state.usersModule.current_user
    })
  },
  methods: {
    ...mapActions({
      updateSelf: "updateSelf"
    }),
    openProfilePicker() {
      document.getElementById("profile-input").click();
    },
    updateProfilePicture() {
      this.updating_profile_picture = true;
      if (this.upload_file) {
        console.log("Change implementation");
        // let fileName = String(this.upload_file.name);
        // let fileType = fileName.substr(fileName.lastIndexOf(".") + 1);
        // this.getUploadURL({
        //   file_type: fileType
        // }).then(resp => {
        //   let profile_image_name = resp.file_name;
        //   let uploadPayload = {
        //     resource: resp.url,
        //     data: this.upload_file,
        //     file_type: fileType
        //   };

        //   this.uploadToGoogleStorage(uploadPayload).then(() => {
        //     this.user.profile_image = profile_image_name;
        //     this.updateUserData();
        //   });
        // });
      }
    },
    updateUserData() {
      let apiData = {};
      if (this.first_name_updating) apiData["name"] = this.user.name;
      if (this.phone_number_updating)
        apiData["phone_number"] = this.user.phone_number;

      apiData["id"] = this.user.id;

      this.updateSelf(apiData).then(res => {
        this.clearLoaders();
        console.log(res);
      });
    },
    clearLoaders() {
      this.first_name_updating = false;
      this.phone_number_updating = false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>