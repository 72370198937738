<template>
  <v-dialog v-model="dialog" persistent width="650px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-data-table
          v-model="selected_permissions"
          :headers="headers"
          :items="permissions"
          text="permission_name"
          value="id"
          show-select
          class="elevation-1"
        ></v-data-table>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="loading"
          @click="attachPermissions"
          type="submit"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "PermissionsDialog",
  props: {
    dialogModel: {
      type: Boolean,
      required: true
    },
    group: {
      type: Object
    }
  },
  data: () => ({
    headers: [
      {
        text: "Permission Name",
        value: "permission_name"
      }
    ],
    attached_permission_ids: [],
    selected_permissions: [],
    loading: false,
    dialog_title: "Add Permissions",
    dialog_button_text: "Attach",
    valid: true,
    dialog: null
  }),
  mounted() {
    this.dialog = this.dialogModel;
  },
  computed: {
    ...mapState({
      permissions: state => state.permissionsModule.permissions
    })
  },
  methods: {
    ...mapActions({
      addGroupPermissions: "addGroupPermissions"
    }),
    attachPermissions() {
      let apiData = {
        group_id: this.group.id,
        permissions: this.selected_permissions.map(perm => perm.id)
      };

      this.addGroupPermissions(apiData).then(() => this.closeDialog());
    },
    resetFields() {
      this.selected_permissions = [];
    },

    reloadAttachedPermissions() {
      this.$emit("reloadPermissions");
    },
    closeDialog() {
      this.loading = false;
      this.$emit("closePermissionsDialog");
    }
  },
  watch: {
    dialogModel: function(val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function(val) {
      if (!val) {
        this.closeDialog();
      }
    }
  }
};
</script>

<style scoped>
</style>