import axios from 'axios'
import { parseAPIResults } from '../utils/utils'

export default {
    post( resource, payload ) {
        if ( payload.upload_file ) {
            return axios.post( `${ resource }/`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } )
                .then( response => {
                    return parseAPIResults( response.data )
                } )
        }
        else {
            return axios.post( `${ resource }/`, payload )
                .then( response => {
                    return parseAPIResults( response.data )
                } )
        }
    },

    get( resource, requestParams ) {

        return axios.get( `${ resource }/`, {
            params: requestParams
        } )
            .then( response => {
                return parseAPIResults( response.data )
            } )
    },

    update( resource, id, payload ) {

        return axios.put( `${ resource }/${ id }/`, payload )
            .then( response => {
                return parseAPIResults( response.data )
            } )
    },

    delete( resource, id ) {
        return axios.delete( `${ resource }/${ id }/` )
            .then( response => {
                return parseAPIResults( response.data )
            } )
    },
}