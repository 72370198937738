import materialsAPI from "../../api/MaterialsAPI"
import appConfig from "../../config"

var baseUrl = appConfig.base_url + appConfig.materials_service_base_port + '/v1/materials_service/';

var materialTypesUrl = baseUrl + 'material_types';
var materialTypesUploadUrl = materialTypesUrl + '/upload_material_types';

var materialsUrl = baseUrl + 'materials';
var materialsUploadUrl = materialsUrl + '/upload_materials';

var serviceCostsUrl = baseUrl + 'service_costs';
var serviceCostsUploadUrl = serviceCostsUrl + '/upload_service_costs';


export default {
    state: {
        material_types: [],
        customer_materials: [],
        service_costs: [],
        status: ''
    },

    getters: {
        materials: state => state.materials,
        material: state => state.material,
        materialsStatus: state => state.status
    },

    actions: {
        uploadMaterialTypes( { commit }, payload ) {
            commit( 'setMaterialsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                materialsAPI.post( materialTypesUploadUrl, payload ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )
        },

        createMaterialType( { commit }, payload ) {
            commit( 'setMaterialsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                materialsAPI.post( materialTypesUrl, payload ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )
        },

        updateMaterialType( { commit }, payload ) {

            commit( 'setMaterialsStatus', 'loading' );

            let id = payload.id;

            return new Promise( ( resolve, reject ) => {
                materialsAPI.update( materialTypesUrl, id, payload ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )
        },

        uploadCustomerMaterials( { commit }, payload ) {
            commit( 'setMaterialsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                materialsAPI.post( materialsUploadUrl, payload ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )

        },

        createCustomerMaterial( { commit }, payload ) {
            commit( 'setMaterialsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                materialsAPI.post( materialsUrl, payload ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )
        },

        updateCustomerMaterial( { commit }, payload ) {
            commit( 'setMaterialsStatus', 'loading' );

            let id = payload.id;

            return new Promise( ( resolve, reject ) => {
                materialsAPI.update( materialsUrl, id, payload ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )
        },

        uploadMaterialServiceCosts( { commit }, payload ) {
            commit( 'setMaterialsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                materialsAPI.post( serviceCostsUploadUrl, payload ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )

        },

        createCustomerServiceCost( { commit }, payload ) {
            commit( 'setMaterialsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                materialsAPI.post( serviceCostsUrl, payload ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )
        },

        updateCustomerServiceCost( { commit }, payload ) {
            commit( 'setMaterialsStatus', 'loading' );

            let id = payload.id;

            return new Promise( ( resolve, reject ) => {
                materialsAPI.update( serviceCostsUrl, id, payload ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )
        },

        getMaterialTypes( { commit } ) {
            commit( 'setMaterialsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                materialsAPI.get( materialTypesUrl ).then( data => {
                    commit( 'setMaterialsStatus', 'success' );
                    commit( 'setMaterialTypes', data );
                    resolve( data )
                } ).catch( err => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject( err )
                } )
            } )
        },

        getCustomerMaterials( { commit }, id ) {
            commit( 'setMaterialsStatus', 'loading' );

            let apiData = {
                customer_id: id
            }

            return new Promise( ( resolve, reject ) => {
                materialsAPI.get( materialsUrl, apiData ).then( data => {
                    commit( 'setMaterialsStatus', 'success' );
                    commit( 'setCustomerMaterials', data );
                    resolve( data )
                } ).catch( err => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject( err )
                } )
            } )
        },

        getCustomerServiceCosts( { commit }, id ) {
            commit( 'setMaterialsStatus', 'loading' );

            let apiData = {
                customer_id: id
            }

            return new Promise( ( resolve, reject ) => {
                materialsAPI.get( serviceCostsUrl, apiData ).then( data => {
                    commit( 'setMaterialsStatus', 'success' );
                    commit( 'setCustomerServiceCosts', data );
                    resolve( data )
                } ).catch( err => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject( err )
                } )
            } )

        },

        deleteMaterialType( { commit }, id ) {
            commit( 'setMaterialsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                materialsAPI.delete( materialTypesUrl, id ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )

        },

        deleteCustomerMaterial( { commit }, id ) {
            commit( 'setMaterialsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                materialsAPI.delete( materialsUrl, id ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )

        },

        deleteServiceCost( { commit }, id ) {
            commit( 'setMaterialsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                materialsAPI.delete( serviceCostsUrl, id ).then( () => {
                    commit( 'setMaterialsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setMaterialsStatus', 'error' );
                    reject()
                } )
            } )

        },

        resetMaterialsData( { commit } ) {
            commit( 'resetData' )
        }
    },

    mutations: {
        setMaterialTypes( state, material_types ) {
            state.material_types = material_types;
        },

        setCustomerMaterials( state, materials ) {
            state.customer_materials = materials;
        },

        setCustomerServiceCosts( state, service_costs ) {
            state.service_costs = service_costs;
        },

        setMaterialsStatus( state, status ) {
            state.status = status;
        },

        resetData( state ) {
            state.material_types = []
            state.customer_materials = []
            state.service_costs = []
            state.status = ''
        }
    }
}