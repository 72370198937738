import { render, staticRenderFns } from "./UserGroupPermissionsDialog.vue?vue&type=template&id=5be649ab&scoped=true&"
import script from "./UserGroupPermissionsDialog.vue?vue&type=script&lang=js&"
export * from "./UserGroupPermissionsDialog.vue?vue&type=script&lang=js&"
import style0 from "./UserGroupPermissionsDialog.vue?vue&type=style&index=0&id=5be649ab&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5be649ab",
  null
  
)

export default component.exports