<template>
  <div>
    <v-card flat class="pb-3">
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <p :class="'primary--text pl-3 pb-0 pt-0'">Printer Settings</p>
          <v-layout row wrap>
            <!-- source -->
            <v-flex xs6>
              <v-select
                class="pl-2 pr-2 pb-3"
                dense
                :items="formatItems"
                item-text="name"
                item-value="value"
                :rules="rules.required_field"
                v-model="format"
                label="Page Size"
              ></v-select>
            </v-flex>

            <!-- destination -->
            <v-flex xs6>
              <v-select
                class="pl-2 pr-2 pb-3"
                dense
                :items="resolutionItems"
                item-text="name"
                item-value="value"
                :rules="rules.required_field"
                v-model="resolution"
                label="Resolution"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn
          class="pl-3 ml-4"
          color="success"
          :disabled="!valid"
          :loading="loading"
          @click="execPrint"
          >{{ dialog_button_text }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import dialogMixins from "../../../../utils/mixins";

export default {
  name: "PrintConfigManagerComponent",
  mixins: [dialogMixins],
  props: {},
  components: {},
  data: () => ({
    loading: false,
    dialog_button_text: "Print",
    format: "a4",
    scale: 250,
    resolution: 200,
    valid: true,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    scaleItems: [
      { name: "1:500000", value: 500 },
      { name: "1:250000", value: 250 },
      { name: "1:100000", value: 100 },
      { name: "1:50000", value: 50 },
      { name: "1:25000", value: 25 },
      { name: "1:10000", value: 10 },
    ],
    formatItems: [
      { name: "A0", value: "a0" },
      { name: "A1", value: "a1" },
      { name: "A2", value: "a2" },
      { name: "A3", value: "a3" },
      { name: "A4", value: "a4" },
      { name: "A5", value: "a5" },
    ],
    resolutionItems: [
      { name: "72 dpi", value: 72 },
      { name: "150 dpi", value: 150 },
      { name: "200 dpi", value: 200 },
      { name: "300 dpi", value: 300 },
    ],
  }),
  mounted() {},
  computed: {},
  methods: {
    execPrint() {
      let options = {};
      options.format = this.format;
      options.scale = this.scale;
      options.resolution = this.resolution;

      this.$emit("print", options);
    },

    clearSelection() {
      this.$emit("refreshComponents");
    },

    displaySuccessAlert(message) {
      this.$notify({
        group: "success",
        title: "",
        text: message,
      });
    },

    displayErrorAlert(message) {
      this.$notify({
        group: "error",
        title: "",
        text: message,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>