<template>
  <v-navigation-drawer
    app
    color="primary"
    expand-on-hover
    class="nav-drawer white--text"
    left
    permanent
    :clipped="true"
  >
    <v-list dense>
      <v-list-item-group>
        <v-list-item
          :prepend-icon="item.action"
          link
          :to="item.href"
          :class="item.href === $route.path ? 'highlighted' : 'unhighlighted'"
          class="drawer-items"
          v-for="(item, i) in nav_menu_items"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon v-text="item.action"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <v-list-item
        :prepend-icon="item.action"
        link
        :to="item.href"
        :class="item.href === $route.path ? 'highlighted' : 'unhighlighted'"
        class="drawer-items white--text"
        v-for="item in profile_menu"
        :key="item.title"
      >
        <v-list-item-icon>
          <v-icon v-text="item.action"></v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        prepend-icon="forward"
        class="drawer-items white--text"
        key="logout"
        @click="execLogout"
      >
        <v-list-item-icon>
          <v-icon color="white">forward</v-icon>
        </v-list-item-icon>

        <v-list-item-content class="white--text">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import appConfig from "../../config";
import { mapState, mapActions } from "vuex";
import { filterMenuOptions } from "../../utils/utils";

export default {
  name: "NavDrawer",
  props: {
    drawerModel: {
      type: Boolean,
      required: false,
    },
    items: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    item: 0,
    profile_menu: [],
    nav_menu_items: [],
  }),
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
    }),
  },
  mounted() {
    this.profile_menu = appConfig.profile_menu;
    this.nav_menu_items = filterMenuOptions(appConfig.main_menu, this.user);
  },
  methods: {
    ...mapActions({
      logout: "logout",
    }),
    execLogout() {
      this.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.drawer-items {
  padding-top: 2.5px !important;
  padding-bottom: 2.5px !important;
}

.v-list-item__content .v-list-item__title {
  font-size: 0.9rem !important;
}

.nav-drawer {
  background: #efefef;
}

.v-list__tile,
.link,
.v-list__tile:hover {
  text-decoration: none;
  background: #999;
}

a.unhighlighted .v-list-item__title,
a.unhighlighted .v-icon {
  color: #fff;
}

a.highlighted,
a.drawer-items.v-list-item--active .v-list-item__title,
a.drawer-items.v-list-item--active .v-icon {
  color: #fff !important;
}
</style>
