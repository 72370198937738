import { mapActions } from "vuex";

export default {
  data: () => ({
    imported_layers_list: null,
  }),

  methods: {
    ...mapActions({
      getImportedLayers: "getImportedLayers",
      deleteImportedLayers: "deleteImportedLayers",
    }),
    fetchImportedLayers() {
      if (this.$route.params.job_id) {
        let job_id = this.$route.params.job_id;

        // only retrieve imported layers that this user imported
        let payload = { job_id: job_id, user: this.user.id };
        this.getImportedLayers(payload).then((res) => {
          this.imported_layers_list = res;
        });
      }
    },

    removeImportedLayer(layerItem) {
      if (layerItem) {
        let payload = {};
        payload.layer_id = layerItem.id;
        this.deleteImportedLayers(payload)
          .then(() => {
            this.displaySuccessAlert("Deleted imported layer with features");
            if (layerItem.feature_type)
              this.fetchFeatures(layerItem.feature_type);
            else this.fetchFeatures();

            this.fetchImportedLayers();
          })
          .catch(() => {
            this.displayErrorAlert(
              "Failed to delete imported layer with features. Contact the systems admin."
            );
          });
      }
    },
  },
};
