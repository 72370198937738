<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <PrefixesComponent
      @close-dialog="closeDialog"
      :job="job"
      :title="true"
      :is_dialog="true"
    ></PrefixesComponent>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import PrefixesComponent from "../../../generic/NamingPrefixes";

export default {
  name: "PrefixesDialogComponent",
  components: {
    PrefixesComponent,
  },
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    job: {
      type: Number,
    },
  },
  data: () => ({
    id: "",
    dialog: null,
  }),
  mounted() {
    this.dialog = this.dialogModel;
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>