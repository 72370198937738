<template>
  <c-app-layout>
    <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>
    <v-layout row wrap>
      <v-flex xs3 class="pa-3">
        <UserDetails
          @resetPassword="reset_password_dialog = !reset_password_dialog"
          @showDeleteDialog="delete_dialog = !delete_dialog"
        ></UserDetails>
      </v-flex>

      <v-flex
        v-if="this.user.customer_id == '0' || loggedinUser.customer_id == '0'"
        xs9
        class="pa-3"
      >
        <v-tabs v-model="tabs">
          <v-tab>Attached Groups</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-data-table
              :loading="status == 'loading'"
              :items="user_groups"
              :headers="headers"
            >
              <template v-slot:item.action="{ item }">
                <v-icon small color="error" @click="deleteGroup(item)"
                  >mdi-delete</v-icon
                >
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>

      <v-btn
        v-if="
          tabs == 0 &&
          (this.user.customer_id == '0' || loggedinUser.customer_id == '0')
        "
        @click="permissions_dialog = !permissions_dialog"
        absolute
        top
        right
        fab
        rounded
        color="primary"
        class="white--text fab-button"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-layout>

    <div v-if="dialog">
      <UserDialog
        @closeDialog="closeDialog"
        :customer_id="customer.id"
        :dialogModel="dialog"
      ></UserDialog>
    </div>

    <div v-if="reset_password_dialog">
      <ResetPasswordDialog
        @closeDialog="closeDialog"
        :dialogModel="reset_password_dialog"
      ></ResetPasswordDialog>
    </div>

    <div v-if="delete_dialog">
      <ConfirmDeleteDialog
        deleteMessage="Are you sure you want to delete this user?"
        :model="user"
        @closeDialog="closeDialog"
        :dialogModel="delete_dialog"
        @deleteConfirmed="execDeleteUser"
      ></ConfirmDeleteDialog>
    </div>

    <div v-if="delete_permission_dialog">
      <ConfirmDeleteDialog
        deleteMessage="Are you sure you want to remove this permission group?"
        :model="deleteModel"
        @closeDialog="closeDialog"
        :dialogModel="delete_permission_dialog"
        @deleteConfirmed="execDeleteGroup"
      ></ConfirmDeleteDialog>
    </div>

    <div v-if="permissions_dialog">
      <PermissionsDialog
        @closeDialog="closeDialog"
        :dialogModel="permissions_dialog"
      ></PermissionsDialog>
    </div>
  </c-app-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UserDialog from "../../components/users/UserDialog";
import dialogMixins from "../../utils/mixins";
import UserDetails from "./sub-components/UserDetails";
import ResetPasswordDialog from "./sub-components/ResetPasswordDialog";
import ConfirmDeleteDialog from "../generic/ConfirmDeleteDialog";
import PermissionsDialog from "./sub-components/PermissionGroupsDialog";

export default {
  name: "CustomerDetail",
  components: {
    UserDetails,
    UserDialog,
    ResetPasswordDialog,
    ConfirmDeleteDialog,
    PermissionsDialog,
  },
  mixins: [dialogMixins],
  data: () => ({
    user_id: "",
    deleteModel: null,
    tabs: 0,
    bread_crumbs: [],
    headers: [
      {
        text: "Group Name",
        value: "group_name",
      },
      {
        text: "More",
        value: "action",
      },
    ],
    menuActions: [
      {
        text: "Send Invite",
        action: "invite",
      },
      {
        text: "Reset Password",
        action: "reset_password",
      },
      {
        text: "Suspend Account",
        action: "suspend",
      },
      {
        text: "Delete Account",
        action: "delete",
      },
    ],
  }),
  computed: {
    ...mapState({
      user: (state) => state.usersModule.user,
      status: (state) => state.usersModule.status,
      customer: (state) => state.customersModule.customer,
      user_groups: (state) => state.usersModule.user_groups,
      loggedinUser: (state) => state.usersModule.current_user,
    }),
  },
  mounted() {
    this.user_id =
      this.$route.params.user_id != undefined
        ? this.$route.params.user_id
        : this.$route.params.company_user_id;

    this.getUser(this.user_id);
    this.getUserPermissionGroups(this.user_id);

    if (this.user.customer_id) {
      this.getCustomer(this.user.customer_id);
    }
  },
  watch: {
    user: function () {
      this.constructBreadCrumbs();
    },
  },
  methods: {
    ...mapActions({
      getUser: "getUser",
      deleteUser: "deleteUser",
      getCustomer: "getCustomer",
      getUserPermissionGroups: "getUserPermissionGroups",
      deleteUserPermissionGroups: "deleteUserPermissionGroups",
    }),
    constructBreadCrumbs() {
      if (this.customer.id == undefined)
        this.getCustomer(this.user.customer_id);

      if (this.$route.params.company_user_id) {
        this.bread_crumbs.push(
          {
            text: "Customers",
            href: "/customers",
          },
          {
            text: this.customer.customer_name,
            href: `/customers/${this.customer.id}/details`,
          },
          {
            text: this.user.name,
            disabled: true,
          }
        );
      } else {
        this.bread_crumbs.push(
          {
            text: "System Users",
            href: "/settings",
          },
          {
            text: this.user.name,
            disabled: true,
          }
        );
      }
    },
    execDeleteUser(user) {
      this.deleteUser(user.id)
        .then(() => {
          this.$notify({
            group: "success",
            title: "Success",
            text: "Deleted user successfully",
          });

          this.$router.push("/settings");
        })
        .catch(() => {
          this.$notify({
            group: "error",
            title: "Permission",
            text:
              "Ensure that you have the required priviledges for this action.",
          });
        });
    },
    execDeleteGroup(group) {
      this.deleteUserPermissionGroups(group.id)
        .then(() => {
          this.$notify({
            group: "success",
            title: "Success",
            text: "Deleted user group successfully",
          });

          this.getUserPermissionGroups(this.user_id);
        })
        .catch(() => {
          this.$notify({
            group: "error",
            title: "Permission",
            text:
              "Ensure that you have the required priviledges for this action.",
          });
        });
    },
    addMaterials() {
      console.log("Adding materials");
    },
    addUser() {
      console.log("Adding user");
    },
    showDelteDialog() {
      this.delete;
    },
    deleteGroup(group) {
      this.deleteModel = group;
      this.delete_permission_dialog = !this.delete_permission_dialog;
    },
  },
};
</script>

<style lang="css" scoped>
</style>