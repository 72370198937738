<template>
  <v-list dense>
    <v-subheader>SUB MENU</v-subheader>
    <v-list-item-group v-model="item" color="primary">
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        link
        :to="item.link"
        exact
        active-class="active"
        class="white"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "Submenu",
  props: {
    items: Array,
    param: String
  },
  data: () => ({
    item: 1,
    url_param: ""
  }),
  mounted() {
    if (this.param != undefined) {
      this.url_param = this.param;
    }
  }
};
</script>

<style scoped>
.v-subheader {
  margin-left: 8px;
}
</style>