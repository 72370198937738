<template>
  <!-- check main.js for custom component details -->
  <c-app-layout>
    <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

    <h1>Customers</h1>

    <v-card>
      <v-card-title>
        <h4></h4>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          class="mr-6 mt-6"
          label="Search customers"
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :search="search"
          :loading="loading == 'loading'"
          :headers="headers"
          :items="customers"
          class="linked-table"
          @click:row="handleClick"
        >
          <template v-slot:item.customer_name="{ item }">
            <router-link :to="'/customers/' + item.id + '/details'">
              {{ item.customer_name }}
            </router-link>
          </template>

          <!-- <template v-slot:item.action="{item}">
            <router-link :to="'/customers/' + item.id + '/details'">
              <v-icon class="primary--text">chevron_right</v-icon>
            </router-link>
          </template> -->

          <template v-slot:item.action="{ item }">
            <router-link :to="'/customers/' + item.id + '/details'">
              <v-icon class="primary--text">chevron_right</v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-btn
      @click="dialog = !dialog"
      absolute
      top
      right
      fab
      rounded
      color="primary"
      class="white--text fab-button"
    >
      <v-icon>add</v-icon>
    </v-btn>

    <div v-if="dialog">
      <CustomerDialog
        @closeDialog="closeDialog"
        :dialogModel="dialog"
      ></CustomerDialog>
    </div>
  </c-app-layout>
</template>

<script>
import CustomerDialog from "./sub-components/CustomerDialog";
import dialogMixins from "../../utils/mixins";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: { CustomerDialog },
  mixins: [dialogMixins],
  data: () => ({
    search: "",
    sub_menu: [],
    bread_crumbs: [
      {
        text: "Customers",
        href: "customers",
      },
    ],
    headers: [
      {
        text: "Customer Name",
        value: "customer_name",
      },
      {
        text: "Location",
        value: "location",
      },
      {
        text: "Country",
        value: "country",
      },
      {
        text: "Email Address",
        value: "email",
      },
      {
        text: "Phone Number",
        value: "phone_number",
      },
      {
        text: "More",
        value: "action",
      },
    ],
  }),
  computed: {
    ...mapState({
      customers: (state) => state.customersModule.customers,
      customer: (state) => state.customersModule.customer,
      loading: (state) => state.customersModule.status,
    }),
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    ...mapActions({
      getCustomers: "getCustomers",
    }),
    ...mapMutations({
      setCustomer: "setCustomer",
      setCustomerUsers: "setCustomerUsers",
    }),
    loadCustomer(item) {
      this.setCustomer(item);
    },
    handleClick(item) {
      this.$router.push("/customers/" + item.id + "/details");
    },
  },
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none !important;
}
</style>