<template>
  <div>
    <v-card flat class="pb-3">
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <p :class="features_selected_class + ' pl-3 pb-0 pt-0'">
            {{ features_selected }}
          </p>
          <v-layout row wrap>
            <!-- source -->
            <v-flex xs6>
              <v-select
                class="pl-2 pr-2 pb-3"
                dense
                v-model="from_feature"
                :items="filtered_features"
                item-text="text"
                item-value="value"
                label="From Feature"
              ></v-select>
            </v-flex>

            <!-- destination -->
            <v-flex xs6>
              <v-select
                class="pl-2 pr-2 pb-3"
                dense
                v-model="to_feature"
                :items="filtered_features"
                item-text="text"
                item-value="value"
                label="To Feature"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn class="pl-3 ml-4" @click="clearSelection">Clear Selection</v-btn>
        <v-btn
          class="pl-3 ml-4"
          color="success"
          :disabled="!valid"
          :loading="loading"
          @click="makeConnection"
          >{{ dialog_button_text }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import dialogMixins from "../../../../utils/mixins";
import GeoJSON from "ol/format/GeoJSON";
import { FEATURE_DUCT } from "../../../../utils/feature_constants";
import {
  getFeatureById,
  getDerivedName,
  mergeLineFeatures,
} from "../../../../utils/map_functions";

export default {
  name: "MergeManagerComponent",
  mixins: [dialogMixins],
  props: {
    features: {
      type: Array,
    },
    vectorSources: {
      type: Array,
    },
    previous_connection: {
      type: Object,
    },
  },
  components: {},
  data: () => ({
    loading: false,
    dialog_button_text: "Merge",
    valid: true,
    status: "",
    to_feature: null,
    from_feature: null,
    geoJSON: null,
  }),
  mounted() {
    this.geoJSON = new GeoJSON();
  },
  watch: {
    connections_exist: function (val) {
      if (val) this.dialog = !this.dialog;
    },
    from_feature: function (val) {
      let selectedFeature = getFeatureById(val, this.vectorSources);
      this.$emit("highlightFromSegment", selectedFeature);
    },
    to_feature: function (val) {
      let selectedFeature = getFeatureById(val, this.vectorSources);
      this.$emit("highlightToSegment", selectedFeature);
    },
  },
  computed: {
    features_selected: function () {
      if (this.features.length == 0) return "No features selected";
      return `${this.features.length} fearures selected`;
    },
    features_selected_class: function () {
      if (this.features.length == 0) return "error--text";
      return "primary--text";
    },
    filtered_features: function () {
      let filteredList = [];
      let validTypes = [FEATURE_DUCT];

      this.features.forEach((feature) => {
        if (validTypes.includes(feature.get("type"))) {
          filteredList.push({
            text: getDerivedName(feature, this.vectorSources),
            value: feature.getId(),
          });
        }
      });

      return filteredList;
    },
  },
  methods: {
    makeConnection() {
      this.closeDialog();

      if (this.$refs.form.validate()) {
        this.loading = true;
        let fromFeature = getFeatureById(this.from_feature, this.vectorSources);
        let toFeature = getFeatureById(this.to_feature, this.vectorSources);

        let mergedFeature = mergeLineFeatures(fromFeature, toFeature);
        if (mergedFeature) {
          // set merged feature to the type of any features being merged
          mergedFeature.set("type", toFeature.get("type"));

          let mergeObj = {};
          mergeObj.from = fromFeature;
          mergeObj.to = toFeature;
          mergeObj.merged = mergedFeature;

          this.$emit("mergedFeatures", mergeObj);
        } else {
          this.displayErrorAlert(
            "Merge failed. Contact your systems admin for help."
          );
        }
      }
    },

    clearSelection() {
      this.$emit("refreshComponents");
    },

    displaySuccessAlert(message) {
      this.$notify({
        group: "success",
        title: "",
        text: message,
      });
    },

    displayErrorAlert(message) {
      this.$notify({
        group: "error",
        title: "",
        text: message,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>