<template>
  <v-app app>
    <router-view></router-view>

    <notifications group="success" :position="position" :duration="duration">
      <template slot="body" slot-scope="props">
        <div class="custom-template success-notif">
          <div class="custom-template-icon">
            <v-icon large class="green--text">info</v-icon>
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">{{ props.item.title }}</div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div class="custom-template-close" @click="props.close">
            <v-icon>close</v-icon>
          </div>
        </div>
      </template>
    </notifications>

    <notifications group="info" :position="position" :duration="duration">
      <template slot="body" slot-scope="props">
        <div class="custom-template primary-notif">
          <div class="custom-template-icon">
            <v-icon large color="#004085">info</v-icon>
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">{{ props.item.title }}</div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div class="custom-template-close" @click="props.close">
            <v-icon>close</v-icon>
          </div>
        </div>
      </template>
    </notifications>

    <notifications group="warning" :position="position" :duration="duration">
      <template slot="body" slot-scope="props">
        <div class="custom-template warning-notif">
          <div class="custom-template-icon">
            <v-icon large color="#856404">info</v-icon>
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">{{ props.item.title }}</div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div class="custom-template-close" @click="props.close">
            <v-icon>close</v-icon>
          </div>
        </div>
      </template>
    </notifications>

    <notifications group="error" :position="position" :duration="duration">
      <template slot="body" slot-scope="props">
        <div class="custom-template error-notif">
          <div class="custom-template-icon">
            <v-icon large color="#721c24">info</v-icon>
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">{{ props.item.title }}</div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div class="custom-template-close" @click="props.close">
            <v-icon>close</v-icon>
          </div>
        </div>
      </template>
    </notifications>

    <notifications group="primary" :position="position" :duration="duration">
      <template slot="body" slot-scope="props">
        <div class="custom-template primary-notif">
          <div class="custom-template-icon">
            <v-icon large color="#004085">info</v-icon>
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">{{ props.item.title }}</div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div class="custom-template-close" @click="props.close">
            <v-icon>close</v-icon>
          </div>
        </div>
      </template>
    </notifications>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    duration: 5000,
    position: "bottom center",
  }),
};
</script>
<style lang="scss">
.vue-notification-group {
  width: auto !important;
  max-width: 500px;
}
.success-notif {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
  border-radius: 5px;
}

.info-notif {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.warning-notif {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.error-notif {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.primary-notif {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.notification.n-light {
  margin: 10px;
  border-radius: 3px;
  font-size: 14px;
  padding: 10px 20px;
  .notification-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #2589f3;
  }
}
.custom-template {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 14px;
  margin: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  width: auto;
  &,
  & > div {
    box-sizing: border-box;
  }
  .custom-template-icon {
    padding: 0 15px;
  }
  .custom-template-close {
    padding: 0 20px;
    font-size: 16px;
    color: #fff;
    opacity: 0.2;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .custom-template-content {
    padding: 10px;
    .custom-template-title {
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
.v-fade-left-enter-active,
.v-fade-left-leave-active,
.v-fade-left-move {
  transition: all 0.5s;
}
.v-fade-left-enter,
.v-fade-left-leave-to {
  opacity: 0;
  transform: translateX(-500px) scale(0.2);
}
</style>
