/*
 * Site Icons
 */

import config from "../config";

let baseIconURL = `${config.base_url}${config.frontend_service_port}/features/`;

// let iconURL = "https://storage.googleapis.com/soliton/icons/";
let iconURL = `${config.base_url}${config.frontend_service_port}/icons/`;

let suffix = "-proposed";

export let nuiIcon = baseIconURL + "niu.png";

export let onuIcon = baseIconURL + "onu_map.png";

export let onuSelectedIcon = baseIconURL + "onu_map.png";

export let rackIcon = baseIconURL + "rack_map.png";

export let rackSelectedIcon = baseIconURL + "rack_map.png";

export let fdpIcon = baseIconURL + "fdp_map.png";

export let fdpSelectedIcon = baseIconURL + "fdp_map.png";

export let accessPointIcon = baseIconURL + "access_point_map.png";

export let splitterIcon = baseIconURL + "splitter_map.png";

export let splitterSelectedIcon = baseIconURL + "splitter_selected.png";

export let patchPanelIcon = baseIconURL + "odf_map.png";

export let patchPanelSelectedIcon = baseIconURL + "odf_selected.png";

export let buildingIcon = baseIconURL + "building.png";

export let buildingSelectedIcon = baseIconURL + "building_selected.png";

export let manholeIcon = baseIconURL + "manhole.png";

export let manholeSelectedIcon = baseIconURL + "manhole_selected.png";

export let handholeIcon = baseIconURL + "handhole.png";

export let handholeSelectedIcon = baseIconURL + "handhole_selected.png";

export let cabinetIcon = `${iconURL}icon-cabinet.png`;

export let proposedCabinetIcon = `${iconURL}icon-cabinet${suffix}.png`;

export let altManholeIcon = `${iconURL}icon-manhole.png`;

export let proposedAltManholeIcon = `${iconURL}icon-manhole${suffix}.png`;

export let odfIcon = `${iconURL}icon-odf.png`;

export let proposedOdfIcon = `${iconURL}icon-odf${suffix}.png`;

export let redArrowIcon = `${iconURL}red-arrow.png`;

export let cabinetSelectedIcon = baseIconURL + "cabinet_map.png";

export let poleIcon = baseIconURL + "pole_map.png";

export let poleSelectedIcon = baseIconURL + "pole_map.png";

export let spliceClosureIcon = baseIconURL + "closure.png";

export let schematicsClosureIcon =
  baseIconURL + "splice_closure_schematics.png";

export let spliceClosureSelectedIcon = baseIconURL + "closure.png";

export let siteIcon = baseIconURL + "site_map.png";

export let siteSelectedIcon = baseIconURL + "site_map.png";

export let faultIcon = baseIconURL + "fault_map.png";

export let landMarkIcon = baseIconURL + "land_mark_map.png";

export let myLocationIcon = baseIconURL + "my_location_map.png";
