/*
 * Module for routine mapping functions
 */

/* eslint-disable no-unused-vars */

import Overlay from "ol/Overlay";
import {
  FEATURE_CLOSURE,
  FEATURE_MANHOLE,
  FEATURE_HANDHOLE,
  FEATURE_POLE,
  FEATURE_BUILDING,
  FEATURE_CABINET,
  FEATURE_DUCT,
  FEATURE_ODF,
  FEATURE_SPLITTER,
  FEATURE_FIBER_CABLE,
  FEATURE_SITE,
  BUILDING_FEATURES,
  SITE_FEATURES,
  POLE_FEATURES,
  ACCESS_POINT_FEATURES,
  CABINET_FEATURES,
  FEATURE_FDP,
  FIBER_CABLE_FEATURES,
  DUCT_FEATURES,
  FEATURE_FACE_PLATE,
  FEATURE_ONT,
  FEATURE_MEASUREMENT_AREA,
  FEATURE_MEASUREMENT_LENGTH,
  ROAD_CROSSING_HOOK,
  POLE_DUCT,
  POLE_FIBER_CABLE,
} from "./feature_constants";
import {
  lineSlice,
  lineString as turfLineString,
  point as turfPoint,
  length as turfLength,
  lineOffset,
  lineSliceAlong,
  lineIntersect,
  lineSplit,
  booleanPointOnLine,
  lineString,
  point,
  pointOnFeature,
  distance as turfDistance,
  pointToLineDistance,
  lineDistance,
  along,
} from "@turf/turf";
import GeoJSON from "ol/format/GeoJSON";
import LineString from "ol/geom/LineString";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import { getFeatureProps } from "./feature_properties";

/**
 * Determines for a given cable if we are at its start or its end by examining the cable coordinates with the
 * current cordinates
 *
 * @param currentCoordinates Point, cableCoordinates Points Array
 *
 */
export function isStartOrEndofCable(currentCoordinates, cableCoordinates) {
  // conclude that we're at the start/end of a cable if our coordinates are 10 meteres or less apart
  var DISTANCE_THRESHOLD = 10;

  var start = cableCoordinates[0];
  var end = cableCoordinates[cableCoordinates.length - 1];

  var distanceX_start = Math.abs(start[0] - currentCoordinates[0]);
  var distanceY_start = Math.abs(start[1] - currentCoordinates[1]);
  var distanceStart = Math.sqrt(
    Math.pow(distanceX_start, 2) + Math.pow(distanceY_start, 2)
  );

  var distanceX_end = Math.abs(end[0] - currentCoordinates[0]);
  var distanceY_end = Math.abs(end[1] - currentCoordinates[1]);
  var distanceEnd = Math.sqrt(
    Math.pow(distanceX_end, 2) + Math.pow(distanceY_end, 2)
  );

  let isAtStart = distanceStart <= DISTANCE_THRESHOLD;
  let isAtEnd = distanceEnd <= DISTANCE_THRESHOLD;

  var startOrEnd = false;
  if (distanceStart <= DISTANCE_THRESHOLD || distanceEnd <= DISTANCE_THRESHOLD)
    startOrEnd = true;

  return startOrEnd;
}

export function getFiberColor(fiberNumber, colorsObject) {
  let colorIndex = parseInt(fiberNumber) % 12;
  let index = colorIndex > 0 ? colorIndex : 12;

  return colorsObject[`${index}`];
}

function isCableStart(currentCoordinates, cableCoordinates) {
  var startCoordinates = cableCoordinates[0];
  return currentCoordinates == startCoordinates;
}

function isCableEnd(currentCoordinates, cableCoordinates) {
  var endCoordinates = cableCoordinates[cableCoordinates.length - 1];
  return currentCoordinates == endCoordinates;
}

export function getDragSelectedFeatures(extent, vectorSources) {
  let skipFeatureTypes = [FEATURE_MEASUREMENT_AREA, FEATURE_MEASUREMENT_LENGTH];

  let selectedFeatures = [];
  vectorSources.forEach((vectorsource) => {
    vectorsource.forEachFeatureIntersectingExtent(extent, function(feature) {
      if (
        feature.get("type") &&
        !skipFeatureTypes.includes(feature.get("type"))
      )
        selectedFeatures.push(feature);
    });
  });

  return selectedFeatures;
}

/**
 * Format length output.
 * @param {module:ol/geom/LineString~LineString} line The line.
 * @return {string} The formatted length.
 */
export var formatLength = function(length) {
  // let projection = 'EPSG:4326'
  // var length = getLength( line, projection );
  var output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " " + "km";
  } else {
    output = Math.round(length * 100) / 100 + " " + "m";
  }

  return output;
};

/**
 * Format area output.
 * @param {module:ol/geom/Polygon~Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
export var formatArea = function(area) {
  // var area = getArea( polygon );
  var output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
  } else {
    output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
  }

  return output;
};

/**
 * Creates a new measure tooltip
 */
export function createMeasureOverlay(measureTooltipElement) {
  let measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: "bottom-center",
  });

  return measureTooltip;
}

export function hasPropertiesTab(featureType) {
  return featureType != undefined;
}

export function hasAttachementsTab(featureType) {
  return featureType != undefined;
}

export function hasServicesTab(featureType) {
  let features = [
    FEATURE_MANHOLE,
    FEATURE_HANDHOLE,
    FEATURE_POLE,
    FEATURE_CABINET,
    FEATURE_FIBER_CABLE,
    FEATURE_DUCT,
    FEATURE_SPLITTER,
    FEATURE_CLOSURE,
  ];
  return features.includes(featureType);
}

export function hasCableSlacksTab(featureType) {
  let features = [FEATURE_MANHOLE, FEATURE_HANDHOLE, FEATURE_POLE, FEATURE_ODF];
  return features.includes(featureType);
}

export function hasMonitoringTab(featureType) {
  let features = [FEATURE_ODF, FEATURE_FIBER_CABLE];
  return features.includes(featureType);
}

export function hasSpliceSchematicsTab(featureType) {
  let features = [
    FEATURE_MANHOLE,
    FEATURE_HANDHOLE,
    FEATURE_POLE,
    FEATURE_BUILDING,
    FEATURE_CABINET,
  ];
  return features.includes(featureType);
}

export function hasSplicesTab(featureType) {
  let features = [
    FEATURE_MANHOLE,
    FEATURE_HANDHOLE,
    FEATURE_POLE,
    FEATURE_BUILDING,
    FEATURE_CABINET,
  ];
  return features.includes(featureType);
}

export function hasTrenchSectionsTab(featureType) {
  let features = [];
  return features.includes(featureType);
}

export function hasAccessoriesTab(featureType) {
  let features = [FEATURE_POLE];
  return features.includes(featureType);
}

export function hasCableManagementTab(featureType) {
  let features = [FEATURE_DUCT];
  return features.includes(featureType);
}

export function hasRoadCrossingsTab(featureType) {
  let features = [FEATURE_DUCT];
  return features.includes(featureType);
}

// export function hasUtilizationTab( featureType ) {
//     let features = [ FEATURE_DUCT, FEATURE_ODF, FEATURE_SPLITTER, FEATURE_CLOSURE, FEATURE_FIBER_CABLE ]
//     return features.includes( featureType )
// }

export function hasUtilizationTab(featureType) {
  let features = [
    FEATURE_ODF,
    FEATURE_SPLITTER,
    FEATURE_FIBER_CABLE,
    FEATURE_FDP,
  ];
  return features.includes(featureType);
}

export function hasContentsTab(featureType) {
  let features = [
    FEATURE_CABINET,
    FEATURE_MANHOLE,
    FEATURE_BUILDING,
    FEATURE_SITE,
  ];
  return features.includes(featureType);
}

export function getCompositionURLKey(feature) {
  if (feature.get("type") == FEATURE_BUILDING) return BUILDING_FEATURES;
  if (feature.get("type") == FEATURE_SITE) return SITE_FEATURES;
  if (feature.get("type") == FEATURE_POLE) return POLE_FEATURES;
  if (feature.get("type") == FEATURE_MANHOLE) return ACCESS_POINT_FEATURES;
  if (feature.get("type") == FEATURE_CABINET) return CABINET_FEATURES;
  if (feature.get("type") == FEATURE_FIBER_CABLE) return FIBER_CABLE_FEATURES;
  if (feature.get("type") == FEATURE_DUCT) return DUCT_FEATURES;

  return "";
}

export function getSnapFeatureJSON(feature) {
  if (feature.get("type") == FEATURE_BUILDING)
    return { building: feature.get("pk") };
  if (feature.get("type") == FEATURE_POLE) return { pole: feature.get("pk") };
  if (feature.get("type") == FEATURE_CABINET)
    return { cabinet: feature.get("pk") };
  if (feature.get("type") == FEATURE_SITE) return { site: feature.get("pk") };
  if (feature.get("type") == FEATURE_ODF) return { odf: feature.get("pk") };
  if (feature.get("type") == FEATURE_CLOSURE)
    return { closure: feature.get("pk") };
  if (feature.get("type") == FEATURE_SPLITTER)
    return { splitter: feature.get("pk") };
  if (feature.get("type") == FEATURE_FDP) return { fdp: feature.get("pk") };
  if (feature.get("type") == FEATURE_MANHOLE)
    return { access_point: feature.get("pk") };
  if (feature.get("type") == FEATURE_FIBER_CABLE)
    return { fiber_cable: feature.get("pk") };
  if (feature.get("type") == FEATURE_DUCT) return { duct: feature.get("pk") };
  if (feature.get("type") == FEATURE_FACE_PLATE)
    return { face_plate: feature.get("pk") };
  if (feature.get("type") == FEATURE_ONT) return { ont: feature.get("pk") };
}

/**
 * This method uses bruteforce search to find a feature by ID by checking for the
 * ID in all the vector sources
 */
export function getFeatureById(featureId, vectorSources) {
  var feature = null;

  if (vectorSources.length != undefined && featureId) {
    try {
      vectorSources.forEach((vectorsource) => {
        if (
          vectorsource.getFeatureById(featureId) != undefined ||
          vectorsource.getFeatureById(featureId) != null
        ) {
          feature = vectorsource.getFeatureById(featureId);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  return feature;
}

export function getFeaturesByType(type, vectorSources) {
  var features = [];

  if (vectorSources.length != undefined) {
    try {
      vectorSources.forEach((vectorsource) => {
        let _features = vectorsource.getFeatures();
        if (_features && _features.length > 0) {
          if (_features[0].get("type") == type) {
            features = _features;
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  return features;
}

/**
 * Get location features
 * This function takes a feature {LineString, Point} and a Vectorsource object and attempts to find location features
 * at the start/end of a line string, or the location features at the same coordinates as the point object
 *
 * returns an object with keys: start: {loc features at start}, end: {loc features at end}, loc: {loc features with same coordinates}
 *
 */
export function getLocationFeatures(feature, vectorSources) {
  var locFeatureTypes = [
    FEATURE_POLE,
    FEATURE_MANHOLE,
    FEATURE_HANDHOLE,
    FEATURE_BUILDING,
    FEATURE_SITE,
    FEATURE_CABINET,
    ROAD_CROSSING_HOOK,
  ];

  var closestFeatures = [];
  var coordinates = [];

  if (feature instanceof Point) {
    coordinates.push(feature.getGeometry().getCoordinates());
  } else {
    coordinates.push(feature.getGeometry().getFirstCoordinate());
    coordinates.push(feature.getGeometry().getLastCoordinate());
  }

  coordinates.forEach((coord) => {
    closestFeatures.push(
      ...getFeatureClosestToCoordinates(
        coord,
        vectorSources,
        locFeatureTypes,
        coordinates
      )
    );
  });

  return closestFeatures;
}

export function getFeaturesOnLineFeature(lineFeature, features) {
  var _features = [];
  var turfLine = lineString(lineFeature.getGeometry().getCoordinates());
  features.forEach((feature) => {
    var turfPt;
    if (feature.getGeometry() instanceof LineString) {
      turfPt = pointOnFeature(
        lineString(feature.getGeometry().getCoordinates())
      );
    } else {
      turfPt = point(feature.getGeometry().getCoordinates());
    }

    if (booleanPointOnLine(turfPt, turfLine)) {
      _features.push(feature);
    }
  });

  return _features;
}

export function getFeatureClosestToCoordinates(
  coordinate,
  vectorSources,
  type = undefined,
  extentCoordinates = undefined
) {
  var features = [];
  if (vectorSources.length != undefined) {
    try {
      vectorSources.forEach((vectorsource) => {
        /**
         * TO DO:
         * refine and optimize
         */
        let vsFeatures = vectorsource.getFeatures();
        let featureAtCoord = getFeatureAtCoordinates(coordinate, vsFeatures);
        if (featureAtCoord) {
          if (!type || type.includes(featureAtCoord.get("type"))) {
            features.push(featureAtCoord);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  return features;
}

export function getFeatureAtCoordinates(
  coordinates,
  features,
  options = undefined
) {
  var featureAtCoordinates;

  // default distance between features
  var distanceMeters = 5;

  if (options && options.distance) {
    distanceMeters = parseFloat(options.distance);
  }

  features.forEach((feature) => {
    // only process point features
    if (feature.getGeometry() instanceof Point) {
      let featureCoordinates = feature.getGeometry().getCoordinates();

      /**
       * Calculate the distance between the points
       * Return the feature if it is within 5 meters to the coordinate
       */

      let fromPoint = turfPoint(coordinates);
      let toPoint = turfPoint(featureCoordinates);

      const distanceInKM = turfDistance(fromPoint, toPoint);
      const distInMeters = distanceInKM * 1000;
      if (distInMeters <= distanceMeters) {
        featureAtCoordinates = feature;
      }
    }
  });

  return featureAtCoordinates;
}

export function getDistanceBetweenPoints(
  fromCoord,
  toCoord,
  lineFeature = false
) {
  let fromPoint = turfPoint(fromCoord);
  let toPoint = turfPoint(toCoord);
  var line;
  if (lineFeature) {
    line = turfLineString(lineFeature.getGeometry().getCoordinates());
  }
  var distanceInKM;
  if (line) {
    const slicedLine = lineSlice(fromPoint, toPoint, line);
    distanceInKM = lineDistance(slicedLine, { units: "kilometers" });
  } else {
    distanceInKM = turfDistance(fromPoint, toPoint);
  }

  var distInMeters = distanceInKM * 1000;

  return distInMeters;
}

export function getPointOnLineString(lineFeature, distanceAlongLine) {
  var coordinate = lineFeature.getGeometry().getFirstCoordinate();
  var line = turfLineString(lineFeature.getGeometry().getCoordinates());
  var pointOnLine = along(line, distanceAlongLine, { units: "meters" });

  return pointOnLine;
}

export function getClosuresOnFiberCable(fiberCable, closures) {
  var _closures = [];
  var turfLine = lineString(fiberCable.getGeometry().getCoordinates());
  closures.forEach((cls) => {
    let turfPt = point(cls.getGeometry().getCoordinates());
    if (booleanPointOnLine(turfPt, turfLine)) {
      _closures.push(cls);
    }
  });

  return _closures;
}

export function getFeaturesOnFiberCable(fiberCable, features) {
  var _features = [];
  var turfLine = lineString(fiberCable.getGeometry().getCoordinates());
  var maxDistanceMeters = 5;
  features.forEach((feature) => {
    let turfPt = point(feature.getGeometry().getCoordinates());
    if (booleanPointOnLine(turfPt, turfLine)) {
      _features.push(feature);
    } else {
      // check if this point is at most 1 meter away from fiber cable
      let distanceFromCable = pointToLineDistance(turfPt, turfLine);
      if (distanceFromCable) {
        if (distanceFromCable * 1000 <= maxDistanceMeters) {
          _features.push(feature);
        }
      }
    }
  });

  return _features;
}

/**
 * This function takes a line feature and returns 2 line features with the same configutration, split into 2 parts, using
 * the splitter as the pivot
 *
 * @param {Fiber Cable/ Trench} feature
 * @param {Manhole/Handhole} splitter
 */
export function splitLineFeature(feature, splitter) {
  let props = feature.getProperties();
  delete props.geometry;
  if (props.start) delete props.start;
  if (props.end) delete props.end;

  let features = splitLineString(
    feature.getGeometry().getCoordinates(),
    splitter.getGeometry().getCoordinates()
  );
  if (features) features.map((f) => f.setProperties(props));

  return features;
}

/**
 * This function takes a fiber cable and returns 2 cables with the same configutration, split into 2 parts, using
 * the splitter as the pivot
 *
 * @param {Fiber Cable} feature
 * @param {Manhole/Handhole} splitter
 */
export function splitFiberCable(feature, splitter) {
  let props = feature.getProperties();
  delete props.geometry;

  let features = splitLineString(
    feature.getGeometry().getCoordinates(),
    splitter.getGeometry().getCoordinates()
  );
  if (features) features.map((f) => f.setProperties(props));

  return features;
}

/**
 * This function takes a trench and returns 2 trenches with the same configutration, split into 2 parts, using
 * the splitter as the pivot
 *
 * @param {Trench} feature
 * @param {Manhole/Handhole} splitter
 */
export function splitTrench(feature, splitter) {
  let props = feature.getProperties();
  delete props.geometry;

  let trenches = splitLineString(
    feature.getGeometry().getCoordinates(),
    splitter.getGeometry().getCoordinates()
  );
  if (trenches) trenches.map((trench) => trench.setProperties(props));

  return trenches;
}

function splitLineString(lineCoordinates, splitterCoordinates) {
  let line = turfLineString(lineCoordinates);
  let splitter = turfPoint(splitterCoordinates);

  let splitFeatures = lineSplit(line, splitter);
  if (splitFeatures.features.length == 0) return;

  let geoJSON = new GeoJSON();
  return splitFeatures.features.map((feature) => geoJSON.readFeature(feature));
}

export function splitLargeDuct(duct, ductFeatures) {
  let geoJSON = new GeoJSON();
  let smallerDucts = [];

  const ductCoordinates = duct.getGeometry().getCoordinates();
  const lastDuctCoordinate = ductCoordinates[ductCoordinates.length - 1];

  ductCoordinates.forEach((firstFeatureCoordinates) => {
    let smallDuctCoordinates = [];
    smallDuctCoordinates.push(firstFeatureCoordinates);
    let firstFeature = getFeatureAtCoordinates(
      firstFeatureCoordinates,
      ductFeatures,
      { distance: 0.5 }
    );
    var secondFeature;

    if (firstFeature) {
      let restOfDuct = lineSlice(
        turfPoint(firstFeatureCoordinates),
        turfPoint(lastDuctCoordinate),
        turfLineString(ductCoordinates)
      );

      let restOfCoordinates = geoJSON
        .readFeature(restOfDuct)
        .getGeometry()
        .getCoordinates();
      var ctr = 1;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (ctr == restOfCoordinates.length - 1) break;
        smallDuctCoordinates.push(restOfCoordinates[ctr]);
        secondFeature = getFeatureAtCoordinates(
          restOfCoordinates[ctr],
          ductFeatures,
          { distance: 0.5 }
        );
        if (secondFeature) {
          break;
        }

        ctr++;
      }

      if (firstFeature && secondFeature) {
        smallerDucts.push({
          from: firstFeature,
          to: secondFeature,
          duct: turfLineString(smallDuctCoordinates),
        });
      }
    }
  });

  return smallerDucts;
}

export function retrieveKeyValObj(value, keyValueArray) {
  if (!Array.isArray(keyValueArray)) return undefined;

  return keyValueArray.find((obj) => obj.value == value);
}

export function getFileExtension(fileName) {
  var extension = fileName.split(".")[fileName.split(".").length - 1];
  return extension;
}

export function getNameFromType(feature) {
  var featureType;
  var featureName;

  if (feature instanceof Object) {
    // fallback name
    featureType = feature.get("type");

    if ((feature && feature.get("name")) || feature.get("_name")) {
      featureName = feature.get("name")
        ? feature.get("name")
        : feature.get("_name");
    }
  }

  if (!featureName) {
    // we passed in a feature type
    if (!featureType) featureType = feature;

    let featureAttributes = getFeatureProps(featureType);
    if (featureAttributes) {
      featureName = featureAttributes["name"]
        ? featureAttributes["name"]
        : featureAttributes["_name"];

      // special case: check for Manholes/Handholes
      if (featureType == FEATURE_HANDHOLE || featureType == FEATURE_MANHOLE)
        featureName = featureAttributes["f_name"];
    }
  }

  if (!featureName) {
    featureName = `${featureType[0].toUpperCase()}${featureType
      .slice(1)
      .toLowerCase()}`;
  }

  // remove hyphens from name
  featureName = featureName.replaceAll("_", " ");

  return featureName;
}

export function getDerivedName(feature, vectorSources) {
  let lineFeatures = [
    FEATURE_DUCT,
    FEATURE_FIBER_CABLE,
    POLE_DUCT,
    POLE_FIBER_CABLE,
  ];
  let namedPointFeatures = [FEATURE_ODF, FEATURE_FACE_PLATE, FEATURE_SPLITTER];

  if (!feature || !feature.get("type")) return "";

  var featureName = getNameFromType(feature);

  if (lineFeatures.includes(feature.get("type"))) {
    let locFeatures = getLocationFeatures(feature, vectorSources);
    if (locFeatures && locFeatures.length == 2) {
      let startFeature = getFeatureById(locFeatures[0].getId(), vectorSources);
      let endFeature = getFeatureById(locFeatures[1].getId(), vectorSources);
      if (startFeature && endFeature)
        featureName = `${getNameFromType(startFeature)} - ${getNameFromType(
          endFeature
        )}`;
    }
  }

  if (namedPointFeatures.includes(feature.get("type"))) {
    let locFeatures = getLocationFeatures(feature, vectorSources);
    if (locFeatures && locFeatures.length > 0) {
      let locFeature = getFeatureById(locFeatures[0].getId(), vectorSources);
      if (locFeature)
        featureName = `${getNameFromType(locFeature)} - ${featureName}`;
    }
  }

  return featureName;
}

export function getHighlightedLineFeature(lineString, featureId, featureType) {
  var feature;
  let geoJSON = new GeoJSON();
  if (lineString.segment) feature = geoJSON.readFeature(lineString.segment);
  else {
    feature = geoJSON.readFeature(geoJSON.writeFeature(lineString));
  }

  feature.setId(featureId);
  feature.set("type", featureType);

  return feature;
}

export function getHighlightedFeature(
  highlightedFeature,
  featureId,
  featureType
) {
  var feature;
  let geoJSON = new GeoJSON();
  if (highlightedFeature.segment)
    feature = geoJSON.readFeature(highlightedFeature.segment);
  else {
    feature = geoJSON.readFeature(geoJSON.writeFeature(highlightedFeature));
  }

  feature.setId(featureId);
  feature.set("type", featureType);

  return feature;
}

export function getFeatureCopy(featureToCopy) {
  var feature;
  let geoJSON = new GeoJSON();
  feature = geoJSON.readFeature(geoJSON.writeFeature(featureToCopy));
  return feature;
}

export function getCoordinates(feature) {
  if (feature && feature.getGeometry())
    return feature.getGeometry().getCoordinates();

  return;
}

export function mergeLineFeatures(feature1, feature2) {
  if (
    feature1.getGeometry() instanceof LineString &&
    feature2.getGeometry() instanceof LineString
  ) {
    let geoJSON = new GeoJSON();

    /**
     * To merge ducts/cables A and B, we merge them where they are closst to each other
     * If the last coord of A is closest to the start coord of B, then we merge B to A since B is a continuation of A
     *
     * Otherwise, we merge A to B because we take it that the end coord of B is closer to the start coord of A
     *
     * The maximum allowed distance between the start/end coordinates is maxCoordDistanceMeters
     */
    let maxCoordDistanceMeters = 6;

    var mergedCoords;
    let coordList1 = feature1.getGeometry().getCoordinates();
    let coordList2 = feature2.getGeometry().getCoordinates();

    // check for linestring coordinate order correctness when merging linestrings
    let f1_FirstCoord = feature1.getGeometry().getFirstCoordinate();
    let f1_LastCoord = feature1.getGeometry().getLastCoordinate();
    let f2_FirstCoord = feature2.getGeometry().getFirstCoordinate();
    let f2_LastCoord = feature2.getGeometry().getLastCoordinate();

    if (
      isCoordinatesEqual(f1_FirstCoord, f2_LastCoord, maxCoordDistanceMeters)
    ) {
      // append feature 1 coordinates to feature 2, i.e. f2 + f1 (an unusual order)
      mergedCoords = coordList2.concat(coordList1);
    }

    if (
      isCoordinatesEqual(f2_FirstCoord, f1_LastCoord, maxCoordDistanceMeters)
    ) {
      // append feature 1 coordinates to feature 2, i.e. f2 + f1 (an unusual order)
      mergedCoords = coordList1.concat(coordList2);
    }

    if (mergedCoords) return geoJSON.readFeature(turfLineString(mergedCoords));
  }

  return;
}

export function convertKeyToName(key) {
  return key
    .split("_")
    .join(" ")
    .toUpperCase();
}

export function calculateLengthProperty(lineFeature, includeSlack = true) {
  let slackLength = lineFeature.get("slack_length")
    ? lineFeature.get("slack_length")
    : 0;

  if (lineFeature.getGeometry() instanceof LineString) {
    let geomFeature = turfLineString(
      lineFeature.getGeometry().getCoordinates()
    );

    // to 2 dp
    let lengthProp = turfLength(geomFeature, { units: "meters" }).toFixed(2);

    if (includeSlack) return parseFloat(lengthProp) + parseFloat(slackLength);

    return parseFloat(lengthProp);
  }

  // this could be a linestring but represented as a point feature
  let poleLineFeatures = ["POLE_DUCT", "POLE_FIBER_CABLE"];
  if (poleLineFeatures.includes(lineFeature.get("type"))) {
    // just return the calculated length property and any slack cable in case it was attached
    return (
      parseFloat(lineFeature.get("calculated_length")) + parseFloat(slackLength)
    );
  }

  return 0;
}

export function offsetLineFeature(
  lineFeature,
  otherPathFeature,
  location,
  offsetDistance = undefined
) {
  if (!location) return;

  if (!offsetDistance) offsetDistance = -0.5; // 0.5 meters offset by default and can be +ve or -ve depending on line intersections btn from_path, to_path

  var offsetPath = getOffsetLine(lineFeature, offsetDistance, location)
    .slicedLine;
  var reverseCoordinates = getOffsetLine(lineFeature, offsetDistance, location)
    .reverseCoordinates;
  var connectedOffsetPath = getOffsetLine(
    otherPathFeature,
    offsetDistance,
    location
  ).slicedLine;
  var splitter;

  // check if there is an intersection
  var intersection = lineIntersection(offsetPath, connectedOffsetPath);
  if (intersection.features.length == 0) {
    // if no intersection, change the offset distance sign
    offsetDistance = offsetDistance * -1;
    offsetPath = getOffsetLine(lineFeature, offsetDistance, location)
      .slicedLine;
    connectedOffsetPath = getOffsetLine(
      otherPathFeature,
      offsetDistance,
      location
    ).slicedLine;
    intersection = lineIntersection(offsetPath, connectedOffsetPath);
  }

  // if there is an intersection, get the longer line after splitting at the intersection point
  if (intersection.features.length > 0) {
    splitter = intersection.features[0];
    var split = lineSplit(offsetPath, splitter);
    if (split.features.length == 2) {
      // take the feature with the longer length
      if (turfLength(split.features[0]) > turfLength(split.features[1])) {
        offsetPath = split.features[0];
      } else {
        offsetPath = split.features[1];
      }
    }
  }

  let geometryCoordinates = offsetPath.geometry.coordinates;
  if (reverseCoordinates) {
    geometryCoordinates = geometryCoordinates.reverse();
  }

  let result = {};
  result.offsetFeature = new Feature(new LineString(geometryCoordinates));

  if (splitter) {
    result.intersection = new Feature(new Point(splitter.geometry.coordinates));
  }

  return result;
}

export function removeDuplicateCoordinates(featureCoordinates) {
  let cleanedCoordinates = [];
  if (Array.isArray(featureCoordinates)) {
    featureCoordinates.forEach((coord) => {
      let coordinateExists = cleanedCoordinates.find(
        (cleanCoord) => coord[0] == cleanCoord[0] && coord[1] == cleanCoord[1]
      );
      if (!coordinateExists) {
        cleanedCoordinates.push(coord);
      }
    });
  }

  return cleanedCoordinates;
}

export function getOffsetLineString(lineFeature, offsetDistance) {
  let lineCoords = lineFeature.getGeometry().getCoordinates();
  let cleanCoords = removeDuplicateCoordinates(lineCoords);
  let cleanLine = turfLineString(cleanCoords);
  let offsetLine = lineOffset(cleanLine, offsetDistance, { units: "meters" });
  let offsetLineFeature = new Feature(
    new LineString(offsetLine.geometry.coordinates)
  );

  return offsetLineFeature;
}

export function createFeatureFromCoordinates(
  coordinates,
  isLineString = false
) {
  console.log("coordinates", coordinates);
  if (isLineString) return new Feature(new LineString(coordinates));

  return new Feature(new Point(coordinates));
}

function getOffsetLine(lineFeature, offsetDistance, location) {
  let lineCoords = lineFeature.getGeometry().getCoordinates();

  // hack: remove duplicate coordinate
  lineCoords.pop();

  let lineLength = calculateLengthProperty(lineFeature);
  let metersToMiles = 1609.34;
  var lineOffsetStart, lineOffsetEnd;
  var offsetLength = 1;
  var offsetStartConstant = 0; // 0 meters from start/end of cable, depending on where the splice location is
  var reverseCoordinates = false;

  if (location) {
    let locationCoordinates = location.getGeometry().getCoordinates();
    if (
      lineCoords[0].includes(locationCoordinates[0]) &&
      lineCoords[0].includes(locationCoordinates[1])
    ) {
      // offset at the benigging of string
      lineOffsetStart = offsetStartConstant;
      lineOffsetEnd = offsetStartConstant + offsetLength;
    } else {
      //offset from the end of string, and reverse coordinates
      reverseCoordinates = true;
      lineOffsetStart = lineLength - (offsetStartConstant + offsetLength);
      lineOffsetEnd = lineLength - offsetStartConstant;
    }
  }

  let line = turfLineString(lineCoords);
  let offsetLine = lineOffset(line, offsetDistance, { units: "meters" });

  var slicedLine;

  try {
    slicedLine = lineSliceAlong(
      offsetLine,
      lineOffsetStart / metersToMiles,
      lineOffsetEnd / metersToMiles,
      { units: "miles" }
    );
    // slicedLine = lineSliceAlong( line, ( lineOffsetStart / metersToMiles ), ( lineOffsetEnd / metersToMiles ), { units: 'miles' } );
  } catch (e) {
    console.log("E", e);
    slicedLine = lineSliceAlong(
      line,
      lineOffsetStart / metersToMiles,
      lineOffsetEnd / metersToMiles,
      { units: "miles" }
    );
  }

  let obj = {};

  if (slicedLine) {
    obj.slicedLine = slicedLine;
    obj.reverseCoordinates = reverseCoordinates;
  }

  return obj;
}

function lineIntersection(line1, line2) {
  return lineIntersect(line1, line2);
}

export function getSpliceConnectionName(fibers) {
  var tubeNames = "";

  // create an array of tube starting index, ie tube1 starts with fiber 1, tube 2 starts with fiber 13, etc
  // returns an array in the form: [1, 13, 25, 37, ...]
  var tubesIndex = Array.from({ length: 12 }, (_, i) => i * 12 + 1);
  tubesIndex.forEach((tubeIndex, index) => {
    // construct all the fibers within a tube given the starting fiber number, which is the
    let tubeArray = Array.from({ length: 12 }, (_, i) => i + tubeIndex);
    let containsTube = tubeArray.every((fiberNumber) =>
      fibers.includes(fiberNumber)
    );
    if (containsTube) {
      // this means that the fibers array includes tube (index+1)

      if (tubeNames.length > 0) tubeNames += ", ";

      tubeNames += `T${index + 1}`;
    } else {
      // if it does not contain all the tube finers, append any fibers within this range that have been included
      // check if the fibers are sequential and return Fi - j or Fi, Fj, ...Fk for non-sequential fibers
      let rangeFibers = fibers.filter(
        (fiberNumber) =>
          fiberNumber >= tubeIndex && fiberNumber <= tubeIndex + 11
      );
      if (rangeFibers.length > 0) {
        if (tubeNames.length > 0) tubeNames += ", ";

        // check if range is sequential
        var fiberNames = ``,
          rangeIndex = 0;
        for (var i = 0; i < rangeFibers.length - 1; i++) {
          if (i + 1 <= rangeFibers.length - 1) {
            if (rangeFibers[i + 1] - rangeFibers[i] > 1) {
              // range broken, so record start index
              if (i == rangeIndex) fiberNames += `${rangeFibers[i]}, `;
              else
                fiberNames += `${rangeFibers[rangeIndex]} ${rangeFibers[i]}, `;
              rangeIndex = i + 1;
            }
          }
        }
        if (rangeIndex == rangeFibers.length - 1)
          fiberNames += `${rangeFibers[rangeIndex]}`;
        else
          fiberNames += `${rangeFibers[rangeIndex]} - ${
            rangeFibers[rangeFibers.length - 1]
          }`;

        tubeNames += `F ${fiberNames}`;
      }
    }
  });

  return tubeNames;
}

export function isCoordinatesEqual(
  sourceCoordinates,
  targetCoordinates,
  maxDistance = undefined
) {
  var maxDistanceMeters = 3;
  if (maxDistance) maxDistanceMeters = maxDistance;

  let fromPoint = turfPoint(sourceCoordinates);
  let toPoint = turfPoint(targetCoordinates);

  const distanceInKM = turfDistance(fromPoint, toPoint);
  const distInMeters = distanceInKM * 1000;

  return distInMeters <= maxDistanceMeters;
}

export function isExistingFeature(feature, currentJobId) {
  var isExisting = false;

  try {
    isExisting =
      parseInt(feature.get("job_id")) && feature.get("job_id") != currentJobId;
  } catch (e) {
    console.log(e);
  }

  return isExisting;
}
