<template>
  <v-dialog v-model="dialog" persistent width="550px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{
        dialog_title
      }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <!-- Upload Excel file for materials -->
            <v-row class="pa-4">
              <v-flex xs12 class="pt-4">
                <v-select
                  :items="upload_types"
                  item-text="text"
                  item-value="value"
                  v-model="upload_data.feature_type"
                  placeholder="Upload type"
                  label="Upload type"
                  :rules="rules.required_field"
                ></v-select>
              </v-flex>

              <v-flex xs12>
                <v-file-input
                  :rules="rules.required_field"
                  v-model="upload_file"
                  color="primary"
                  class="pt-4"
                  counter
                  label="Data File"
                  accept=".zip"
                  placeholder="Select Excel File"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="primary" dark label small>{{
                      text
                    }}</v-chip>
                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                      >+{{ files.length - 2 }} File(s)</span
                    >
                  </template>
                </v-file-input>
              </v-flex>

              <v-flex xs12>
                <v-checkbox
                  label="Replace existing data"
                  v-model="upload_data.replace_existing"
                ></v-checkbox>
              </v-flex>
            </v-row>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="loading"
          @click="importSelectedFeatures"
          >{{ dialog_button_text }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "MaterialsTypesDialog",
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    editModel: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
    }),
  },
  data: () => ({
    upload_types: [
      { text: "Counties", value: "COUNTY" },
      { text: "Buildings", value: "BUILDING" },
      { text: "Roads", value: "ROAD" },
    ],
    upload_type: 0,
    loading: false,
    dialog_title: "Upload Data Files",
    dialog_button_text: "Upload",
    valid: true,
    dialog: null,
    upload_file: null,
    upload_data: {},
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
  }),
  mounted() {
    this.dialog = this.dialogModel;
  },
  methods: {
    ...mapActions({
      importFeatures: "importFeatures",
    }),
    importSelectedFeatures() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        var formData = new FormData();
        if (this.upload_data.replace_existing)
          formData.append("replace_existing", "replace");

        formData.append("feature_type", this.upload_data.feature_type);
        formData.append("created_by", this.user.id);
        formData.append("file", this.upload_file);

        this.importFeatures(formData).then(() => {
          this.closeDialog();
        });
      }
    },
    resetFields() {
      this.upload_file = null;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();
      this.$emit("reloadData");
      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    editModel: function (val) {
      if (val.id != undefined) {
        this.upload_data = val;
      }
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.upload_data = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>