<template>
  <v-container
    align-center
    justify-center
    fill-height
    grid-list-md
    class="login-container-bg"
  >
    <v-row>
      <!-- <img src="../../assets/logo.png" class="app-logo" alt /> -->

      <v-layout
        class="resized-input-form"
        wrap
        align-center
        justify-center
        fill-height
      >
        <v-col class="col-3 light-border">
          <v-container fill-height justify-center>
            <v-card flat class style="width: 100%; text-align: center">
              <v-card-title style="width: 100%; text-align: center">
                <span class="headline pb-0 mb-0 login-header primary--text"
                  >Optics 360</span
                >

                <span class="login-header pt-4">Reset Password</span>

                <span
                  v-if="reset_success_message != ''"
                  class="login-header success white--text pl-3 pr-3 mt-4"
                  style="border-radius: 6px"
                >
                  <p style="font-size: 14px" class="ma-0">
                    {{ reset_success_message }}
                  </p>
                </span>

                <span
                  v-if="reset_failed_message != ''"
                  class="login-header error white--text pl-3 pr-3 mt-4"
                  style="border-radius: 6px"
                >
                  <p style="font-size: 14px" class="ma-0">
                    {{ reset_failed_message }}
                  </p>
                </span>
              </v-card-title>

              <v-card-text
                v-if="reset_failed_message == '' && reset_success_message == ''"
              >
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container class="txtfieldContainer">
                    <v-text-field
                      class="pt-3"
                      v-model="credentials.email"
                      type="email"
                      dense
                      outlined
                      label="Email address"
                      :rules="rules.email"
                    />
                  </v-container>

                  <v-btn
                    block
                    class="primary darken-2 white--text login-button"
                    :loading="authStatus == 'loading'"
                    :disabled="!valid"
                    @click="execPasswordReset"
                    type="button"
                    >Reset Password</v-btn
                  >
                </v-form>
              </v-card-text>

              <router-link to="/login" class="forgot-password-link"
                >Login instead</router-link
              >
            </v-card>
          </v-container>
        </v-col>
      </v-layout>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SetPasswordPage",
  methods: {
    ...mapActions({
      requestResetPassword: "initiateResetPassword",
    }),
    execPasswordReset() {
      if (this.$refs.form.validate()) {
        this.requestResetPassword(this.credentials)
          .then(() => {
            this.credentials = {};
            this.reset_success_message = "Success! Check your email";
          })
          .catch(() => {
            this.reset_failed_message = "Reset password failed";
          });
      }
    },
  },
  data: () => ({
    valid: false,
    credentials: {},
    rules: {
      email: [(v) => !!v || "An email is required"],
    },
    reset_success_message: "",
    reset_failed_message: "",
  }),
  computed: {
    ...mapState({
      authStatus: (state) => state.usersModule.status,
    }),
  },
};
</script>

<style scoped>
.light-border {
  border-radius: 3px;
}

.headline {
  font-size: 1.8rem !important;
  padding-bottom: 25px;
  font-family: "Ubuntu" !important;
  font-weight: bold;
}

.login-container-bg {
  max-width: initial;
  min-width: fit-content;
  background-size: cover;
}

.login-header {
  text-align: center;
  width: 100%;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.login-button {
  border-radius: 5px;
  margin-bottom: 25px;
}

.login-col {
  padding: 0;
  border-radius: 6px;
}

.login-form {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}

.login-col > * {
  background: #fff;
  position: relative;
}

.login-alert {
  position: absolute;
  width: 93%;
  padding-left: 15px;
  border-radius: 5px;
  top: -100px;
}

a.forgot-password-link {
  text-decoration: none;
  padding-top: 15px;
  margin-right: 25px;
}

.txtfieldContainer {
  padding: 0;
  padding-bottom: 15px;
}

.login-container-bg {
  /* background-color: #330033;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5000' height='5000' viewBox='0 0 5000 5000'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E"); */
}

@media screen and (max-width: 600px) {
  .side-image {
    display: none;
  }

  .login-form {
    border-radius: 0;
  }
}
</style>