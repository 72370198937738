<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pa-4">
      <v-card-title justify-center class="success white--text justify-center"
        >Map Mode</v-card-title
      >
      <v-container grid-list-sm class="pa-4">
        <p v-if="mode == 'DOCUMENTATION_MODE' || mode == ''">
          You are currently in
          <span class="primary--text">DOCUMENTATION</span>
        </p>

        <p v-if="mode == 'MAINTENANCE_MODE'">
          You are currently in
          <span class="primary--text">MAINTENANCE</span>
        </p>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :loading="status == 'loading'"
          @click="toggleMode"
          >Activate {{ button_text }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "MaintenanceDialogComponent",
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    id: "",
    dialog: null,
  }),
  mounted() {
    this.dialog = this.dialogModel;
    if (this.mode == "" || this.mode == "DOCUMENTATION_MODE")
      this.button_text = "MAINTENANCE";
    else this.button_text = "DOCUMENTATION";
  },
  computed: {
    button_text: function () {
      if (this.mode == "" || this.mode == "DOCUMENTATION_MODE")
        return "MAINTENANCE";
      else return "DOCUMENTATION";
    },

    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
      mode: (state) => state.featuresModule.map_mode,
    }),
  },
  methods: {
    toggleMode() {
      if (this.mode == "" || this.mode == "DOCUMENTATION_MODE")
        this.activateMaintenanceMode();
      else this.activatePlanningMode();

      this.reloadFeatures();
      this.closeDialog();
    },

    ...mapActions({
      activatePlanningMode: "activatePlanningMode",
      activateMaintenanceMode: "activateMaintenanceMode",
    }),
    reloadFeatures() {
      this.$emit("reload-features");
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>