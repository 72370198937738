<template>
  <v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <div
          class="dialog-title pa-2 success white--text custom-align-center relative"
          style="width: inherit"
        >
          <h4>Print Manager</h4>

          <v-icon
            color="white"
            class="cursor-item"
            @click="$emit('close-print-manager')"
            title="Close Print Manager"
            style="position: absolute; top: 8px; right: 10px"
            >clear</v-icon
          >
        </div>
      </v-flex>

      <v-flex xs12>
        <PrintConfig
          :key="merger_key"
          @refreshComponents="refreshComponents"
          @print="emitPrint"
        ></PrintConfig>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import PrintConfig from "./print-manager/PrintConfig";

export default {
  name: "PrintMaganerParentComponent",
  props: {},
  components: {
    PrintConfig,
  },
  data: () => ({
    tabs: 0,
    merger_key: 1,
  }),
  methods: {
    refreshComponents() {
      this.merger_key += 1;
    },
    emitPrint(data) {
      this.$emit("print", data);
    },
  },
};
</script>

<style lang="css" scoped>
.tab-item {
  height: 500px;
  overflow-y: scroll;
}

.dialog-title {
  text-align: center;
  border-radius: 3px;
}

.cursor-item {
  cursor: pointer;
}

.feature-dialog {
  position: absolute;
  width: 450px;
  min-height: 100px !important;
  max-height: 600px;
  background: #fff;
  top: 10em;
  left: 2em;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 3px;
}
</style>