import { mapState } from "vuex";
import { hasPermission } from "./utils";

export default {
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      roles: (state) => state.usersModule.roles,
    }),
    can_manage_projects: function() {
      let permissionCode = 11;
      return hasPermission(this.user, permissionCode);
    },

    can_manage_jobs: function() {
      let permissionCode = 11;
      return hasPermission(this.user, permissionCode);
    },
  },
};
