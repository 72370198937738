/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import {
  FEATURE_MEASUREMENT_AREA,
  FEATURE_MEASUREMENT_LENGTH,
  REDLINE_LINE,
  POLE_FIBER_CABLE,
  AREA_OF_INTEREST,
  FEATURE_FIBER_CABLE,
  FEATURE_DUCT,
  FEATURE_ODF,
  FEATURE_CABLE_HIGHLIGHT,
  HIGHLIGHT_FROM_ID,
  HIGHLIGHT_TO_ID,
  FEATURE_FACE_PLATE,
  FEATURE_ONT,
  FEATURE_OLT,
  FEATURE_FDP,
  FEATURE_CLOSURE,
  FEATURE_SPLITTER,
  featureSnapConfig,
  FEATURE_POLE,
  FEATURE_MANHOLE,
  FEATURE_BUILDING,
  FEATURE_SITE,
  FEATURE_CABINET,
  FEATURE_MICRO_TUNNEL,
  FEATURE_HANDHOLE,
  TRENCH,
  POLE_DUCT,
} from "../feature_constants";
import { v4 as uuidv4 } from "uuid";
import { Draw, Snap, Modify } from "ol/interaction";
import {
  getDragSelectedFeatures,
  getCompositionURLKey,
  getSnapFeatureJSON,
  getFeatureAtCoordinates,
  splitLargeDuct,
  getFeatureById,
  getHighlightedLineFeature,
  calculateLengthProperty,
  isCoordinatesEqual,
  splitTrench,
  splitFiberCable,
  splitLineFeature,
  getDerivedName,
  mergeLineFeatures,
  getFeaturesOnLineFeature,
  getFeaturesByType,
  getLocationFeatures,
} from "../../utils/map_functions";
import { getFeatureProps } from "../../utils/feature_properties";
import {
  getFeatureStyle,
  fromSegmentStyle,
  toSegmentStyle,
  thirdPartyFeatureStyle,
} from "../feature_styles";
import { mapActions } from "vuex";
import Collection from "ol/Collection";
import { LineString, Point } from "ol/geom";
import { lineSlice, feature } from "@turf/turf";

export default {
  /*
    1) Get access point (Manhole/Handhole)
    2) Save access point
    3) Determine if access point touches any ducts
    if 3 is true:
        4) Get all ducts that lie on this access point
        5) For each duct:
            5a) Check if it has start/end features
            if true:
                - split duct using access point as the splitter
                - Copy props from split duct to new smaller ducts
                - Change start/end features for smaller ducts
                - Save smaller ducts
                - Add smaller ducts to the map
                - Remove larger, split duct
    */

  methods: {
    async saveAccessPoint(data) {
      /*
            Save access point(s)
            */

      let feature = data.feature;
      let payload = {};
      let snapFeatures = data.snapFeatures;

      let featureProps = feature.getProperties();
      delete featureProps.geometry;

      payload.type = feature.get("type");
      if (feature.get("pk")) payload.pk = feature.get("pk");
      payload.data = JSON.stringify(this.geoJSON.writeFeatureObject(feature));
      let response = await this.createFeature(payload);

      /**
       * Split duct(s) where access point was placed
       */
      this.onSavedAccessPoint(response, feature, snapFeatures);

      /*
            Conclude feature saving operations
            */
      this.feature_saving = false;
      this.featureSaved = true;
      this.hideFeatureWindow();
    },

    splitAcessPointDucts(ductsArray, feature) {
      if (ductsArray.length > 0) {
        ductsArray.forEach((duct) => {
          // only proceed if duct in question has a pk
          if (duct.get("pk")) {
            // if this access point is on the duct, use it as the splitter
            const splitDucts = splitLineFeature(duct, feature);
            if (splitDucts.length > 0) {
              let ductToDeleteID = duct.get("pk");
              duct.unset("pk");
              const snapFeatures = [feature];
              const splitterFeature = feature;
              this.saveFeature(
                duct,
                snapFeatures,
                undefined,
                splitterFeature
              ).then(() => {
                const silentDelete = true;
                duct.set("pk", ductToDeleteID);
                this.deleteFeature(duct, silentDelete);
              });
            }
          }
        });
      }
    },

    async onSavedAccessPoint(response, feature, snapFeatures) {
      if (response && response.pk) {
        if (!feature.get("pk")) {
          feature.set("pk", response.pk);

          /*
                    Check for ducts on this access point (if it was just added to the map, ie, it had no p.k attribute
                    */
          let ductsArray = snapFeatures.filter(
            (f) => f.get("type") == FEATURE_DUCT && f.get("pk")
          );
          this.splitAcessPointDucts(ductsArray, feature);
        }
      }
    },
  },
};
