<template>
  <v-layout>
    <v-btn
      @click="add_feature_dialog = !add_feature_dialog"
      small
      color="success"
      absolute
      right
      outlined
      class="mt-5"
    >
      <v-icon>add</v-icon>Add
    </v-btn>
    <v-flex xs12 class="mt-9">
      <v-data-table
        :loading="status == 'loading'"
        :headers="header"
        :items="attached_features"
        hide-default-footer
      >
        <template v-slot:[`item.action`]="{ item }" style="width: 100px">
          <v-icon small color="primary" class="pr-3" @click="editItem(item)"
            >edit</v-icon
          >
          <v-icon small color="error" @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-flex>

    <div v-if="add_feature_dialog">
      <AddFeatureDialog
        :dialogModel="add_feature_dialog"
        :vectorSources="vectorSources"
        :parentFeature="feature"
        :createdFeature="createdFeature"
        @saveFeature="saveFeature"
        @closeDialog="closeDialog"
      ></AddFeatureDialog>
    </div>

    <ConfirmDelete
      :deleteMessage="deleteMessage"
      :dialogModel="delete_dialog"
      @closeDialog="closeDialog"
      @deleteConfirmed="deleteFeature"
      :model="deleteModel"
    ></ConfirmDelete>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dialogMixins from "../../../utils/mixins";
import ConfirmDelete from "../../generic/ConfirmDeleteDialog";
import AddFeatureDialog from "./dialogs/AddFeatureDialog";

import {
  FEATURE_BUILDING,
  BUILDING_FEATURES,
  FEATURE_SITE,
  SITE_FEATURES,
  FEATURE_POLE,
  POLE_FEATURES,
  FEATURE_MANHOLE,
  ACCESS_POINT_FEATURES,
  FEATURE_CABINET,
  CABINET_FEATURES,
} from "../../../utils/feature_constants";
import {
  getCompositionURLKey,
  getFeatureById,
} from "../../../utils/map_functions";

export default {
  name: "FeatureContentsTableComponent",
  components: { ConfirmDelete, AddFeatureDialog },
  mixins: [dialogMixins],
  props: {
    feature: {
      type: Object,
      required: true,
    },
    vectorSources: {
      type: Array,
    },
  },
  data: () => ({
    deleteModel: null,
    createdFeature: null,
    deleteMessage: "Are you sure you want to delete this feature?",
    attached_features: [],
    enable_reload: false,
    header: [
      {
        text: "Feature",
        value: "feature_name",
        sortable: false,
      },
      // {
      //   text: "Type",
      //   value: "feature_type",
      //   sortable: false,
      // },
      {
        text: "More",
        value: "action",
      },
    ],
  }),
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
      compositionStatus: (state) =>
        state.featuresModule.feature_composition_status,
    }),
  },
  destroyed() {
    this.enable_reload = false;
  },
  mounted() {
    if (this.feature) {
      if (this.feature.get("pk")) {
        this.loadCompositionFeatures(this.feature);
      }
    }
  },
  watch: {
    compositionStatus: function (val) {
      if (this.enable_reload && val == "success" && this.feature.get("pk")) {
        this.enable_reload = false;
        this.loadCompositionFeatures(this.feature);
      }
    },
  },
  methods: {
    ...mapActions({
      getCompositionFeatures: "getCompositionFeatures",
      removeCompositionFeature: "removeCompositionFeature",
    }),
    saveFeature(feature) {
      this.enable_reload = true;
      this.$emit("saveFeature", feature);
    },
    editItem(item) {
      console.log("Editing item", item);
      this.createdFeature = getFeatureById(
        item.feature_uuid,
        this.vectorSources
      );
      if (this.createdFeature) {
        this.add_feature_dialog = !this.add_feature_dialog;
      }
    },
    deleteItem(item) {
      this.createdFeature = getFeatureById(
        item.feature_uuid,
        this.vectorSources
      );
      if (this.createdFeature) {
        this.showDeleteDialog(this.createdFeature);
      }
    },
    loadCompositionFeatures(feature) {
      this.attached_features = [];

      let urlKey = getCompositionURLKey(feature);
      if (urlKey != "") {
        let payload = {
          id: feature.get("pk"),
          type: urlKey,
        };

        this.getCompositionFeatures(payload).then((res) => {
          res.results.forEach((feature) => {
            var infoObj;

            if (feature.odf_info) infoObj = feature.odf_info;
            if (feature.splitter_info) infoObj = feature.splitter_info;
            if (feature.fdp_info) infoObj = feature.fdp_info;
            if (feature.closure_info) infoObj = feature.closure_info;
            if (feature.ont_info) infoObj = feature.ont_info;
            if (feature.face_plate_info) infoObj = feature.face_plate_info;

            if (infoObj) {
              this.attached_features.push({
                id: feature.id,
                feature_name: infoObj.name,
                feature_type: infoObj.type,
                feature_uuid: infoObj.uuid,
              });
            }
          });
        });
      }
    },
    showDeleteDialog(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    getURLKey() {
      if (this.feature.get("type") == FEATURE_BUILDING)
        return BUILDING_FEATURES;
      if (this.feature.get("type") == FEATURE_SITE) return SITE_FEATURES;
      if (this.feature.get("type") == FEATURE_POLE) return POLE_FEATURES;
      if (this.feature.get("type") == FEATURE_MANHOLE)
        return ACCESS_POINT_FEATURES;
      if (this.feature.get("type") == FEATURE_CABINET) return CABINET_FEATURES;

      return "";
    },
    deleteFeature(feature) {
      this.deleteModel = null;
      this.$emit("deleteFeature", feature);
      // let urlKey = this.getURLKey();
      // if (urlKey != "") {
      //   let payload = {
      //     id: feature.get("pk"),
      //     type: urlKey,
      //   };
      //   this.removeCompositionFeature(payload).then(() =>
      //     this.loadCompositionFeatures(this.feature)
      //   );
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>