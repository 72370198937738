import backendAPI from "../../api/ProjectsAPI"
import appConfig from "../../config"
import { getCustomerName } from "../../utils/utils"

var baseUrl = appConfig.base_url + appConfig.projects_service_base_port + '/v1/projects_service/';

var projectsUrl = baseUrl + 'projects';
var jobsUrl = baseUrl + 'jobs';
var jobAreasUrl = baseUrl + 'job_areas';


export default {
    state: {
        job_areas: [],
        projects: [],
        project: {},
        jobs: [],
        job: {},
        status: ''
    },

    actions: {
        createProject( { commit }, payload ) {
            commit( 'setProjectsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                backendAPI.post( projectsUrl, payload ).then( () => {
                    commit( 'setProjectsStatus', 'success' );
                    this.dispatch( 'getProjects' );
                    resolve()
                } ).catch( () => {
                    commit( 'setProjectsStatus', 'error' );
                    reject()
                } )
            } )
        },

        updateProject( { commit }, payload ) {

            commit( 'setProjectsStatus', 'loading' );

            let id = payload.id;

            return new Promise( ( resolve, reject ) => {
                backendAPI.update( projectsUrl, id, payload ).then( () => {
                    commit( 'setProjectsStatus', 'success' );
                    this.dispatch( 'getProjects' );
                    resolve()
                } ).catch( () => {
                    commit( 'setProjectsStatus', 'error' );
                    reject()
                } )
            } )
        },

        createJob( { commit }, payload ) {
            commit( 'setProjectsStatus', 'loading' );

            let project_id = payload.project;

            return new Promise( ( resolve, reject ) => {
                backendAPI.post( jobsUrl, payload ).then( () => {
                    commit( 'setProjectsStatus', 'success' );
                    this.dispatch( 'getJobs', project_id );
                    resolve()
                } ).catch( () => {
                    commit( 'setProjectsStatus', 'error' );
                    reject()
                } )
            } )
        },

        updateJob( { commit }, payload ) {
            commit( 'setProjectsStatus', 'loading' );

            let id = payload.id;
            let project_id = payload.project;

            return new Promise( ( resolve, reject ) => {
                backendAPI.update( jobsUrl, id, payload ).then( () => {
                    commit( 'setProjectsStatus', 'success' );
                    this.dispatch( 'getJobs', project_id );
                    resolve()
                } ).catch( () => {
                    commit( 'setProjectsStatus', 'error' );
                    reject()
                } )
            } )
        },

        createJobArea( { commit }, payload ) {
            commit( 'setProjectsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                backendAPI.post( jobAreasUrl, payload ).then( () => {
                    commit( 'setProjectsStatus', 'success' );
                    this.dispatch( 'getJobAreas' );
                    resolve()
                } ).catch( () => {
                    commit( 'setProjectsStatus', 'error' );
                    reject()
                } )
            } )
        },

        updateJobArea( { commit }, payload ) {
            commit( 'setProjectsStatus', 'loading' );

            let id = payload.id;

            return new Promise( ( resolve, reject ) => {
                backendAPI.update( jobAreasUrl, id, payload ).then( () => {
                    commit( 'setProjectsStatus', 'success' );
                    this.dispatch( 'getJobAreas' );
                    resolve()
                } ).catch( () => {
                    commit( 'setProjectsStatus', 'error' );
                    reject()
                } )
            } )
        },

        getProjects( { commit, rootState } ) {
            commit( 'setProjectsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                backendAPI.get( projectsUrl ).then( data => {
                    commit( 'setProjectsStatus', 'success' );

                    let customers = rootState.customersModule.customers
                    data.forEach( project => {
                        project.project_owner = getCustomerName( project.customer_id, customers );
                    } )

                    commit( 'setProjects', data );
                    resolve( data )
                } ).catch( err => {
                    commit( 'setProjectsStatus', 'error' );
                    reject( err )
                } )
            } )
        },

        getProject( { commit, rootState }, id ) {
            commit( 'setProjectsStatus', 'loading' );

            let projectResource = `${ projectsUrl }/${ id }`

            return new Promise( ( resolve, reject ) => {
                backendAPI.get( projectResource ).then( data => {
                    commit( 'setProjectsStatus', 'success' );

                    let customers = rootState.customersModule.customers
                    data.project_owner = getCustomerName( data.customer_id, customers );
                    commit( 'setProject', data );
                    resolve( data )
                } ).catch( err => {
                    commit( 'setProjectsStatus', 'error' );
                    reject( err )
                } )
            } )
        },

        getJobs( { commit }, project_id ) {
            commit( 'setProjectsStatus', 'loading' );

            let apiData = {
                project_id: project_id
            }

            return new Promise( ( resolve, reject ) => {
                backendAPI.get( jobsUrl, apiData ).then( data => {
                    commit( 'setProjectsStatus', 'success' );
                    commit( 'setJobs', data );
                    resolve( data )
                } ).catch( err => {
                    commit( 'setProjectsStatus', 'error' );
                    reject( err )
                } )
            } )
        },

        getJob( { commit }, id ) {
            commit( 'setProjectsStatus', 'loading' );

            let jobResource = `${ jobsUrl }/${ id }`

            return new Promise( ( resolve, reject ) => {
                backendAPI.get( jobResource ).then( data => {
                    commit( 'setProjectsStatus', 'success' );
                    commit( 'setJob', data );
                    resolve( data )
                } ).catch( err => {
                    commit( 'setProjectsStatus', 'error' );
                    reject( err )
                } )
            } )
        },

        getJobAreas( { commit } ) {
            commit( 'setProjectsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                backendAPI.get( jobAreasUrl ).then( data => {
                    commit( 'setProjectsStatus', 'success' );
                    commit( 'setCustomerServiceCosts', data );
                    resolve( data )
                } ).catch( err => {
                    commit( 'setProjectsStatus', 'error' );
                    reject( err )
                } )
            } )

        },

        deleteJobArea( { commit }, id ) {
            commit( 'setProjectsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                backendAPI.delete( jobAreasUrl, id ).then( () => {
                    commit( 'setProjectsStatus', 'success' );
                    this.dispatch( 'getJobAreas' );
                    resolve()
                } ).catch( () => {
                    commit( 'setProjectsStatus', 'error' );
                    reject()
                } )
            } )

        },

        deleteProject( { commit }, id ) {
            commit( 'setProjectsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                backendAPI.delete( projectsUrl, id ).then( () => {
                    commit( 'setProjectsStatus', 'success' );
                    this.dispatch( 'getProjects' );
                    resolve()
                } ).catch( () => {
                    commit( 'setProjectsStatus', 'error' );
                    reject()
                } )
            } )

        },

        deleteJob( { commit }, id ) {
            commit( 'setProjectsStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                backendAPI.delete( jobsUrl, id ).then( () => {
                    commit( 'setProjectsStatus', 'success' );
                    resolve()
                } ).catch( () => {
                    commit( 'setProjectsStatus', 'error' );
                    reject()
                } )
            } )

        },

        resetProjectsData( { commit } ) {
            commit( 'resetData' )
        }
    },

    mutations: {
        setJobAreas( state, results ) {
            state.job_areas = results;
        },

        setProjects( state, results ) {
            state.projects = results;
        },

        setProject( state, results ) {
            state.project = results;
        },

        setJobs( state, results ) {
            state.jobs = results;
        },

        setJob( state, results ) {
            state.job = results;
        },

        setProjectsStatus( state, status ) {
            state.status = status;
        },

        resetData( state ) {
            state.projects = []
            state.project = {}
            state.jobs = []
            state.job = {}
            state.status = ''
        }
    }
}