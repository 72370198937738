<template>
  <v-card flat>
    <v-card-title>
      <h4>Service Cost</h4>

      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Search service costs"></v-text-field>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :search="search"
        :loading="status == 'loading'"
        :items="service_costs"
        :headers="headers"
      >
        <template v-slot:item.action="{item}" style="width: 100px">
          <v-icon small color="primary" class="pr-3" @click="editItem(item)">edit</v-icon>
          <v-icon small color="error" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ServiceCostsComponent",
  props: {
    customer_id: {
      type: Number
    }
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "Material Type",
        value: "type_name"
      },
      {
        text: "Service",
        value: "service_name"
      },
      {
        text: "Service Cost (UOM)",
        value: "service_cost"
      },
      {
        text: "Actions",
        value: "action"
      }
    ]
  }),
  mounted() {
    if (this.service_costs.length == 0)
      this.getServiceCosts(this.$route.params.id);
  },
  computed: {
    ...mapState({
      service_costs: state => state.materialsModule.service_costs,
      status: state => state.materialsModule.status
    })
  },
  methods: {
    ...mapActions({
      getServiceCosts: "getCustomerServiceCosts"
    }),
    editItem(item) {
      this.$emit("editServiceCost", item);
    },
    deleteItem(item) {
      this.$emit("deleteServiceCost", item);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>