<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="success white--text justify-center">{{
        dialog_title
      }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <!-- slack_type -->
            <v-flex>
              <v-text-field
                v-model="monitoring.port_number"
                :rules="rules.required_field"
                placeholder="Port Number"
                label="Port Number"
                type="number"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="monitoring.optical_route_name"
                  :rules="rules.required_field"
                  placeholder="Optical Route Name"
                  label="Optical Route Name"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="createOrEdit"
          >{{ dialog_button_text }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../../utils/mixins";

export default {
  name: "MonitoringDialogComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    selectedFeatures: {
      type: Array,
    },
    vectorSources: {
      type: Array,
    },
    feature: {
      type: Object,
      required: true,
    },
    editModel: {
      type: Object,
    },
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "Add Monitoring Info",
    dialog_button_text: "Create",
    valid: true,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    dialog: null,
    monitoring: {},
    upload_file: null,
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.slack = {};

    if (this.editModel) {
      this.is_edit_mode = true;

      Object.assign(this.monitoring, this.editModel);
      this.dialog_title = "Edit Monitoring Info";
      this.dialog_button_text = "Update";
    }
  },
  destroyed() {
    this.$emit("deleteAllHighlightFeatures");
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    ...mapActions({
      createSlack: "createSlack",
      createFMSPortInfo: "createFMSPortInfo",
      updateFMSPortInfo: "updateFMSPortInfo",
    }),

    async createOrEdit() {
      if (this.$refs.form.validate()) {
        if (this.is_edit_mode) {
          console.log("To be implemented");
        } else {
          try {
            this.monitoring.patch_panel_id = this.feature.getId().toString();
            await this.createFMSPortInfo(this.monitoring);
            this.showAlertMessage("Added monitoring info", "success");
            this.$emit("reload");
            this.closeDialog();
          } catch (e) {
            this.showAlertMessage(
              "An error occurred while adding monitoring info",
              "error"
            );
          }
        }
      }
    },
    resetFields() {
      this.monitoring = {};
      this.is_edit_mode = false;
      this.upload_file = null;
      this.loading = false;
    },

    closeDialog() {
      this.resetFields();
      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.slack = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>