<template>
  <v-dialog v-model="dialog" persistent width="550px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <!-- Upload single material -->
            <v-layout row wrap>
              <v-flex xs12 class="pt-3">
                <v-text-field
                  v-model="accessory.accessory_name"
                  :rules="rules.required_field"
                  placeholder="Accessory Name"
                  label="Accessory Name"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="loading"
          @click="createOrEdit"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PoleAccessoriesDialogComponent",
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    editModel: {
      type: Object,
    },
  },
  data: () => ({
    is_edit_mode: false,
    loading: false,
    dialog_title: "Create Pole Accessory",
    dialog_button_text: "Create",
    valid: true,
    dialog: null,
    accessory: {},
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
  }),
  mounted() {
    this.dialog = this.dialogModel;
    if (this.editModel) {
      this.is_edit_mode = true;
      this.dialog_title = "Edit Pole Accessory";
      this.dialog_button_text = "Update";
      this.accessory = Object.assign(this.accessory, this.editModel);
    }
  },
  methods: {
    ...mapActions({
      createPoleAccessory: "createPoleAccessory",
      updatePoleAccessory: "updatePoleAccessory",
    }),
    createOrEdit() {
      this.loading = true;

      var formData = this.accessory;

      // check if we're editing
      if (this.is_edit_mode) {
        this.updatePoleAccessory(formData).then(() => {
          this.closeDialog();
        });
      } else {
        this.createPoleAccessory(formData).then(() => {
          this.closeDialog();
        });
      }
    },
    resetFields() {
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();
      this.$emit("resetEditModel");
      this.$emit("reload");
      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    editModel: function (val) {
      if (val.id != undefined) {
        this.accessory = val;
      }
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.accessory = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>