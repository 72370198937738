<template>
  <div
    style="height: 100%; width: auto"
    :class="move_tool_enabled ? 'move-cursor-item' : ''"
  >
    <!-- Invisible Google map move-cursor-item -->
    <gmap-map
      class="relative hidden"
      ref="googleMapRef"
      :options="googleMapOptions"
      :center="googleMapsCenter"
      :zoom="12"
      style="width: 100%; height: 100%"
    ></gmap-map>

    <!-- Loading features UI -->
    <div v-if="loading_features">
      <c-loader message="Loading features. Please wait..."></c-loader>
    </div>

    <!-- Feature contents popup -->
    <div id="popup" class="ol-popup">
      <a href="#" id="popup-closer" class="ol-popup-closer"></a>
      <div id="popup-content">{{ overlay_content }}</div>
    </div>

    <div
      v-if="show_faults && !loading_features"
      class="faults-display"
      style="
        position: absolute;
        top: 2em;
        left: 3.5em;
        min-width: 500px;
        z-index: 1;
      "
    >
      <FaultsComponent
        :vectorSources="vectorSources"
        :faultsData="faultsData"
        @locate-fault="addFaultToMap"
      ></FaultsComponent>
    </div>

    <!-- Layer management drawer -->
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon>layers</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Layer Management</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link>
          <v-list-item-icon>
            <v-icon color="grey">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="grey--text">{{
              item.text
            }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon v-if="item.feature_type == 'ROAD'">
            <v-icon
              @click="toggleLayerFeatureNames(item)"
              small
              :color="getLayerNamesVisibility(item) ? 'error' : 'grey'"
              >edit</v-icon
            >
          </v-list-item-icon>

          <v-list-item-icon>
            <v-icon
              @click="toggleVisibility(item)"
              small
              :color="getVisibility(item) ? 'success' : 'grey'"
              >{{
                getVisibility(item) ? "visibility" : "visibility_off"
              }}</v-icon
            >
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Imported layers drawer -->
    <v-navigation-drawer v-model="manage_imports_dialog" absolute temporary>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon>layers</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Imported Layers</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in imported_layers_list" :key="item.id" link>
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{
              item.layer_name
            }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon @click="showDeleteDialog(item)" color="error" small
              >delete</v-icon
            >
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Map -->
    <div
      id="map"
      style="width: 100%; height: 100%; position: fixed"
      class="map"
    ></div>

    <!-- Mouse position view -->
    <div id="mouse-position" class="primary white--text"></div>

    <!-- Map Mode Indicator -->
    <div id="map-mode" :class="'map-mode ' + map_mode_class">
      <p class="white--text">{{ map_mode_text }}</p>
    </div>

    <!-- Network Faults -->
    <div v-if="show_faults_button" :class="'network-faults'">
      <v-btn @click="toggleFaults" class="error">Faults Alert</v-btn>
    </div>

    <input
      v-model="place_search"
      id="pac-input"
      class="controls"
      type="text"
      placeholder="Go to place or coordinates (lat, lon)"
    />

    <!-- Custom autocomplete results table -->
    <table>
      <tr>
        <td colspan="2">
          <div id="results" class="pac-container"></div>
        </td>
      </tr>
    </table>

    <DrawControls
      v-if="!isNocView"
      :show_drawing_tools="show_drawing_tools && !isCustomerNetwork"
      :move_tool_btn_class="move_tool_btn_class"
      @show-blow-manager="showBlowFiberManager"
      @show-print-manager="showPrintManager"
      @show-prefixes-dialog="prefix_dialog = !prefix_dialog"
      @show-maintenance-dialog="maintenance_dialog = !maintenance_dialog"
      @drawLineString="drawLineString"
      @drawPoint="drawPoint"
      @drawPolygon="drawPolygon"
      @handleConnectionsClick="handleConnectionsClick"
      @drawMeasurementFeature="drawMeasurementFeature"
      @changeBaseLayer="baseLayerAction"
      @handleFeatureImport="handleFeatureImport"
      @handleFeatureExport="handleFeatureExport"
      @handleMoveToolClick="handleMoveToolClick"
      @handleReportsClick="handleReportsClick"
    ></DrawControls>
    <!-- @toggleSplicesLayer="loadSpliceSchematics" -->

    <!-- Export dialog -->
    <div v-if="dialog && !isCustomerNetwork && !isNocView">
      <ExportDialog
        @closeDialog="closeDialog"
        :dialogModel="dialog"
        :selectedFeatures="selectedFeatures"
        :selectedFeatureTypes="selectedFeatureTypes"
      ></ExportDialog>
    </div>

    <!-- Import dialog -->
    <div v-if="generic_dialog && !isCustomerNetwork && !isNocView">
      <ImportDialog
        @processBlueprintData="processBlueprintData"
        @resetFeatures="fetchFeatures"
        @resetImportLayers="fetchImportedLayers"
        @closeDialog="closeDialog"
        :dialogModel="generic_dialog"
      ></ImportDialog>
    </div>

    <div class="draggable">
      <div
        v-if="show_feature_window"
        :class="'ui-widget-content feature-dialog pa-0'"
      >
        <FeaturePopupWindow
          :key="currentFeature ? currentFeature.ol_uid : 1"
          :selectedFeaturesVisible="selectedFeaturesVisible"
          :currentFeature="currentFeature"
          :selectedFeatures="selectedFeatures"
          :isMeasurementFeature="isMeasurementFeature"
          :selectedFeatureTypes="selectedFeatureTypes"
          :vectorSources="vectorSources"
          :feature_saving="feature_saving"
          :opticalDevices="opticalDevices"
          @openFeatureWindow="openFeatureWindow"
          @hideFeatureWindow="handleCloseFeatureWindow"
          @showSelectedFeatures="showSelectedFeatures"
          @saveFeature="execSaveFeature"
          @addFeatureToMap="addFeatureToMap"
          @deleteFeature="deleteFeature"
          @highlightSelectedFeature="highlightSelectedFeature"
          @deleteAllHighlightFeatures="deleteAllHighlightFeatures"
        ></FeaturePopupWindow>
      </div>

      <div class="connection-manager-dialog" v-if="connectionManagerVisible">
        <ConnectionManager
          @highlightFromSegment="highlightFromSegment"
          @highlightToSegment="highlightToSegment"
          @clearHighlightedFeatures="deleteAllHighlightFeatures"
          :features="selectedFeatures"
          :vectorSources="vectorSources"
          @close-connection-manager="hideConnectionManager"
          @traceSignal="traceSignal"
          @clearSignalTrace="clearSignalTrace"
        ></ConnectionManager>
      </div>

      <div class="connection-manager-dialog" v-if="mergeFeaturesManagerVisible">
        <MergeManager
          @highlightFromSegment="highlightFromSegment"
          @highlightToSegment="highlightToSegment"
          @clearHighlightedFeatures="deleteAllHighlightFeatures"
          @mergedFeatures="mergeFeatures"
          :features="selectedFeatures"
          :vectorSources="vectorSources"
          @close-merge-features="hideMergeManager"
        ></MergeManager>
      </div>

      <div class="connection-manager-dialog" v-if="blowFiberManagerVisible">
        <BlowFiberManager
          :features="selectedFeatures"
          :vectorSources="vectorSources"
          :map="map"
          @close-blow-manager="hideBlowFiberManager"
          @blowFiberCable="execBlowFiberCable"
          @clearHighlightedFeatures="deleteAllHighlightFeatures"
          @highlightFeature="highlightSelectedFeature"
        ></BlowFiberManager>
      </div>

      <div class="connection-manager-dialog" v-if="printManagerVisible">
        <PrintManager
          @print="printMapSection"
          @close-print-manager="hidePrintManager"
        ></PrintManager>
      </div>

      <div v-if="delete_dialog && !isCustomerNetwork">
        <ConfirmDialog
          :dialogModel="delete_dialog"
          :model="deleteModel"
          deleteMessage="Are you sure you want to delete this imported layer with its features?"
          @deleteConfirmed="removeImportedLayer"
          @closeDialog="closeDialog"
        ></ConfirmDialog>
      </div>

      <div v-if="prefix_dialog && !isCustomerNetwork">
        <PrefixDialog
          :dialogModel="prefix_dialog"
          :job="parseInt($route.params.job_id)"
          @closeDialog="closeDialog"
        ></PrefixDialog>
      </div>

      <div v-if="maintenance_dialog">
        <MaintenanceDialog
          :dialogModel="maintenance_dialog"
          @closeDialog="closeDialog"
          @reload-features="fetchFeatures"
        ></MaintenanceDialog>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-undef */
import { gmapApi } from "vue2-google-maps";
import ScaleLine from "ol/control/ScaleLine";
import MousePosition from "ol/control/MousePosition";
import { createStringXY } from "ol/coordinate";
import { platformModifierKeyOnly } from "ol/events/condition";
import { DragBox } from "ol/interaction";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { defaults as defaultControls } from "ol/control";
import GeoJSON from "ol/format/GeoJSON";

// import mixins
import drawingMixin from "../../utils/featuresMixins/drawMixins";
import initMapMixin from "../../utils/featuresMixins/initMapMixin";
import layersMixin from "../../utils/featuresMixins/layers";
import featuresMixin from "../../utils/featuresMixins/features";
import initJobFeatures from "../../utils/featuresMixins/initJobFeaturesMixin";
import measurementMixin from "../../utils/featuresMixins/measurements";
import importedLayersMixin from "../../utils/featuresMixins/importedLayersMixin";
import faultsMixin from "../../utils/faultsMixin";
import infoWindowMixin from "../../utils/featuresMixins/infoWindowMixin";
import printMixin from "../../utils/featuresMixins/printMixin";
import dialogMixins from "../../utils/mixins";
import prefixesMixins from "../../utils/featuresMixins/prefixesMixin";
import featureMoveMixin from "../../utils/featuresMixins/moveFeatureMixin";
import saveDuctsMixin from "../../utils/featuresMixins/saveDuctsMixin";
import saveAccessPointMixin from "../../utils/featuresMixins/saveAccessPointMixin";
import graphingMixin from "../../utils/graphingMixin";
import schematicsMixin from "../../utils/featuresMixins/schematicsMixin";
import fmsMixin from "../../utils/featuresMixins/fmsMixin";

import FaultsComponent from "./sub-components/Faults.vue";

import {
  FEATURE_MEASUREMENT_AREA,
  FEATURE_MEASUREMENT_LENGTH,
  EXCLUDE_SELECTION,
  INITIAL_ZOOM_NO_FEATURES,
  BLUE_PRINT_POINT,
  BLUE_PRINT_LINE,
} from "../../utils/feature_constants";

import FeaturePopupWindow from "./sub-components/FeatureWindow";
import DrawControls from "./sub-components/DrawControls";
import ConnectionManager from "./sub-components/ConnectionManager";
import MergeManager from "./sub-components/MergeFeaturesManager";
import BlowFiberManager from "./sub-components/BlowFiberManager";
import PrintManager from "./sub-components/PrintManager";
import { mapState, mapActions } from "vuex";

import ExportDialog from "./sub-components/ExportDialog";
import ImportDialog from "./sub-components/ImportDialog";
import ConfirmDialog from "../generic/ConfirmDeleteDialog";
import PrefixDialog from "./sub-components/dialogs/PrefixNamingDialog";
import MaintenanceDialog from "./sub-components/dialogs/MaintenanceDialog";

export default {
  components: {
    FeaturePopupWindow,
    DrawControls,
    ConnectionManager,
    ImportDialog,
    ExportDialog,
    ConfirmDialog,
    MergeManager,
    BlowFiberManager,
    PrintManager,
    PrefixDialog,
    MaintenanceDialog,
    FaultsComponent,
  },
  mixins: [
    featuresMixin,
    layersMixin,
    measurementMixin,
    drawingMixin,
    initMapMixin,
    initJobFeatures,
    dialogMixins,
    importedLayersMixin,
    infoWindowMixin,
    printMixin,
    faultsMixin,
    prefixesMixins,
    featureMoveMixin,
    saveDuctsMixin,
    saveAccessPointMixin,
    graphingMixin,
    schematicsMixin,
    fmsMixin,
  ],
  data: () => ({
    show_drawing_tools: true,
    active_mode: "",
    feature_window_class: "hidden",
    currentFeatureType: null,
    currentFeature: null,
    selectedFeaturesVisible: false,
    connectionManagerVisible: false,
    mergeFeaturesManagerVisible: false,
    blowFiberManagerVisible: false,
    featureSaved: true,
    selectedFeatures: [],
    currentVectorSource: null,
    googleMap: null,
    googleMapOptions: {
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: false,
      disableDefaultUI: false,
    },
    attachedExitDrawListener: false,
    dragBox: null,
    modify: null,
    draw: null,
    snap: null,
    source: null,
    map: null,
    place_search: "",
    drawnFeatures: [],
    drawType: undefined,
    center: [36.8167, -1.2833],
    rotation: 0,
    geolocPosition: undefined,
    googleMapsCenter: { lat: -1.28333, lng: 36.81667 }, // -1.28333 36.81667,  0.3476, 32.5825
    geoJSON: null,
    scaleLine: null,
    mousePosition: null,
    isMapInstance: true,
  }),
  created() {
    document.removeEventListener("keyup", this.exitEditModeListener);
  },
  destroyed() {
    document.removeEventListener("keyup", this.exitEditModeListener);
  },
  async mounted() {
    this.$refs.googleMapRef.$mapPromise.then((map) => (this.googleMap = map));
    this.initLayers();
    this.geoJSON = new GeoJSON();
    this.fetchImportedLayers();

    // hide some layers
    this.hideLayer(this.splicesLayer);
    this.hideLayer(this.areaOfInterestLayer);

    // get all customer materials
    this.getMaterialTypes();

    if (this.$route.params.id) {
      // fetch customer materials
      this.getCustomerMaterials(this.$route.params.id);

      // fetch customer service costs
      this.getCustomerServiceCosts(this.$route.params.id);
    }

    // add exit drawing mode listerner
    document.addEventListener("keyup", this.exitEditModeListener);

    this.dragBox = new DragBox({
      condition: platformModifierKeyOnly,
    });

    this.dragBox.on("boxend", this.dragBoxListener);

    // we will need to manipulate the scale when printing, so we need to store it in its var
    this.scaleLine = new ScaleLine({
      bar: true,
      minWidth: 125,
      steps: 4,
      text: true,
    });

    this.mousePosition = new MousePosition({
      coordinateFormat: createStringXY(4),
      className: "custom-mouse-position",
      target: document.getElementById("mouse-position"),
      undefinedHTML: "&nbsp;",
    });

    this.map = new Map({
      controls: defaultControls().extend([this.scaleLine, this.mousePosition]),
      target: "map",
      layers: this.mapLayers,
      overlays: [this.overlay], // overlay from infoWindowMixin
      view: new View({
        projection: "EPSG:4326",
        center: this.center,
        zoom: INITIAL_ZOOM_NO_FEATURES,
      }),
    });

    // add drag interaction
    this.map.addInteraction(this.dragBox);

    /**
     * Add a click handler to the map to render the popup (still defined in the overlay mixin).
     */
    this.map.on("singleclick", this.overlayClickListener);
    this.map.on("singleclick", this.schematicsClickListener);

    // init job features
    await this.fetchFeatures();

    // initialize ducts connectivity graph
    await this.initGraph();

    this.fetchAreaOfInterestFeatures();

    // check if draw tools should show
    if (this.$route.query.view_only) {
      this.show_drawing_tools = false;
    }
  },
  computed: {
    map_mode_class: function() {
      if (this.map_mode == "MAINTENANCE_MODE") return "error";

      return "primary";
    },
    map_mode_text: function() {
      if (this.map_mode == "" || this.map_mode == "DOCUMENTATION_MODE")
        return "DOCUMENTATION";
      else return "MAINTENANCE";
    },
    ...mapState({
      user: (state) => state.usersModule.current_user,
      map_mode: (state) => state.featuresModule.map_mode,
    }),
    move_tool_btn_class: function() {
      return this.move_tool_enabled ? "success" : "";
    },
    isCustomerNetwork: function() {
      return this.$route.params.customer != undefined;
    },
    isNocView: function() {
      return this.$route.params.noc != undefined;
    },
    show_feature_window: function() {
      return (
        (this.selectedFeaturesVisible || this.currentFeature) &&
        !this.isMeasurementFeature &&
        !this.connectionManagerVisible &&
        !this.mergeFeaturesManagerVisible &&
        !this.blowFiberManagerVisible
      );
    },
    google: gmapApi,
    isMeasurementFeature: function() {
      return (
        this.currentFeatureType == FEATURE_MEASUREMENT_AREA ||
        this.currentFeatureType == FEATURE_MEASUREMENT_LENGTH
      );
    },
    selectedFeatureTypes: function() {
      let uniqueFeatures = [];
      let processedFeatures = [];
      this.selectedFeatures.forEach((feature) => {
        let featureType = feature.get("type");
        if (
          !processedFeatures.includes(featureType) &&
          !EXCLUDE_SELECTION.includes(featureType)
        ) {
          let featureCount = this.selectedFeatures.filter(
            (filteredFeature) => filteredFeature.get("type") == featureType
          ).length;
          uniqueFeatures.push({
            feature: feature,
            type: featureType,
            count: featureCount,
          });

          processedFeatures.push(featureType);
        }
      });

      return uniqueFeatures;
    },
  },
  watch: {
    googleMap: function(val) {
      this.initMap();
    },
    show_feature_window: function(val) {
      if (val) {
        jQuery(".draggable").draggable();
        jQuery(".draggable").resizable();
      }
    },
    connectionManagerVisible: function(val) {
      if (val) {
        jQuery(".draggable").draggable();
        jQuery(".draggable").resizable();
      }
    },
    mergeFeaturesManagerVisible: function(val) {
      if (val) {
        jQuery(".draggable").draggable();
        jQuery(".draggable").resizable();
      }
    },
    blowFiberManagerVisible: function(val) {
      if (val) {
        jQuery(".draggable").draggable();
        jQuery(".draggable").resizable();
      }
    },
  },
  methods: {
    ...mapActions({
      getCustomerMaterials: "getCustomerMaterials",
      getMaterialTypes: "getMaterialTypes",
      getCustomerServiceCosts: "getCustomerServiceCosts",
    }),

    exitEditModeListener(evt) {
      if (evt.keyCode === 27) {
        // exit map edit mode if its active
        this.exitEditMode();

        // remove all highlighted features
        this.deleteAllHighlightFeatures();
        this.removeHighlightedSegments();

        // deactivate move tool
        if (this.move_tool_enabled) {
          this.disableMoveTool();
        }

        // exit from splice schematics mode
        if (this.splice_schematics_visible) {
          this.toggleSpliceSchematics();
          this.displaySuccessAlert("Exiting splice schematics view");
        }

        // exit from ducts plan mode
        if (this.ducts_plan_visible) {
          this.toggleDuctsPlan();
          this.displaySuccessAlert("Exiting ducts plan view");
        }

        if (this.show_faults) {
          this.toggleFaults();
          this.displaySuccessAlert("Exiting faults view");
        }
      }
    },

    snapFeature(feature) {
      var coordinates = feature.getGeometry().getCoordinates();
      var pixel = this.map.getPixelFromCoordinate(coordinates);
      let clickedFeatures = [];

      this.map.forEachFeatureAtPixel(pixel, function(feature, layer) {
        if (feature.getId() != "") {
          clickedFeatures.push(feature);
        }
      });
    },

    baseLayerAction(menuItem) {
      if (menuItem.action === "osm") {
        this.hideLayer(this.satelliteLayer);
        this.showLayer(this.rasterLayer);
      }

      if (menuItem.action === "faults") {
        this.show_faults = !this.show_faults;
      }

      if (menuItem.action === "clear_blueprints") {
        this.deleteAllFeatures(BLUE_PRINT_POINT);
        this.deleteAllFeatures(BLUE_PRINT_LINE);

        this.displaySuccessAlert("Clearing all blueprint layer");
      }

      if (menuItem.action === "satellite") {
        this.hideLayer(this.rasterLayer);
        this.showLayer(this.satelliteLayer);
      }

      if (menuItem.action === "hide_all") {
        this.hideAllBaseLayers();
      }

      if (menuItem.action === "layer_manager") {
        this.drawer = !this.drawer;
      }

      if (menuItem.action === "imported_layers") {
        this.manage_imports_dialog = !this.manage_imports_dialog;
      }
    },

    handleCloseFeatureWindow() {
      if (this.move_tool_enabled) {
        this.displayErrorAlert("Please deactivate the move tool first.");
      }

      this.hideFeatureWindow();
    },

    handleReportsClick(menuItem) {
      if (menuItem.action == "splice_schematics") {
        this.toggleSpliceSchematics();
      }

      if (menuItem.action == "duct_Plan") {
        this.toggleDuctsPlan();
      }
    },
    handleFeatureImport(menuItem) {
      this.generic_dialog = !this.generic_dialog;
    },
    handleFeatureExport(menuItem) {
      if (this.selectedFeatures.length > 0) {
        // open export dialog
        this.dialog = !this.dialog;
      } else {
        this.displayErrorAlert("You have to select some features to continue.");
      }
    },
    setFeatureProperties(feature, properties) {
      for (let property in properties) {
        if (properties[property] != undefined) {
          feature.set(property, properties[property]);
        } else {
          feature.set(property, undefined);
        }
      }

      return feature;
    },
    execSaveFeature(feature, config = undefined) {
      this.saveFeature(feature, undefined, config).then(() => {
        this.displaySuccessAlert("Success");
      });
    },
    displaySuccessAlert(message) {
      this.$notify({
        group: "success",
        title: "",
        text: message,
      });
    },
    displayErrorAlert(message) {
      this.$notify({
        group: "error",
        title: "",
        text: message,
      });
    },
  },
};
</script>

<style lang="css" scoped>
.dialog-title {
  text-align: center;
  border-radius: 3px;
}

.cursor-item {
  cursor: pointer;
}

.move-cursor-item * {
  cursor: move;
}

.feature-dialog {
  position: absolute;
  min-width: 400px;
  max-width: 500px;
  min-height: 300px;
  max-height: 600px;
  background: #fff;
  top: 10em;
  left: 2em;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 3px;
}

.connection-manager-dialog {
  position: absolute;
  width: 600px;
  max-height: 600px;
  background: #fff;
  top: 10em;
  left: 2em;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 3px;
}

.controls {
  margin-top: 12px;
  border: 1px solid transparent;
  border-radius: 6px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 38px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  position: absolute !important;
  top: 0;
  left: 3em;
  background: red;
}

#mouse-position {
  margin-top: 12px;
  border: 1px solid transparent;
  height: 38px;
  position: absolute;
  top: 0;
  left: 470px;
  background: white;
  font-size: 14px;
  border-radius: 3px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
}

table {
  border-collapse: collapse;
  margin-left: 3.3em;
  margin-top: 50px;
  position: absolute !important;
}

table td {
  padding: 3px 5px !important;
}

label {
  display: inline-block;
  width: 160px;
  font-size: 11px;
  color: #777;
}

#pac-input,
#pac-features-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

#pac-input:focus,
pac-features-input:focus {
  border-color: #4d90fe;
}

/* New styles start */
#results {
  width: 400px;
}

.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
}

.pac-icon-marker {
  background-position: -1px -161px;
}

.pac-item {
  cursor: pointer;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  vertical-align: middle;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  color: #999;
}

.pac-item.custom {
  background-color: #fff9c4;
}

.pac-item:hover {
  background-color: #efefef;
}

.pac-item-error,
.pac-item-error:hover {
  color: #aaa;
  padding: 0 5px;
  cursor: default;
  background-color: #fff;
}

/* New styles end */

.pac-container {
  font-family: Roboto;
  background-color: #fff;
  z-index: 1000;
  border-radius: 2px;
  font-size: 11px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  width: 350px;
}

#type-selector {
  color: #fff;
  background-color: #4d90fe;
  padding: 5px 11px 0px 11px;
}

#type-selector label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
}

/* popup styles */
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}
.map-mode,
.network-faults {
  position: absolute;
  top: 5px;
  right: 0;
  padding: 5px 10px;
  border: solid 1px #888;
  background: #fff;
  border-radius: 5px;
}

.map-mode {
  border: 1px solid red;
}

.map-mode p {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  padding: 0.3em;
}

.network-faults {
  right: 9em;
  padding: 0;
  border: none;
}

.network-faults * {
  font-size: 12px;
}
</style>
