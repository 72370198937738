<template>
  <!-- check main.js for custom component details -->
  <c-submenu-layout>
    <!-- SubMenu Column -->
    <v-flex class="sub-menu" xs2>
      <c-sub-menu :items="sub_menu"></c-sub-menu>
    </v-flex>

    <!-- Main Content Column -->
    <v-flex xs10 class="main-content-border">
      <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

      <h1>LDAP Settings</h1>

      <v-card class="mt-9">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <div v-if="loading">
            <c-loader message="Loading settings. Please wait..."></c-loader>
          </div>

          <div v-else class="row uploads-data pa-5">
            <div class="flex">
              <table class="table">
                <tr>
                  <th>LDAP Setting Name</th>
                  <th>Value</th>
                </tr>
                <tr v-for="(index, key) in ldap" :key="key">
                  <td>{{ key }}</td>
                  <td>{{ ldap[key] }}</td>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-btn
        @click="dialog = !dialog"
        absolute
        top
        right
        fab
        rounded
        color="primary"
        class="white--text fab-button"
      >
        <v-icon>edit</v-icon>
      </v-btn>

      <Modal
        v-if="dialog"
        @close-dialog="closeDialog"
        :dialogModel="dialog"
        :width="650"
      >
        <v-card>
          <v-card-title class="headline primary white--text tw-justify-center">
            LDAP Settings
          </v-card-title>

          <v-card-text class="tw-justify-center">
            <v-form ref="ldapForm" v-model="valid">
              <v-text-field
                class="tw-py-2"
                v-model="ldap.ldap_server_url"
                label="LDAP Server URL"
                :rules="rules.required_field"
                required
              ></v-text-field>

              <v-text-field
                class="tw-py-2"
                v-model="ldap_password"
                type="password"
                label="LDAP Server Password"
                :rules="rules.required_field"
                required
              ></v-text-field>

              <v-text-field
                class="tw-py-2 pb-2"
                v-model="ldap.ldap_server_bind_dn"
                label="LDAP Bind DN"
                hint="ldap_server_bind_dn"
                :rules="rules.required_field"
                persistent-hint
              ></v-text-field>

              <v-text-field
                class="tw-py-2 pb-2"
                v-model="ldap.ldap_base_dn"
                label="LDAP Base DN"
                :rules="rules.required_field"
                hint="ldap_base_dn"
                persistent-hint
                required
              ></v-text-field>

              <v-text-field
                class="tw-py-2"
                v-model="ldap.objectClass"
                :rules="rules.required_field"
                label="LDAP Object Class"
                required
              ></v-text-field>
            </v-form>

            <div class="pb-4"></div>

            <v-btn
              @click="updateLDAP"
              class="primary white--text mr-4"
              :rules="rules.required_field"
              :loading="loading"
              :disabled="!valid"
              >Update</v-btn
            >
            <v-btn @click="closeDialog"> Cancel </v-btn>
          </v-card-text>
        </v-card>
      </Modal>
    </v-flex>
  </c-submenu-layout>
</template>

<script>
import appConfig from "../../config";
import dialogMixins from "../../utils/mixins";
import { mapState, mapActions } from "vuex";
import Modal from "../../components/Modal.vue";

export default {
  name: "LdapSettingsComponent",
  components: { Modal },
  mixins: [dialogMixins],
  data: () => ({
    obj: { a: 1, b: 2, c: 3 },
    ldap: {},
    valid: true,
    sub_menu: [],
    loading: false,
    updating_settings: false,
    ldap_password: null,
    bread_crumbs: [
      {
        text: "LDAP Settings",
        href: "ldap-settings",
      },
    ],
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
  }),
  async mounted() {
    this.sub_menu = appConfig.settings_page_sub_menus;
    await this.getLDAP();
  },
  computed: {
    ...mapState({
      status: (state) => state.usersModule.status,
    }),
  },
  methods: {
    ...mapActions({
      getLDAPSettings: "getLDAPSettings",
      postLDAPSettings: "postLDAPSettings",
    }),
    async getLDAP() {
      this.loading = true;
      let res = await this.getLDAPSettings();
      if (res && res.result) {
        this.ldap = res.result;
        this.ldap_password = this.ldap.ldap_server_password;
        delete this.ldap.ldap_server_password;
      }
      this.loading = false;
    },
    async updateLDAP() {
      if (this.$refs.ldapForm.validate()) {
        this.updating_settings = true;
        // set password
        this.ldap.ldap_server_password = this.ldap_password;

        console.log("ldap settings", this.ldap);

        await this.postLDAPSettings(this.ldap);
        await this.getLDAP();
        this.updating_settings = false;
        this.closeDialog();
      }
    },
  },
};
</script>

<style lang="css" scoped>
table td,
table th {
  border: solid 1px #ccc;
  min-width: 300px;
  padding: 0.5em;
}
</style>