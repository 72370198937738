/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

/*
 * File that contains all the properties for
 * the different features that we have on the map as objects
 */

import {
  FEATURE_BUILDING,
  FEATURE_MICRO_TUNNEL,
  REDLINE_LINE,
  REDLINE_POLYGON,
  FEATURE_OLT,
  TRENCH,
  FEATURE_MANHOLE,
  FEATURE_HANDHOLE,
  FEATURE_FIBER_CABLE,
  FEATURE_DUCT,
  FEATURE_ODF,
  FEATURE_SPLITTER,
  FEATURE_POLE,
  FEATURE_CABINET,
  FEATURE_INNER_DUCT,
  FEATURE_CLOSURE,
  FEATURE_SITE,
  FEATURE_FDP,
  FEATURE_ONT,
  FEATURE_FACE_PLATE,
  REDLINE_POINT,
  ROAD_CROSSING_HOOK,
  POLE_FIBER_CABLE,
  POLE_DUCT,
  BLUE_PRINT_LINE,
  BLUE_PRINT_POINT,
  AREA_OF_INTEREST,
  FEATURE_ROAD,
  FEATURE_RTU,
} from "./feature_constants";

var redlineAtttributes = {
  _name: "Redline (Line)",
  type: REDLINE_LINE,
  label: "",
  comments: "",
};

var areaOfInterestAtttributes = {
  _name: "Area of Interest",
  type: AREA_OF_INTEREST,
};

/**
 * Crossing Attribute Values
 */

var crossingValues = {};
crossingValues.crossing_type = {
  1: "Green Field",
  2: "Brown Field",
};

var roadCrossingHookAttributes = {
  _name: "Tunnel Pit",
  type: ROAD_CROSSING_HOOK,
};

var redlinePointAtttributes = {
  _name: "Redline (Point)",
  type: REDLINE_POINT,
  label: "",
  comments: "",
};

var redlinePolygonAtttributes = {
  _name: "Redline (Polygon)",
  type: REDLINE_POLYGON,
  label: "",
  comments: "",
};

var siteAttributes = {
  name: "Site",
  site_id_new: "",
  type: "SITE",
  fms_site_id: "",
  site_type: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  owner: "",
  building: "",
  link: "",
  install_date: "",
  site_location: "",
  comments: "",
};

var fdpAttributes = {
  name: "FDP",
  type: "FDP",
  input_ports: "12",
  output_ports: "12",
  customer_material: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  owner: "",
  address: "",
  comments: "",
};

var facePlateAttributes = {
  name: "Face Plate",
  type: "FACE_PLATE",
  input_ports: "1",
  output_ports: "1",
  customer_material: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  owner: "",
  address: "",
  comments: "",
};

var ontAttributes = {
  name: "ONT/Router",
  type: "ONT",
  input_ports: "1",
  output_ports: "1",
  customer_material: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  owner: "",
  address: "",
  comments: "",
};

var buildingAttributes = {
  name: "Building",
  type: "BUILDING",
  building_id: "",
  fms_site_id: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  location: "",
  street_name: "",
  building_type: "",
  number_of_floors: "",
  number_of_clients: "",
  client_name: "",
  main_cabinet_location: "",
  main_fat_location: "",
  main_fat_size: "",
  sub_fat_location: "",
  sub_fat_size: "",
  indoor_cable_size: "",
  indoor_cable_length: "",
  main_fat_termination: "",
  sub_fat_termination: "",
  power_source: "",
  power_cable_length: "",
  comments: "",
};

/**
 * Manhole Attribute Values
 */

var manholeValues = {};
manholeValues.access_point_type = {
  1: "Slack Manhole",
  2: "Closure Manhole",
  3: "Access Manhole",
};

manholeValues.ballmarker = {
  1: "NO",
  2: "YES",
};

manholeValues.access_point_priority = {
  1: "Low",
  2: "Medium",
  3: "High",
};

manholeValues.status = {
  1: "Good",
  2: "Waterlogged",
  3: "Damaged",
};

var manholeAttributes = {
  name: "",
  f_name: "Manhole",
  type: "MANHOLE",
  customer_material: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  access_point_type: "1",
  creation_user: "",
  owner: "",
  manufacturer: "",
  access_point_priority: "2",
  key_type: "",
  nearest_landmark: "",
  street: "",
  ballmarker: "1",
  status: "1",
  install_company: "",
  install_date: "",
  comments: "",
};

/**
 * Handhole Attribute Values
 */

var handholeValues = {};
handholeValues.handhole_type = {
  1: "Access Handhole",
  2: "Closure Handhole",
  3: "Slack Handhole",
};

handholeValues.ballmarker = {
  1: "NO",
  2: "YES",
};

handholeValues.access_point_priority = {
  1: "Low",
  2: "Medium",
  3: "High",
};

handholeValues.status = {
  1: "Good",
  2: "Waterlogged",
  3: "Damaged",
};

var handholeAttributes = {
  name: "",
  f_name: "Handhole",
  type: "HANDHOLE",
  customer_material: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  handhole_type: "1",
  creation_user: "",
  owner: "",
  manufacturer: "",
  access_point_priority: "2",
  key_type: "",
  nearest_landmark: "",
  street: "",
  ballmarker: "1",
  status: "1",
  install_company: "",
  install_date: "",
  comments: "",
};

/**
 * Duct Attribute Values
 */

var ductValues = {};
ductValues.material = {
  1: "HDPE",
  2: "PVC",
  3: "SplitHDPE",
  4: "SplitPVC",
  5: "BlackIronPipe",
  6: "BlackSteelPipe",
  7: "CastIronPipe",
  8: "ClaySewerPipe",
  9: "FlexDuct",
  10: "SteelCasing",
  11: "TerraCotta",
  12: "Wood",
  13: "Quad",
  14: "Unknown",
  15: "NotApplicable",
};

ductValues.color = {
  1: "Black",
  2: "Blue",
  3: "Green",
  4: "Yellow",
  5: "Red",
  6: "Orange",
};

ductValues.duct_type = {
  1: "Normal Duct",
  2: "Micro Duct",
};

ductValues.inner_ducts = {
  0: "SELECT Inner / No. of DUCTS",
  1: "1 Way",
  2: "2 Way",
  3: "3 Way",
  4: "4 Way",
  7: "7 Way",
};

ductValues.is_3rd_party = {
  1: "No",
  2: "Yes",
};

ductValues.duct_installation_type = {
  2: "Blowing",
  3: "Pulling",
};

var ductAttributes = {
  id: "",
  name: "Duct",
  type: "DUCT",
  color: "1",
  duct_type: "1",
  inner_ducts: "1",
  customer_material: "",
  creation_user: "",
  owner: "",
  is_3rd_party: "1",
  duct_installation_type: "2",
  start: "",
  end: "",
  street_name: "",
  contractor: "",
  link_cluster: "",
  status: "",
  comments: "",
  calculated_length: "",
  install_date: "",
};

var poleDuctValues = ductValues;

var poleDuctAttributes = {
  name: "Pole Duct",
  type: "POLE_DUCT",
  duct_type: "1",
  inner_ducts: "1",
  customer_material: "",
  creation_user: "",
  owner: "",
  is_3rd_party: "1",
  comments: "",
  street_name: "",
  contractor: "",
  link_cluster: "",
  status: "",
  manintenance_vendor: "",
  soil_profile: "",
  trench_depth: "",
  calculated_length: "",
  install_date: "",
};

var microTunnelAttributes = {
  _name: "Micro Tunnel",
  type: "MICRO_TUNNEL",
  creation_user: "",
  owner: "",
  comments: "",
  diameter: "",
  start: "",
  end: "",
  contractor: "",
  status: "",
  calculated_length: "",
  install_date: "",
};

/**
 * Inner Duct Attribute Values
 */

var trenchValues = {};

trenchValues.trench_type = {
  1: "Green Field",
  2: "Brown Field",
};

trenchValues.soil_profile = {
  1: "Normal",
  2: "Rocky",
  3: "Sand",
  4: "Unknown",
};

var trenchAttributes = {
  _name: "Trench",
  type: "TRENCH",
  trench_type: "1",
  creation_user: "",
  trench_distance: "",
  trench_depth: "1.2",
  soil_profile: "4",
  install_date: "",
  start: "",
  end: "",
};

/**
 * Inner Duct Attribute Values
 */

var innerductValues = {};

innerductValues.material = {
  1: "HDPE",
  2: "PVC",
  3: "SplitHDPE",
  4: "SplitPVC",
  5: "BlackIronPipe",
  6: "BlackSteelPipe",
  7: "CastIronPipe",
  8: "ClaySewerPipe",
  9: "FlexDuct",
  10: "SteelCasing",
  11: "TerraCotta",
  12: "Wood",
  13: "Quad",
  14: "Unknown",
  15: "NotApplicable",
};

innerductValues.color = {
  1: "red",
  2: "blue",
  3: "green",
  4: "yellow",
  5: "black",
  6: "orange",
  7: "black",
};

var innerDuctAttributes = {
  name: "Inner Duct",
  type: "INNER_DUCT",
  owner: "",
  work_order_id: "",
  project_name: "",
  calculated_length: "",
  measured_length: "",
  install_date: "",
  material: "",
  comments: "",
  diameter: "",
  inner_duct_number: "",
  color: "",
  attachements: "",
};

/**
 * Cabinet Attribute Values
 */

var cabinetValues = {};
cabinetValues.mount_type = {
  1: "Wall",
  2: "Rack",
  3: "Unknown",
};

cabinetValues.priority = {
  1: "Low",
  2: "Medium",
  3: "High",
};

var cabinetAttributes = {
  name: "Cabinet",
  type: "CABINET",
  size: "",
  customer_material: "",
  creation_user: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  owner: "",
  manufacturer: "",
  key_type: "",
  priority: "2",
  drawing_number: "",
  link_type: "",
  nearest_landmark: "",
  contractor: "",
  street: "",
  location: "",
  surrounding_surfaces: "",
  ch_distance: "",
  install_date: "",
};

/**
 * Underground/ Indoor Fiber Cable Attribute Values
 */

var fiberCableValues = {};
fiberCableValues.core_type = {
  1: "Fiber",
  2: "Glass",
};

fiberCableValues.cable_type = {
  1: "Undergdound",
  2: "Overhead",
  3: "Unknown",
};

fiberCableValues.cable_category = {
  1: "Backbone Cable",
  2: "Drop Fiber",
};

fiberCableValues.fiber_type = {
  1: "Single Mode Fiber",
  2: "Multi Mode Fiber",
};

fiberCableValues.placement_type = {
  1: "Underground",
  2: "DirectBuried",
  3: "Aerial",
  4: "Indoor",
  5: "Unknown",
};

fiberCableValues.cable_size = {
  1: "1 Core",
  2: "2 Cores",
  12: "12 Core",
  24: "24 Cores",
  48: "48 Core",
  96: "96 Cores",
  144: "144 Core",
  288: "288 Core",
  576: "576 Core",
};

fiberCableValues.is_3rd_party = {
  1: "No",
  2: "Yes",
};

var fiberCableAttributes = {
  id: "",
  name: "Fiber Cable",
  type: "FIBER_CABLE",
  cable_size: "12",
  customer_material: "",
  creation_user: "",
  cable_type: "3",
  cable_category: "1",
  fiber_type: "3",
  // "diameter": "",
  // "placement_type": "5",
  is_3rd_party: "1",
  owner: "",
  calculated_length: "", // add slacks
  // "physical_length": "", // add slacks
  install_date: "",
  start: "",
  end: "",
  contractor: "",
  comments: "",
};

var poleFiberCableValues = fiberCableValues;

var poleFiberCableAttributes = {
  name: "Pole Fiber Cable",
  type: "POLE_FIBER_CABLE",
  cable_size: "12",
  customer_material: "",
  creation_user: "",
  cable_type: "3",
  cable_category: "1",
  fiber_type: "3",
  // "diameter": "",
  placement_type: "5",
  is_3rd_party: "1",
  owner: "",
  calculated_length: "",
  physical_length: "",
  install_date: "",
  contractor: "",
  comments: "",
};

var spliceClosureAttributes = {
  name: "Splice Closure", // cl _ manhole_name
  creation_user: "",
  type: "SPLICE_CLOSURE",
  capacity: "144",
  customer_material: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  manufacturer: "",
  comments: "",
  install_date: "",
};

/**
 * Pole SELECT Attribute Values
 */

var poleValues = {};
poleValues.foundation_type = {
  1: "Set Extra Deep In Earth",
  2: "Set Normally In Earth",
  3: "Set Normal With Concrete Backfill",
  4: "Set On Concrete Foundation",
  5: "Other",
};

poleValues.style = {
  1: "Decorative",
  2: "Normal",
  3: "Other",
};

poleValues.grounded = {
  1: "Yes",
  2: "No",
};

poleValues.class = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
  11: "H1",
  12: "H2",
  13: "H3",
  14: "H4",
  15: "Unknown",
};

poleValues.height = {
  1: "2.0 m",
  2: "2.5 m",
  3: "3.0 m",
  4: "3.5 m",
  5: "4.0 m",
  6: "4.5 m",
  7: "5.0 m",
  8: "5.5 m",
  9: "6.0 m",
  10: "6.5 m",
  11: "7.0 m",
  12: "7.5 m",
  13: "8.0 m",
  14: "8.5 m",
  15: "9.0 m",
  16: "9.5 m",
  17: "10.0 m",
};

poleValues.material = {
  1: "Wood",
  3: "Concrete",
  7: "Other",
};

poleValues.treatment_type = {
  1: "Treated",
  2: "Not Treated",
};

poleValues.has_riser = {
  1: "Yes",
  2: "No",
};

var poleAttributes = {
  name: "Pole",
  creation_user: "",
  type: "POLE",
  height: "",
  customer_material: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  owner: "",
  treatment_type: "",
  material: "",
  comments: "",
  install_date: "",
};

// ODF Select Values
var odfValues = {};

odfValues.preconnectorized = {
  1: "No",
  2: "Yes",
};

var odfAttributes = {
  name: "Patch Panel",
  creation_user: "",
  type: "ODF",
  preconnectorized: "1", // default: not preconnecterized
  input_ports: "12",
  output_ports: "12",
  customer_material: "",
  link: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  owner: "",
  comments: "",
  install_date: "",
};

var oltAttributes = {
  name: "OLT",
  creation_user: "",
  type: "OLT",
  output_ports: "12",
  customer_material: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  owner: "",
  comments: "",
  install_date: "",
};

var rtuAttributes = {
  name: "RTU",
  creation_user: "",
  type: "RTU",
  // "input_ports": "12",
  attached_rtu: "",
  output_ports: "12",
  customer_material: "",
  link: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  owner: "",
  comments: "",
  install_date: "",
};

var splitterAttributes = {
  name: "Splitter",
  creation_user: "",
  type: "SPLITTER",
  input_ports: "1",
  output_ports: "12",
  customer_material: "",
  latitude: "", //auto generated but modifiable
  longitude: "", //auto generated but modifiable
  comments: "",
  splitter_ratio: "",
  install_date: "",
};

var blueprintLineAttributes = {
  name: "Blueprint Lines",
  type: BLUE_PRINT_LINE,
};

var blueprintPointAttributes = {
  name: "Blueprint Points",
  type: BLUE_PRINT_POINT,
};

var roadAttributes = {
  name: "Road Name Here",
  type: FEATURE_ROAD,
};

export function getPropsFromFeature(feature) {
  let props = feature.getProperties();
  delete props.geometry;

  return props;
}

export function getFeatureProps(type) {
  if (type == FEATURE_BUILDING) return buildingAttributes;
  if (type == FEATURE_MANHOLE) return manholeAttributes;
  if (type == FEATURE_HANDHOLE) return handholeAttributes;
  if (type == FEATURE_CABINET) return cabinetAttributes;
  if (type == FEATURE_FIBER_CABLE) return fiberCableAttributes;
  if (type == FEATURE_DUCT) return ductAttributes;
  if (type == FEATURE_POLE) return poleAttributes;
  if (type == FEATURE_CLOSURE) return spliceClosureAttributes;
  if (type == FEATURE_ODF) return odfAttributes;
  if (type == FEATURE_SPLITTER) return splitterAttributes;
  if (type == FEATURE_SITE) return siteAttributes;
  if (type == FEATURE_FDP) return fdpAttributes;
  if (type == FEATURE_MICRO_TUNNEL) return microTunnelAttributes;
  if (type == FEATURE_ONT) return ontAttributes;
  if (type == FEATURE_FACE_PLATE) return facePlateAttributes;
  if (type == FEATURE_OLT) return oltAttributes;
  if (type == REDLINE_LINE) return redlineAtttributes;
  if (type == REDLINE_POINT) return redlinePointAtttributes;
  if (type == REDLINE_POLYGON) return redlinePolygonAtttributes;
  if (type == ROAD_CROSSING_HOOK) return roadCrossingHookAttributes;
  if (type == TRENCH) return trenchAttributes;
  if (type == POLE_DUCT) return poleDuctAttributes;
  if (type == POLE_FIBER_CABLE) return poleFiberCableAttributes;
  if (type == BLUE_PRINT_LINE) return blueprintLineAttributes;
  if (type == BLUE_PRINT_POINT) return blueprintPointAttributes;
  if (type == FEATURE_ROAD) return roadAttributes;
  if (type == FEATURE_RTU) return rtuAttributes;

  //roadCrossingHookAttributes
}

export function getSelectOptions(featureType) {
  if (featureType == FEATURE_ODF) return odfValues;
  if (featureType == FEATURE_DUCT) return ductValues;
  if (featureType == FEATURE_INNER_DUCT) return innerductValues;
  if (featureType == FEATURE_POLE) return poleValues;
  if (featureType == FEATURE_FIBER_CABLE) return fiberCableValues;
  if (featureType == FEATURE_CABINET) return cabinetValues;
  if (featureType == FEATURE_MANHOLE) return manholeValues;
  if (featureType == FEATURE_HANDHOLE) return handholeValues;
  if (featureType == TRENCH) return trenchValues;
  if (featureType == ROAD_CROSSING_HOOK) return crossingValues;
  if (featureType == POLE_FIBER_CABLE) return poleFiberCableValues;
  if (featureType == POLE_DUCT) return poleDuctValues;
}
