<template>
  <!-- check main.js for custom component details -->
  <c-submenu-layout>
    <!-- SubMenu Column -->
    <v-flex class="sub-menu" xs2>
      <c-sub-menu :items="sub_menu"></c-sub-menu>
    </v-flex>

    <!-- Main Content Column -->
    <v-flex xs10 class="main-content-border">
      <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

      <h1>Account Security</h1>

      <!-- v-card start -->
      <v-card flat class="content-card">
        <v-flex class="content-card-title" xs12>Security Information</v-flex>
        <v-layout class="pa-0 form-layout-border">
          <v-flex class="editable-content-form" xs5>
            <span class="span-title">Password</span>
            <span class="span-subtitle">
              <v-switch
                v-model="change_password_fields"
                color="blue"
                class="ma-2"
                :label="
                  change_password_fields
                    ? 'Change password fields are now active'
                    : 'Toggle switch to change your password'
                "
              ></v-switch>
            </span>
          </v-flex>
          <v-flex class="editable-content-form" xs7>
            <v-row>
              <v-flex xs12 class="pa-3">
                <v-text-field
                  v-model="current_password"
                  class="form-textfield"
                  type="password"
                  flat
                  :dense="input_dense"
                  :disabled="!change_password_fields"
                  placeholder="Current password"
                  :loading="select_loading"
                  solo
                ></v-text-field>
              </v-flex>

              <v-flex xs6 class="pa-3">
                <v-text-field
                  v-model="password"
                  class="form-textfield"
                  :dense="input_dense"
                  flat
                  :disabled="!change_password_fields"
                  type="password"
                  placeholder="New password"
                  :loading="select_loading"
                  solo
                ></v-text-field>
              </v-flex>

              <v-flex xs6 class="pa-3">
                <v-text-field
                  v-model="confirm_password"
                  class="form-textfield"
                  flat
                  solo
                  :disabled="!change_password_fields"
                  :dense="input_dense"
                  type="password"
                  placeholder="Repeat new password"
                  :loading="select_loading"
                ></v-text-field>
              </v-flex>
            </v-row>

            <v-btn
              v-if="change_password_fields"
              class="mt-3 mb-5"
              raised
              :loading="changing_password"
              :disabled="!passwords_match || current_password == ''"
              color="primary"
              @click="changePassword"
              >Change Password</v-btn
            >

            <v-row v-if="change_password_fields && error_message != ''">
              <v-flex xs12 class="pa-3 pt-0 error--text small-text">
                <p>{{ error_message }}</p>
              </v-flex>
            </v-row>

            <v-row v-if="api_success_message != ''">
              <v-flex xs12 class="pa-3 small-text">
                <p style="border-radius: 3px" class="pa-3 info white--text">
                  {{ api_success_message }}
                </p>
              </v-flex>
            </v-row>
          </v-flex>
        </v-layout>

        <v-layout class="pa-0 form-layout-border">
          <v-flex class="editable-content-form pb-5" xs5>
            <span class="span-title">2 Factor Authentication</span>
            <span class="span-subtitle">
              Enable/ disable 2-factor authentication.
              <div class="custom-info-section">{{ auth_message }}</div>
            </span>
          </v-flex>
          <v-flex class="editable-content-form" xs7>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="two_f_auth"
                  color="blue"
                  class="ma-2"
                  :label="two_f_auth ? 'Enabled' : 'Disabled'"
                ></v-switch>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </c-submenu-layout>
</template>

<script>
import appConfig from "../../config";
import { mapActions, mapState } from "vuex";

var authMessage = appConfig.two_factor_auth_message;

export default {
  data: () => ({
    sub_menu: [],
    bread_crumbs: [
      {
        text: "Security",
        href: "/profile/security",
      },
    ],
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    api_success_message: "",
    api_error_message: "",
    changing_password: false,
    current_password: "",
    password: "",
    confirm_password: "",
    select_loading: false,
    input_dense: true,
    two_f_auth: false,
    change_password_fields: false,
    auth_message: authMessage,
  }),
  mounted() {
    this.sub_menu = appConfig.profile_page_sub_menus;
  },
  methods: {
    ...mapActions({
      logout: "logout",
      resetPassword: "resetPassword",
    }),
    logoutUser() {
      setTimeout(() => {
        this.logout();
        location.reload();
      }, 5000);
    },
    changePassword() {
      this.api_error_message = "";
      this.api_success_message = "";
      this.changing_password = true;

      let apiData = {
        id: this.user.id,
        current_password: this.current_password,
        password: this.password,
        confirm_password: this.confirm_password,
      };

      this.resetPassword(apiData)
        .then((resp) => {
          this.changing_password = false;
          if (resp.status == 1) {
            this.api_success_message = resp.message;
            this.change_password_fields = false;

            // automatically log out user
            this.logoutUser();
          } else {
            this.api_error_message = resp.message;

            // clear api error message after 3 s
            let ctx = this;
            setTimeout(() => {
              ctx.api_error_message = "";
            }, 3000);
          }
        })
        .catch(() => {
          this.changing_password = false;
        });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
    }),
    passwords_match: function () {
      return (
        this.password != "" &&
        this.password.length >= 8 &&
        this.password === this.confirm_password
      );
    },
    error_message: function () {
      if (
        this.password != "" &&
        this.confirm_password != "" &&
        this.password != this.confirm_password
      ) {
        return "Passwords do not match";
      } else if (
        this.password != "" &&
        this.confirm_password != "" &&
        this.password.length < 8
      ) {
        return "Password must contain a minimum of 8 characters";
      } else if (
        this.password != "" &&
        this.confirm_password != "" &&
        this.current_password == ""
      ) {
        return "Current password field is required";
      } else if (this.api_error_message != "") {
        return this.api_error_message;
      }

      return "";
    },
  },
  watch: {
    change_password_fields: function (val) {
      if (!val) {
        this.current_password = "";
        this.password = "";
        this.confirm_password = "";
      }
    },
  },
};
</script>