import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from "../pages/Dashboard.vue"
import AuthPage from "../pages/AuthPage.vue"
import SetPasswordPage from "../pages/SetPassword.vue"
import ResetPasswordPage from "../pages/ResetPassword.vue"
import Settings from "../pages/settings/Container.vue"
import Users from "../pages/settings/Users.vue"
import LDAPSettings from "../pages/settings/LDAPSettings.vue"
import User from "../pages/users/User"
import UserGroups from "../pages/settings/UserGroups.vue"
// import Materials from "../pages/customers/Materials.vue"
import MaterialTypes from "../pages/settings/MaterialTypes.vue"
import PoleAccessories from "../pages/settings/PoleAccessories.vue"
import Support from "../pages/settings/Support.vue"
import SystemActivity from "../pages/settings/SystemActivity.vue"
import DataUploads from "../pages/settings/DataUploads.vue"
import Customers from "../pages/customers/Customers.vue"
import CustomerDetail from "../pages/customers/Customer.vue"
import CustomersContainer from "../pages/customers/Container.vue"

import Projects from "../pages/projects/Projects.vue"
import CustomerProjects from "../pages/projects/CompanyProjects.vue"
import Project from "../pages/projects/Project.vue"
import ProjectsContiner from "../pages/projects/Container.vue"

import Profile from "../pages/profile/Container.vue"
import Account from "../pages/profile/Account.vue"
import Security from "../pages/profile/Security.vue"
import CloseAccount from "../pages/profile/CloseAccount.vue"
// import { timeToTokenExpiry } from "../utils/utils"
// import GoogleMap from "../pages/mapping/GoogleMap.vue"
import GoogleMap from "../pages/mapping/VueMap.vue"

// import store from "../store/index"

Vue.use( Router )

const ifAuthenticated = ( to, from, next ) => {
    let userToken = localStorage.getItem( 'token' ) || '';

    // let oneHour = 1;
    // if ( userToken != '' && timeToTokenExpiry( userToken ) >= oneHour ) {

    if ( userToken != '' ) {
        next()

        return
    }

    next( '/login' )
}

const router = new Router( {
    mode: 'history',
    routes: [
        {
            path: '/mapping-studio',
            name: 'MapDemo',
            component: GoogleMap,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/network/:customer/mapping-studio',
            name: 'CustomerNetwork',
            component: GoogleMap,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/noc/:noc/network-faults',
            name: 'NocFaults',
            component: GoogleMap,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/',
            name: 'Dashboard',
            component: Dashboard,
            beforeEnter: ifAuthenticated
        },
        {
            path: '/customer-projects',
            name: 'CustomerProjects',
            component: ProjectsContiner,
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: '',
                    component: Projects
                },
                {
                    path: ':id/projects',
                    component: CustomerProjects
                },
                {
                    path: ':id/projects/:project_id/details',
                    component: Project
                },
                {
                    path: ':id/projects/:project_id/details/:job_id/mapping-studio',
                    component: GoogleMap
                }
            ]
        },
        // {
        //     path: '/projects',
        //     name: 'Projects',
        //     component: ProjectsContiner,
        //     beforeEnter: ifAuthenticated,
        //     children: [
        //         {
        //             path: '',
        //             component: Projects
        //         },
        //         {
        //             path: ':id/details',
        //             component: Project
        //         },
        //         {
        //             path: ':id/details/:job_id/mapping-studio',
        //             component: GoogleMap
        //         }
        //     ]
        // },
        {
            path: '/customers',
            name: 'Customers',
            component: CustomersContainer,
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: '',
                    component: Customers
                },
                {
                    path: ':id/details',
                    component: CustomerDetail
                },
                {
                    path: ':id/details/:company_user_id/user_details',
                    component: User
                },
            ]
        },
        {
            path: '/settings',
            component: Settings,
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: '',
                    component: Users
                },
                {
                    path: 'users/:user_id/details',
                    component: User
                },
                {
                    path: 'user-groups',
                    component: UserGroups
                },
                {
                    path: 'ldap-settings',
                    component: LDAPSettings
                },
                {
                    path: 'materials-types',
                    component: MaterialTypes
                },
                {
                    path: 'data-uploads',
                    component: DataUploads
                },
                {
                    path: 'pole-accessories',
                    component: PoleAccessories
                },
                {
                    // help
                    path: 'help',
                    component: Support
                },
                {
                    // authorised users only - which user performed what action
                    path: 'system-activity',
                    component: SystemActivity
                },

            ]
        },
        {
            path: '/profile',
            component: Profile,
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: '',
                    name: 'Account',
                    component: Account
                },
                {
                    path: 'security',
                    component: Security
                },
                {
                    path: 'close-account',
                    component: CloseAccount
                }
            ]
        },
        {
            path: '/login',
            name: 'AuthPage',
            component: AuthPage
        },
        {
            path: '/set-password',
            name: 'SetPasswordPage',
            component: SetPasswordPage
        },
        {
            path: '/reset-password',
            name: 'ResetPasswordPage',
            component: ResetPasswordPage
        },
    ]
} )

export default router