<template>
  <v-layout>
    <v-flex xs12 class="mt-9">
      <v-data-table
        :loading="loading"
        :items="faultsData"
        :headers="header"
        hide-default-footer
      >
        <template v-slot:[`item.action`]="{ item }"
          ><v-btn
            :disabled="loading"
            @click="locateFault(item)"
            text
            color="success"
            >Locate</v-btn
          ></template
        >
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dialogMixins from "../../../utils/mixins";
import messagingMixin from "../../../utils/messagingMixin";
import {
  FAULT,
  FEATURE_FIBER_CABLE,
  FEATURE_HANDHOLE,
  FEATURE_MANHOLE,
  FEATURE_ODF,
  FEATURE_POLE,
} from "../../../utils/feature_constants";
import {
  createFeatureFromCoordinates,
  getDistanceBetweenPoints,
  getFeatureById,
  getFeaturesByType,
  getFeaturesOnLineFeature,
  getPointOnLineString,
} from "../../../utils/map_functions";

export default {
  name: "FaultsTableComponent",
  props: {
    vectorSources: {
      type: Array,
    },
    faultsData: Array,
  },
  mixins: [dialogMixins, messagingMixin],
  data: () => ({
    loading: false,
    header: [
      {
        text: "Optical Route",
        value: "name",
        sortable: false,
      },
      {
        text: "Fault Location (km)",
        value: "fault_distance",
        sortable: false,
      },
      {
        text: "Locate",
        value: "action",
      },
    ],
  }),
  mounted() {
    console.log("Faults...", this.faultsData);
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    ...mapActions({
      getConnections: "getConnections",
      traceSignal: "getConnections",
      getMonitoredRoutes: "getMonitoredRoutes",
      getFeature: "getFeature",
    }),

    async locateFault(fault) {
      try {
        this.loading = true;
        const rtuData = await this.getMonitoredRoutes({ route_id: fault.id });
        if (rtuData && rtuData.port) {
          const connectionsData = await this.getConnections({
            node: rtuData.port,
            node_type: "port",
            signal_trace: true,
            include_length: true,
          });

          if (connectionsData && connectionsData.signal_trace) {
            var cumulativeCableDistance = 0;
            const faultDistanceMeters = fault.fault_distance * 1000;
            const signalTraceData = connectionsData.signal_trace;
            for (var i = 0; i < signalTraceData.length; i++) {
              const traceObj = signalTraceData[i];
              if (
                traceObj.optical_length + cumulativeCableDistance <
                faultDistanceMeters
              ) {
                cumulativeCableDistance += traceObj.optical_length;
                continue;
              }

              // get fiber cable from API
              const fiberCableObj = await this.getFeature({
                id: traceObj.fiber_cable_id,
                type: FEATURE_FIBER_CABLE,
              });

              // we are on the cable where the fault is - pass current cable to algorithm
              var newFaultDistance =
                faultDistanceMeters - cumulativeCableDistance;

              // get all slack features on cable
              var slackFeatures = [];
              slackFeatures = slackFeatures.concat(
                getFeaturesByType(FEATURE_ODF, this.vectorSources)
              );
              slackFeatures = slackFeatures.concat(
                getFeaturesByType(FEATURE_MANHOLE, this.vectorSources)
              );
              slackFeatures = slackFeatures.concat(
                getFeaturesByType(FEATURE_HANDHOLE, this.vectorSources)
              );
              slackFeatures = slackFeatures.concat(
                getFeaturesByType(FEATURE_POLE, this.vectorSources)
              );

              const fiberCable = getFeatureById(
                traceObj.fiber_cable_uuid,
                this.vectorSources
              );
              const featuresOnCable = getFeaturesOnLineFeature(
                fiberCable,
                slackFeatures
              );

              var orderedCableFeatures = featuresOnCable.map((feature) => {
                return {
                  feature: feature,
                  distance: 0,
                };
              });

              const cableStart = fiberCable.getGeometry().getFirstCoordinate();
              orderedCableFeatures = orderedCableFeatures.map(
                (orderedFeature) => {
                  const feature = orderedFeature.feature;
                  const distance = getDistanceBetweenPoints(
                    cableStart,
                    feature.getGeometry().getCoordinates(),
                    fiberCable
                  );
                  orderedFeature.distance = distance;

                  return orderedFeature;
                }
              );

              orderedCableFeatures.sort(
                (featA, featB) => featA.distance - featB.distance
              );

              var targetFeature;
              const cableSlacks = fiberCableObj.slacks;
              for (var j = 0; j < orderedCableFeatures.length; j++) {
                const distanceOnCable = orderedCableFeatures[j].distance;
                if (distanceOnCable > newFaultDistance) {
                  if (j == 0) targetFeature = orderedCableFeatures[j];
                  else targetFeature = orderedCableFeatures[j - 1];
                  break;
                }
              }

              if (targetFeature) {
                const feature = targetFeature.feature;
                const distanceOnCable = targetFeature.distance;
                const slacks = cableSlacks.filter(
                  (slack) => slack.location_uuid == feature.getId()
                );

                var faultFeature;

                if (distanceOnCable < newFaultDistance) {
                  // distance is along cable, but after slack
                  var totalSlack = 0;
                  if (slacks.length > 0) {
                    slacks.forEach(
                      (slackObj) => (totalSlack += parseFloat(slackObj.slack))
                    );
                  }
                  newFaultDistance -= parseFloat(totalSlack);
                  var faultPoint = getPointOnLineString(
                    fiberCable,
                    newFaultDistance
                  );
                  faultFeature = createFeatureFromCoordinates(
                    faultPoint.geometry.coordinates
                  );
                } else {
                  // fault is at the current location
                  faultFeature =
                    createFeatureFromCoordinates[
                      feature.getGeometry().getCoordinates()
                    ];
                }

                if (faultFeature) {
                  faultFeature.set("type", FAULT);
                  this.$emit("locate-fault", faultFeature);
                }
              }

              break;
            }
          }
        }

        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>