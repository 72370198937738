/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import {
  FEATURE_MEASUREMENT_AREA,
  FEATURE_MEASUREMENT_LENGTH,
  POLE_FIBER_CABLE,
  FEATURE_FIBER_CABLE,
  FEATURE_DUCT,
  FEATURE_ODF,
  FEATURE_CABLE_HIGHLIGHT,
  HIGHLIGHT_FROM_ID,
  HIGHLIGHT_TO_ID,
  FEATURE_FACE_PLATE,
  FEATURE_ONT,
  FEATURE_OLT,
  FEATURE_FDP,
  FEATURE_CLOSURE,
  FEATURE_SPLITTER,
  featureSnapConfig,
  FEATURE_POLE,
  FEATURE_MANHOLE,
  FEATURE_BUILDING,
  FEATURE_SITE,
  FEATURE_MICRO_TUNNEL,
  FEATURE_HANDHOLE,
  TRENCH,
  POLE_DUCT,
  GABION,
  POINT_FEATURE_ID,
  FEATURE_POINT_HIGHLIGHT,
  FEATURE_RTU,
  FAULT,
} from "../feature_constants";
import { v4 as uuidv4 } from "uuid";
import { Draw, Snap, Modify } from "ol/interaction";
import {
  getDragSelectedFeatures,
  getCompositionURLKey,
  getSnapFeatureJSON,
  getFeatureAtCoordinates,
  splitLargeDuct,
  getFeatureById,
  getHighlightedLineFeature,
  calculateLengthProperty,
  splitLineFeature,
  getFeaturesOnLineFeature,
  getHighlightedFeature,
} from "../../utils/map_functions";
import {
  getFeatureProps,
  getPropsFromFeature,
} from "../../utils/feature_properties";
import {
  getFeatureStyle,
  fromSegmentStyle,
  toSegmentStyle,
  thirdPartyFeatureStyle,
} from "../feature_styles";
import { mapActions } from "vuex";
import Collection from "ol/Collection";
import { LineString } from "ol/geom";

export default {
  data: () => ({
    snap_features: [],
    feature_saving: false,
    move_tool_enabled: false,
    inner_ducts: null,
  }),
  computed: {
    is_edit_mode: function() {
      return this.currentFeatureType != undefined;
    },
  },
  methods: {
    ...mapActions({
      createFeature: "createFeature",
      removeFeature: "removeFeature",
      createCompositionFeature: "createCompositionFeature",
      getCompositionFeatures: "getCompositionFeatures",
      getFiberSegmentFeatures: "getFiberCableSegmentFeatures",
      getDuctSegmentFeatures: "getDuctSegmentFeatures",
      removeCompositionFeature: "removeCompositionFeature",
      updateInnerDuctCable: "updateInnerDuctCable",
    }),

    enableMoveTool() {
      if (this.selectedFeatures.length > 0) {
        this.setEditable(this.selectedFeatures);
        this.move_tool_enabled = true;
        this.displaySuccessAlert("Activated move tool");
      } else {
        this.displayErrorAlert("Select some features to edit/move");
      }
    },

    disableMoveTool() {
      this.map.removeInteraction(this.modify);
      this.map.removeInteraction(this.snap);
      this.modify = null;
      this.snap = null;
      this.move_tool_enabled = false;
      this.displaySuccessAlert("Deactivated move tool");
    },

    highlightSelectedFeature(feature) {
      if (feature.get("pk") || feature.get("blown_cable")) {
        if (feature.getGeometry() instanceof LineString) {
          this.highlightFromSegment(feature);
        } else {
          this.highlightPointFeature(feature);
        }
      }
    },

    removeHighlightedSegments() {
      this.deleteAllFeatures(FEATURE_CABLE_HIGHLIGHT);
    },

    highlightFromSegment(lineString) {
      // delete initial segment
      this.deleteHighlightFeature(HIGHLIGHT_FROM_ID, FEATURE_CABLE_HIGHLIGHT);

      var feature = getHighlightedLineFeature(
        lineString,
        HIGHLIGHT_FROM_ID,
        FEATURE_CABLE_HIGHLIGHT
      );
      let highlightStyle =
        !feature.get("is_3rd_party") || feature.get("is_3rd_party") == "1"
          ? fromSegmentStyle
          : thirdPartyFeatureStyle;
      feature.setStyle(highlightStyle);

      this.bulkAddFeatureToMap([feature], FEATURE_CABLE_HIGHLIGHT);
    },

    highlightToSegment(lineString) {
      // delete initial segment
      this.deleteHighlightFeature(HIGHLIGHT_TO_ID, FEATURE_CABLE_HIGHLIGHT);

      var feature = getHighlightedLineFeature(
        lineString,
        HIGHLIGHT_TO_ID,
        FEATURE_CABLE_HIGHLIGHT
      );
      let highlightStyle =
        !feature.get("is_3rd_party") || feature.get("is_3rd_party") == "1"
          ? toSegmentStyle
          : thirdPartyFeatureStyle;
      feature.setStyle(highlightStyle);
      this.bulkAddFeatureToMap([feature], FEATURE_CABLE_HIGHLIGHT);
    },

    highlightPointFeature(pointFeature) {
      // delete initial segment
      this.deleteHighlightFeature(POINT_FEATURE_ID, FEATURE_POINT_HIGHLIGHT);

      var feature = getHighlightedFeature(
        pointFeature,
        POINT_FEATURE_ID,
        FEATURE_POINT_HIGHLIGHT
      );
      let highlightStyle = getFeatureStyle(feature);
      feature.setStyle(highlightStyle);
      this.bulkAddFeatureToMap([feature], FEATURE_POINT_HIGHLIGHT);
    },

    exitEditMode() {
      if (this.currentFeatureType) {
        if (this.featureSaved) {
          this.drawType = undefined;
          this.snap_features = [];
          this.currentFeatureType = null;
          this.clearCurrentFeature();
          this.removeInteractions();
          this.hideFeatureWindow();
        } else {
          this.displayErrorAlert("Save the current feature attributes first.");
        }
      }
    },

    drawPoint(menuItem) {
      this.exitEditMode();
      this.hideFeatureWindow();

      this.currentFeatureType = menuItem.feature_type;
      this.drawType = "Point";
      this.addDrawInteraction();
    },

    drawLineString(menuItem) {
      this.exitEditMode();
      this.hideFeatureWindow();

      this.currentFeatureType = menuItem.feature_type;
      this.drawType = "LineString";
      this.addDrawInteraction();
    },

    drawPolygon(menuItem) {
      this.exitEditMode();
      this.hideFeatureWindow();

      this.currentFeatureType = menuItem.feature_type;
      this.drawType = "Polygon";
      this.addDrawInteraction();
    },

    drawMeasurementFeature(menuItem) {
      this.exitEditMode();

      if (menuItem.feature_type == FEATURE_MEASUREMENT_AREA)
        this.drawPolygon(menuItem);
      if (menuItem.feature_type == FEATURE_MEASUREMENT_LENGTH)
        this.drawLineString(menuItem);

      if (menuItem.action == "clearAll") {
        this.deleteAllFeatures(menuItem.type);
        this.removeMeasurementOverlays();
      }
    },

    showSelectedFeatures() {
      this.clearCurrentFeature();
      this.removeHighlightedSegments();
      this.selectedFeaturesVisible = true;
    },

    hideSelectedFeatures() {
      this.selectedFeaturesVisible = false;
    },

    openFeatureWindow(feature = undefined) {
      if (feature) {
        this.currentFeature = feature;
        this.hideSelectedFeatures();
        this.highlightSelectedFeature(feature);
      }
      this.feature_window_class = "";
      this.tabs = 0;
    },

    hideFeatureWindow(config = undefined) {
      if (!config || config.hide_feature_window) {
        this.deleteAllHighlightFeatures();
        this.removeHighlightedSegments();

        if (!this.move_tool_enabled) {
          if (this.featureSaved) {
            this.hideSelectedFeatures();
            this.selectedFeatures = [];
            this.clearCurrentFeature();
          } else {
            this.displayErrorAlert(
              "Save the current feature attributes first."
            );
          }
        }
      }
    },

    removeInteractions() {
      this.map.removeInteraction(this.draw);
      this.map.removeInteraction(this.snap);
      this.map.removeInteraction(this.modify);
      this.modifiedFeature = null;
      this.currentVectorSource = null;
    },

    clearCurrentFeature() {
      this.currentFeature = null;
    },

    handleConnectionsClick(item) {
      if (item.action == "connection_manager") this.showConnectionManager();
      if (item.action == "merge_manager") this.showMergeManager();
    },

    showConnectionManager() {
      this.connectionManagerVisible = true;
    },

    showMergeManager() {
      this.mergeFeaturesManagerVisible = true;
    },

    showPrintManager() {
      this.printManagerVisible = true;
    },

    showBlowFiberManager() {
      this.blowFiberManagerVisible = true;
    },

    execBlowFiberCable(payload) {
      if (payload.fiberCable && payload.inner_ducts) {
        this.inner_ducts = payload.inner_ducts;
        let fiberCableFeature = payload.fiberCable;
        fiberCableFeature.unset("pk");

        fiberCableFeature = this.setFeatureProperties(
          fiberCableFeature,
          getFeatureProps(FEATURE_FIBER_CABLE)
        );

        // set cable props and create cable
        fiberCableFeature.setId(uuidv4());
        fiberCableFeature.set("job_id", this.$route.params.job_id);
        fiberCableFeature.set("created_by", this.user.id);

        // open attributes table
        this.addFeatureToMap(fiberCableFeature);
        this.hideBlowFiberManager();
        this.openFeatureWindow(fiberCableFeature);
      }
    },

    hideConnectionManager() {
      this.deleteAllHighlightFeatures();
      this.connectionManagerVisible = false;
    },

    hidePrintManager() {
      this.deleteAllHighlightFeatures();
      this.printManagerVisible = false;
    },

    hideMergeManager() {
      this.deleteAllHighlightFeatures();
      this.mergeFeaturesManagerVisible = false;
    },

    hideBlowFiberManager() {
      this.deleteAllHighlightFeatures();
      this.blowFiberManagerVisible = false;
    },

    async mergeFeatures(mergedeaturesObj) {
      if (mergedeaturesObj && mergedeaturesObj.merged) {
        var feature = mergedeaturesObj.merged;

        feature.setProperties(getPropsFromFeature(mergedeaturesObj.from));
        feature.setId(uuidv4());
        feature.unset("pk");

        // set job id and creation user
        feature.set("job_id", this.$route.params.job_id);
        feature.set("created_by", this.user.id);

        let snap_features = this.getSnapFeatures(feature);

        this.deleteAllHighlightFeatures();

        await this.saveFeature(feature, snap_features);

        let silentDelete = true;
        await this.deleteFeature(mergedeaturesObj.from, silentDelete);
        await this.deleteFeature(mergedeaturesObj.to, silentDelete);
      }
    },

    addDrawInteraction() {
      this.removeInteractions();

      // set up draw
      var source = this.getFeatureVectorSource(this.currentFeatureType);
      this.currentVectorSource = source;

      var draw = new Draw({
        source: this.getFeatureVectorSource(this.currentFeatureType),
        type: this.drawType,
      });
      draw.on("drawstart", this.drawStartListener);
      draw.on("drawend", this.drawEndListener);
      this.draw = draw;

      // set up snap
      let snapCollection = this.getFeatureSnapCollection(
        this.currentFeatureType
      );
      this.snap = new Snap({
        features: snapCollection,
        pixelTolerance: 20,
      });

      // set up modify
      this.modify = new Modify({ source: source });
      this.modify.on("modifyend", this.modifyEndListener);

      // add all interactions
      this.map.addInteraction(this.draw);
      this.map.addInteraction(this.snap);
      // this.map.addInteraction( this.modify );
    },

    drawStartListener(event) {
      if (this.isMeasurementFeature) {
        // track measurement events
        this.trackMeasurementSketch(event);
      }
    },

    drawEndListener(event) {
      if (this.isMeasurementFeature) this.clearMeasurements();

      if (this.shouldDrawFeature()) {
        if (!this.isMeasurementFeature) this.featureSaved = false;

        var feature = event.feature;
        feature.setId(uuidv4());
        feature.set("type", this.currentFeatureType);

        feature = this.setFeatureProperties(
          feature,
          getFeatureProps(this.currentFeatureType)
        );

        // set job id and creation user
        feature.set("job_id", this.$route.params.job_id);
        feature.set("created_by", this.user.id);
        this.snap_features = this.getSnapFeatures(feature);

        // set fiber cable style since they can have multiple styles
        if (this.currentFeatureType == FEATURE_FIBER_CABLE) {
          feature.setStyle(getFeatureStyle(feature));
        }

        this.currentFeature = feature;
      } else {
        if (this.currentVectorSource && event.feature) {
          try {
            this.currentVectorSource.removeFeature(event.feature);
          } catch (error) {
            console.log(error);
          }
        }

        this.displayErrorAlert(
          "You must save the current feature attributes first."
        );
      }
    },

    /**
     * Returns a boolean showing whether we should go ahead and draw the current feature, or
     * we should abort sue to some reason
     */
    shouldDrawFeature() {
      if (!this.featureSaved) return false;

      return true;
    },

    setEditable(features) {
      let collection = new Collection(features);

      this.snap = new Snap({
        features: collection,
        pixelTolerance: 20,
      });

      this.modify = new Modify({ features: collection });
      this.modify.on("modifyend", this.modifyEndListener);
      this.map.addInteraction(this.modify);
      this.map.addInteraction(this.snap);
    },

    modifyEndListener(event) {
      // update all selected features
      this.selectedFeatures.forEach((feature) => this.saveFeature(feature));
    },

    searchNetworkFeatures(searchTerm) {
      searchTerm = searchTerm.toLowerCase();
      var matches = [];

      if (this.vectorSources.length != undefined) {
        this.vectorSources.forEach((vectorsource) => {
          var features = vectorsource.getFeatures();
          features.forEach((feature) => {
            let featureProperties = feature.getProperties();
            for (let prop in featureProperties) {
              if (feature.get(prop)) {
                if (
                  String(feature.get(prop))
                    .toLowerCase()
                    .includes(searchTerm)
                ) {
                  if (matches.length < 20) matches.push(feature);
                  break;
                }
              }
            }
          });
        });
      }

      return matches;
    },

    async saveFeature(
      feature,
      snap_features = undefined,
      config = undefined,
      splitter = undefined
    ) {
      this.feature_saving = true;

      if (snap_features) this.snap_features = snap_features;
      if (this.snap_features.length == 0)
        this.snap_features = this.getSnapFeatures(feature);
      if (!feature.getId()) feature.setId(uuidv4());
      let featureCollection = [];
      var shouldRedrawDucts = false;

      // ave duct
      if (feature.get("type") == FEATURE_DUCT) {
        this.saveDuct({
          feature: feature,
          snapFeatures: this.snap_features,
          splitter: splitter,
        });

        return;
      }

      // save access point
      if (
        feature.get("type") == FEATURE_MANHOLE ||
        feature.get("type") == FEATURE_HANDHOLE
      ) {
        this.saveAccessPoint({
          feature: feature,
          snapFeatures: this.snap_features,
        });

        return;
      }

      // set duct start/end features
      if (
        feature.get("type") == FEATURE_DUCT &&
        feature.get("pk") == undefined
      ) {
        // check if this is a normal duct with more than 1 inner ducts
        if (
          parseInt(feature.get("duct_type")) == 1 &&
          parseInt(feature.get("inner_ducts")) > 1
        ) {
          // redraw ducts to pull all the created ducts
          shouldRedrawDucts = true;
        }

        let featureProps = feature.getProperties();

        // remove the geometry field -- it represents the coordinates
        delete featureProps.geometry;

        // create a feature collection
        var splitDucts = splitLargeDuct(feature, this.snap_features);

        // hack: rewrite this section
        if (splitDucts.length == 0 && this.snap_features.length == 1)
          splitDucts = splitLineFeature(feature, this.snap_features[0]);

        if (splitDucts.length > 0) {
          splitDucts.forEach((subDuct) => {
            var duct;
            if (subDuct.duct) {
              duct = this.geoJSON.readFeature(subDuct.duct);
            } else {
              duct = subDuct;
            }

            featureProps.geometry = duct.getGeometry();
            duct.setProperties(featureProps);
            duct.set("calculated_length", calculateLengthProperty(duct));
            duct.setId(uuidv4());

            featureCollection.push(duct);
          });
        }
      }

      let payload = {};
      payload.type = feature.get("type");
      if (featureCollection.length > 0) {
        payload.data = JSON.stringify(
          this.geoJSON.writeFeaturesObject(featureCollection)
        );
      } else {
        payload.data = JSON.stringify(this.geoJSON.writeFeatureObject(feature));
      }

      // in case of updates, add the pk
      if (feature.get("pk")) payload.pk = feature.get("pk");

      this.createFeature(payload).then((res) => {
        if (res.length && featureCollection.length > 0) {
          // remove original feature from map if we're not splitting a duct
          let deleteSilently = true;
          if (!feature.get("pending"))
            this.deleteFeature(feature, deleteSilently);

          // check if this is a normal/hdpe duct and redraw if inner ducts > 1
          if (shouldRedrawDucts) {
            this.fetchFeatures(feature.get("type"));
          }

          // add ducts to map with primary keys from the backend
          featureCollection.forEach((duct) => {
            let savedDuct = res.find((obj) => obj.uuid == duct.getId());
            if (savedDuct) duct.set("pk", savedDuct.pk);
            duct.set("calculated_length", calculateLengthProperty(duct));

            // uniquely snap each created sub duct to the manhole/feature it starts from, and the feature it ends at
            let ductSnapFeatures = [];
            ductSnapFeatures.push(
              getFeatureById(duct.get("start"), this.vectorSources)
            );
            ductSnapFeatures.push(
              getFeatureById(duct.get("end"), this.vectorSources)
            );

            this.snapLineFeature(duct, ductSnapFeatures).then(() => {
              // add duct to map
              this.addFeatureToMap(duct);
              this.feature_saving = false;
              this.featureSaved = true;
              this.hideFeatureWindow();
            });
          });
        } else {
          feature.set("pk", res.pk);
          if (this.snap_features.length > 0) {
            if (feature.getGeometry() instanceof LineString) {
              // check if this is a fiber cable and
              if (feature.get("type") == FEATURE_FIBER_CABLE) {
                // check if we have any inner ducts set from blowing a fiber cable
                if (this.inner_ducts && this.inner_ducts.length > 0) {
                  // associate fiber cable with all inner ducts
                  let inner_ducts = [...this.inner_ducts];
                  inner_ducts.forEach((inner_duct) => {
                    let payload = {};
                    payload.fiber_cable = res.pk;
                    payload.inner_duct = inner_duct;
                    this.updateInnerDuctCable(payload);
                  });

                  // clear inner ducts
                  this.inner_ducts = null;
                }
              }

              this.snapLineFeature(feature, this.snap_features).then(() => {
                this.feature_saving = false;
                this.featureSaved = true;
                this.hideFeatureWindow(config);
              });
            } else {
              // we just added a point feature, so check if we placed it on a larger duct
              let ductsArray = this.snap_features.filter(
                (f) => f.get("type") == FEATURE_DUCT && f.get("pk")
              );
              this.shouldSplitDucts(ductsArray, feature);

              this.snapPointFeature(feature, this.snap_features).then(() => {
                this.feature_saving = false;
                this.featureSaved = true;
                this.hideFeatureWindow(config);
              });
            }
          } else {
            this.feature_saving = false;
            this.featureSaved = true;
            this.hideFeatureWindow(config);
          }
        }
      });

      return;
    },

    /**
     * For Fiber Cables, Ducts
     */
    async snapLineFeature(feature, snappedFeatures) {
      if (feature.get("pk") != undefined) {
        let pk = feature.get("pk");
        let payload = {};
        payload.type = getCompositionURLKey(feature);
        if (feature.get("type") == FEATURE_FIBER_CABLE)
          payload.fiber_cable = pk;
        if (feature.get("type") == FEATURE_DUCT) payload.duct = pk;

        snappedFeatures.forEach((snapFeature) => {
          if (snapFeature) {
            let payloadCopy = {
              ...payload,
              ...getSnapFeatureJSON(snapFeature),
            };
            this.createCompositionFeature(payloadCopy);
          }
        });
      }
    },

    /**
     * For Buildings, Poles, Cabinets, Manholes
     */
    async snapPointFeature(feature, snappedFeatures) {
      snappedFeatures.forEach((snapFeature) => {
        if (snapFeature && feature) {
          let payload = {};
          payload.type = getCompositionURLKey(snapFeature);
          let payloadCopy = {
            ...payload,
            ...getSnapFeatureJSON(feature),
            ...getSnapFeatureJSON(snapFeature),
          };

          this.createCompositionFeature(payloadCopy);
        }
      });
    },

    /**
     * Split ducts if there was a manhole/handhole placed on them
     */
    async shouldSplitDucts(ductsArray, feature) {
      if (ductsArray.length > 0) {
        ductsArray.forEach((duct) => {
          if (duct.get("pk")) {
            // check if the current feature is on some duct
            const featuresOnDuct = getFeaturesOnLineFeature(duct, [feature]);

            // if this feature is on the duct, use it as the splitter
            const splitDucts = splitLineFeature(duct, feature);
            if (splitDucts.length > 0) {
              let ductToDeleteID = duct.get("pk");
              duct.unset("pk");
              duct.unset("id");
              const snapFeatures = [feature];
              this.saveFeature(duct, snapFeatures).then(() => {
                const silentDelete = true;
                duct.set("pk", ductToDeleteID);
                this.deleteFeature(duct, silentDelete);
              });
            }
          }
        });
      }
    },

    /**
     * Split fiber cables if there was a manhole/handhole placed on them
     */
    shouldSplitFiberCable(featureArray, feature) {
      var cables;

      if (featureArray.length > 0) {
        featureArray.forEach((cable) => {
          if (cable.get("pk")) {
            cables = splitLineFeature(cable, feature);
          }
        });
      }

      return cables;
    },

    /**
     * Split trenches if there was a manhole/handhole placed on them
     */
    shouldSplitTrench(featureArray, feature) {
      var trenches;

      if (featureArray.length > 0) {
        featureArray.forEach((trench) => {
          if (trench.get("pk")) {
            trenches = splitLineFeature(trench, feature);
          }
        });
      }

      return trenches;
    },

    async deleteFeature(feature, silent = false) {
      let pk = feature.get("pk");
      let featureType = feature.get("type");

      let vectorSource = this.getFeatureVectorSource(feature.get("type"));
      if (vectorSource) {
        try {
          vectorSource.removeFeature(feature);
        } catch (error) {
          console.log(error);
        }

        // remove feature through API
        if (pk) {
          let payload = {
            type: featureType,
            id: pk,
          };
          this.removeFeature(payload).then((res) => {
            if (!silent) this.displaySuccessAlert("Feature deleted");
          });
        }

        this.map.removeInteraction(this.modify);
        this.feature_saving = false;
        this.clearCurrentFeature();
        this.featureSaved = true;
        // this.hideFeatureWindow()
        this.showSelectedFeatures();
        this.snap_features = [];
      }
    },

    deleteFeatureById(featureId, silent = false) {
      let feature = getFeatureById(featureId, this.vectorSources);
      if (feature) {
        let pk = feature.get("pk");
        let featureType = feature.get("type");
        let vectorSource = this.getFeatureVectorSource(featureType);
        if (vectorSource) {
          try {
            vectorSource.removeFeature(feature);
          } catch (error) {
            console.log(error);
          }

          // remove feature through API
          if (pk) {
            let payload = {
              type: featureType,
              id: pk,
            };
            this.removeFeature(payload).then((res) => {
              if (!silent) this.displaySuccessAlert("Feature deleted");
            });
          }

          this.map.removeInteraction(this.modify);
          this.feature_saving = false;
          this.clearCurrentFeature();
          this.featureSaved = true;
          this.hideFeatureWindow();
          this.snap_features = [];
        }
      }
    },

    deleteHighlightFeature(featureId, featureType) {
      let vectorSource = this.getFeatureVectorSource(featureType);
      if (vectorSource) {
        try {
          let feature = vectorSource.getFeatureById(featureId);
          if (feature) {
            vectorSource.removeFeature(feature);
          }
        } catch (error) {
          // console.log( error ) -- logs are for losers!
        }
      }
    },

    deleteAllHighlightFeatures() {
      this.highlightVectorSource.clear();
    },

    async addFeatureToMap(feature) {
      let vectorSource = this.getFeatureVectorSource(feature.get("type"));
      if (vectorSource) vectorSource.addFeature(feature);
    },

    async bulkAddFeatureToMap(features, type) {
      let vectorSource = this.getFeatureVectorSource(type);
      if (vectorSource) vectorSource.addFeatures(features);
    },

    async addFaultToMap(feature) {
      await this.clearFaults();
      await this.addFeatureToMap(feature);
      await this.zoomToFeature(feature);
    },

    async clearFaults() {
      this.deleteAllFeatures(FAULT);
    },

    dragBoxListener() {
      // control what happens after the dragbox event is complete
      this.removeHighlightedSegments();

      let extent = this.dragBox.getGeometry().getExtent();
      this.selectedFeatures = getDragSelectedFeatures(
        extent,
        this.vectorSources
      );
      this.showSelectedFeatures();
    },

    getFeatureSnapCollection(featureType) {
      let featureArray = [];

      if (
        featureType == FEATURE_FIBER_CABLE ||
        featureType == POLE_FIBER_CABLE
      ) {
        featureArray.push(...this.buildingsVectorSource.getFeatures());
        featureArray.push(...this.sitesVectorSource.getFeatures());
        featureArray.push(...this.cabinetsVectorSource.getFeatures());
        featureArray.push(...this.manholesVectorSource.getFeatures());
        featureArray.push(...this.handholesVectorSource.getFeatures());
        featureArray.push(...this.polesVectorSource.getFeatures());
        featureArray.push(...this.ductsVectorSource.getFeatures());

        // add blueprint layers to allow snapping
        featureArray.push(...this.blueprintVectorSource.getFeatures());
      }

      if (featureType == FEATURE_MICRO_TUNNEL) {
        featureArray.push(...this.buildingsVectorSource.getFeatures());
        featureArray.push(...this.sitesVectorSource.getFeatures());
        featureArray.push(...this.cabinetsVectorSource.getFeatures());
        featureArray.push(...this.manholesVectorSource.getFeatures());
        featureArray.push(...this.handholesVectorSource.getFeatures());
        featureArray.push(...this.roadCrossingVectorSource.getFeatures());

        // add blueprint layers to allow snapping
        featureArray.push(...this.blueprintVectorSource.getFeatures());
      }

      if (featureType == TRENCH) {
        featureArray.push(...this.buildingsVectorSource.getFeatures());
        featureArray.push(...this.sitesVectorSource.getFeatures());
        featureArray.push(...this.cabinetsVectorSource.getFeatures());
        featureArray.push(...this.manholesVectorSource.getFeatures());
        featureArray.push(...this.handholesVectorSource.getFeatures());
        featureArray.push(...this.roadCrossingVectorSource.getFeatures());

        // add blueprint layers to allow snapping
        featureArray.push(...this.blueprintVectorSource.getFeatures());
      }

      if (featureType == FEATURE_DUCT) {
        featureArray.push(...this.buildingsVectorSource.getFeatures());
        featureArray.push(...this.sitesVectorSource.getFeatures());
        featureArray.push(...this.cabinetsVectorSource.getFeatures());
        featureArray.push(...this.manholesVectorSource.getFeatures());
        featureArray.push(...this.handholesVectorSource.getFeatures());
        featureArray.push(...this.roadCrossingVectorSource.getFeatures());
        featureArray.push(...this.trenchVectorSource.getFeatures());
        featureArray.push(...this.gabionVectorSource.getFeatures());
        featureArray.push(...this.bridgeAttachementVectorSource.getFeatures());

        // add blueprint layers
        featureArray.push(...this.blueprintVectorSource.getFeatures());
      }

      if (featureType == GABION) {
        featureArray.push(...this.trenchVectorSource.getFeatures());
      }

      if (featureType == FEATURE_SITE || featureType == FEATURE_BUILDING) {
        // add blueprint layers to allow snapping
        featureArray.push(...this.blueprintVectorSource.getFeatures());
      }

      if (featureType == POLE_DUCT) {
        featureArray.push(...this.polesVectorSource.getFeatures());
      }

      if (featureType == FEATURE_ODF || FEATURE_RTU) {
        featureArray.push(...this.buildingsVectorSource.getFeatures());
        featureArray.push(...this.sitesVectorSource.getFeatures());
        featureArray.push(...this.cabinetsVectorSource.getFeatures());
      }

      if (featureType == FEATURE_FDP) {
        featureArray.push(...this.polesVectorSource.getFeatures());
        featureArray.push(...this.buildingsVectorSource.getFeatures());
        featureArray.push(...this.manholesVectorSource.getFeatures());
        featureArray.push(...this.handholesVectorSource.getFeatures());
      }

      if (featureType == FEATURE_ONT || featureType == FEATURE_FACE_PLATE) {
        featureArray.push(...this.buildingsVectorSource.getFeatures());
      }

      if (featureType == FEATURE_OLT) {
        featureArray.push(...this.buildingsVectorSource.getFeatures());
        featureArray.push(...this.sitesVectorSource.getFeatures());
      }

      if (featureType == FEATURE_CLOSURE) {
        featureArray.push(...this.polesVectorSource.getFeatures());
        featureArray.push(...this.manholesVectorSource.getFeatures());
        featureArray.push(...this.handholesVectorSource.getFeatures());
      }

      if (featureType == FEATURE_SPLITTER) {
        featureArray.push(...this.cabinetsVectorSource.getFeatures());
      }

      if (featureType == FEATURE_POLE) {
        featureArray.push(...this.fiberCablesVectorSource.getFeatures());

        // add blueprint layers to allow snapping
        featureArray.push(...this.blueprintVectorSource.getFeatures());
      }

      if (featureType == FEATURE_MANHOLE || featureType == FEATURE_HANDHOLE) {
        featureArray.push(...this.fiberCablesVectorSource.getFeatures());
        featureArray.push(...this.trenchVectorSource.getFeatures());
        featureArray.push(...this.ductsVectorSource.getFeatures());

        // add blueprint layers to allow snapping
        featureArray.push(...this.blueprintVectorSource.getFeatures());
      }

      let uniqueCollections = [];
      var filteredCollection = [];
      featureArray.forEach((item) => {
        if (!uniqueCollections.includes(item.id_)) {
          filteredCollection.push(item);
          uniqueCollections.push(item.id_);
        }
      });

      var snapCollection = new Collection(filteredCollection, {
        unique: false,
      });

      return snapCollection;
    },

    /**
     * This function gets all features that have snapped to the drawn feature. For example, this will return all the poles
     * that have snapped to a drawn fiber cable, or the manholes that have snapped to a duct
     * @param {feature that we have just drawn} feature
     */
    getSnapFeatures(feature) {
      let coordinates = feature.getGeometry().getCoordinates();
      let snapFeatures = [];
      let snapFeatureIds = [];
      let snapFeatureTypes = featureSnapConfig[feature.get("type")];

      if (snapFeatureTypes && snapFeatureTypes.length > 0) {
        if (feature.getGeometry() instanceof LineString) {
          let vectorSources = [
            this.polesVectorSource,
            this.manholesVectorSource,
            this.handholesVectorSource,
            this.buildingsVectorSource,
            this.sitesVectorSource,
            this.cabinetsVectorSource,
            this.roadCrossingVectorSource,
          ];

          coordinates.forEach((coordinate) => {
            var ctr = vectorSources.length - 1;
            var intersectedFeature;
            // eslint-disable-next-line no-constant-condition
            while (true) {
              if (ctr < 0 || intersectedFeature) break;
              intersectedFeature = getFeatureAtCoordinates(
                coordinate,
                vectorSources[ctr].getFeatures()
              );
              ctr--;
            }

            if (intersectedFeature) {
              if (snapFeatureTypes.includes(intersectedFeature.get("type"))) {
                if (!snapFeatureIds.includes(intersectedFeature.getId())) {
                  snapFeatureIds.push(intersectedFeature.getId());
                  snapFeatures.push(intersectedFeature);
                }
              }
            }
          });
        } else {
          let pixel = this.map.getPixelFromCoordinate(coordinates);
          this.map.forEachFeatureAtPixel(pixel, function(snapFeature, layer) {
            if (snapFeatureTypes.includes(snapFeature.get("type"))) {
              if (
                snapFeature.getId() &&
                snapFeature.getId() != feature.getId()
              ) {
                if (!snapFeatureIds.includes(snapFeature.getId())) {
                  snapFeatureIds.push(snapFeature.getId());
                  snapFeatures.push(snapFeature);
                }
              }
            }
          });
        }
      }

      return snapFeatures;
    },
  },
};
