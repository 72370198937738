<template>
  <c-app-layout>
    <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>
    <v-layout row wrap>
      <v-flex xs3 class="pa-3">
        <v-card class="pa-3">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="secondary"
                text
                absolute
                top
                right
                class="mr-3"
              >
                <v-icon>more_horiz</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(menuItem, index) in items"
                :key="index"
                @click="handleMenuClick(menuItem)"
              >
                <v-list-item-title class="menu-item pa-0 ma-0">{{
                  menuItem.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-flex
            v-if="customer.logo_url != null"
            xs12
            class="align-center pt-8 mt-3"
          >
            <v-avatar tile width="250" height="auto">
              <img :src="customer.logo_url" alt />
            </v-avatar>
          </v-flex>
          <v-card-title class="align-center customer-name bold pt-8">
            <h1>{{ this.customer.customer_name }}</h1>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-phone</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{
                    this.customer.phone_number
                  }}</v-list-item-title>
                  <v-list-item-subtitle>Personal</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-email</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{
                    this.customer.email
                  }}</v-list-item-title>
                  <v-list-item-subtitle>Work</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="this.customer.location != null">
                <v-list-item-icon>
                  <v-icon color="indigo">place</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{
                    this.customer.location
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    this.customer.country
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs9 class="pa-3">
        <v-tabs v-model="tabs">
          <v-tab>Users</v-tab>
          <v-tab>Materials</v-tab>
          <v-tab>Service Cost</v-tab>
          <v-tab>Name Prefixes</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
          <v-tab-item transition="none" reverse-transition="none">
            <UsersList :customer_id="customer.id"></UsersList>
          </v-tab-item>

          <v-tab-item transition="none" reverse-transition="none">
            <MaterialsList
              @editMaterial="editMaterial"
              @deleteMaterial="deleteMaterial"
            ></MaterialsList>
          </v-tab-item>

          <v-tab-item transition="none" reverse-transition="none">
            <ServiceCostsList
              @editServiceCost="editServiceCost"
              @deleteServiceCost="deleteServiceCost"
            ></ServiceCostsList>
          </v-tab-item>

          <v-tab-item transition="none" reverse-transition="none">
            <NamePrefixComponent :customer="customer.id"></NamePrefixComponent>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>

      <v-btn
        v-if="tabs == 0"
        @click="dialog = !dialog"
        absolute
        top
        right
        fab
        rounded
        color="primary"
        class="white--text fab-button"
      >
        <v-icon>add</v-icon>
      </v-btn>

      <v-btn
        v-if="tabs == 1"
        @click="dialog = !dialog"
        absolute
        top
        right
        fab
        rounded
        color="primary"
        class="white--text fab-button"
      >
        <v-icon>add</v-icon>
      </v-btn>

      <v-btn
        v-if="tabs == 2"
        @click="dialog = !dialog"
        absolute
        top
        right
        fab
        rounded
        color="primary"
        class="white--text fab-button"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-layout>

    <div v-if="dialog && tabs == 0">
      <UserDialog
        @closeDialog="closeDialog"
        :customer_id="customer.id"
        :dialogModel="dialog"
      ></UserDialog>
    </div>

    <div v-if="dialog && tabs == 1">
      <MaterialsDialog
        @closeDialog="closeDialog"
        @resetEditModels="resetEditModels"
        :customer_id="customer.id"
        :dialogModel="dialog"
        :editModel="editMaterialModel"
      ></MaterialsDialog>
    </div>

    <div v-if="dialog && tabs == 2">
      <ServiceCostsDialog
        @closeDialog="closeDialog"
        @resetEditModels="resetEditModels"
        :customer_id="customer.id"
        :dialogModel="dialog"
        :editModel="editServiceCostModel"
      ></ServiceCostsDialog>
    </div>

    <div v-if="edit_customer_dialog">
      <CustomerDialog
        :editModel="editCustomerModel"
        @closeDialog="closeDialog"
        :dialogModel="edit_customer_dialog"
      ></CustomerDialog>
    </div>

    <div v-if="delete_dialog">
      <DeleteDialog
        @deleteConfirmed="deleteConfirmed"
        @closeDialog="closeDialog"
        :dialogModel="delete_dialog"
        :deleteMessage="delete_text"
        :model="deleteModel"
      ></DeleteDialog>
    </div>
  </c-app-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UsersList from "../../components/users/UsersList";
import MaterialsList from "../../components/materials/MaterialsList";
import ServiceCostsList from "../../components/materials/ServiceCostsList";
import ServiceCostsDialog from "./sub-components/ServiceCostsDialog";
import UserDialog from "../../components/users/UserDialog";
import MaterialsDialog from "./sub-components/MaterialDialog";
import dialogMixins from "../../utils/mixins";
import DeleteDialog from "../generic/ConfirmDeleteDialog";
import CustomerDialog from "./sub-components/CustomerDialog";
import NamePrefixComponent from "../generic/NamingPrefixes";

export default {
  name: "CustomerDetail",
  components: {
    UsersList,
    MaterialsList,
    UserDialog,
    MaterialsDialog,
    ServiceCostsList,
    ServiceCostsDialog,
    DeleteDialog,
    CustomerDialog,
    NamePrefixComponent,
  },
  mixins: [dialogMixins],
  data: () => ({
    items: [
      { title: "Edit Customer", action: "edit" },
      { title: "Delete Customer", action: "delete" },
    ],
    deleteModel: null,
    is_service_cost: false,
    is_material: false,
    tabs: 0,
    bread_crumbs: [],
    editCustomerModel: null,
    editMaterialModel: null,
    editServiceCostModel: null,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Phone Number",
        value: "phone",
      },
      {
        text: "Group",
        value: "group_name",
      },
      {
        text: "More",
        value: "action",
      },
    ],
  }),
  computed: {
    ...mapState({
      customer: (state) => state.customersModule.customer,
      customer_users: (state) => state.usersModule.customer_users,
    }),
  },
  mounted() {
    this.getCustomer(this.$route.params.id);
    this.getCustomerUsers(this.$route.params.id);
    this.getServiceCosts(this.$route.params.id);
    this.getMaterials(this.$route.params.id);
  },
  watch: {
    customer: function (val) {
      this.bread_crumbs = [];
      this.bread_crumbs.push(
        {
          text: "Customers",
          href: "/customers",
        },
        {
          text: val.customer_name,
          disabled: true,
        }
      );
    },
  },
  methods: {
    ...mapActions({
      getCustomer: "getCustomer",
      deleteCustomer: "deleteCustomer",
      getCustomerUsers: "getCustomerUsers",
      getServiceCosts: "getCustomerServiceCosts",
      getMaterials: "getCustomerMaterials",
      deleteCustomerMaterial: "deleteCustomerMaterial",
      deleteCustomerServiceCost: "deleteServiceCost",
    }),
    editMaterial(item) {
      this.editMaterialModel = item;
      this.dialog = !this.dialog;
    },
    resetEditModels() {
      this.editMaterialModel = undefined;
      this.editServiceCostModel = undefined;
      this.editCustomerModel = undefined;
    },
    editServiceCost(item) {
      this.editServiceCostModel = item;
      this.dialog = !this.dialog;
    },
    editCustomer() {
      this.editCustomerModel = this.customer;
      this.edit_customer_dialog = !this.edit_customer_dialog;
    },
    deleteMaterial(item) {
      this.is_service_cost = false;
      this.is_material = true;
      this.delete_text = "Are you sure you want to delete this material?";

      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    handleMenuClick(item) {
      if (item.action == "edit") {
        this.editCustomer();
      }

      if (item.action == "delete") {
        this.execDeleteCustomer();
      }
    },
    execDeleteCustomer() {
      this.delete_text = "Are you sure you want to delete this customer?";
      this.deleteModel = this.customer;
      this.delete_dialog = !this.delete_dialog;
    },
    deleteServiceCost(item) {
      this.is_service_cost = true;
      this.is_material = false;
      this.delete_text = "Are you sure you want to delete this service cost?";

      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    async deleteConfirmed(item) {
      if (this.is_service_cost) {
        this.deleteCustomerServiceCost(item.id).then(() => {
          this.getServiceCosts(this.$route.params.id);
          this.is_service_cost = false;
        });
      }

      if (this.is_material) {
        this.deleteCustomerMaterial(item.id).then(() => {
          this.getMaterials(this.$route.params.id);
          this.is_material = false;
        });
      }

      if (item.customer_name) {
        // deleting customer
        await this.deleteCustomer(item.id);
        this.showAlertMessage("Customer deleted", "success");
        this.$router.push(`/customers`);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.align-center {
  text-align: center;
  width: 100%;
  display: block;
}

.menu-item {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.customer-name {
  word-break: keep-all !important;
}
</style>