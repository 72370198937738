<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="group.group_name"
                  :rules="rules.required_field"
                  placeholder="Group Name"
                  label="Group Name"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="createGroup"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "UserGroupDialog",
  props: {
    dialogModel: {
      type: Boolean,
      required: true
    },
    editModel: {
      type: Object
    }
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    dialog_title: "Add User Group",
    dialog_button_text: "Create",
    group: {},
    valid: true,
    rules: {
      required_field: [v => !!v || "Field is required"]
    },
    dialog: null
  }),
  computed: {
    ...mapState({
      status: state => state.usersModule.status
    })
  },
  mounted() {
    this.dialog = this.dialogModel;

    if (this.editModel) {
      this.is_edit_mode = true;
      this.dialog_title = "Edit Group";
      this.dialog_button_text = "Update";

      Object.assign(this.group, this.editModel);
    }
  },
  methods: {
    ...mapActions({
      createUserGroup: "createUserGroup",
      editUserGroup: "editUserGroup"
    }),
    createGroup() {
      if (this.is_edit_mode) {
        this.editUserGroup(this.group)
          .then(() => {
            this.$notify({
              group: "success",
              title: "Success",
              text: "Edited user group"
            });

            this.closeDialog();
          })
          .catch(() => {
            this.$notify({
              group: "error",
              title: "Failed",
              text:
                "Operation failed. Make sure that you have the required permissions."
            });

            this.closeDialog();
          });
      } else {
        this.createUserGroup(this.group)
          .then(() => {
            this.$notify({
              group: "success",
              title: "Success",
              text: "Created new user group"
            });

            this.closeDialog();
          })
          .catch(() => {
            this.$notify({
              group: "error",
              title: "Failed",
              text:
                "Operation failed. Make sure that you have the required permissions."
            });

            this.closeDialog();
          });
      }
    },
    resetFields() {
      this.group = {};
      this.is_edit_mode = false;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();
      this.$emit("resetEditModel");
      this.$emit("closeDialog");
    }
  },
  watch: {
    dialogModel: function(val) {
      this.dialog = val;
    },

    editableEntity: function(val) {
      this.group = val;

      this.dialog_title = "Edit Group";
      this.is_edit_mode = true;
      this.dialog_button_text = "Update";
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function(val) {
      if (!val) {
        this.group_name = {};
        this.closeDialog();
      }
    }
  }
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>