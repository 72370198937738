/*
 Function to decode a user's auth token
*/
export function decodeJWTToken(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

/*
Returns the time in hours to expiry for the token
*/
export function timeToTokenExpiry(token) {
  let minutes = 60;
  let seconds = 60;
  let milliseconds = 1000;

  let currentTimestamp = Date.parse(new Date());
  let expiryTimestamp = decodeJWTToken(token)["exp"] * 1000;

  return (
    (expiryTimestamp - currentTimestamp) / (minutes * seconds * milliseconds)
  );
}

export function getStatusColor(chipState) {
  if (chipState.toString().toLowerCase() == "prop") return "warning";
  if (chipState.toString().toLowerCase() == "impl") return "primary";
  if (chipState.toString().toLowerCase() == "closed") return "success";

  return "secondary";
}

export function getCustomerName(customer_id, customers) {
  if (customers.length > 0) {
    let customer = customers.find((customer) => customer.id == customer_id);
    if (customer) return customer.customer_name;
  }

  return;
}

/*
 Function to parse API results
*/
export function parseAPIResults(data) {
  if (data.results != undefined) return data.results;

  return data;
}

/*
 Function to filter menu options to determine what items to show for a given user.
*/
export function filterMenuOptions(menuItems, user) {
  let userRoles = user.permissions;
  let ADMIN_USER = 4;
  let SUPER_USER_ROLE = 8;
  let isCustomer = user.customer_id > 0;

  // check if this is a big dog
  let isBigDog =
    user.user_type == ADMIN_USER ||
    userRoles.includes(SUPER_USER_ROLE) ||
    user.is_superuser;

  let userMenu = menuItems.filter((setting) => {
    var returnSetting = false;
    let is_customer_route = setting.is_customer_route != undefined;

    if (setting.user_types != undefined) {
      if (setting.user_types.includes(user.user_type)) {
        returnSetting = true;
      }
    }

    if (isCustomer && is_customer_route) returnSetting = true;

    if (setting.user_roles != undefined) {
      userRoles.forEach((role) => {
        if (setting.user_roles.includes(role)) {
          returnSetting = true;
        }
      });
    }

    if (setting.user_roles == undefined && setting.user_types == undefined)
      returnSetting = true;

    // return all menu settings to super users except those meant for customers only
    if (returnSetting || (isBigDog && !is_customer_route)) return setting;
  });

  return userMenu;
}

/*
 Function to determine of a user has permissions for a given page
*/
export function hasPermission(user, permissionCode) {
  let ADMIN_USER = 4;
  let SUPER_USER_ROLE = 8;

  // check if this is a big dog
  let isBigDog =
    user.user_type == ADMIN_USER ||
    user.permissions.includes(SUPER_USER_ROLE) ||
    user.is_superuser;

  var has_permission = false;
  user.permissions.forEach((role) => {
    if (role == permissionCode) has_permission = true;
  });

  return has_permission || isBigDog;
}

export var FMS_REFRESH_TIMEOUT = 10 * 60 * 1000;
export var FMS_FAULTS_TIMEOUT = 10 * 60 * 1000;
