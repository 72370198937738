<template>
  <v-card flat>
    <v-card-title>
      <h4>Current Jobs</h4>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field
        class="mr-6 mt-6"
        label="Search jobs"
        v-model="search"
      ></v-text-field>
    </v-card-title>

    <v-card-text class="ma-0 pa-0">
      <v-tabs v-model="tabs">
        <v-tab>FTTH Jobs</v-tab>
        <v-tab>FTTB Jobs</v-tab>
        <v-tab>FTTS/A Jobs</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-data-table
            :search="search"
            :loading="status == 'loading'"
            :headers="headers"
            :items="ftth_jobs"
          >
            <template v-slot:[`item.job_name`]="{ item }">
              <p
                @click="editItem(item)"
                class="primary--text ma-0 pa-0 cursor-item"
              >
                {{ item.job_name }}
              </p>
            </template>

            <template v-slot:[`item.boqs`]="{ item }">
              <v-btn text color="success" @click="generateBOQ(item)">
                <v-icon>link</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.current_state`]="{ item }">
              <v-chip
                :color="getStatusColor(item.current_state)"
                class="pa-2 mr-2"
                small
                >{{ getStatus(item.current_state) }}</v-chip
              >
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <!-- view only mode (no edit tools) -->
              <router-link
                class="no-underline"
                :to="'details/' + item.id + '/mapping-studio?view_only=true'"
              >
                <v-icon small color="grey" class="pr-3">visibility</v-icon>
              </router-link>

              <!-- edit mode (with edit tools) -->
              <router-link
                class="no-underline"
                :to="'details/' + item.id + '/mapping-studio'"
              >
                <v-icon small color="primary">edit</v-icon>
              </router-link>

              <!-- delete job -->
              <v-icon @click="deleteJob(item)" small color="error" class="pl-3"
                >delete</v-icon
              >
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            :search="search"
            :loading="status == 'loading'"
            :headers="headers"
            :items="fttb_jobs"
          >
            <template v-slot:[`item.job_name`]="{ item }">
              <p
                @click="editItem(item)"
                class="primary--text ma-0 pa-0 cursor-item"
              >
                {{ item.job_name }}
              </p>
            </template>

            <template v-slot:[`item.boqs`]="{ item }">
              <v-btn text color="success" @click="generateBOQ(item)">
                <v-icon>link</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.current_state`]="{ item }">
              <v-chip
                :color="getStatusColor(item.current_state)"
                class="pa-2 mr-2"
                small
                >{{ getStatus(item.current_state) }}</v-chip
              >
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <!-- view only mode (no edit tools) -->
              <router-link
                class="no-underline"
                :to="'details/' + item.id + '/mapping-studio?view_only=true'"
              >
                <v-icon small color="grey" class="pr-3">visibility</v-icon>
              </router-link>

              <router-link
                class="no-underline"
                :to="'details/' + item.id + '/mapping-studio'"
              >
                <v-icon small color="primary">edit</v-icon>
              </router-link>

              <!-- delete job -->
              <v-icon @click="deleteJob(item)" small color="error" class="pl-3"
                >delete</v-icon
              >
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            :search="search"
            :loading="status == 'loading'"
            :headers="headers"
            :items="ftts_jobs"
          >
            <template v-slot:[`item.job_name`]="{ item }">
              <p
                @click="editItem(item)"
                class="primary--text ma-0 pa-0 cursor-item"
              >
                {{ item.job_name }}
              </p>
            </template>

            <template v-slot:[`item.boqs`]="{ item }">
              <v-btn text color="success" @click="generateBOQ(item)">
                <v-icon>link</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.current_state`]="{ item }">
              <v-chip
                :color="getStatusColor(item.current_state)"
                class="pa-2 mr-2"
                small
                >{{ getStatus(item.current_state) }}</v-chip
              >
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <!-- view only mode (no edit tools) -->
              <router-link
                class="no-underline"
                :to="'details/' + item.id + '/mapping-studio?view_only=true'"
              >
                <v-icon small color="grey" class="pr-3">visibility</v-icon>
              </router-link>

              <router-link
                class="no-underline"
                :to="'details/' + item.id + '/mapping-studio'"
              >
                <v-icon small color="primary">edit</v-icon>
              </router-link>

              <!-- delete job -->
              <v-icon @click="deleteJob(item)" small color="error" class="pl-3"
                >delete</v-icon
              >
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

const FTTH_JOB = 1;
const FTTB_JOB = 2;
const FTTS_JOB = 3;

export default {
  name: "JobsListComponent",
  props: {
    project_id: {
      type: Number,
    },
  },
  data: () => ({
    tabs: 0,
    search: "",
    items: [{ title: "Load Job" }],
    headers: [
      {
        text: "Job Name",
        sortable: false,
        value: "job_name",
      },
      {
        text: "P.O Number",
        sortable: false,
        value: "po_number",
      },
      {
        text: "Job Area",
        sortable: false,
        value: "job_area",
      },
      {
        text: "Status",
        sortable: false,
        value: "current_state",
      },
      {
        text: "BOQs",
        sortable: false,
        value: "boqs",
        align: "center",
      },
      {
        text: "More",
        sortable: false,
        value: "action",
        align: "center",
      },
    ],
  }),
  mounted() {
    if (this.customers.length == 0) {
      this.getCustomers().then(() =>
        this.getJobs(this.$route.params.project_id)
      );
    } else {
      this.getJobs(this.$route.params.project_id);
    }
  },
  computed: {
    ...mapState({
      jobs: (state) => state.projectsModule.jobs,
      status: (state) => state.projectsModule.status,
      customers: (state) => state.customersModule.customers,
      ftth_jobs: function () {
        return this.jobs.filter((job) => job.job_type == FTTH_JOB);
      },
      fttb_jobs: function () {
        return this.jobs.filter((job) => job.job_type == FTTB_JOB);
      },
      ftts_jobs: function () {
        return this.jobs.filter((job) => job.job_type == FTTS_JOB);
      },
    }),
  },
  methods: {
    ...mapActions({
      getJobs: "getJobs",
      getCustomers: "getCustomers",
    }),
    deleteJob(job) {
      this.$emit("deleteJob", job);
    },
    generateBOQ(item) {
      this.$emit("generateBOQ", item);
    },
    viewJob(item) {
      this.$emit("viewJob", item);
    },
    getStatusColor(chipState) {
      if (chipState == 0) return "warning";
      if (chipState == 1 || chipState == 2) return "primary";
      if (chipState == 3) return "success";

      return "secondary";
    },
    getStatus(chipState) {
      if (chipState == 0) return "Proposed";
      if (chipState == 1) return "Implementation";
      if (chipState == 2) return "Updated";
      if (chipState == 3) return "Documented";

      return "";
    },
    loadJob(item) {
      console.log(item, "Loading Job Map");
    },
    editItem(item) {
      this.$emit("editJob", item);
    },
    deleteItem(item) {
      console.log(item);
      //   this.$emit("deleteJob", item);
    },
  },
};
</script>

<style lang="css" scoped>
.cursor-item {
  cursor: pointer;
}

.no-underline {
  text-decoration: none;
}
</style>