<template>
  <v-dialog v-model="dialog" persistent width="250px">
    <v-card class="pb-3">
      <v-card-title justify-center class="success white--text justify-center">{{
        dialog_title
      }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex class="mt-3 pl-3" xs12>
              <v-select
                v-model="boq.format"
                :items="boq_format"
                label="Select BOQ Format"
                placeholder="BOQ Format"
                :rules="rules.required_field"
                clearable
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="execGenerateBOQ"
          >{{ dialog_button_text }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../utils/mixins";

export default {
  name: "GenerateBOQComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    editModel: {
      type: Object,
    },
    boqItem: {
      type: Object,
    },
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "Generate BOQ",
    dialog_button_text: "GENERATE",
    valid: true,
    boq_format: [
      {
        text: "PDF",
        value: 1,
      },
    ],
    rules: {
      email_field: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Field should be a valid email",
      ],
      required_field: [(v) => !!v || "Field is required"],
    },
    dialog: null,
    boq: {},
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.boq = {};

    // preselect PDF option
    this.boq.format = 1;
  },
  computed: {
    ...mapState({
      status: (state) => state.featuresModule.status,
      user: (state) => state.usersModule.current_user,
    }),
  },
  methods: {
    ...mapActions({
      createJob: "createJob",
      getProjects: "getProjects",
      updateJob: "updateJob",
      exportBOQs: "exportBOQs",
    }),
    execGenerateBOQ() {
      if (this.$refs.form.validate()) {
        // add logo, project name, customer name
        this.loading = true;

        this.boq.job = this.boqItem.id;
        this.boq.job_name = this.boqItem.job_name;
        this.boq.customer = this.$route.params.id;
        this.boq.project = this.$route.params.project_id;
        this.boq.planner = this.user.name;

        let fileName = `${this.boqItem.job_name}.zip`;
        let blobType = "application/x-zip-compressed";

        this.exportBOQs(this.boq)
          .then((response) => {
            this.$notify({
              group: "success",
              title: "BOQ Export",
              text: `Your export file will download automatically in a few.`,
            });

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // IE variant
              window.navigator.msSaveOrOpenBlob(
                new Blob([response.data], { type: blobType }),
                fileName
              );
            } else {
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: blobType })
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              console.log(link);
              link.click();
            }

            this.resetFields();
            this.closeDialog();
          })
          .catch(() => {
            this.$notify({
              group: "error",
              title: "Exception",
              text: `An exception occurred. Try again or ontact the system administrator`,
            });
            this.resetFields();
            this.closeDialog();
          });
      }
    },
    resetFields() {
      this.boq = {};
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();
      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.job = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>