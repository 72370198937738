import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";

import ToolBar from "./components/nav/ToolBar.vue";
import SubMenu from "./components/nav/SubMenu.vue";
import NavDrawer from "./components/nav/NavDrawer.vue";
import BreabCrumbs from "./components/nav/BreadCrumbs.vue";
import CustomLoader from "./components/progress-loaders/Loader.vue";
import UnderConstruction from "./components/UnderConstruction.vue";
import AppLayout from "./layouts/AppLayout.vue";
import SubMenuLayout from "./layouts/SubMenuLayout.vue";
import LoginLayput from "./layouts/LoginLayout.vue";
import store from "./store/index";
import Notifications from "vue-notification";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDYtFxbhEzulWMfVKZYkX3MS1Hynec7o90", //AIzaSyDYtFxbhEzulWMfVKZYkX3MS1Hynec7o90
    libraries: "places,drawing,visualization", // necessary for places input
  },
});

// API call timeout (up to 10 sec)
axios.defaults.timeout = 240000;

axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch("logout");
        router.push("/login");
      }
    }

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    console.log(error);

    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch("logout");
        router.push("/login");
      }
    }

    return Promise.reject(error);
  }
);

Vue.component("c-toolbar", ToolBar);
Vue.component("c-sub-menu", SubMenu);
Vue.component("c-nav-drawer", NavDrawer);
Vue.component("c-bread-crumbs", BreabCrumbs);
Vue.component("c-loader", CustomLoader);
Vue.component("c-under-construction", UnderConstruction);
Vue.component("c-app-layout", AppLayout);
Vue.component("c-submenu-layout", SubMenuLayout);
Vue.component("c-login-layout", LoginLayput);

Vue.use(Notifications);

// Define global text uppercase filter
Vue.filter("uppercase", function(val) {
  if (val) {
    val = val.toString();
    val = val.toUpperCase();
  }

  return val;
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
