<template>
  <!-- check main.js for custom component details -->
  <c-submenu-layout>
    <!-- SubMenu Column -->
    <v-flex class="sub-menu" xs2>
      <c-sub-menu :items="sub_menu"></c-sub-menu>
    </v-flex>

    <!-- Main Content Column -->
    <v-flex xs10 class="main-content-border">
      <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

      <c-under-construction page_title="System Activity"></c-under-construction>
    </v-flex>
  </c-submenu-layout>
</template>

<script>
import appConfig from "../../config";
export default {
  data: () => ({
    sub_menu: [],
    bread_crumbs: [
      {
        text: "System Activity",
        href: "system-activity"
      }
    ]
  }),
  mounted() {
    this.sub_menu = appConfig.settings_page_sub_menus;
  }
};
</script>