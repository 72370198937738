/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import { INITIAL_ZOOM } from "../feature_constants";
import { getFeatureById } from "../../utils/map_functions";
import axios from "axios";

export default {
  data: () => ({
    autoCompleteService: null,
    placesService: null,
    results: null,
  }),
  methods: {
    initMap() {
      this.results = document.getElementById("results");
      let autoCompleteObj = this.google.maps.places.Autocomplete;
      let serviceObj = this.google.maps.places.AutocompleteService;
      let placeServiceObj = this.google.maps.places.PlacesService;
      this.autoCompleteService = new serviceObj();
      this.placesService = new placeServiceObj(this.googleMap);
      let self = this;

      // Bind listener for address search
      this.google.maps.event.addDomListener(
        document.getElementById("pac-input"),
        "input",
        function() {
          self.results.style.display = "block";
          // self.getPlacePredictions( document.getElementById( 'pac-input' ).value );

          if (
            document.getElementById("pac-input").value.split(",").length == 2
          ) {
            let coords = document.getElementById("pac-input").value.split(",");

            // hack to check if coordinates entered are more than 2 digits each
            if (coords[1].length > 2 && coords[0].length > 2) {
              self.goToCoordinates(coords[1], coords[0]);
            }
          } else {
            self.results.style.display = "block";
            self.getPlacePredictions(
              document.getElementById("pac-input").value
            );
          }
        }
      );

      // Show results when address field is focused (if not empty)
      this.google.maps.event.addDomListener(
        document.getElementById("pac-input"),
        "focus",
        function() {
          if (document.getElementById("pac-input").value !== "") {
            self.results.style.display = "block";
            // self.getPlacePredictions( document.getElementById( 'pac-input' ).value );

            if (
              document.getElementById("pac-input").value.split(",").length == 2
            ) {
              let coords = document
                .getElementById("pac-input")
                .value.split(",");

              // hack to check if coordinates entered are more than 2 digits each
              if (coords[1].length > 2 && coords[0].length > 2) {
                self.goToCoordinates(coords[1], coords[0]);
              }
            } else {
              self.results.style.display = "block";
              self.getPlacePredictions(
                document.getElementById("pac-input").value
              );
            }
          }
        }
      );

      // Hide results when click occurs out of the results and inputs
      this.google.maps.event.addDomListener(document, "click", function(e) {
        if (
          e.target.parentElement.className !== "pac-container" &&
          e.target.parentElement.className !== "pac-item" &&
          e.target.tagName !== "INPUT"
        ) {
          if (document.getElementById("pac-input")) {
            document.getElementById("pac-input").blur();
            self.results.style.display = "none";
            self.place_search = "";
          }
        }
      });
    },

    async getPlacePredictions(place) {
      this.results.innerHTML = "";

      if (place && place.length > 0) {
        await this.execGetGooglePredictions(place);
        await this.addNetworkFeatures(place);
        this.addSearchListClickListener();
      }
    },

    async execGetGooglePredictions(place) {
      // replace this impl with a Mapbox Impl
      let placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${place}.json?access_token=pk.eyJ1IjoiZW11ZGRlIiwiYSI6ImNqbGJvbjI3eDNjbHkzanF5a2d5ank0cWgifQ.6wK8LAOaea5Z7FkxN9fquA`;
      axios.get(`${placeUrl}`).then((response) => {
        this.predictionsResultsCallback(response.data);
      });
    },

    addSearchListClickListener() {
      var items = document.getElementsByClassName("pac-item");

      // Results items click
      let self = this;
      for (var j = 0; j < items.length; j++) {
        let item = items[j];

        item.addEventListener("click", function() {
          if (this.dataset) {
            if (this.dataset.placeid) {
              self.getPlaceDetails(this.dataset);
            }

            if (this.dataset.featureid) {
              let feature = getFeatureById(
                this.dataset.featureid,
                self.vectorSources
              );
              if (feature) {
                // highlight this feature if it is a point feature
                self.highlightSelectedFeature(feature);

                let zoomLevel = 18;
                self.zoomToFeature(feature, zoomLevel);
              }
            }
          }
        });
      }
    },

    getPlaceDetails(dataset) {
      let self = this;
      if (dataset.lat && dataset.lon && dataset.name) {
        self.place_search = dataset.name;

        var lat = dataset.lat;
        var lon = dataset.lon;

        self.goToCoordinates(lon, lat);

        // Hide autocomplete results
        self.results.style.display = "none";
      }
    },

    predictionsResultsCallback(predictionsObj) {
      if (predictionsObj && predictionsObj.features) {
        let predictions = predictionsObj.features;

        this.results.innerHTML = "";
        let htmlContent = "";
        for (var i = 0; i < predictions.length; i++) {
          let prediction = predictions[i];

          // Insert output in results container
          htmlContent +=
            '<div class="pac-item" data-lat="' +
            prediction.center[1] +
            '" data-lon="' +
            prediction.center[0] +
            '" data-placeid="' +
            prediction.id +
            '" data-name="' +
            prediction.place_name +
            '"><span class="pac-icon pac-icon-marker"></span>' +
            prediction.place_name +
            "</div>";
        }
        this.results.innerHTML = htmlContent;

        // add results if there ere results here
        this.addNetworkFeatures(this.place_search);
        this.addSearchListClickListener();
      }
    },

    async addNetworkFeatures(searchTerm) {
      if (searchTerm.length > 0) {
        let networkFeatuees = this.searchNetworkFeatures(searchTerm);
        if (networkFeatuees.length) {
          networkFeatuees.forEach((feature) => {
            this.results.innerHTML += `<div data-featureid="${feature.getId()}" class="pac-item custom"><span class="pac-icon pac-icon-marker"></span>Feature: ${feature.get(
              "name"
            )}, Type: ${feature.get("type")}</div>`;
          });
        }
      }
    },

    goToCoordinates(lon, lat, zoomLevel = undefined) {
      var transformedCoordinates = [parseFloat(lon), parseFloat(lat)];
      if (zoomLevel == undefined) {
        zoomLevel = INITIAL_ZOOM;
      }

      if (transformedCoordinates.length == 2) {
        this.map.getView().animate({
          // center: fromLonLat( transformedCoordinates ),
          center: transformedCoordinates,
          zoom: zoomLevel,
        });
      } else {
        this.displayErrorAlert(
          "There was an error with the supplied coordinates. Correct them to continue."
        );
      }
    },
  },
};
