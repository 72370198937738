<template>
  <v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <div
          class="dialog-title pa-2 success white--text custom-align-center relative"
          style="width: inherit"
        >
          <h4>Blow Fiber Manager</h4>

          <v-icon
            color="white"
            class="cursor-item"
            @click="$emit('close-blow-manager')"
            title="Close Blow Fiber Manager"
            style="position: absolute; top: 8px; right: 10px"
            >clear</v-icon
          >
        </div>
      </v-flex>

      <v-flex xs12>
        <BlowManager
          :key="merger_key"
          @refreshComponents="refreshComponents"
          @highlightFeature="emitHighlightFeature"
          @blowFiberCable="emitBlowFiberCable"
          :vectorSources="vectorSources"
          :features="features"
          :map="map"
        ></BlowManager>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import BlowManager from "./blow-fiber/BlowManager";

export default {
  name: "BlowFiberMaganerParentComponent",
  props: {
    map: {
      type: Object,
    },
    features: {
      type: Array,
    },
    vectorSources: {
      type: Array,
    },
  },
  components: {
    BlowManager,
  },
  data: () => ({
    tabs: 0,
    merger_key: 1,
  }),
  methods: {
    emitHighlightFeature(feature) {
      this.$emit("highlightFeature", feature);
    },
    refreshComponents() {
      this.merger_key += 1;
      this.$emit("clearHighlightedFeatures");
    },
    emitBlowFiberCable(fiberCable) {
      this.$emit("blowFiberCable", fiberCable);
    },
  },
};
</script>

<style lang="css" scoped>
.tab-item {
  height: 500px;
  overflow-y: scroll;
}

.dialog-title {
  text-align: center;
  border-radius: 3px;
}

.cursor-item {
  cursor: pointer;
}

.feature-dialog {
  position: absolute;
  width: 450px;
  min-height: 100px !important;
  max-height: 600px;
  background: #fff;
  top: 10em;
  left: 2em;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 3px;
}
</style>