<template>
  <v-layout>
    <v-btn
      @click="dialog = !dialog"
      small
      color="success"
      absolute
      right
      outlined
      class="mt-5"
    >
      <v-icon>add</v-icon>Add
    </v-btn>

    <v-flex xs12 class="mt-9">
      <v-data-table
        :items="items"
        :loading="status == 'loading'"
        :headers="header"
        hide-default-footer
      >
        <template v-slot:[`item.service`]="{ item }"
          >{{ getServiceCost(item).service_name }}
        </template>

        <template v-slot:[`item.service_cost`]="{ item }"
          >{{ getServiceCost(item).service_cost }}/=</template
        >

        <template v-slot:[`item.action`]="{ item }">
          <v-btn @click="showDeleteDialog(item)" small text>
            <v-icon small color="error">delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-flex>

    <div v-if="dialog">
      <DialogComponent
        :selectedFeatures="selectedFeatures"
        :feature="feature"
        :dialogModel="dialog"
        @reload="loadItems"
        @closeDialog="closeDialog"
      ></DialogComponent>
    </div>

    <ConfirmDelete
      :deleteMessage="deleteMessage"
      :dialogModel="delete_dialog"
      @closeDialog="closeDialog"
      @deleteConfirmed="deleteItem"
      :model="deleteModel"
    ></ConfirmDelete>
  </v-layout>
</template>

<script>
import DialogComponent from "./dialogs/ServicesDialog";
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../utils/mixins";
import ConfirmDelete from "../../generic/ConfirmDeleteDialog";
// import { retrieveKeyValObj } from "../../../utils/map_functions";

export default {
  name: "ServicesTableComponent",
  components: { DialogComponent, ConfirmDelete },
  mixins: [dialogMixins],
  props: {
    feature: {
      type: Object,
      required: true,
    },
    selectedFeatures: {
      type: Array,
    },
  },
  data: () => ({
    deleteModel: null,
    deleteMessage: "Are you sure you want to delete this service?",
    header: [
      {
        text: "Service",
        value: "service",
        sortable: false,
      },
      {
        text: "Service Cost",
        value: "service_cost",
        sortable: false,
      },
      {
        text: "More",
        value: "action",
      },
    ],
    items: [],
  }),
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
      serviceCosts: (state) => state.materialsModule.service_costs,
    }),
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    ...mapActions({
      getItems: "getFeatureServices",
      removeItem: "deleteFeatureService",
    }),
    showDeleteDialog(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    deleteItem(item) {
      this.deleteModel = null;
      this.removeItem(item.id).then(() => this.loadItems());
    },
    loadItems() {
      let payload = {
        feature: this.feature.getId().toString(),
      };

      this.getItems(payload).then((res) => {
        console.log(res);
        this.items = res.results;
      });
    },
    getServiceCost(service) {
      return this.serviceCosts.find(
        (serviceObj) => serviceObj.id == service.service
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>