<template>
  <div>
    <v-app id="inspire">
      <!-- Navigation Drawer -->
      <c-nav-drawer :drawerModel="true"></c-nav-drawer>

      <!-- App Toolbar -->
      <c-toolbar></c-toolbar>

      <!-- App Main Content Area -->
      <v-main class="permanent-drawer">
        <v-container fluid fill-height>
          <v-layout row wrap class="layout no-padding">
            <!-- Main Content Column -->
            <v-flex xs12 class="main-content-border">
              <slot></slot>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import dialogMixin from "../utils/mixins.js";

export default {
  name: "AppLayput",
  mixins: [dialogMixin],
  data: () => ({
    item: "",
  }),
};
</script>

<style scoped>
nav.v-toolbar {
  z-index: 10 !important;
}

.spaced-drawer-items {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  border-bottom: solid 0.1px #eee;
}

.layout {
  padding: 25px;
  padding-top: 0;
}

.borderd-list {
  border: solid 1px #ccc;
}
</style>
