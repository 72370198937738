/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import { mapActions, mapState } from "vuex";
import { getCenter } from "ol/extent";
import {
  INITIAL_ZOOM,
  FEATURE_BUILDING,
  FEATURE_SPLICE_SCHEMATIC,
  FEATURE_FIBER_CABLE,
  FEATURE_SPLICE_SCHEMATIC_POINT,
  SIGNAL_TRACE,
  FEATURE_SPLICE_SCHEMATIC_LINESTRING,
  FEATURE_SPLICE_SCHEMATIC_PATH,
  FEATURE_CLOSURE,
} from "../feature_constants";
import Polygon from "ol/geom/Polygon";
import {
  buildingPolygonStyle,
  pointLayersStyleFunction,
  schematicsStyleFunction,
} from "../feature_styles";
import {
  calculateLengthProperty,
  getOffsetLineString,
  getFeatureById,
  getSpliceConnectionName,
  offsetLineFeature,
  getFeaturesOnFiberCable,
  getFeaturesByType,
  isCoordinatesEqual,
} from "../map_functions";
import { Feature } from "ol";
import LineString from "ol/geom/LineString";

export default {
  data: () => ({
    loading_features: false,
    splice_schematics_visible: false,
    ducts_plan_visible: false,
    customer_prefixes: null,
    job_prefixes: null,
    current_job: null,
  }),
  mounted() {
    // load feature name prefixes
    this.setFeaturePrefixes();

    if (this.$route.params.job_id)
      this.getJob(this.$route.params.job_id).then((job) => {
        this.current_job = job;
      });
  },
  computed: {
    ...mapState({
      job: (state) => state.projectsModule.job,
    }),
  },
  methods: {
    ...mapActions({
      getAllFeatures: "getAllFeatures",
      getAreaOfInterestFeatures: "getAreaOfInterestFeatures",
      getSpliceSchematics: "getSpliceSchematics",
      getPrefixes: "getPrefixes",
      getJob: "getJob",
    }),

    traceSignal(signalData) {
      if (signalData && signalData.signal_trace) {
        let paths = signalData.signal_trace;
        let collection = [];
        paths.forEach((pathArray) => {
          if (pathArray.fiber_cable_uuid && pathArray.fiber_cable_uuid != "") {
            // display a whole cable
            let lineFeature = getFeatureById(
              pathArray.fiber_cable_uuid,
              this.vectorSources
            );
            if (lineFeature) {
              let signalFeature = new Feature(
                new LineString(lineFeature.getGeometry().getCoordinates())
              );
              signalFeature.set("type", SIGNAL_TRACE);
              signalFeature.set("fiber_number", pathArray.fiber_number);

              collection.push(signalFeature);
            }
          } else if (pathArray.from_path || pathArray.to_path) {
            // let signalFeatures = [];
            let fromPathCoords = [];
            for (let yCoord in pathArray.from_path) {
              fromPathCoords.push([
                parseFloat(yCoord),
                pathArray.from_path[yCoord],
              ]);
            }
            let fromSignalFeature = new Feature(new LineString(fromPathCoords));
            if (fromSignalFeature) {
              fromSignalFeature.set("type", SIGNAL_TRACE);
              fromSignalFeature.set(
                "fiber_number",
                pathArray.from_fiber_number
              );

              collection.push(fromSignalFeature);
            }

            let toPathCoords = [];
            for (let yCoord in pathArray.to_path) {
              toPathCoords.push([
                parseFloat(yCoord),
                pathArray.to_path[yCoord],
              ]);
            }
            let toSignalFeature = new Feature(new LineString(toPathCoords));
            if (toSignalFeature) {
              toSignalFeature.set("fiber_number", pathArray.to_fiber_number);
              toSignalFeature.set("type", SIGNAL_TRACE);

              collection.push(toSignalFeature);
            }
          }
        });

        this.bulkAddFeatureToMap(collection, SIGNAL_TRACE);
      }
    },

    clearSignalTrace() {
      this.deleteAllFeatures(SIGNAL_TRACE);
    },

    toggleDuctsPlan() {
      if (!this.ducts_plan_visible) {
        this.ducts_plan_visible = true;
        this.showDuctsPlanLayer();
        this.setDuctPlanStyle();
      } else {
        this.ducts_plan_visible = false;
        this.hideDuctsPlanLayer();
        this.setDefaultStyle();
      }
    },

    toggleSpliceSchematics() {
      if (!this.splice_schematics_visible) {
        this.splice_schematics_visible = true;
        this.showSchematicsLayer();
        this.setSchematicsStyle();
        this.showLayer(this.splicesLayer);

        // remove all features
        this.splicesVectorSource.clear();

        if (this.$route.params.job_id) {
          let payload = {};
          payload.job = this.$route.params.job_id;

          this.getSpliceSchematics(payload).then((apiResult) => {
            let res = apiResult.splice_schematics;
            let closureConnections = apiResult.closure_connections;

            let collection = [];
            let addedConnectionPaths = [];
            let allClosures = getFeaturesByType(
              FEATURE_CLOSURE,
              this.vectorSources
            );
            let offsetCables = [];

            res.forEach((resArray) => {
              resArray.forEach((result) => {
                if (result.from_path && result.to_path) {
                  let fibers = result.fibers;
                  var connectionName = getSpliceConnectionName(fibers);
                  let locationId = result.location;
                  let location = getFeatureById(locationId, this.vectorSources);

                  let fromPath = new Feature(new LineString(result.from_path));
                  let toPath = new Feature(new LineString(result.to_path));

                  let offserRes = offsetLineFeature(fromPath, toPath, location);

                  if (offserRes && offserRes.offsetFeature) {
                    // add line feature
                    let offsetPathFeature = offserRes.offsetFeature;
                    offsetPathFeature.set("type", FEATURE_SPLICE_SCHEMATIC);
                    offsetPathFeature.set("name", connectionName);

                    collection.push(offsetPathFeature);

                    // check if we have an intersection point
                    if (offserRes.intersection) {
                      let intersectionFeature = offserRes.intersection;
                      intersectionFeature.set("name", "");
                      intersectionFeature.set(
                        "type",
                        FEATURE_SPLICE_SCHEMATIC_POINT
                      );

                      collection.push(intersectionFeature);
                    }
                  }

                  // add from_path to map, showing the number of connected fibers, and must be overlaid with fiber cable,
                  // also, its label should be on top/ below
                  let pathExists = addedConnectionPaths.find(
                    (path) =>
                      path.coordinateString ==
                      fromPath
                        .getGeometry()
                        .getCoordinates()
                        .toString()
                  );
                  if (!pathExists) {
                    addedConnectionPaths.push({
                      coordinateString: fromPath
                        .getGeometry()
                        .getCoordinates()
                        .toString(),
                      label: fibers.length,
                    });

                    fromPath.set("type", FEATURE_SPLICE_SCHEMATIC_PATH);
                    fromPath.set("name", fibers.length);
                    collection.push(fromPath);
                  }

                  // offset real fiber cable
                  /**
                   * Check if we should have an offset path for the entire cable
                   * 1. Get closure at the end of the cable
                   * 2. Get all splices that belong to current cable at the location
                   * 3. If splices are less than cable size, then display offset for entire cable
                   * 4. If splices are equal to cable size, then ignore it
                   */
                  if (result.fiber_cable_uuid) {
                    let fiberCableOffsetDistance = 2; // in meters
                    let fiberCableId = result.fiber_cable_uuid;
                    let fiberCable = getFeatureById(
                      fiberCableId,
                      this.vectorSources
                    );
                    let cableLastCoord = fiberCable
                      .getGeometry()
                      .getLastCoordinate();
                    let closuresOnCable = getFeaturesOnFiberCable(
                      fiberCable,
                      allClosures
                    );
                    let closuresAtCableEnd;
                    if (closuresOnCable) {
                      closuresAtCableEnd = closuresOnCable.filter((cls) =>
                        isCoordinatesEqual(
                          cls.getGeometry().getCoordinates(),
                          cableLastCoord
                        )
                      );
                      if (closuresAtCableEnd.length > 0) {
                        let totalCableConnections = 0;
                        closuresAtCableEnd.forEach((cls) => {
                          let clsConnections =
                            closureConnections[cls.get("pk")];
                          if (clsConnections) {
                            totalCableConnections +=
                              clsConnections[fiberCable.get("pk")];
                          }
                        });

                        if (
                          totalCableConnections < fiberCable.get("cable_size")
                        ) {
                          let cableOffsetAlready = offsetCables.find(
                            (cable) => cable.get("pk") == fiberCable.get("pk")
                          );
                          if (!cableOffsetAlready) {
                            // add current fiber cable to already offset cables
                            offsetCables.push(fiberCable);

                            // add fulllength offset cable
                            let offsetFiberCable = getOffsetLineString(
                              fiberCable,
                              fiberCableOffsetDistance
                            );
                            if (offsetFiberCable) {
                              offsetFiberCable.set("name", "");
                              offsetFiberCable.set(
                                "type",
                                FEATURE_SPLICE_SCHEMATIC_LINESTRING
                              );
                              collection.push(offsetFiberCable);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
            });

            this.bulkAddFeatureToMap(collection, FEATURE_SPLICE_SCHEMATIC);
          });
        }
      } else {
        this.splice_schematics_visible = false;
        this.hideSchematicsLayer();
        this.setDefaultStyle();
      }
    },

    zoomToFeature(feature, zoomLevel = undefined) {
      if (feature) {
        var ext = feature.getGeometry().getExtent();
        var center = getCenter(ext);

        if (!zoomLevel) zoomLevel = INITIAL_ZOOM;

        this.map.getView().animate({
          center: center,
          zoom: zoomLevel,
        });
      }
    },

    async fetchFeatures(featureType = undefined) {
      let payload = {};
      var viewType;

      // clear all vector sources before adding new features
      if (!featureType) {
        this.loading_features = true;
        this.clearVectorSources();
      } else {
        //
        viewType = "ignore";
        let vectorSource = this.getFeatureVectorSource(featureType);
        if (vectorSource) vectorSource.clear();
      }

      // set the job and project
      if (this.$route.params.job_id) {
        payload.job_id = this.$route.params.job_id;
        payload.project = this.$route.params.project_id;
      }

      // set the customer if we are loading the entire customer network
      if (this.$route.params.customer) {
        payload.customer = this.$route.params.customer;
        viewType = "customer";
      }

      if (this.$route.params.noc) {
        payload.noc = true;
        viewType = "noc";
      }

      // set the customer if we are reloading a specific feature type vectorsource
      if (featureType) payload.feature_type = featureType;

      if (
        payload.job_id ||
        payload.customer ||
        payload.feature_type ||
        payload.noc
      ) {
        await this.getAllFeatures(payload)
          .then((featureCollections) => {
            this.loading_features = false;
            this.addFeaturesToMap(featureCollections, viewType);
          })
          .catch(() => {
            this.loading_features = false;
            this.displayErrorAlert("An error occured while loading features");
          });
      }
    },

    async fetchAreaOfInterestFeatures() {
      let payload = {};
      var viewType = "ignore";

      // set the job and project
      if (this.$route.params.job_id) {
        payload.job_id = this.$route.params.job_id;
        payload.project = this.$route.params.project_id;
      }

      if (payload.job_id) {
        this.getAreaOfInterestFeatures(payload)
          .then((featureCollections) => {
            this.loading_features = false;
            this.addFeaturesToMap(featureCollections, viewType);
          })
          .catch(() => {
            this.loading_features = false;
            this.displayErrorAlert("An error occured while loading features");
          });
      }
    },

    processBlueprintData(importObj) {
      this.addFeaturesToMap(
        [importObj.geojson],
        undefined,
        importObj.import_type
      );
    },

    async addFeaturesToMap(
      featureCollections,
      viewType = undefined,
      importType = undefined
    ) {
      // feature to zoom to after loading all features
      var zoomFeature;

      featureCollections.forEach((featureCollection) => {
        var featureType = "";
        let mapFeatureCollection = [];
        let featuresArray = featureCollection.features;
        featuresArray.forEach((geoJSONfeature) => {
          var feature = this.geoJSON.readFeature(geoJSONfeature);
          if (feature && feature.getGeometry() != null) {
            if (this.getLengthProperty(feature)) {
              feature.set(
                "calculated_length",
                calculateLengthProperty(feature)
              );
            }

            if (importType) featureType = importType;
            else featureType = feature.get("feature_type");

            feature.setId(feature.get("uuid"));
            feature.set("type", featureType);

            // set job state
            if (this.current_job) {
              feature.set("job_state", this.current_job.current_state);
            }

            // check if type is building and if it is a polygon
            if (
              featureType == FEATURE_BUILDING &&
              feature.getGeometry() instanceof Polygon
            ) {
              feature.setStyle(buildingPolygonStyle);
            }

            // check if it is a fiber cable and set the physical length prop
            if (featureType == FEATURE_FIBER_CABLE) {
              let includeSlack = false;
              feature.set(
                "physical_length",
                calculateLengthProperty(feature, includeSlack)
              );
            }

            mapFeatureCollection.push(feature);

            if (zoomFeature == undefined) zoomFeature = feature;
          }
        });

        this.bulkAddFeatureToMap(mapFeatureCollection, featureType);

        // control when/how to zoom into network
        let ignore_zoom_contsants = ["customer", "ignore_zoom"];
        if (!viewType || !ignore_zoom_contsants.includes(viewType))
          this.zoomToFeature(zoomFeature);
      });
    },

    clearVectorSources() {
      this.vectorSources.forEach((vectorSource) => vectorSource.clear());
    },

    setFeaturePrefixes() {
      // load manhole/handhole prefixes when the map is mounted
      this.getPrefixes({ customer: this.$route.params.id }).then((res) => {
        this.customer_prefixes = res.results;
      });

      this.getPrefixes({ job: this.$route.params.job_id }).then((res) => {
        this.job_prefixes = res.results;
      });
    },
  },
};
