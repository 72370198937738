<template>
  <div>
    <v-card-text class="pt-0 t-0">
      <h2 v-if="password_error == '' && display_name" class="grey--text">
        Hi {{ display_name }}!
      </h2>
      <h4 v-else-if="password_error != ''" class="error--text">
        {{ password_error }}
      </h4>
      <h3 v-else class="primary--text">Set Password</h3>

      <v-form class="pt-2" ref="form" v-model="valid" lazy-validation>
        <v-container class="txtfieldContainer pa-0 ma-0">
          <v-text-field
            class="pt-4"
            type="password"
            dense
            v-model="credentials.password"
            :rules="rules.required"
            outlined
            label="Password"
            required
          />

          <v-text-field
            class="pb-2"
            type="password"
            dense
            v-model="credentials.confirm_password"
            :rules="rules.min_chars"
            outlined
            label="Confirm Password"
          />
        </v-container>

        <v-btn
          block
          class="primary darken-2 white--text login-button"
          :loading="authStatus == 'loading'"
          :disabled="!valid || password_error != ''"
          @click="execSetPassword"
          type="button"
          >Set Password</v-btn
        >
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SetPasswordComponent",
  data: () => ({
    credentials: {
      confirm_password: "",
      password: "",
    },
    loading: false,
    email_error: "",
    password_error: "",
    showAlert: false,
    valid: true,
    rules: {
      required: [(v) => !!v || "Field is required"],
      min_chars: [(v) => v.length >= 8 || "Minimun characters is 8"],
    },
    token: null,
    token_valid: false,
    display_name: null,
  }),
  mounted() {
    this.app_title = "Optics 360";

    if (!this.$route.query.token)
      this.password_error =
        "You need a special token to proceed. Contact the system administrator.";
    else {
      this.token = this.$route.query.token;
      this.getUserFromToken({ token: this.token })
        .then((res) => {
          if (res && res.user) {
            this.display_name = res.user;
            this.token_valid = true;
          }
        })
        .catch((error) => {
          console.log("Here...", error);
          this.password_error =
            "You need a special token to proceed. Contact the system administrator.";
        });
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.usersModule.isLoggedIn,
      current_user: (state) => state.usersModule.current_user,
      authStatus: (state) => state.usersModule.status,
    }),
  },
  watch: {
    isLoggedIn: function (val) {
      return val;
    },
    "credentials.confirm_password": function () {
      if (this.token_valid) this.password_error = "";
    },
    "credentials.password": function () {
      if (this.token_valid) this.password_error = "";
    },
  },
  methods: {
    ...mapActions({
      setNewPassword: "setNewPassword",
      getUserFromToken: "getUserFromToken",
    }),
    execSetPassword() {
      if (
        this.$refs.form.validate() &&
        this.credentials.password == this.credentials.confirm_password
      ) {
        this.credentials.token = this.token;
        this.setNewPassword(this.credentials)
          .then(() => {
            this.$notify({
              group: "success",
              title: "Success",
              text: "Your password was set successfully!",
            });

            this.$router.push("/login");
          })
          .catch(() => {
            this.$notify({
              group: "error",
              title: "Operation Unsuccessful.",
              text:
                "Check your data and try again, or get help from the administrator.",
            });
          });
      } else {
        this.password_error =
          "Make sure your passwords are not empty and that they match!";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>