<template>
  <div class="custom-draw-controls">
    <div class="btn-group success">
      <v-icon
        @click="$router.go(-1)"
        title="Back to all jobs"
        class="pa-2 white--text"
        >reply_all</v-icon
      >
    </div>

    <div :class="btn_class">
      <v-icon
        @click="$emit('show-maintenance-dialog')"
        title="Maintenance"
        :class="icon_class"
        >wifi_protected_setup</v-icon
      >
    </div>

    <div :class="btn_class" v-if="show_drawing_tools">
      <v-icon
        @click="$emit('show-prefixes-dialog')"
        title="Job Naming Prefix"
        :class="icon_class"
        >text_format</v-icon
      >
    </div>

    <div :class="btn_class">
      <v-icon
        @click="$emit('show-print-manager')"
        title="Print"
        :class="icon_class"
        >print</v-icon
      >
    </div>

    <div :class="btn_class" v-if="show_drawing_tools">
      <v-icon
        @click="$emit('show-blow-manager')"
        title="Blow fiber"
        :class="icon_class"
        >model_training</v-icon
      >
    </div>

    <div
      v-if="show_drawing_tools"
      :class="`${btn_class} ${move_tool_btn_class}`"
    >
      <v-icon
        @click="$emit('handleMoveToolClick')"
        title="Edit/move features"
        class="pa-2"
        >touch_app</v-icon
      >
    </div>

    <div class="btn-group">
      <v-menu left offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" title="Report layers" class="pa-2">gamepad</v-icon>
        </template>

        <v-list class="menu-items" dense>
          <v-list-item
            v-for="(item, index) in reportsMenu"
            :key="index"
            @click="$emit('handleReportsClick', item)"
          >
            <v-list-item-content>
              <v-list-item-title :id="item.text">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="btn-group" v-if="show_drawing_tools">
      <v-menu left offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" title="Feature Connections" class="pa-2"
            >device_hub</v-icon
          >
        </template>

        <v-list class="menu-items" dense>
          <v-list-item
            v-for="(item, index) in connectionMenu"
            :key="index"
            @click="$emit('handleConnectionsClick', item)"
          >
            <v-list-item-content>
              <v-list-item-title :id="item.text">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="btn-group" v-if="show_drawing_tools">
      <v-menu left offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" title="Polygon features" class="pa-2">map</v-icon>
        </template>

        <v-list class="menu-items" dense>
          <v-list-item
            v-for="(item, index) in polygonFeatures"
            :key="index"
            @click="$emit('drawPolygon', item)"
          >
            <v-list-item-content>
              <v-list-item-title :id="item.text">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu class="menu" left offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" title="Line Features" class="pa-2">timeline</v-icon>
        </template>

        <v-list class="menu-items" dense>
          <v-list-item
            v-for="(item, index) in lineFeatures"
            :key="index"
            @click="$emit('drawLineString', item)"
          >
            <v-list-item-icon>
              <v-icon color="primary" v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title :id="item.text">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu left offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" title="Point Features" class="pa-2">place</v-icon>
        </template>

        <v-list class="menu-items" dense>
          <v-list-item
            v-for="(item, index) in pointFeatures"
            :key="index"
            @click="$emit('drawPoint', item)"
          >
            <v-list-item-icon>
              <v-icon color="primary">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title :id="item.text">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- <v-icon @click="drawPoint" title="Building" class="pa-2">map</v-icon> -->
    </div>

    <div class="btn-group">
      <v-menu left offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" title="Measurements" class="pa-2"
            >straighten</v-icon
          >
        </template>

        <v-list class="menu-items" dense>
          <v-list-item
            v-for="(item, index) in measureFeatures"
            :key="index"
            @click="$emit('drawMeasurementFeature', item)"
          >
            <v-list-item-content>
              <v-list-item-title :id="item.text">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="btn-group" v-if="show_drawing_tools">
      <v-menu left offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" title="Upload features" class="pa-2"
            >cloud_upload</v-icon
          >
        </template>

        <v-list class="menu-items" dense>
          <v-list-item
            v-for="(item, index) in importMenus"
            :key="index"
            @click="$emit('handleFeatureImport', item)"
          >
            <v-list-item-content>
              <v-list-item-title :id="item.text">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu left offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" title="Download features" class="pa-2"
            >cloud_download</v-icon
          >
        </template>

        <v-list class="menu-items" dense>
          <v-list-item
            v-for="(item, index) in exportMenus"
            :key="index"
            @click="$emit('handleFeatureExport', item)"
          >
            <v-list-item-content>
              <v-list-item-title :id="item.text">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="btn-group">
      <v-menu left offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" title="Switch layers" class="pa-2">layers</v-icon>
        </template>

        <v-list class="menu-items" dense>
          <v-list-item
            v-for="(item, index) in baseMaps"
            :key="index"
            @click="$emit('changeBaseLayer', item)"
          >
            <v-list-item-content>
              <v-list-item-title :id="item.text">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import featuresMixin from "../../../utils/featuresMixins/features";

export default {
  name: "DrawControlsComponent",
  mixins: [featuresMixin],
  props: {
    show_drawing_tools: {
      type: Boolean,
    },
    move_tool_btn_class: {
      type: String,
    },
  },
  data: () => ({
    btn_class: "btn-group",
    icon_class: "pa-2",
    layer_on: false,
  }),
  methods: {
    toggleSplicesLayer() {
      this.layer_on = !this.layer_on;
      this.btn_class = this.layer_on ? "btn-group success" : "btn-group";
      this.icon_class = this.layer_on ? "pa-2 white--text" : "pa-2";

      this.$emit("toggleSplicesLayer");
    },
  },
};
</script>

<style lang="css" scoped>
.dialog-title {
  text-align: center;
  border-radius: 3px;
}

.cursor-item {
  cursor: pointer;
}

.feature-dialog {
  position: absolute;
  width: 350px;
  min-height: 300px;
  max-height: 600px;
  background: #fff;
  top: 10em;
  left: 2em;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 3px;
}

.controls {
  margin-top: 12px;
  border: 1px solid transparent;
  border-radius: 6px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 38px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  position: absolute !important;
  top: 0;
  left: 3em;
  background: red;
}

#mouse-position {
  margin-top: 12px;
  border: 1px solid transparent;
  height: 38px;
  position: absolute;
  top: 0;
  left: 470px;
  background: white;
  font-size: 14px;
  border-radius: 3px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
}

.custom-draw-controls {
  position: absolute !important;
  bottom: 1%;
  right: 15px;
  display: block;
}

.custom-draw-controls .v-icon {
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 5px;
  cursor: pointer; */
  font-size: 1em !important;
  /* border-bottom: solid 1px rgb(236, 221, 221); */
  /* color: #8ea0c0 !important; */
  /* color: #999 !important; */
}

.custom-draw-controls .v-icon.white--text {
  color: #fff !important;
}

.custom-draw-controls * {
  display: block;
}

.custom-draw-controls i {
  color: #8ea0c0;
}

#pac-input,
#pac-features-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

#pac-input:focus,
pac-features-input:focus {
  border-color: #4d90fe;
}

.pac-container {
  font-family: Roboto;
}

#type-selector {
  color: #fff;
  background-color: #4d90fe;
  padding: 5px 11px 0px 11px;
}

#type-selector label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

.btn-group {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 0.625rem;
}

.menu-items .v-list-item__icon {
  margin-right: 15px !important;
}

.menu-items * {
  cursor: pointer;
}

.success .v-icon {
  color: #fff !important;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
}
</style>