/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import { FEATURE_DUCT } from "./feature_constants";
import { getLocationFeatures } from "./map_functions";

export default {
  data: () => ({
    graph: null,
  }),
  methods: {
    addEdge(v, w) {
      if (!this.graph[v]) {
        this.graph[v] = [];
      }

      if (!this.graph[v].includes(w)) this.graph[v].push(w);
    },

    findPathhNodes(fromNode, toNode) {
      // Mark all the vertices as not visited(By default set
      // as false)
      let visited = {};
      let path_nodes = [];

      // Create a queue for BFS
      let queue = [];

      // Mark the current node as visited and enqueue it
      visited[fromNode] = true;
      queue.push(fromNode);

      while (queue.length != 0) {
        // Dequeue a vertex from queue and print it
        let node = queue.shift();
        path_nodes.push(node);

        if (node == toNode) return path_nodes;

        for (let i = 0; i < this.graph[node].length; i++) {
          if (!visited[this.graph[node][i]]) {
            queue.push(this.graph[node][i]);
            visited[this.graph[node][i]] = true;
          }
        }
      }

      // If BFS is complete without visited d
      return false;
    },

    async initGraph() {
      /**
       * Get IDs for all ducts on the map
       * For each duct D: find the start and end feature IDs
       * Add an edge between the start/end feature IDs
       *
       */

      let ductsVectorSource = this.getFeatureVectorSource(FEATURE_DUCT);
      let ducts = ductsVectorSource.getFeatures();

      this.graph = {};

      ducts.forEach((duct) => {
        let locFeatures = getLocationFeatures(duct, this.vectorSources);
        if (locFeatures.length == 2) {
          this.addEdge(locFeatures[0].getId(), locFeatures[1].getId());
          this.addEdge(locFeatures[1].getId(), locFeatures[0].getId());
        }
      });

      // test path between the site and any crossing hook
      // let sitesVs = this.getFeatureVectorSource( FEATURE_SITE )
      // let hooksVs = this.getFeatureVectorSource( ROAD_CROSSING_HOOK )
      // let site = sitesVs.getFeatures()[ 0 ]
      // let hook = hooksVs.getFeatures()[ 0 ]

      // let pathNodes = this.findPathhNodes( site.getId(), hook.getId() )
      // if ( pathNodes && pathNodes.length > 0 ) {
      //     pathNodes.forEach( nodeId => {
      //         let feature = getFeatureById( nodeId, this.vectorSources )
      //         if ( feature ) console.log( 'Path Node:', feature.get( 'name' ), feature.get( 'type' ) )
      //     } )
      // }
    },
  },
};
