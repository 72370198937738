<template>
  <v-layout>
    <v-btn
      @click="dialog = !dialog"
      small
      color="success"
      absolute
      right
      outlined
      class="mt-5"
    >
      <v-icon>add</v-icon>Add
    </v-btn>

    <v-flex xs12 class="mt-9">
      <v-data-table
        :items="slacks"
        :loading="status == 'loading'"
        :headers="header"
        hide-default-footer
      >
        <template v-slot:[`item.slack_type`]="{ item }">{{
          getSlackObj(item.slack_type) ? getSlackObj(item.slack_type).text : ""
        }}</template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn @click="showDeleteDialog(item)" small text>
            <v-icon small color="error">delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-flex>

    <div v-if="dialog">
      <SlackDialog
        :selectedFeatures="selectedFeatures"
        :feature="feature"
        :dialogModel="dialog"
        :vectorSources="vectorSources"
        @reloadSlacks="loadSlacks"
        @closeDialog="closeDialog"
        @highlightSelectedFeature="emitHighlightSelectedFeature"
        @deleteAllHighlightFeatures="emitDeleteAllHighlightFeatures"
      ></SlackDialog>
    </div>

    <ConfirmDelete
      :deleteMessage="deleteMessage"
      :dialogModel="delete_dialog"
      @closeDialog="closeDialog"
      @deleteConfirmed="deleteSlack"
      :model="deleteModel"
    ></ConfirmDelete>
  </v-layout>
</template>

<script>
import SlackDialog from "./dialogs/SlackDialog";
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../utils/mixins";
import ConfirmDelete from "../../generic/ConfirmDeleteDialog";
import { retrieveKeyValObj } from "../../../utils/map_functions";

export default {
  name: "CableSlacksTableComponent",
  components: { SlackDialog, ConfirmDelete },
  mixins: [dialogMixins],
  props: {
    feature: {
      type: Object,
      required: true,
    },
    vectorSources: {
      type: Array,
    },
    selectedFeatures: {
      type: Array,
    },
  },
  data: () => ({
    deleteModel: null,
    deleteMessage: "Are you sure you want to delete this slack loop?",
    header: [
      {
        text: "Fiber Cable",
        value: "fiber_cable_name",
        sortable: false,
      },
      {
        text: "Slack Length",
        value: "length",
        sortable: false,
      },
      {
        text: "Slack Type",
        value: "slack_type",
        sortable: false,
      },
      {
        text: "More",
        value: "action",
      },
    ],
    slacks: [],
    slack_types: [
      {
        text: "Generic Slack",
        value: 1,
      },
      {
        text: "Before Closure",
        value: 2,
      },
      {
        text: "After Closure",
        value: 3,
      },
    ],
  }),
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  mounted() {
    this.loadSlacks();
  },
  methods: {
    ...mapActions({
      getSlacks: "getSlacks",
      removeSlack: "removeSlack",
    }),
    emitDeleteAllHighlightFeatures() {
      this.$emit("deleteAllHighlightFeatures");
    },
    emitHighlightSelectedFeature(feature) {
      this.$emit("highlightSelectedFeature", feature);
    },
    showDeleteDialog(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    deleteSlack(slack) {
      this.deleteModel = null;
      this.removeSlack(slack.id).then(() => this.loadSlacks());
    },
    loadSlacks() {
      let payload = {
        feature: this.feature.getId().toString(),
      };

      this.getSlacks(payload).then((res) => {
        this.slacks = res.results;
      });
    },
    getSlackObj(value) {
      return retrieveKeyValObj(value, this.slack_types);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>