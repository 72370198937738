<template>
  <!-- check main.js for custom component details -->
  <c-app-layout>
    <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

    <div v-if="loading">
      <c-loader message="Loading. Please wait..."></c-loader>
    </div>
    <div v-else>
      <h1>Customers</h1>
      <div class="grid-container">
        <DashboardCustomer
          v-for="customer in filteredCustomers"
          :key="customer.id"
          :customer="customer"
        ></DashboardCustomer>
      </div>

      <h1 style="margin-top: 25px">Monitoring</h1>
      <div class="grid-container">
        <NocMonitoring></NocMonitoring>
      </div>
    </div>
  </c-app-layout>
</template>

<script>
import { mapState } from "vuex";
import DashboardCustomer from "../components/dashboard/Customer";
import NocMonitoring from "../components/dashboard/NocMonitoring.vue";

export default {
  components: { DashboardCustomer, NocMonitoring },
  data: () => ({
    bread_crumbs: [
      {
        text: "Dashboard",
        href: "dashboard",
      },
    ],
  }),
  computed: {
    ...mapState({
      customers: (state) => state.customersModule.customers,
      loading: (state) => state.customersModule.status,
      loggedinUser: (state) => state.usersModule.current_user,
    }),
    filteredCustomers: function () {
      if (this.loggedinUser.customer_id == "0") return this.customers;
      else
        return this.customers.filter(
          (customer) => customer.id == this.loggedinUser.customer_id
        );
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  gap: 35px 35px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 25px;
}
</style>
