/* eslint-disable no-unused-vars */

import { FMS_FAULTS_TIMEOUT } from "../utils";
import { mapActions, mapState } from "vuex"; //monitoredRoutesUrl

export default {
  data: () => ({
    username: "admin",
    password: "admin",
    authUrl:
      "https://auth.fms.soliton.co.ke/auth/realms/Fiber/protocol/openid-connect/token",
    rtuUrl:
      "https://api.fms.soliton.co.ke/api/topology/remotetestunits/?pageNumber=1&pageSize=100",
    opticalRoutesUrl:
      "https://api.fms.soliton.co.ke/api/topology/opticalroutes/",
    rtuDetailUrl:
      "https://api.fms.soliton.co.ke/api/topology/remotetestunits/<<RTU_ID>>",
    selectedRtu: null,
    assetIDKey: "<<ASSET_ID>>",
    rtuIDKey: "<<RTU_ID>>",
    faultResultsUrl: `https://data.fms.soliton.co.ke/v1/results?$filter=metadata/AssetId eq <<ASSET_ID>> and (metadata/TestCategory ne 'MonitoringWithoutFault') and (brief ne null)&$orderby=metadata/TestTime desc&$top=1&$skip=0&$select=brief/Measurement/OtdrMeasurements/RftsFiberFaults`,
    // token: null,
    // refreshToken: null,
    apiConfig: {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    axiosInstance: null,
    timeoutID: null,
    faultsMonitoringID: null,
    opticalDevices: [],
    opticalRoutes: [],
    show_faults: false,
    show_faults_button: false,
    faultsData: [],
    faultsFound: false,
  }),

  async mounted() {
    // this.axiosInstance = axios.create({
    //   transformRequest: (data, headers) => {
    //     delete headers.common;
    //     return data;
    //   },
    // });
    // await this.forceResetToken();
    // await this.getOpticalRoutes();
    // await this.getRTUs();
    // this.checkMonitoredRoutes();
    // // refresh token before it expires
    // this.faultsMonitoringID = setInterval(
    //   this.checkMonitoredRoutes,
    //   FMS_REFRESH_TIMEOUT
    // );
    // this.timeoutID = setInterval(this.forceResetToken, FMS_REFRESH_TIMEOUT);
  },

  destroyed() {
    if (this.timeoutID) clearInterval(this.timeoutID);
    if (this.faultsMonitoringID) {
      clearInterval(this.faultsMonitoringID);
    }
  },

  watch: {
    token: function() {
      // only run this in map instance
      this.getOpticalRoutes();
      this.checkMonitoredRoutes();
      this.faultsMonitoringID = setInterval(
        this.checkMonitoredRoutes,
        FMS_FAULTS_TIMEOUT
      );
    },

    faultsFound: function(val) {
      if (val) this.show_faults_button = true;
      else this.show_faults_button = false;
    },
  },

  computed: {
    ...mapState({
      token: (state) => state.usersModule.fms_token,
      refreshToken: (state) => state.usersModule.fms_refresh_token,
    }),
  },

  methods: {
    ...mapActions({
      getMonitoredRoutes: "getMonitoredRoutes",
      saveFmsToken: "saveFmsToken",
      resetFMSTokens: "resetFMSTokens",
    }),

    toggleFaults() {
      if (this.show_faults) this.clearFaults();
      this.show_faults = !this.show_faults;
    },

    async checkMonitoredRoutes() {
      this.faultsData = [];
      const payload = { job_id: "all" };
      if (this.$route.params.job_id) {
        payload["job_id"] = this.$route.params.job_id;
      }

      const res = await this.getMonitoredRoutes(payload);

      if (res && res.routes) {
        for (var i = 0; i < res.routes.length; i++) {
          await this.getOpticalRouteResults(res.routes[i]);
        }
      }

      const faults = this.faultsData.filter((fault) => fault.fault_distance);
      this.faultsFound = faults.length > 0;
    },

    async forceResetToken() {
      this.resetFMSTokens();
      await this.getAPIToken();
    },

    async getAPIToken() {
      if (!this.token) {
        const params = new URLSearchParams();
        params.append("client_id", "fg-topologyui");

        if (!this.refreshToken) {
          params.append("username", this.username);
          params.append("password", this.password);
          params.append(
            "client_secret",
            "493f468b-9ceb-4139-8910-89d7b178c58f"
          );
          params.append("grant_type", "password");
        } else {
          params.append("grant_type", "refresh_token");
          params.append("refresh_token", this.refreshToken);
        }

        try {
          const tokenResponse = await this.axiosInstance.post(
            this.authUrl,
            params,
            this.apiConfig
          );
          this.saveAuthToken(tokenResponse.data);
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log(this.token);
      }
    },

    saveAuthToken(tokenResponse) {
      if (tokenResponse && tokenResponse.access_token) {
        this.saveFmsToken({
          token: tokenResponse.access_token,
          refresh_token: tokenResponse.refresh_token,
        });
      }
    },

    async getRTUs() {
      const headers = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await this.axiosInstance.get(this.rtuUrl, headers);
        if (res && res.data) {
          this.opticalDevices = res.data.objects.map(
            (obj) => obj.opticalDevice
          );
        }
      } catch (e) {
        console.log(e, "RTU exception");
      }
    },

    async getOpticalRoutes() {
      const headers = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await this.axiosInstance.get(
          this.opticalRoutesUrl,
          headers
        );
        if (res && res.data) {
          this.opticalRoutes = res.data.objects.map((obj) => obj.opticalRoute);
          console.log("ors", this.opticalRoutes);
        }
      } catch (e) {
        console.log(e, "Optical routes exception");
      }
    },

    async getRTU(rtuId) {
      if (rtuId) {
        const rtuDetailUrl = this.rtuDetailUrl.replace(this.rtuIDKey, rtuId);
        const headers = {
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json",
          },
        };

        try {
          const res = await this.axiosInstance.get(rtuDetailUrl, headers);
          if (res && res.data) {
            this.selectedRtu = res.data.opticalDevice;
          }
        } catch (e) {
          console.log(e, "RTU detail exception");
        }
      }
    },

    async getOpticalRouteResults(opticalRouteId) {
      const resultsUrl = this.faultResultsUrl.replace(
        this.assetIDKey,
        opticalRouteId
      );
      const headers = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await this.axiosInstance.get(resultsUrl, headers);
        if (res && res.data) {
          const brief = res.data.results[0].brief;
          const faultsInfo =
            brief.Measurement.OtdrMeasurements[0].RftsFiberFaults;

          if (faultsInfo.length > 0) {
            if (faultsInfo[0].FaultType == "Break" && faultsInfo[0].Position) {
              const faultDistance = faultsInfo[0].Position / 1000;
              const route = this.faultsData.find(
                (route) => route.id == opticalRouteId
              );
              if (!route) this.faultsData.push({ id: opticalRouteId });
              this.faultsData = this.faultsData.map((route) => {
                if (route.id == opticalRouteId) {
                  const opticalRoute = this.opticalRoutes.find(
                    (route) => route.id == opticalRouteId
                  );
                  if (opticalRoute) {
                    route.name = opticalRoute.name;
                    route.fault_distance = faultDistance;
                  }
                }

                return route;
              });
            }
          }
        }
      } catch (e) {
        console.log(e, "RTU exception");
        this.getAPIToken();
      }
    },
  },
};
