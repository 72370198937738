<template>
  <v-layout>
    <v-btn
      @click="dialog = !dialog"
      small
      color="success"
      absolute
      right
      outlined
      class="mt-5"
    >
      <v-icon>add</v-icon>Add
    </v-btn>

    <v-flex xs12 class="mt-9">
      <v-data-table
        :items="pole_accessories"
        :loading="status == 'loading'"
        :headers="header"
        hide-default-footer
      >
        <template v-slot:[`item.accessory_name`]="{ item }">{{
          getMaterialName(item.material)
        }}</template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn @click="showDeleteDialog(item)" small text>
            <v-icon small color="error">delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-flex>

    <div v-if="dialog">
      <PoleAccessoryDialog
        :selectedFeatures="selectedFeatures"
        :feature="feature"
        :accessory_types="pole_accessory_types"
        :dialogModel="dialog"
        @reload="loadAccessories"
        @closeDialog="closeDialog"
      ></PoleAccessoryDialog>
    </div>

    <ConfirmDelete
      :deleteMessage="deleteMessage"
      :dialogModel="delete_dialog"
      @closeDialog="closeDialog"
      @deleteConfirmed="deleteAccessory"
      :model="deleteModel"
    ></ConfirmDelete>
  </v-layout>
</template>

<script>
import PoleAccessoryDialog from "./dialogs/PoleAccessoryItemDialog";
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../utils/mixins";
import ConfirmDelete from "../../generic/ConfirmDeleteDialog";

export default {
  name: "PoleAccessoriesTableComponent",
  components: { PoleAccessoryDialog, ConfirmDelete },
  mixins: [dialogMixins],
  props: {
    feature: {
      type: Object,
      required: true,
    },
    selectedFeatures: {
      type: Array,
    },
  },
  data: () => ({
    deleteModel: null,
    deleteMessage: "Are you sure you want to delete this accessory?",
    header: [
      {
        text: "Accessory",
        value: "accessory_name",
        sortable: false,
      },
      {
        text: "Quantity",
        value: "quantity",
        sortable: false,
      },
      {
        text: "Action",
        value: "action",
      },
    ],
    pole_accessories: [],
    pole_accessory_types: [],
  }),
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
      materials: (state) => state.materialsModule.material_types,
    }),
  },
  mounted() {
    this.loadAccessoryTypes();
    this.loadAccessories();

    if (this.materials.length == 0) this.getMaterialTypes();
  },
  methods: {
    ...mapActions({
      getPoleAccessoryItems: "getPoleAccessoryItems",
      createPoleAccessoryItem: "createPoleAccessoryItem",
      getPoleAccessories: "getPoleAccessories",
      removePoleAccessoryItem: "removePoleAccessoryItem",
      getMaterialTypes: "getMaterialTypes",
    }),
    getMaterialName(materialId) {
      let material = this.materials.find(
        (material) => material.id == materialId
      );
      if (material) return material.type_name;
    },
    showDeleteDialog(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    deleteAccessory(item) {
      this.deleteModel = null;
      this.removePoleAccessoryItem(item.id).then(() => this.loadAccessories());
    },
    loadAccessories() {
      let payload = {
        pole: this.feature.get("pk"),
      };

      this.getPoleAccessoryItems(payload).then((res) => {
        this.pole_accessories = res.results;
      });
    },
    loadAccessoryTypes() {
      this.getPoleAccessories().then((res) => {
        this.pole_accessory_types = res.results;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>