/**
 * Contains variables and functions for measurements
 */

/* eslint-disable no-unused-vars */

import {
  formatArea,
  formatLength,
  createMeasureOverlay,
} from "../map_functions";
import { unByKey } from "ol/Observable";
import { Polygon, LineString } from "ol/geom";
import {
  length as turfLength,
  area as turfArea,
  lineString as turfLineString,
  polygon as turfPolygon,
} from "@turf/turf";

export default {
  data: () => ({
    measureTooltipElement: null,
    measureTooltipOverlay: null,
    sketchListener: null,
  }),
  methods: {
    getLengthProperty(lineFeature) {
      if (lineFeature.getGeometry() instanceof LineString) {
        let geomFeature = turfLineString(
          lineFeature.getGeometry().getCoordinates()
        );
        return formatLength(turfLength(geomFeature, { units: "meters" }));
      }

      return undefined;
    },

    trackMeasurementSketch(evt) {
      // create measure tooltip element if it is undefined
      if (this.measureTooltipElement == null)
        this.measureTooltipElement = document.createElement("div");
      var measureTooltipOverlay = createMeasureOverlay(
        this.measureTooltipElement
      );

      // add overlay to map
      if (this.map) this.map.addOverlay(measureTooltipOverlay);

      let sketch = evt.feature;

      /** @type {module:ol/coordinate~Coordinate|undefined} */
      var tooltipCoord = evt.coordinate;

      let self = this;
      this.sketchListener = sketch
        .getGeometry()
        .on("change", function(innerEvent) {
          var geom = innerEvent.target;
          var geomFeature;

          var output;
          if (geom instanceof Polygon) {
            // TODO: Fix this feature
            if (geom.getCoordinates().length >= 3) {
              geomFeature = turfPolygon(geom.getCoordinates());
              output = formatArea(turfArea(geomFeature));
              tooltipCoord = geom.getInteriorPoint().getCoordinates();
            }
          } else if (geom instanceof LineString) {
            geomFeature = turfLineString(geom.getCoordinates());
            output = formatLength(turfLength(geomFeature, { units: "meters" }));
            tooltipCoord = geom.getLastCoordinate();
          }

          self.measureTooltipElement.innerHTML = output;
          measureTooltipOverlay.setPosition(tooltipCoord);
        });
    },

    clearMeasurements() {
      this.measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
      this.measureTooltipElement.style = `background: #ffcc33; padding: 3px; border-radius: 6px; border-color: #ffcc33; border: solid 1px #c1950e;`;
      // this.measureTooltipOverlay.setOffset( [ 0, -7 ] );
      this.measureTooltipElement = null;
      unByKey(this.sketchListener);
    },

    removeMeasurementOverlays() {
      if (this.map) {
        this.map.getOverlays().clear();
      }
    },
  },
};
