<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="success white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-select
                  v-model="inner_duct_cable.fiber_cable"
                  :rules="rules.required_field"
                  :items="filteredFeatures"
                  item-text="text"
                  item-value="value"
                  label="Fiber Cable"
                ></v-select>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="createOrEdit"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../../utils/mixins";
import { getDerivedName } from "../../../../utils/map_functions";
import { FEATURE_FIBER_CABLE } from "../../../../utils/feature_constants";

export default {
  name: "DuctCableDialogComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    feature: {
      type: Object,
      required: true,
    },
    selectedInnerDuct: {
      type: Object,
    },
    selectedFeatures: {
      type: Array,
    },
    vectorSources: {
      type: Array,
      required: true,
    },
    editModel: {
      type: Object,
    },
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "Add Fiber Cable",
    dialog_button_text: "Add/Edit",
    valid: true,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    dialog: null,
    inner_duct_cable: {},
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.inner_duct_cable = {};
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
    filteredFeatures: function () {
      let fiberCablesArray = [];
      this.selectedFeatures.forEach((feature) => {
        if (feature.get("type") == FEATURE_FIBER_CABLE) {
          fiberCablesArray.push({
            text: getDerivedName(feature, this.vectorSources),
            value: feature.get("pk"),
          });
        }
      });
      return fiberCablesArray;
    },
  },
  methods: {
    ...mapActions({
      getInnerDuctCable: "getInnerDuctCable",
      updateInnerDuctCable: "updateInnerDuctCable",
    }),
    createOrEdit() {
      if (this.$refs.form.validate()) {
        if (this.feature.get("pk")) {
          this.inner_duct_cable.inner_duct = this.selectedInnerDuct.id;
          this.updateInnerDuctCable(this.inner_duct_cable).then((res) => {
            this.$emit("reload");
            console.log(res);
            this.resetFields();
            this.closeDialog();
          });
        }
      }
    },
    resetFields() {
      this.is_edit_mode = false;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();

      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>