<template>
  <!-- check main.js for custom component details -->
  <c-submenu-layout>
    <!-- SubMenu Column -->
    <v-flex class="sub-menu" xs2>
      <c-sub-menu :items="sub_menu"></c-sub-menu>
    </v-flex>

    <!-- Main Content Column -->
    <v-flex xs10 class="main-content-border">
      <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

      <h1>Materials Types</h1>

      <v-card class="mt-9">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search material types"
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :search="search"
            :loading="status == 'loading'"
            :headers="headers"
            :items="material_types"
          >
            <template v-slot:item.uom="{ item }">
              <p v-if="item.uom == 1">PIECE</p>
              <p v-else>METERS</p>
            </template>

            <template v-slot:item.action="{ item }">
              <v-icon small color="primary" class="pr-3" @click="editItem(item)"
                >edit</v-icon
              >
              <v-icon small color="error" @click="deleteItem(item)"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-btn
        @click="dialog = !dialog"
        absolute
        top
        right
        fab
        rounded
        color="primary"
        class="white--text fab-button"
      >
        <v-icon>add</v-icon>
      </v-btn>

      <div v-if="dialog">
        <MaterialTypesDialog
          @closeDialog="closeDialog"
          :editModel="editModel"
          @resetEditModel="resetEditModel"
          :dialogModel="dialog"
        ></MaterialTypesDialog>
      </div>

      <div v-if="delete_dialog">
        <DeleteDialog
          :deleteMessage="delete_message"
          @deleteConfirmed="deleteConfirmed"
          @closeDialog="closeDialog"
          :dialogModel="delete_dialog"
          :model="deleteModel"
        ></DeleteDialog>
      </div>
    </v-flex>
  </c-submenu-layout>
</template>

<script>
import appConfig from "../../config";
import MaterialTypesDialog from "./sub-components/MaterialTypesDialog";
import DeleteDialog from "../generic/ConfirmDeleteDialog";
import dialogMixins from "../../utils/mixins";
import { mapState, mapActions } from "vuex";

export default {
  name: "MaterialTypesComponent",
  components: { MaterialTypesDialog, DeleteDialog },
  mixins: [dialogMixins],
  data: () => ({
    search: "",
    deleteModel: null,
    editModel: null,
    delete_message: "Are you sure you want to delete this material type?",
    sub_menu: [],
    bread_crumbs: [
      {
        text: "Materials Types",
        href: "materials-types",
      },
    ],
    headers: [
      {
        text: "Material Type",
        value: "type_name",
      },

      {
        text: "UOM",
        value: "uom",
      },
      {
        text: "Action",
        value: "action",
      },
    ],
  }),
  mounted() {
    this.sub_menu = appConfig.settings_page_sub_menus;
    if (this.material_types.length == 0) this.getMaterialTypes();
  },
  computed: {
    ...mapState({
      material_types: (state) => state.materialsModule.material_types,
      status: (state) => state.materialsModule.status,
    }),
  },
  methods: {
    ...mapActions({
      getMaterialTypes: "getMaterialTypes",
      deleteMaterialType: "deleteMaterialType",
    }),
    resetEditModel() {
      this.editModel = undefined;
    },
    editItem(item) {
      this.editModel = item;
      this.dialog = !this.dialog;
    },
    deleteItem(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    deleteConfirmed(item) {
      this.deleteMaterialType(item.id).then(() => {
        this.getMaterialTypes();
      });
    },
  },
};
</script>