/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import {
  FEATURE_ODF,
  FEATURE_SPLITTER,
  FEATURE_FIBER_CABLE,
  FEATURE_FDP,
  FEATURE_ONT,
  FEATURE_OLT,
  FEATURE_FACE_PLATE,
} from "../feature_constants";
import { getFeatureClosestToCoordinates } from "../map_functions";

export default {
  data: () => ({
    connection_features: [],
  }),

  mounted() {},

  watch: {
    connection_features: {
      deep: true,
      handler() {
        console.log("Loading conenction manager ...", this.connection_features);
      },
    },
  },

  methods: {
    schematicsClickListener(event) {
      // only activate if in splice schematics view
      if (this.splice_schematics_visible) {
        this.connection_features = [];
        let connectionFeatures = [
          FEATURE_ODF,
          FEATURE_SPLITTER,
          FEATURE_FIBER_CABLE,
          FEATURE_FDP,
          FEATURE_ONT,
          FEATURE_OLT,
          FEATURE_FACE_PLATE,
        ];
        var coordinate = event.coordinate;
        this.connection_features = getFeatureClosestToCoordinates(
          coordinate,
          this.vectorSources,
          connectionFeatures
        );
        this.selectedFeatures = this.connection_features;
        this.showConnectionManager();
      }
    },
  },
};
