<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="success white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <!-- accessory_type -->
            <!-- <v-flex>
              <v-select
                v-model="accessory.pole_accessory"
                :rules="rules.required_field"
                :items="accessory_types"
                item-text="accessory_name"
                item-value="id"
                label="Pole Accessory"
              ></v-select>
            </v-flex>-->

            <v-flex>
              <v-autocomplete
                v-model="accessory.material"
                :items="materials"
                color="white"
                item-text="type_name"
                item-value="id"
                label="Pole Accessory"
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="accessory.quantity"
                  :rules="rules.required_field"
                  placeholder="Qty"
                  label="Quantity"
                  type="number"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="createOrEdit"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../../utils/mixins";

export default {
  name: "PoleAccessoryItemDialogComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    feature: {
      type: Object,
      required: true,
    },
    accessory_types: {
      type: Array,
      required: true,
    },
    editModel: {
      type: Object,
    },
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "Pole Accessories",
    dialog_button_text: "Create",
    valid: true,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    dialog: null,
    accessory: {},
    upload_file: null,
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.accessory = {};

    if (this.materials.length == 0) this.getMaterialTypes();
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
      materials: (state) => state.materialsModule.material_types,
    }),
  },
  methods: {
    ...mapActions({
      createPoleAccessoryItem: "createPoleAccessoryItem",
      getMaterialTypes: "getMaterialTypes",
    }),
    createOrEdit() {
      if (this.$refs.form.validate()) {
        this.accessory.pole = this.feature.get("pk");

        this.createPoleAccessoryItem(this.accessory)
          .then(() => {
            this.closeDialog();
          })
          .catch(() => {
            this.closeDialog();
          });
      }
    },
    resetFields() {
      this.accessory = {};
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();
      this.$emit("reload");
      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.accessory = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>