<template>
  <!-- check main.js for custom component details -->
  <c-app-layout>
    <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

    <h1>Projects List</h1>

    <v-card class="mt-9">
      <v-card-title class="ma-0 pa-0">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          class="mr-3"
          label="Search customer projects"
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :search="search"
          :loading="loading == 'loading'"
          :headers="headers"
          :items="customer_projects"
          @click:row="handleClick"
          class="linked-table"
        >
          <template v-slot:item.project_status="{ item }">
            <v-chip
              v-for="(status, i) in item.project_status"
              :key="i"
              :color="getChipColor(status)"
              class="pa-2 mr-2"
              small
              >{{ status == "Closed" ? "Documented" : status }}</v-chip
            >
          </template>

          <template v-slot:item.action="{ item }">
            <router-link
              :to="'/customer-projects/' + item.customer.id + '/projects'"
            >
              Load Projects
              <v-icon class="primary--text">chevron_right</v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-btn
      @click="dialog = !dialog"
      absolute
      top
      right
      fab
      rounded
      color="primary"
      class="white--text fab-button"
    >
      <v-icon>add</v-icon>
    </v-btn>

    <div v-if="dialog">
      <ProjectDialog
        @closeDialog="closeDialog"
        :dialogModel="dialog"
      ></ProjectDialog>
    </div>
  </c-app-layout>
</template>

<script>
import ProjectDialog from "./sub-components/ProjectDialog";
import dialogMixins from "../../utils/mixins";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: { ProjectDialog },
  mixins: [dialogMixins],
  data: () => ({
    search: "",
    sub_menu: [],
    bread_crumbs: [
      {
        text: "Projects",
        href: "projects",
      },
    ],
    headers: [
      {
        text: "Customer Name",
        value: "customer.customer_name",
      },
      {
        text: "Number of Projects",
        value: "projects",
      },
      {
        text: "More",
        value: "action",
      },
    ],
  }),
  computed: {
    ...mapState({
      customers: (state) => state.customersModule.customers,
      loading: (state) => state.projectsModule.status,
      projects: (state) => state.projectsModule.projects,
      customer_projects: function () {
        let projects = [];
        this.customers.forEach((customer) => {
          let customerProjects = this.projects.filter(
            (project) => project.customer_id == customer.id
          );
          if (customerProjects.length > 0)
            projects.push({
              customer: customer,
              projects: customerProjects.length,
            });
        });

        return projects;
      },
    }),
  },
  created() {
    if (this.customers.length == 0 || this.projects.length == 0) {
      this.getCustomers().then(() => this.getProjects());
    } else if (this.projects.length == 0) {
      this.getProjects();
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      getCustomers: "getCustomers",
      getProjects: "getProjects",
    }),
    ...mapMutations({
      setCustomer: "setCustomer",
      setCustomerUsers: "setCustomerUsers",
    }),
    handleClick(item) {
      this.$router.push("/customer-projects/" + item.customer.id + "/projects");
    },
    getChipColor(chipState) {
      if (chipState.toString().toLowerCase() == "proposed") return "warning";
      if (
        chipState.toString().toLowerCase() == "implementation" ||
        chipState.toString().toLowerCase() == "updated"
      )
        return "primary";
      if (chipState.toString().toLowerCase() == "closed") return "success";

      return "secondary";
    },
    loadCustomer(item) {
      this.setCustomer(item);
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
</style>
