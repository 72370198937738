<template>
  <div>
    <v-card
      :to="'network/' + customer.id + '/mapping-studio'"
      class="customer-card align-center"
    >
      <v-flex v-if="customer.logo_url != null" xs12 class="pt-3">
        <v-avatar tile height="100" width="100%">
          <img :src="customer.logo_url" alt />
        </v-avatar>
      </v-flex>
      <v-card-title class="card-header">
        <h1>{{ customer.customer_name }}</h1>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../utils/mixins";

export default {
  name: "DashboardCustomerComponent",
  mixins: [mixins],
  props: {
    customer: {
      type: Object,
    },
  },
  mounted() {},
};
</script>

<style scoped>
.customer-card {
  padding: 2rem;
  text-align: center;
}
.customer-card img {
  object-fit: contain;
  max-width: 200px;
}
.card-header {
  width: 100%;
}
.card-header h1 {
  word-break: break-word;
  font-size: 0.9em !important;
  text-align: center;
  width: 100%;
}
.align-center {
  text-align: center;
}
</style>