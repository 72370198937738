<template>
  <!-- check main.js for custom component details -->
  <c-submenu-layout>
    <!-- SubMenu Column -->
    <v-flex class="sub-menu" xs2>
      <c-sub-menu :items="sub_menu"></c-sub-menu>
    </v-flex>

    <!-- Main Content Column -->
    <v-flex xs10 class="main-content-border">
      <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

      <h1>System Users</h1>

      <UsersList></UsersList>

      <v-btn
        @click="dialog=!dialog"
        absolute
        top
        right
        fab
        rounded
        color="primary"
        class="white--text fab-button"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-flex>

    <div v-if="dialog">
      <UserDialog @closeDialog="closeDialog" :dialogModel="dialog"></UserDialog>
    </div>
  </c-submenu-layout>
</template>

<script>
import appConfig from "../../config";
import UsersList from "../../components/users/UsersList";
import UserDialog from "../../components/users/UserDialog";
import dialogMixins from "../../utils/mixins";
import { mapState } from "vuex";
import { filterMenuOptions } from "../../utils/utils";

export default {
  name: "UsersPageComponent",
  components: { UsersList, UserDialog },
  mixins: [dialogMixins],
  data: () => ({
    sub_menu: [],
    bread_crumbs: [
      {
        text: "Users",
        href: "users"
      }
    ],
    headers: [
      {
        text: "User Name",
        value: "user_name"
      },
      {
        text: "Email Address",
        value: "user_email"
      },
      {
        text: "User Group",
        value: "user_group"
      },
      {
        text: "Actions",
        value: "action"
      }
    ]
  }),
  computed: {
    ...mapState({
      user: state => state.usersModule.current_user
    })
  },
  mounted() {
    this.sub_menu = filterMenuOptions(
      appConfig.settings_page_sub_menus,
      this.user
    );
    // this.sub_menu = appConfig.settings_page_sub_menus;
  }
};
</script>