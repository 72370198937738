<template>
  <v-layout>
    <v-btn
      @click="dialog = !dialog"
      small
      color="success"
      absolute
      right
      outlined
      class="mt-5"
    >
      <v-icon>add</v-icon>Add
    </v-btn>

    <v-flex xs12 class="mt-9">
      <v-data-table
        :items="trenches"
        :loading="status == 'loading'"
        :headers="header"
        hide-default-footer
      >
        <template v-slot:[`item.trench_depth`]="{ item }">{{
          getFormattedLength(item.trench_depth)
        }}</template>
        <template v-slot:[`item.trench_distance`]="{ item }">{{
          getFormattedLength(item.trench_distance)
        }}</template>
        <template v-slot:[`item.soil_profile`]="{ item }">{{
          getObjectName(item.soil_profile).text
        }}</template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn @click="showDeleteDialog(item)" small text>
            <v-icon small color="error">delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-flex>

    <div v-if="dialog">
      <TrenchesDialog
        :vectorSources="vectorSources"
        :feature="feature"
        :dialogModel="dialog"
        @reloadTrenches="loadTrenches"
        @closeDialog="closeDialog"
      ></TrenchesDialog>
    </div>

    <ConfirmDelete
      :deleteMessage="deleteMessage"
      :dialogModel="delete_dialog"
      @closeDialog="closeDialog"
      @deleteConfirmed="deleteTrench"
      :model="deleteModel"
    ></ConfirmDelete>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TrenchesDialog from "./dialogs/TrenchDialog";
import dialogMixins from "../../../utils/mixins";
import ConfirmDelete from "../../generic/ConfirmDeleteDialog";
import { formatLength, retrieveKeyValObj } from "../../../utils/map_functions";

export default {
  name: "TrenchesTableComponent",
  components: { TrenchesDialog, ConfirmDelete },
  props: {
    feature: {
      type: Object,
      required: true,
    },
    vectorSources: {
      type: Array,
    },
  },
  mixins: [dialogMixins],
  data: () => ({
    header: [
      {
        text: "Trench Distance",
        value: "trench_distance",
        sortable: false,
      },
      {
        text: "Trench Depth",
        value: "trench_depth",
        sortable: false,
      },
      {
        text: "Soil Profile",
        value: "soil_profile",
        sortable: false,
      },
      {
        text: "More",
        value: "action",
      },
    ],
    trenches: [],
    soil_profiles: [
      {
        text: "Rock",
        value: 1,
      },
      {
        text: "Sand",
        value: 2,
      },
      {
        text: "Soft",
        value: 3,
      },
      {
        text: "Unknown",
        value: 4,
      },
    ],
  }),
  mounted() {
    this.loadTrenches();
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    ...mapActions({
      getTrenches: "getTrenches",
      removeTrench: "removeTrench",
    }),
    getFormattedLength(length) {
      return formatLength(length);
    },
    deleteTrench(trench) {
      this.deleteModel = null;
      this.removeTrench(trench.id).then(() => this.loadTrenches());
    },
    loadTrenches() {
      let payload = {
        feature: this.feature.get("pk").toString(),
      };

      this.getTrenches(payload).then((res) => {
        console.log(res);
        this.trenches = res.results;
      });
    },
    getObjectName(value) {
      return retrieveKeyValObj(value, this.soil_profiles);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>