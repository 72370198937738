/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import {
  Circle as CircleStyle,
  Icon,
  Fill,
  Stroke,
  Style,
  RegularShape,
  Text,
} from "ol/style";
import {
  siteScale,
  iconScale,
  MAX_FEATURE_RESOLUTION,
  iconAnchorUnits,
  FEATURE_BUILDING,
  FEATURE_HANDHOLE,
  FEATURE_FIBER_CABLE,
  FEATURE_DUCT,
  FEATURE_MEASUREMENT_LENGTH,
  FEATURE_MEASUREMENT_AREA,
  FEATURE_CABLE_HIGHLIGHT,
  FEATURE_DUCT_HIGHLIGHT,
  FEATURE_MICRO_TUNNEL,
  REDLINE_LINE,
  REDLINE_POLYGON,
  REDLINE_POINT,
  TRENCH,
  FEATURE_ODF,
  FEATURE_ROAD,
  FEATURE_SITE,
  FEATURE_SPLICE_SCHEMATIC,
  FEATURE_SPLICE_SCHEMATIC_POINT,
  SIGNAL_TRACE,
  FEATURE_MANHOLE,
  FEATURE_SPLICE_SCHEMATIC_LINESTRING,
  FEATURE_SPLICE_SCHEMATIC_PATH,
  FIBER_COLORS,
  BLUE_PRINT_LINE,
  BRIDGE_ATTACHMENT,
  GABION,
} from "./feature_constants";
import {
  buildingIcon,
  siteIcon,
  patchPanelIcon,
  patchPanelSelectedIcon,
  splitterIcon,
  splitterSelectedIcon,
  buildingSelectedIcon,
  redArrowIcon,
} from "./feature_icons";
import { calculateLengthProperty, getFiberColor } from "./map_functions";
import {
  ductPlanStylesConfig,
  featureStylesConfig,
  schematicsStylesConfig,
  implStylesConfig,
  proposedStylesConfig,
} from "./point_feature_styles_config";

export var buildingPolygonStyle = new Style({
  fill: new Fill({
    color: "#bdb7b7",
  }),
  stroke: new Stroke({
    color: "#adabab",
    width: 2,
  }),
});

var measurementsLayerStyle = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.2)",
  }),
  stroke: new Stroke({
    color: "#ffcc33",
    width: 5,
  }),
  image: new CircleStyle({
    radius: 7,
    fill: new Fill({
      color: "#ffcc33",
    }),
  }),
});

var fiberCableSelectedStyle = new Style({
  fill: new Fill({
    color: "blue",
  }),
  stroke: new Stroke({
    color: "blue",
    lineDash: [2.5, 2.5],
    width: 5,
  }),
});

var fiberCableStyle = new Style({
  fill: new Fill({
    color: "green",
  }),
  stroke: new Stroke({
    color: "green",
    lineDash: [2.5, 2.5],
    width: 3,
  }),
});

var fiberCableSchematicsStyle = new Style({
  fill: new Fill({
    color: "black",
  }),
  stroke: new Stroke({
    color: "black",
    lineDash: [1, 1],
    width: 2,
  }),
});

var spliceSchematicsStyle = new Style({
  fill: new Fill({
    color: "#f8334e",
  }),
  stroke: new Stroke({
    color: "#f8334e",
    lineDash: [1, 1],
    width: 2,
  }),
});

var spliceSchematicsPathStyle = new Style({
  fill: new Fill({
    color: "#111",
  }),
  stroke: new Stroke({
    color: "#111",
    lineDash: [1, 1],
    width: 2,
  }),
});

var spliceSchematicsOffsetLineStyle = new Style({
  fill: new Fill({
    color: "#111",
  }),
  stroke: new Stroke({
    color: "#111",
    lineDash: [6, 6],
    width: 2,
  }),
});

var ductPlanStyle = new Style({
  fill: new Fill({
    color: "blue",
  }),
  stroke: new Stroke({
    color: "blue",
    lineDash: [1, 1],
    width: 4,
  }),
});

var microTunnelStyle = new Style({
  fill: new Fill({
    color: "lightpink",
  }),
  stroke: new Stroke({
    color: "lightpink",
    lineDash: [2.5, 2.5],
    width: 12,
  }),
});

var trenchStyle = new Style({
  fill: new Fill({
    // color: 'lightblue'
    color: "#ac9494",
  }),
  stroke: new Stroke({
    // color: 'lightblue',
    color: "#b3adad",
    lineDash: [2.5, 2.5],
    width: 12,
  }),
});

var gabionStyle = new Style({
  fill: new Fill({
    color: "#fff",
  }),
  stroke: new Stroke({
    color: "#fff",
    lineDash: [2.5, 2.5],
    width: 5,
  }),
});

var bridgeAttachementStyle = new Style({
  fill: new Fill({
    color: "lightblue",
  }),
  stroke: new Stroke({
    color: "lightblue",
    lineDash: [2.5, 2.5],
    width: 5,
  }),
});

var signalTraceStyle = new Style({
  fill: new Fill({
    color: "blue",
  }),
  stroke: new Stroke({
    color: "blue",
    lineDash: [2.5, 2.5],
    width: 5,
  }),
});

var ductSelectedStyle = new Style({
  fill: new Fill({
    color: "black",
  }),
  stroke: new Stroke({
    color: "black",
    lineDash: [2.5, 2.5],
    width: 3,
  }),
});

var lateralFiberStyle = new Style({
  fill: new Fill({
    color: "green",
  }),
  stroke: new Stroke({
    color: "green",
    lineDash: [2.5, 2.5],
    width: 3,
  }),
});

var backboneFiberStyle = new Style({
  fill: new Fill({
    color: "purple",
  }),
  stroke: new Stroke({
    color: "purple",
    lineDash: [2.5, 2.5],
    width: 3,
  }),
});

var feederFiberStyle = new Style({
  fill: new Fill({
    color: "orange",
  }),
  stroke: new Stroke({
    color: "orange",
    lineDash: [2.5, 2.5],
    width: 3,
  }),
});

var dropFiberStyle = new Style({
  fill: new Fill({
    color: "pink",
  }),
  stroke: new Stroke({
    color: "pink",
    lineDash: [2.5, 2.5],
    width: 3,
  }),
});

var roadStyle = new Style({
  fill: new Fill({
    color: "blue",
  }),

  backgroundFill: new Fill({ color: "blue" }),

  stroke: new Stroke({
    color: "blue",
    lineDash: [2, 3],
    width: 2,
  }),
});

export var fromSegmentStyle = new Style({
  fill: new Fill({
    color: "blue",
  }),
  stroke: new Stroke({
    color: "blue",
    lineDash: [2.5, 2.5],
    width: 5,
  }),
});

export var thirdPartyFeatureStyle = new Style({
  fill: new Fill({
    color: "purple",
  }),
  stroke: new Stroke({
    color: "purple",
    lineDash: [2.5, 2.5],
    width: 5,
  }),
});

export var toSegmentStyle = new Style({
  fill: new Fill({
    color: "red",
  }),
  stroke: new Stroke({
    color: "red",
    lineDash: [2.5, 2.5],
    width: 5,
  }),
});

export var blueprintStyle = new Style({
  fill: new Fill({
    color: "red",
  }),
  stroke: new Stroke({
    color: "red",
    lineDash: [6, 6],
    width: 2,
  }),
});

// export function fiberCableStyle( feature ) {
//     let cableCategory = feature.get( 'cable_category' ) ? feature.get( 'cable_category' ) : '';

//     if ( cableCategory == 2 ) return backboneFiberStyle;
//     if ( cableCategory == 3 ) return feederFiberStyle;
//     if ( cableCategory == 4 ) return dropFiberStyle;

//     return ductSelectedStyle;
// }

var defaultPatchpanelStyle = new Style({
  image: new Icon(
    /** @type {module:ol/style/Icon~Options} */ ({
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      scale: iconScale,
      opacity: 1,
      anchor: iconAnchorUnits,
      src: patchPanelIcon,
    })
  ),
});

var defaultPatchpanelSelectedStyle = new Style({
  image: new Icon(
    /** @type {module:ol/style/Icon~Options} */ ({
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      scale: iconScale,
      opacity: 1,
      anchor: iconAnchorUnits,
      src: patchPanelSelectedIcon,
    })
  ),
});

function createTextLabel(feature, resolution) {
  return new Text({
    font: "10px Calibri,sans-serif",
    fill: new Fill({ color: "red" }),
    backgroundFill: new Fill({ color: "#fff" }),
    stroke: new Stroke({
      color: "#fff",
    }),
    backgroundStroke: new Stroke({
      color: "yellow",
    }),
    offsetY: -17,
    textAlign: "end",
    textBaseline: "middle",
    text: feature.get("name"),
  });
}

function createSchematicsTextLabel(feature, resolution) {
  let allowedFeatureTypes = [
    FEATURE_ODF,
    FEATURE_FIBER_CABLE,
    FEATURE_ROAD,
    FEATURE_BUILDING,
    FEATURE_SITE,
    FEATURE_SPLICE_SCHEMATIC,
    FEATURE_SPLICE_SCHEMATIC_POINT,
    FEATURE_SPLICE_SCHEMATIC_LINESTRING,
    FEATURE_SPLICE_SCHEMATIC_PATH,
  ];

  var rotation = 0;
  var bottomOfCableOffset = 5; // bottom of cable
  var topOfCableOffset = -18; // top of cable
  var isCable = feature.get("type") == FEATURE_FIBER_CABLE;
  var isSpliceSchematic = feature.get("type") == FEATURE_SPLICE_SCHEMATIC;
  var isSpliceSchematicOffsetLine =
    feature.get("type") == FEATURE_SPLICE_SCHEMATIC_LINESTRING;
  var isSpliceSchematicPath =
    feature.get("type") == FEATURE_SPLICE_SCHEMATIC_PATH;
  var segmentLength = 0;

  var finalCableOffset = isSpliceSchematicPath
    ? bottomOfCableOffset
    : topOfCableOffset;

  let geometry = feature.getGeometry();
  if (geometry instanceof LineString && feature.get("type") != FEATURE_ROAD) {
    // offsetY = -15;

    geometry.forEachSegment(function(start, end) {
      let segLineString = new LineString([start, end]);
      let length = segLineString.getLength();
      if (length > segmentLength || rotation == 0) {
        segmentLength = length;

        var dx = end[0] - start[0];
        var dy = end[1] - start[1];

        rotation = Math.atan2(dy, dx);
      }
    });
  }

  if (allowedFeatureTypes.includes(feature.get("type"))) {
    let fiberCableName = feature.get("cable_size")
      ? `${feature.get("cable_size")}C OFC`
      : "<undef>";
    var label = isCable ? fiberCableName : "";
    var textFillColor;

    if (label == "") {
      if (feature.get("name")) label = feature.get("name").toString();
    }

    if (label && label.length > 0 && feature.get("type") != FEATURE_ROAD)
      label = label.toUpperCase();
    if (feature.get("type") == FEATURE_ROAD) textFillColor = "red";
    else textFillColor = isSpliceSchematic ? "#f8334e" : "black";

    return new Text({
      font: "12px Calibri,sans-serif",
      fill: new Fill({ color: textFillColor }),
      backgroundFill: new Fill({ color: "#fff" }),
      stroke: new Stroke({
        color: "#fff",
      }),
      backgroundStroke: new Stroke({
        color: "#fff",
      }),
      offsetY: finalCableOffset,
      rotateWithView: true,
      rotation: -rotation,
      textBaseline: "top",
      textAlign: "end",
      text: label,
    });
  }

  return;
}

function createDuctPlanTextLabel(feature, resolution) {
  let allowedFeatureTypes = [
    FEATURE_MANHOLE,
    FEATURE_HANDHOLE,
    FEATURE_DUCT,
    FEATURE_SITE,
  ];

  var rotation = 0;
  var offsetY = -25;
  var offsetX = 10;
  var isDuct = feature.get("type") == FEATURE_DUCT;
  var isSite = feature.get("type") == FEATURE_SITE;
  var segmentLength = 0;

  let geometry = feature.getGeometry();
  if (geometry instanceof LineString) {
    offsetY = -12;

    geometry.forEachSegment(function(start, end) {
      let segLineString = new LineString([start, end]);
      let length = segLineString.getLength();
      if (length > segmentLength || rotation == 0) {
        segmentLength = length;

        var dx = end[0] - start[0];
        var dy = end[1] - start[1];

        rotation = Math.atan2(dy, dx);
      }
    });
  }

  if (allowedFeatureTypes.includes(feature.get("type"))) {
    var coords = feature.getGeometry().getCoordinates();
    var label;
    if (isSite) {
      label = feature.get("name");
    } else {
      label = isDuct
        ? `${calculateLengthProperty(feature)} m`
        : `${feature.get("name")} \nS: ${coords[1].toFixed(
            4
          )} \nE: ${coords[0].toFixed(4)}`;
    }

    var fillColor = isDuct ? "#FF1493" : "#FF0000";

    return new Text({
      font: "14px Calibri,sans-serif",
      fill: new Fill({ color: fillColor }),
      offsetY: offsetY,
      offsetX: offsetX,
      rotateWithView: true,
      rotation: -rotation,
      textAlign: "end",
      text: label.toUpperCase(),
    });
  }

  return;
}

export function redliningStyleFunction(feature, resolution) {
  if (resolution > MAX_FEATURE_RESOLUTION) {
    return null;
  }

  if (feature.get("type") == REDLINE_LINE) {
    return new Style({
      fill: new Fill({
        color: "red",
      }),

      backgroundFill: new Fill({ color: "red" }),

      stroke: new Stroke({
        color: "red",
        lineDash: [2.5, 2.5],
        width: 3,
      }),

      text: createTextLabel(feature, resolution),
    });
  }

  if (feature.get("type") == REDLINE_POLYGON) {
    return new Style({
      fill: new Fill({
        color: "rgba(255, 0, 0, 0.3)",
      }),

      stroke: new Stroke({
        color: "red",
        lineDash: [2.5, 2.5],
        width: 3,
      }),

      text: createTextLabel(feature, resolution),
    });
  }

  if (feature.get("type") == REDLINE_POINT) {
    let redlinePointStyle = createStyleIcon(
      featureStylesConfig[REDLINE_POINT],
      createTextLabel(feature, resolution)
    );
    return redlinePointStyle;
  }
}

export function roadsStyleFunction(feature, resolution) {
  if (resolution > MAX_FEATURE_RESOLUTION) {
    return null;
  }

  return new Style({
    fill: new Fill({
      color: "black",
    }),

    backgroundFill: new Fill({ color: "black" }),

    stroke: new Stroke({
      color: "black",
      lineDash: [2, 3],
      width: 2,
    }),
  });
}

export function namedRoadsStyleFunction(feature, resolution) {
  if (resolution > MAX_FEATURE_RESOLUTION) {
    return null;
  }

  return new Style({
    fill: new Fill({
      color: "black",
    }),

    backgroundFill: new Fill({ color: "black" }),

    stroke: new Stroke({
      color: "black",
      lineDash: [2, 3],
      width: 2,
    }),

    text: createTextLabel(feature, resolution),
  });
}

export function areaOfInterestStyleFunction(feature, resolution) {
  if (resolution > MAX_FEATURE_RESOLUTION) {
    return null;
  }

  return new Style({
    fill: new Fill({
      color: "rgba(255, 0, 0, 0.1)",
    }),

    stroke: new Stroke({
      color: "black",
      lineDash: [2.5, 2.5],
      width: 1,
    }),

    text: createTextLabel(feature, resolution),
  });
}

export function patchpanelStyle(feature, resolution) {
  if (resolution > MAX_FEATURE_RESOLUTION) {
    return null;
  }

  return defaultPatchpanelStyle;
}

export function patchpanelSelectedStyle(feature, resolution) {
  if (resolution > MAX_FEATURE_RESOLUTION) {
    return null;
  }

  return defaultPatchpanelSelectedStyle;
}

var splitterStyle = new Style({
  image: new Icon(
    /** @type {module:ol/style/Icon~Options} */ ({
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      scale: iconScale,
      opacity: 1,
      anchor: iconAnchorUnits,
      src: splitterIcon,
    })
  ),
});

var splitterSelectedStyle = new Style({
  image: new Icon(
    /** @type {module:ol/style/Icon~Options} */ ({
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      scale: iconScale,
      opacity: 1,
      anchor: iconAnchorUnits,
      src: splitterSelectedIcon,
    })
  ),
});

var _buildingStyle = new Style({
  image: new Icon(
    /** @type {module:ol/style/Icon~Options} */ ({
      anchorXUnits: "fraction",
      anchorYUnits: "pixel",
      scale: iconScale,
      opacity: 1,
      anchor: iconAnchorUnits,
      src: buildingIcon,
    })
  ),
});

var _buildingSelectedStyle = new Style({
  image: new Icon(
    /** @type {module:ol/style/Icon~Options} */ ({
      anchorXUnits: "fraction",
      anchorYUnits: "pixel",
      scale: iconScale,
      opacity: 1,
      anchor: iconAnchorUnits,
      src: buildingSelectedIcon,
    })
  ),
});

export function buildingStyle(feature, resolution) {
  if (resolution > MAX_FEATURE_RESOLUTION) {
    return null;
  }

  return _buildingStyle;
}

export function pointLayersStyleFunction(feature, resolution) {
  if (
    !measurementFeatures.includes(feature.get("type")) &&
    !isLineFeature(feature.get("type"))
  )
    if (resolution > MAX_FEATURE_RESOLUTION) return null;

  let isProposed = feature.get("job_state") != 3;

  return getFeatureStyle(feature, isProposed);
}

export function schematicsStyleFunction(feature, resolution) {
  if (
    !measurementFeatures.includes(feature.get("type")) &&
    !isLineFeature(feature.get("type"))
  )
    if (resolution > MAX_FEATURE_RESOLUTION) return null;

  let styles = [];
  let style = getFeatureSchematicsStyle(feature.get("type"));
  style.setText(createSchematicsTextLabel(feature, resolution));
  styles.push(style);

  // add direction arrow
  var geometry = feature.getGeometry();
  if (feature.get("type") == FEATURE_SPLICE_SCHEMATIC) {
    geometry.forEachSegment(function(start, end) {
      var dx = end[0] - start[0];
      var dy = end[1] - start[1];
      var rotation = Math.atan2(dy, dx);
      // arrows
      styles.push(
        new Style({
          geometry: new Point(end),
          image: new Icon({
            src: redArrowIcon,
            anchor: [0.75, 0.5],
            rotateWithView: true,
            rotation: -rotation,
          }),
        })
      );
    });
  }

  return styles;
}

export function ductPlanStyleFunction(feature, resolution) {
  let style = getDuctPlanStyle(feature.get("type"));
  let textStyle = createDuctPlanTextLabel(feature, resolution);
  if (textStyle) style.setText(textStyle);

  return style;
}

var siteStyle = new Style({
  image: new Icon(
    /** @type {module:ol/style/Icon~Options} */ ({
      anchorXUnits: "fraction",
      anchorYUnits: "pixel",
      scale: siteScale,
      opacity: 1,
      anchor: iconAnchorUnits,
      src: siteIcon,
    })
  ),
});

/*
 * Function to tell line features from point features
 * Input is a feature type
 * Output is a Boolean, indicating whether it is a point/line feature
 */
const measurementFeatures = [
  FEATURE_MEASUREMENT_LENGTH,
  FEATURE_MEASUREMENT_AREA,
];
const lineFeatures = [
  FEATURE_DUCT,
  FEATURE_FIBER_CABLE,
  FEATURE_MEASUREMENT_LENGTH,
  FEATURE_DUCT_HIGHLIGHT,
  FEATURE_CABLE_HIGHLIGHT,
  FEATURE_MICRO_TUNNEL,
  TRENCH,
  FEATURE_SPLICE_SCHEMATIC,
  FEATURE_SPLICE_SCHEMATIC_LINESTRING,
  FEATURE_SPLICE_SCHEMATIC_PATH,
  SIGNAL_TRACE,
  BLUE_PRINT_LINE,
  BRIDGE_ATTACHMENT,
  GABION,
  FEATURE_ROAD,
];
var isOtherFeature = (featureType) => !lineFeatures.includes(featureType);
var isLineFeature = (featureType) => lineFeatures.includes(featureType);

function createStyleIcon(styleConfig, textCallback = undefined) {
  var scale = siteScale; // styleConfig.scale ? styleConfig.scale : 0.05;

  // check if an icon was set
  if (styleConfig.icon) {
    let style = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ ({
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          opacity: 1,
          // anchor: [ 0.5, 250 ],
          anchor: iconAnchorUnits,
          scale: scale,
          src: styleConfig.icon,
        })
      ),
      text: textCallback,
    });

    return style;
  }

  // there is no icon set, so use the configured styles
  var styleObject = {};
  var strokeColor = styleConfig.strokeColor ? styleConfig.strokeColor : "black";
  Object.assign(styleObject, styleConfig);
  var fill = new Fill({ color: styleConfig.color });
  var stroke = new Stroke({ color: strokeColor, width: 2 });
  styleObject.fill = fill;
  styleObject.stroke = stroke;

  return new Style({
    image: new RegularShape(styleObject),
    text: textCallback,
  });
}

export function getFeatureStyle(feature, isProposed = true) {
  let featureType = feature instanceof Object ? feature.get("type") : feature;

  if (isLineFeature(featureType)) {
    if (featureType == BLUE_PRINT_LINE) return blueprintStyle;
    if (featureType == FEATURE_FIBER_CABLE) return fiberCableStyle;
    if (featureType == FEATURE_MEASUREMENT_LENGTH)
      return measurementsLayerStyle;
    if (featureType == FEATURE_CABLE_HIGHLIGHT) return fiberCableSelectedStyle;
    if (featureType == FEATURE_DUCT_HIGHLIGHT) return ductSelectedStyle;
    if (featureType == FEATURE_MICRO_TUNNEL) return microTunnelStyle;
    if (featureType == TRENCH) return trenchStyle;
    if (featureType == GABION) return gabionStyle;
    if (featureType == BRIDGE_ATTACHMENT) return bridgeAttachementStyle;

    if (featureType == FEATURE_DUCT) {
      if (feature instanceof Object) {
        if (feature.get("is_3rd_party") && feature.get("is_3rd_party") == "2") {
          let styleOptions = {
            fill: new Fill({
              color: "red",
            }),
            stroke: new Stroke({
              color: "red",
              lineDash: [2.5, 2.5],
              width: 3,
            }),
          };

          return new Style(styleOptions);
        }
      }

      return ductSelectedStyle;
    }

    if (featureType == SIGNAL_TRACE) {
      let styleOptions = {
        fill: new Fill({
          color: "blue",
        }),
        stroke: new Stroke({
          color: "blue",
          lineDash: [2.5, 2.5],
          width: 5,
        }),
      };

      if (feature instanceof Object && feature.get("fiber_number")) {
        let fiberNumber = parseInt(feature.get("fiber_number"));
        if (fiberNumber > 0) {
          let color = getFiberColor(fiberNumber, FIBER_COLORS);
          styleOptions.fill = new Fill({
            color: color,
          });

          styleOptions.stroke = new Stroke({
            color: color,
            lineDash: [2.5, 2.5],
            width: 5,
          });
        }
      }

      return new Style(styleOptions);
    }
  }

  if (!isLineFeature(featureType)) {
    if (
      featureStylesConfig[featureType] ||
      proposedStylesConfig[featureType] ||
      implStylesConfig[featureType]
    ) {
      // return proposed style icon
      if (isProposed && proposedStylesConfig[featureType])
        return createStyleIcon(proposedStylesConfig[featureType]);

      // return documented style icon
      if (implStylesConfig[featureType])
        return createStyleIcon(implStylesConfig[featureType]);

      // return default style icon
      return createStyleIcon(featureStylesConfig[featureType]);
    } else if (featureType == FEATURE_MEASUREMENT_AREA)
      return measurementsLayerStyle;
  }

  return null;
}

export function getFeatureSchematicsStyle(featureType) {
  if (isLineFeature(featureType)) {
    if (featureType == FEATURE_FIBER_CABLE) return fiberCableSchematicsStyle;
    if (featureType == FEATURE_ROAD) return roadStyle;
    if (featureType == FEATURE_SPLICE_SCHEMATIC) return spliceSchematicsStyle;
    if (featureType == FEATURE_SPLICE_SCHEMATIC_LINESTRING)
      return spliceSchematicsOffsetLineStyle;
    if (featureType == FEATURE_SPLICE_SCHEMATIC_PATH)
      return spliceSchematicsPathStyle;
  }

  if (isOtherFeature(featureType)) {
    if (schematicsStylesConfig[featureType]) {
      return createStyleIcon(schematicsStylesConfig[featureType]);
    }
  }

  return null;
}

export function getDuctPlanStyle(featureType) {
  if (isLineFeature(featureType)) {
    if (featureType == FEATURE_DUCT) return ductPlanStyle;
  }

  if (isOtherFeature(featureType)) {
    if (ductPlanStylesConfig[featureType]) {
      return createStyleIcon(ductPlanStylesConfig[featureType]);
    }
  }

  return null;
}
