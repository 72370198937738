
<!-- Takes a loation and returns al splices and patches at this location -->
<template>
  <v-layout>
    <v-btn style="margin: auto" color="success" class="mt-9">Open Splices View</v-btn>
  </v-layout>
</template>

<script>
export default {
  name: "SplicesTableComponent",
  data: () => ({
    headers: [],
  }),
};
</script>

<style lang="css" scoped>
</style>