<template>
  <v-layout>
    <v-btn
      @click="dialog = !dialog"
      small
      color="success"
      absolute
      right
      outlined
      class="mt-5"
    >
      <v-icon>add</v-icon>Add
    </v-btn>
    <v-flex xs12 class="mt-9">
      <v-data-table
        :loading="status == 'loading'"
        :items="attachments"
        :headers="header"
        hide-default-footer
      >
        <template v-slot:[`item.attachment`]="{ item }">
          <v-btn :href="item.attachment" target="_blank" text color="success">
            <v-icon>link</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.attachement_type`]="{ item }">{{
          getAttachmentType(item.attachment)
        }}</template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn text small color="error" @click="showDeleteDialog(item)">
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-flex>

    <div v-if="dialog">
      <AttachmentsDialog
        @reloadAttachments="loadAttaachments"
        :feature="feature"
        :dialogModel="dialog"
        @closeDialog="closeDialog"
      ></AttachmentsDialog>
    </div>

    <div v-if="delete_dialog"></div>
    <ConfirmDelete
      :deleteMessage="deleteMessage"
      :dialogModel="delete_dialog"
      @closeDialog="closeDialog"
      @deleteConfirmed="deleteAttachment"
      :model="deleteModel"
    ></ConfirmDelete>
  </v-layout>
</template>

<script>
import AttachmentsDialog from "./dialogs/AttachmentsDialog";
import dialogMixins from "../../../utils/mixins";
import { mapActions, mapState } from "vuex";
import ConfirmDelete from "../../generic/ConfirmDeleteDialog";

export default {
  name: "AttachementsTableComponent",
  components: { AttachmentsDialog, ConfirmDelete },
  props: {
    feature: {
      type: Object,
      required: true,
    },
  },
  mixins: [dialogMixins],
  data: () => ({
    deleteModel: null,
    deleteMessage: "Are you sure you want to delete this attachment?",
    header: [
      {
        text: "Attachment",
        value: "attachment_name",
        sortable: false,
      },
      {
        text: "Type",
        value: "attachement_type",
        sortable: false,
      },
      {
        text: "URL",
        value: "attachment",
        sortable: false,
      },
      {
        text: "More",
        value: "action",
      },
    ],
    attachments: [],
  }),
  mounted() {
    this.loadAttaachments();
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    ...mapActions({
      getAttachments: "getFeatureAttachments",
      removeAttachment: "removeFeatureAttachment",
    }),
    showDeleteDialog(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    deleteAttachment(attachment) {
      this.deleteModel = null;
      this.removeAttachment(attachment.id).then(() => this.loadAttaachments());
    },
    loadAttaachments() {
      let payload = {
        feature: this.feature.getId().toString(),
      };

      this.getAttachments(payload).then((res) => {
        console.log(res);
        this.attachments = res.results;
      });
    },
    getAttachmentType(url) {
      let images = ["jpg", "jpeg", "png"];
      let pdf = ["pdf"];
      let docs = ["doc", "docx"];
      let xls = ["xls", "xlsx"];

      // extract file extension
      var fileExtention = url.split(".")[url.split(".").length - 1].toString();
      fileExtention = fileExtention.toLowerCase();

      if (docs.includes(fileExtention)) return "DOC";
      if (images.includes(fileExtention)) return "IMAGE";
      if (pdf.includes(fileExtention)) return "PDF";
      if (xls.includes(fileExtention)) return "Excel / CSV";

      return "Uknown";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>