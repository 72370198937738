export default {
  methods: {
    displayErrorAlert(message) {
      this.$notify({
        group: "error",
        title: "error",
        text: message,
      });
    },

    displayWarningAlert(message) {
      this.$notify({
        group: "warning",
        title: "warning",
        text: message,
      });
    },

    displaySuccessAlert(message) {
      this.$notify({
        group: "success",
        title: "success",
        text: message,
      });
    },

    displayInfoAlert(message) {
      this.$notify({
        group: "info",
        title: "info",
        text: message,
      });
    },
  },
};
