<!-- @click="$emit('showDeleteDialog')" -->
<template>
  <v-card class="pa-2 pt-9">
    <v-overlay :value="overlay" style="text-align: center">
      <p class="white--text">Sending Invite</p>
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </v-overlay>

    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="mt-0" text absolute top right>
          <v-icon v-on="on" color="primary">more_vert</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-for="(menuItem, index) in menuActions" :key="index">
          <v-list-item-title class="cursor-item" @click="itemClicked(menuItem)">{{ menuItem.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-card-title class="align-center bold user-name pt-5">
      <h1>{{ user.name }}</h1>
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-phone</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ user.phone_number ? user.phone_number : 'Phone number not set' }}</v-list-item-title>
            <v-list-item-subtitle>Personal</v-list-item-subtitle>
          </v-list-item-content>

          <!-- <v-list-item-icon>
                  <v-icon color="indigo">edit</v-icon>
          </v-list-item-icon>-->
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-email</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ user.email }}</v-list-item-title>
            <v-list-item-subtitle>Work</v-list-item-subtitle>
          </v-list-item-content>

          <!-- <v-list-item-icon>
                  <v-icon color="indigo">edit</v-icon>
          </v-list-item-icon>-->
        </v-list-item>

        <!-- <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">lock</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Password Reset</v-list-item-title>
            <v-list-item-subtitle>Change User Password</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon @click="$emit('resetPassword')" color="indigo">arrow_forward_ios</v-icon>
          </v-list-item-icon>
        </v-list-item>-->
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "UserDetailsComponent",
  data: () => ({
    overlay: false,
    menuActions: [
      {
        text: "Send Invite",
        action: "invite",
      },
      {
        text: "Reset Password",
        action: "reset_password",
      },
      // {
      //   text: "Suspend Account",
      //   action: "suspend",
      // },
      {
        text: "Delete Account",
        action: "delete",
      },
    ],
  }),
  mounted() {
    if (this.user == undefined) {
      this.getUser(this.$route.params.user_id);
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.user,
    }),
  },
  methods: {
    ...mapActions({
      getUser: "getUser",
      sendInvite: "sendInvite",
    }),
    execSendInvite() {
      if (this.user) {
        this.overlay = true;

        let payload = {
          user_id: this.user.id,
        };

        this.sendInvite(payload)
          .then(() => {
            this.overlay = false;
            this.$notify({
              group: "success",
              title: "Success",
              text: "Invite sent successfully",
            });
          })
          .catch(() => {
            this.overlay = false;
            this.$notify({
              group: "error",
              title: "Failed",
              text:
                "Invite not sent. Please contact the administrator for support.",
            });
          });
      }
    },
    itemClicked(item) {
      if (item.action == "invite") this.execSendInvite();
      if (item.action == "reset_password") this.$emit("resetPassword");
      if (item.action == "suspend") this.$emit("showSuspendDialog");
      if (item.action == "delete") this.$emit("showDeleteDialog");
    },
  },
};
</script>

<style lang="css" scoped>
.align-center {
  text-align: center;
  width: 100%;
  display: block;
}

.bold {
  font-weight: bold;
}

.cursor-item {
  cursor: pointer;
}

.user-name {
  word-break: keep-all !important;
}
</style>