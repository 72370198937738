<template>
  <v-card class="flat pb-3" elevation="0">
    <v-card-title
      v-if="title"
      justify-center
      class="success white--text justify-center"
      >{{ dialog_title }}</v-card-title
    >
    <v-container grid-list-sm class="pa-4">
      <v-form ref="form" lazy-validation>
        <v-layout row wrap>
          <!-- Upload single material -->
          <v-layout row wrap>
            <v-flex xs12 class="pt-3">
              <v-text-field
                v-model="manhole_prefix"
                placeholder="Manhole Prefix"
                label="Manhole Prefix"
                :loading="status == 'loading'"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 class="pt-3">
              <v-text-field
                v-model="handhole_prefix"
                placeholder="Handhole Prefix"
                label="Handhole Prefix"
                :loading="status == 'loading'"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-btn v-if="is_dialog" @click="closeDialog">Close</v-btn>
      <v-btn
        color="success"
        :loading="status == 'loading'"
        @click="execSetPrefixes"
        >{{ dialog_button_text }}</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn outlined class="error--text" @click="execClearPrefix">Clear</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import messagesMixin from "../../utils/messagingMixin"

import {
  FEATURE_HANDHOLE,
  FEATURE_MANHOLE,
} from "../../utils/feature_constants";

export default {
  name: "NamingPrefixes",
  props: {
    title: {
      type: Boolean,
    },
    is_dialog: {
      type: Boolean,
    },
    job: {
      type: Number,
    },
    customer: {
      type: Number,
    },
  },
  mixins: [messagesMixin],
  data: () => ({
    type: null,
    dialog_title: "Set Job Prefixes",
    dialog_button_text: "Update Settings",
    manhole_prefix: "",
    handhole_prefix: "",
    loading: false,
    prefixes: [],
    mh_prefixes_running: false,
    hh_prefixes_running: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  watch: {
    prefixes: {
      deep: true,
      handler() {
        let manholePrefix = this.prefixes.find(
          (prefix) => prefix.feature_type == FEATURE_MANHOLE
        );

        this.manhole_prefix = manholePrefix ? manholePrefix.prefix : "";

        let handholePrefix = this.prefixes.find(
          (prefix) => prefix.feature_type == FEATURE_HANDHOLE
        );

        this.handhole_prefix = handholePrefix ? handholePrefix.prefix : "";
      },
    },
  },
  mounted() {
    this.execGetPrefixes();
  },
  methods: {
    ...mapActions({
      createPrefix: "createPrefix",
      getPrefixes: "getPrefixes",
      updatePrefix: "updatePrefix",
      deletePrefix: "deletePrefix",
    }),

    closeDialog() {
      this.$emit('close-dialog')
    },

    /**
     * Get feature type prefixes for either a job or a customer
     */
    async execGetPrefixes() {
      if (this.customer) {
        this.getPrefixes({ customer: this.customer }).then((res) => {
          this.prefixes = res.results;
        });
      }

      if (this.job) {
        this.getPrefixes({ job: this.job }).then((res) => {
          this.prefixes = res.results;
        });
      }

      return Promise.resolve(1)
    },

    /**
     * Set feature type prefixes for either a job or a customer
     */
    async execSetPrefixes() {
      await this.setManholePrefixes()
      await this.setHandholePrefixes()

      // display success alert and close dialog
      this.displaySuccessAlert('Prefixes were set successfully')
      this.closeDialog();
    },

    async setManholePrefixes() {
      if (this.manhole_prefix) {
        let payload = {};
        payload.job = this.job ? this.job : undefined;
        payload.customer = this.customer ? this.customer : undefined;
        payload.feature_type = FEATURE_MANHOLE;

        let currentPrefix = this.prefixes.find(
          (prefix) => prefix.feature_type == FEATURE_MANHOLE
        );
        if (currentPrefix) payload = currentPrefix;
        payload.prefix = this.manhole_prefix;

        if (payload.id) 
          await this.updatePrefix(payload)
        else 
          await this.createPrefix(payload);
        
        this.execGetPrefixes();
      }
    },

    async setHandholePrefixes() {
      if (this.handhole_prefix) {
        let payload = {};
        payload.job = this.job ? this.job : undefined;
        payload.customer = this.customer ? this.customer : undefined;
        payload.feature_type = FEATURE_HANDHOLE;

        let currentPrefix = this.prefixes.find(
          (prefix) => prefix.feature_type == FEATURE_HANDHOLE
        );
        if (currentPrefix) payload = currentPrefix;
        payload.prefix = this.handhole_prefix;
        
        if (payload.id) 
          await this.updatePrefix(payload)
        else 
          await this.createPrefix(payload);

        this.execGetPrefixes();
      }

      return
    },

    /**
     * Clear feature type prefixes for either a job or a customer
     */
    execClearPrefix() {
      let manholePrefix = this.prefixes.find(
        (prefix) => prefix.feature_type == FEATURE_MANHOLE
      );
      if (manholePrefix) {
        this.deletePrefix(manholePrefix).then(() => this.execGetPrefixes());
      }

      let handholePrefix = this.prefixes.find(
        (prefix) => prefix.feature_type == FEATURE_HANDHOLE
      );

      if (handholePrefix) {
        this.deletePrefix(handholePrefix).then(() => this.execGetPrefixes());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>