<template>
  <div>
    <v-card :to="'noc/1/network-faults'" :class="border_class">
      <v-card-title class="card-header">
        <h1>NOC Monitored Routes</h1>
      </v-card-title>

      <v-card-text>
        <p small class="error--text" v-if="show_faults_button">
          Fault located. Click to find fault.
        </p>
        <p small class="success--text" v-else>All systems are fine</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../utils/mixins";
import fmsMixin from "../../utils/featuresMixins/fmsMixin";

export default {
  name: "DashboardCustomerComponent",
  mixins: [mixins, fmsMixin],
  data: () => ({}),
  mounted() {},
  computed: {
    border_class: function () {
      if (this.show_faults_button)
        return "customer-card align-center border-fault-class";
      return "customer-card align-center border-class";
    },
  },
};
</script>

<style scoped>
.customer-card {
  padding: 2rem;
  text-align: center;
}

.border-fault-class {
  border: solid red 1px;
}

.border-class {
  border: solid green 1px;
}
.customer-card img {
  object-fit: contain;
  max-width: 200px;
}
.card-header {
  width: 100%;
}
.card-header h1 {
  word-break: break-word;
  font-size: 0.9em !important;
  text-align: center;
  width: 100%;
}
.align-center {
  text-align: center;
}
</style>