export default {
  methods: {
    handleMoveToolClick() {
      if (this.move_tool_enabled) {
        this.disableMoveTool();
      } else {
        this.enableMoveTool();
      }
    },
  },
};
