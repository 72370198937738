<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="success white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="attachment.attachment_name"
                  :rules="rules.required_field"
                  placeholder="Attachment Name"
                  label="Attachment Name"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs12>
              <v-file-input
                v-model="upload_file"
                :rules="rules.required_field"
                color="primary"
                counter
                label="Attachment"
                placeholder="Select file"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip v-if="index < 2" color="success" dark label small>{{ text }}</v-chip>
                  <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
                  >+{{ files.length - 2 }} File(s)</span>
                </template>
              </v-file-input>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="createOrEdit"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../../utils/mixins";

export default {
  name: "AttachmentsDialogComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true
    },
    feature: {
      type: Object,
      required: true
    },
    editModel: {
      type: Object
    }
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "Add Attachment",
    dialog_button_text: "Create",
    valid: true,
    rules: {
      required_field: [v => !!v || "Field is required"]
    },
    dialog: null,
    attachment: {},
    upload_file: null
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.attachment = {};

    if (this.editModel) {
      this.is_edit_mode = true;

      Object.assign(this.attachment, this.editModel);
      this.dialog_title = "Edit Attachment";
      this.dialog_button_text = "Update";
    }
  },
  computed: {
    ...mapState({
      user: state => state.usersModule.current_user,
      status: state => state.featuresModule.status
    })
  },
  methods: {
    ...mapActions({
      createAttachment: "createFeatureAttachment"
    }),
    createOrEdit() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        for (let field in this.attachment) {
          // remove the logo, just in case we're editing this attachment
          if (field != "attachment") {
            if (this.attachment[field]) {
              formData.append(field, this.attachment[field]);
            }
          }
        }

        if (this.upload_file) {
          formData.append("attachment", this.upload_file);
          formData.append("upload_file", true);
        }

        if (this.is_edit_mode) {
          console.log("To be implemented");
        } else {
          formData.append("feature", this.feature.getId());
          formData.append("created_by", this.user.id);

          this.createAttachment(formData)
            .then(() => {
              this.$emit("reloadAttachments");
              this.resetFields();
              this.closeDialog();
            })
            .catch(() => {
              this.resetFields();
              this.closeDialog();
            });
        }
      }
    },
    resetFields() {
      this.attachment = {};
      this.is_edit_mode = false;
      this.upload_file = null;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();

      this.$emit("closeDialog");
    }
  },
  watch: {
    dialogModel: function(val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function(val) {
      if (!val) {
        this.attachment = {};
        this.closeDialog();
      }
    }
  }
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>