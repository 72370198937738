
<template>
  <v-layout>
    <v-card flat>
      <v-layout row wrap>
        <v-flex xs8 class="ml-4 pl-2">
          <v-select
            v-if="is_port_equipment || is_fdp"
            clearable
            v-model="port_type"
            :items="port_types"
            item-text="text"
            item-value="value"
            label="Port Type"
          ></v-select>
        </v-flex>

        <v-flex xs12>
          <!-- Fiber Connections Table -->
          <v-data-table
            :loading="loading"
            style="max-width: 100%"
            v-if="is_fiber"
            disable-sort
            fixed-header
            height="400"
            :headers="fiber_headers"
            :items="feature_connections"
            :items-per-page="600"
            hide-default-footer
          >
            <template v-slot:[`item.termination_A`]="{ item }">{{
              item.termination_A ? `PORT ${item.termination_A}` : ""
            }}</template>

            <template v-slot:[`item.termination_feature_A`]="{ item }">{{
              item.termination_feature_A &&
              retrieveFeature(item.termination_feature_A)
                ? retrieveFeature(item.termination_feature_A).get("name")
                : ``
            }}</template>

            <template v-slot:[`item.termination_B`]="{ item }">{{
              item.termination_B ? `PORT ${item.termination_B}` : ""
            }}</template>

            <template v-slot:[`item.termination_feature_B`]="{ item }">{{
              item.termination_feature_B &&
              retrieveFeature(item.termination_feature_B)
                ? retrieveFeature(item.termination_feature_B).get("name")
                : ``
            }}</template>

            <template v-slot:[`item.splice_A_fiber`]="{ item }">{{
              item.splice_A_fiber ? `FIBER ${item.splice_A_fiber}` : ``
            }}</template>

            <template v-slot:[`item.splice_A_feature`]="{ item }">{{
              item.splice_A_feature && retrieveFeature(item.splice_A_feature)
                ? retrieveFeature(item.splice_A_feature).get("name")
                : ``
            }}</template>

            <template v-slot:[`item.splice_A_location`]="{ item }">{{
              item.splice_A_location && retrieveFeature(item.splice_A_location)
                ? retrieveFeature(item.splice_A_location).get("name")
                : ``
            }}</template>

            <template v-slot:[`item.splice_B_fiber`]="{ item }">{{
              item.splice_B_fiber ? `FIBER ${item.splice_B_fiber}` : ``
            }}</template>

            <template v-slot:[`item.splice_B_feature`]="{ item }">{{
              item.splice_B_feature && retrieveFeature(item.splice_B_feature)
                ? retrieveFeature(item.splice_B_feature).get("name")
                : ``
            }}</template>

            <template v-slot:[`item.splice_B_location`]="{ item }">{{
              item.splice_B_location && retrieveFeature(item.splice_B_location)
                ? retrieveFeature(item.splice_B_location).get("name")
                : ``
            }}</template>
          </v-data-table>

          <!-- Port Connections Table -->
          <v-data-table
            class="pl-2"
            :loading="loading"
            v-if="is_fdp || is_port_equipment"
            disable-sort
            fixed-header
            height="400"
            :items="feature_connections"
            :items-per-page="600"
            hide-default-footer
            :headers="is_fdp ? fdp_headers : port_headers"
          >
            <template v-slot:[`item.to_feature`]="{ item }">{{
              item.to_feature
                ? retrieveFeature(item.to_feature).get("name")
                : ""
            }}</template>

            <template v-slot:[`item.port_fiber`]="{ item }"
              >{{ item.to_fiber ? `FIBER ${item.to_fiber}` : `` }}
              {{ item.to_port ? `PORT ${item.to_port}` : `` }}</template
            >

            <template v-slot:[`item.connection_type`]="{ item }">{{
              item.connection_type
                ? retrieveConnectionType(item.connection_type).text
                : ``
            }}</template>

            <template v-slot:[`item.priority`]="{ item }">{{
              retrievePriority(item.priority)
                ? retrievePriority(item.priority).text
                : ``
            }}</template>

            <template v-slot:[`item.action`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }" v-if="item.connected">
                  <v-icon v-on="on" small color="primary">more_vert</v-icon>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(menuItem, index) in equipmentMenuList"
                    :key="index"
                  >
                    <v-list-item-title
                      class="clickable"
                      @click="menuClicked(item, menuItem)"
                      >{{ menuItem.text }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card>

    <div v-if="dialog">
      <PortDialog
        @closeDialog="closeDialog"
        @reload="loadConnections(feature)"
        :port="selectedPort"
        :dialogModel="dialog"
      ></PortDialog>
    </div>

    <div v-if="delete_dialog"></div>
    <ConfirmDelete
      :deleteMessage="deleteMessage"
      :dialogModel="delete_dialog"
      @closeDialog="closeDialog"
      @deleteConfirmed="clearPortData"
      :model="deleteModel"
    ></ConfirmDelete>
  </v-layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState, mapActions } from "vuex";
import dialogMixins from "../../../utils/mixins";
import PortDialog from "./dialogs/PortInfoDialog";
import ConfirmDelete from "../../generic/ConfirmDeleteDialog";
import {
  FEATURE_FIBER_CABLE,
  FEATURE_ODF,
  FEATURE_SPLITTER,
  FEATURE_FDP,
} from "../../../utils/feature_constants";
import { getFeatureById } from "../../../utils/map_functions";

export default {
  name: "UtilizationTableComponent",
  mixins: [dialogMixins],
  components: { PortDialog, ConfirmDelete },
  props: {
    feature: {
      type: Object,
      required: true,
    },
    vectorSources: {
      type: Array,
    },
  },
  data: () => ({
    deleteModel: null,
    deleteMessage: "Are you sure you want to clear this port data?",
    fdpMenuList: [
      {
        text: "Edit Port Data",
        value: "edit_port_data",
        feature_type: FEATURE_FDP,
      },
      {
        text: "Delete Port Connection",
        value: "clear_port_connection",
      },
    ],
    menuList: [
      {
        text: "Delete Port Connection",
        value: "clear_port_connection",
      },
    ],
    selectedPort: {},
    feature_connections: [],
    loading: false,
    port_type: 1,
    connection_types: [
      {
        text: "Splice",
        value: 1,
      },
      {
        text: "Termination",
        value: 2,
      },
      {
        text: "Patch",
        value: 3,
      },
    ],
    priority_settings: [
      {
        text: "LOW",
        value: 1,
      },
      {
        text: "MEDIUM",
        value: 2,
      },
      {
        text: "HIGH",
        value: 3,
      },
    ],
    splitter_port_types: [
      {
        text: "INPUT PORT",
        value: 1,
      },
      {
        text: "OUTPUT PORTS",
        value: 2,
      },
    ],
    odf_port_types: [
      {
        text: "PATCH PORTS",
        value: 1,
      },
      {
        text: "PIGTAIL PORTS",
        value: 2,
      },
    ],
    fdp_port_types: [
      {
        text: "CUSTOMER PORTS",
        value: 1,
      },
      {
        text: "INCOMING CABLE PORTS",
        value: 2,
      },
    ],
    ont_port_types: [
      {
        text: "CUSTOMER PORT",
        value: 1,
      },
      {
        text: "DROP CABLE PORTS",
        value: 2,
      },
    ],
    other_port_types: [
      {
        text: "INPUT PORTS",
        value: 1,
      },
      {
        text: "OUTPUT PORTS",
        value: 2,
      },
    ],
    fdp_headers: [
      {
        text: "Port",
        value: "name",
        sortable: false,
        fixed: true,
        width: "80px",
      },
      {
        text: "To Feature",
        value: "to_feature",
        sortable: false,
        fixed: true,
        width: "250px",
      },
      {
        text: "Port/Fiber",
        value: "port_fiber",
        sortable: false,
        fixed: true,
        width: "100px",
      },
      {
        text: "Connection Type",
        value: "connection_type",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Priority",
        value: "priority",
        sortable: false,
        width: "150px",
      },
      {
        text: "Customer Name",
        value: "customer_name",
        sortable: false,
        width: "200px",
      },
      {
        text: "Customer Number",
        value: "customer_number",
        sortable: false,
        width: "150px",
      },
      {
        text: "House Number",
        value: "house_number",
        sortable: false,
        width: "150px",
      },
      {
        text: "ONT Serial Number",
        value: "equipment_serial_number",
        sortable: false,
        width: "200px",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        width: "50px",
      },
    ],

    fiber_headers: [
      {
        text: "Fiber",
        value: "name",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "A Termination",
        value: "termination_A",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "A Feature",
        value: "termination_feature_A",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "B Termination",
        value: "termination_B",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "B Feature",
        value: "termination_feature_B",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice A",
        value: "splice_A_fiber",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice A Feature",
        value: "splice_A_feature",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice A Location",
        value: "splice_A_location",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice B",
        value: "splice_B_fiber",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice B Feature",
        value: "splice_B_feature",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice B Location",
        value: "splice_B_location",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        width: "50px",
      },
    ],

    port_headers: [
      {
        text: "Port",
        value: "name",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "To Feature",
        value: "to_feature",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Port/Fiber",
        value: "port_fiber",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Connection Type",
        value: "connection_type",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Priority",
        value: "priority",
        sortable: false,
        width: "150px",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        width: "50px",
      },
    ],
  }),
  watch: {
    port_type: function () {
      this.feature_connections = [];
      this.loadConnections(this.feature);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
    is_fiber: function () {
      return this.feature.get("type") == FEATURE_FIBER_CABLE;
    },
    is_port_equipment: function () {
      let validTypes = [FEATURE_ODF, FEATURE_SPLITTER];
      return validTypes.includes(this.feature.get("type"));
    },
    is_fdp: function () {
      return this.feature.get("type") == FEATURE_FDP;
    },
    port_types: function () {
      if (this.feature.get("type") == FEATURE_ODF) return this.odf_port_types;
      if (this.feature.get("type") == FEATURE_FDP) return this.fdp_port_types;
      if (this.feature.get("type") == FEATURE_SPLITTER)
        return this.splitter_port_types;

      return this.other_port_types;
    },
    equipmentMenuList: function () {
      if (this.feature.get("type") == FEATURE_FDP) return this.fdpMenuList;
      else return this.menuList;
    },
  },
  mounted() {
    if (this.feature) {
      if (this.feature.get("pk")) {
        this.loadConnections(this.feature);
      }
    }
  },
  methods: {
    ...mapActions({
      getConnections: "getConnections",
      clearPortInfo: "clearPortInfo",
    }),
    retrieveConnectionType(connection_type) {
      return this.connection_types.find(
        (type) => type.value == connection_type
      );
    },
    retrieveFeature(feature_id) {
      return getFeatureById(feature_id, this.vectorSources);
    },
    retrievePriority(priority) {
      return this.priority_settings.find((type) => type.value == priority);
    },
    setPortInfo(port) {
      this.selectedPort = port;
      this.dialog = !this.dialog;
    },
    loadConnections() {
      this.loading = true;
      let payload = {};
      if (this.is_fiber) {
        payload.fiber_cable = this.feature.get("pk");
      } else {
        payload.feature_uuid = this.feature.getId();
        payload.port_type = this.port_type;
      }

      this.getConnections(payload).then((res) => {
        console.log("Conn", res);
        this.feature_connections = res;
        this.loading = false;
      });
    },
    menuClicked(port, menuItem) {
      if (menuItem.value == "edit_port_data") {
        this.setPortInfo(port);
      }

      if (menuItem.value == "clear_port_connection") {
        this.showDeleteDialog(port);
      }
    },
    showDeleteDialog(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    clearPortData(port) {
      let payload = {};
      payload.port = port.id;
      this.clearPortInfo(payload).then(() =>
        this.loadConnections(this.feature)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>