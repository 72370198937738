<template>
  <c-app-layout>
    <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>
    <v-layout row wrap>
      <v-flex xs3 class="pa-3">
        <ProjectDetails
          @editProject="editProject"
          @delete-project="execDelete"
          :project_id="project.id"
        ></ProjectDetails>
      </v-flex>

      <v-flex xs9 class="pa-3">
        <JobsList
          @generateBOQ="generateBOQ"
          @viewJob="viewJob"
          @editJob="editJob"
          @deleteJob="execDelete"
        ></JobsList>
      </v-flex>

      <v-btn
        @click="dialog = !dialog"
        absolute
        top
        right
        fab
        rounded
        color="primary"
        class="white--text fab-button"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-layout>

    <div v-if="dialog">
      <JobDialog
        :editModel="editJobModel"
        :dialogModel="dialog"
        @closeDialog="closeDialog"
      ></JobDialog>
    </div>

    <div v-if="generate_boq_dialog">
      <BOQDialog
        :boqItem="boqItem"
        :dialogModel="generate_boq_dialog"
        @closeDialog="closeDialog"
      ></BOQDialog>
    </div>

    <div v-if="view_job_dialog">
      <ViewJobDialog
        :job="viewJobModel"
        :dialogModel="view_job_dialog"
        @closeDialog="closeDialog"
      ></ViewJobDialog>
    </div>

    <div v-if="edit_project_dialog">
      <ProjectDialog
        :editModel="editProjectModel"
        :dialogModel="edit_project_dialog"
        @closeDialog="closeDialog"
      ></ProjectDialog>
    </div>

    <div v-if="delete_dialog">
      <DeleteDialog
        @deleteConfirmed="deleteConfirmed"
        @closeDialog="closeDialog"
        :dialogModel="delete_dialog"
        :deleteMessage="delete_text"
        :model="deleteModel"
      ></DeleteDialog>
    </div>
  </c-app-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import JobsList from "../../components/projects/JobsList";
import ProjectDetails from "./sub-components/ProjectDetails";
import DeleteDialog from "../generic/ConfirmDeleteDialog";
import JobDialog from "./sub-components/JobDialog";
import ViewJobDialog from "./sub-components/ViewJobDialog";
import ProjectDialog from "./sub-components/ProjectDialog";

import dialogMixins from "../../utils/mixins";
import authMixin from "../../utils/authMixin";
import BOQDialog from "./sub-components/BOQDialog";

export default {
  name: "ProjectDetailPage",
  components: {
    JobsList,
    DeleteDialog,
    ProjectDetails,
    JobDialog,
    BOQDialog,
    ViewJobDialog,
    ProjectDialog,
  },
  mixins: [dialogMixins, authMixin],
  data: () => ({
    items: [
      { title: "Edit Customer", action: "edit" },
      { title: "Delete Customer", action: "delete" },
    ],
    boqItem: null,
    deleteModel: null,
    is_service_cost: false,
    is_material: false,
    tabs: 0,
    bread_crumbs: [],
    editJobModel: null,
    viewJobModel: null,
    editProjectModel: null,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Phone Number",
        value: "phone",
      },
      {
        text: "Group",
        value: "group_name",
      },
      {
        text: "More",
        value: "action",
      },
    ],
  }),
  computed: {
    ...mapState({
      customer: function () {
        return this.customers.find(
          (customer) => customer.id == this.$route.params.id
        );
      },
      customers: (state) => state.customersModule.customers,
      project: (state) => state.projectsModule.project,
    }),
  },
  async mounted() {
    if (this.customers.length == 0) {
      this.getCustomers().then(() => {
        this.fetchProject();
      });
    } else {
      this.fetchProject();
    }

    await this.loadJobs();
    this.configBreadCrumbs();
  },
  watch: {
    project: function (val) {
      if (val.customer_id) {
        this.configBreadCrumbs();
      }
    },
    customer: function () {
      this.getCustomer(this.customer.id);
      this.configBreadCrumbs();
    },
  },
  methods: {
    ...mapActions({
      getCustomer: "getCustomer",
      getCustomers: "getCustomers",
      getProject: "getProject",
      getJobs: "getJobs",
      editJob: "editJob",
      deleteJob: "deleteJob",
      deleteProject: "deleteProject",
    }),
    configBreadCrumbs() {
      if (this.customer && this.project) {
        this.bread_crumbs = [];
        this.bread_crumbs.push(
          {
            text: "Customer Projects",
            href: "/customer-projects",
          },
          {
            text: this.customer.customer_name,
            href: `/customer-projects/${this.$route.params.id}/projects`,
          },
          {
            text: this.project.project_name,
            disabled: true,
          }
        );
      }
    },
    fetchProject() {
      if (
        this.project.id == undefined ||
        this.project.id != this.$route.params.project_id
      ) {
        this.getProject(this.$route.params.project_id);
      }
    },
    async loadJobs() {
      await this.getJobs(this.$route.params.project_id);
    },
    editJob(item) {
      this.editJobModel = item;
      this.dialog = !this.dialog;
    },
    editProject() {
      this.editProjectModel = this.project;
      this.edit_project_dialog = !this.edit_project_dialog;
    },
    viewJob(item) {
      this.viewJobModel = item;
      this.view_job_dialog = !this.view_job_dialog;
    },
    resetEditModels() {
      this.editJobModel = undefined;
      this.editProjectModel = undefined;
    },
    execDelete(item) {
      if (this.can_manage_projects) {
        this.delete_text = "Are you sure you want to delete this item?";
        this.deleteModel = item;
        this.delete_dialog = !this.delete_dialog;
      } else {
        this.showAlertMessage(
          "You have no permission to perform this action",
          "error"
        );
      }
    },
    generateBOQ(item) {
      this.boqItem = item;
      this.generate_boq_dialog = !this.generate_boq_dialog;
    },
    async deleteConfirmed(item) {
      if (item.project_name) {
        // deleting a project
        await this.deleteProject(item.id);
        this.showAlertMessage("Project deleted", "success");

        // navigate to projects page
        this.$router.push(
          `/customer-projects/${this.$route.params.id}/projects`
        );
      }

      if (item.job_name) {
        // deleting a job
        await this.deleteJob(item.id);
        await this.loadJobs();

        this.showAlertMessage("Job deleted", "success");
      }
    },
  },
};
</script>

<style lang="css" scoped>
.align-center {
  text-align: center;
  width: 100%;
  display: block;
}

.menu-item {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}
</style>