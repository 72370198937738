<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-flex xs12>
          <v-data-table
            v-model="selected"
            hide-default-footer
            show-select
            :items="groups"
            :loading="status == 'loading'"
            :headers="headers"
          ></v-data-table>
        </v-flex>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="selected.length == 0"
          :loading="status == 'loading'"
          @click="add"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "UserPermissionGroupsDialog",
  props: {
    dialogModel: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    user_id: null,
    dialog: null,
    dialog_title: "User Groups",
    dialog_button_text: "Manage",
    selected: [],
    headers: [
      {
        text: "Group Name",
        value: "group_name"
      }
    ]
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.user_id =
      this.$route.params.user_id != undefined
        ? this.$route.params.user_id
        : this.$route.params.company_user_id;

    if (this.groups.length == 0) this.getUserGroups();
  },
  computed: {
    ...mapState({
      groups: state => state.usersModule.groups,
      user_groups: state => state.usersModule.user_groups,
      status: state => state.usersModule.status
    })
  },
  methods: {
    ...mapActions({
      getUserGroups: "getUserGroups",
      getUserPermissionGroups: "getUserPermissionGroups",
      addUserPermissionGroups: "addUserPermissionGroups"
    }),
    add() {
      let group_ids = this.selected.map(group => group.id);
      let apiData = {
        user_id: this.user_id,
        groups: group_ids
      };

      this.addUserPermissionGroups(apiData).then(() => {
        this.getUserPermissionGroups(this.user_id);
        this.closeDialog();
      });
    },
    resetFields() {
      this.selected = [];
    },
    closeDialog() {
      this.resetFields();
      this.$emit("closeDialog");
    }
  },
  watch: {
    dialogModel: function(val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function(val) {
      if (!val) {
        this.selected = [];
        this.closeDialog();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>