import BackendAPI from "../../api/UsersAPI.js"
import appConfig from "../../config"

var baseUrl = appConfig.base_url + appConfig.user_service_base_port + '/v1/users_service/account';
var permissionsUrl = baseUrl + '/permissions';
var groupPermissionsUrl = baseUrl + '/group_permissions';
var addGroupPermissionsUrl = groupPermissionsUrl + '/add_group_permissions'

let MSG_COMMIT_LOADING = 'loading'
let MSG_COMMIT_SUCCESS = 'success'
let MSG_COMMIT_ERROR = 'error'

let COMMIT_STATUS = 'setPermissionsStatus'
let COMMIT_SET_ALL = 'setPermissions'
let COMMIT_SET_SINGLE = 'setGroupPermissions'

// let ACTION_GET_ALL = 'getPermissions'
let ACTION_GET_SINGLE = 'getGroupPermissions'

export default {
    state: {
        permissions: [],
        group_permissions: [],
        status: '',
    },

    actions: {
        addGroupPermissions( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                BackendAPI.post( addGroupPermissionsUrl, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );

                    // dispatch and get permissions for an individual group
                    this.dispatch( ACTION_GET_SINGLE, payload.group_id );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getPermissions( { commit } ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                BackendAPI.get( permissionsUrl ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    commit( COMMIT_SET_ALL, response );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getGroupPermissions( { commit }, id ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            let apiData = {
                group: id
            }

            return new Promise( ( resolve, reject ) => {
                BackendAPI.get( groupPermissionsUrl, apiData ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    commit( COMMIT_SET_SINGLE, response );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        deleteGroupPermission( { commit }, id ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                BackendAPI.delete( groupPermissionsUrl, id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        }
    },

    mutations: {
        [ COMMIT_STATUS ]( state, results ) {
            state.status = results;
        },

        [ COMMIT_SET_ALL ]( state, results ) {
            state.permissions = results;
        },

        [ COMMIT_SET_SINGLE ]( state, results ) {
            state.group_permissions = results;
        }
    }
}