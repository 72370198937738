<template>
  <v-layout>
    <c-loader
      v-if="project.customer_id != customer.id"
      message="Loading project"
    ></c-loader>
    <v-card v-else class="pa-3" style="width: 100%">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="secondary" text absolute top right tile>
            <v-icon>more_horiz</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(menuItem, index) in items"
            :key="index"
            @click="handleMenuClick(menuItem)"
          >
            <v-list-item-title class="menu-item pa-0 ma-0">{{
              menuItem.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-flex
        v-if="customer.logo != null"
        xs12
        class="center-items align-center pt-8 mt-3"
      >
        <v-avatar tile width="250" height="auto">
          <img :src="customer.logo_url" alt />
        </v-avatar>
      </v-flex>
      <v-card-title class="align-center customer-name bold pt-8 center-items">
        <h1 style="width: 100%">{{ project.project_name }}</h1>
        <p class="small-text">{{ project.project_owner }}</p>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-if="project.project_location != null">
            <v-list-item-icon>
              <v-icon color="indigo">place</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                project.project_location
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                customer.country
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ProjectDetailsComponent",
  props: {
    project_id: {
      type: Number,
    },
  },
  data: () => ({
    customer_name: "",
    items: [
      { title: "Edit Project", action: "edit" },
      { title: "Delete Project", action: "delete" },
    ],
    editProjectModel: null,
    editJobModel: null,
  }),
  methods: {
    handleMenuClick(item) {
      if (item.action == "edit") {
        this.$emit("editProject");
      }
      if (item.action == "delete") {
        this.$emit("delete-project", this.project);
      }
    },
    mounted() {
      this.getProject(this.$route.params.project_id);
    },
    ...mapActions({
      getCustomer: "getCustomer",
      getProject: "getProject",
      updateProject: "updateProject",
      deleteProject: "deleteProject",
    }),
    resetEditModels() {
      this.editProjectModel = undefined;
      this.editJobModel = undefined;
    },
    editJob() {
      this.editJobModel = this.customer;
      this.edit_job_dialog = !this.edit_job_dialog;
    },
  },
  computed: {
    ...mapState({
      customer: (state) => state.customersModule.customer,
      project: (state) => state.projectsModule.project,
    }),
  },
  watch: {
    project: function (val) {
      if (val.customer_id) {
        this.getCustomer(val.customer_id);
      }
    },
  },
};
</script>

<style lang="css" scoped>
p.small-text {
  width: 100%;
  font-size: 14px !important;
}

.center-items {
  text-align: center !important;
}

.center-items * {
  white-space: normal !important;
}

.customer-name {
  word-break: keep-all !important;
}
</style>