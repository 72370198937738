import UsersAPI from "../../api/UsersAPI.js"
import appConfig from "../../config"
import axios from 'axios'

var userObject;

try {
    userObject = JSON.parse( localStorage.getItem( 'user' ) )
} catch ( error ) {
    userObject = {}
}

var authUrl = appConfig.base_url + appConfig.user_service_base_port + '/authenticate';
var initiatePassworsdResetUrl = appConfig.base_url + appConfig.user_service_base_port + '/request_password_reset';
var getUserFromTokenUrl = appConfig.base_url + appConfig.user_service_base_port + '/get_user_from_token';
var setPasswordUrl = appConfig.base_url + appConfig.user_service_base_port + '/create_new_password';

var serviceUrl = appConfig.base_url + appConfig.user_service_base_port + '/v1/users_service/account';
var baseUrl = serviceUrl;
var sendInviteUrl = baseUrl + '/send_invite';
var ldapURL = `${ baseUrl }/ldap`

export default {
    state: {
        users: [],
        customer_users: [],
        groups: [],
        user_groups: [],
        roles: localStorage.getItem( 'roles' ) != undefined ? JSON.parse( localStorage.getItem( 'roles' ) ) : [],
        user: {},
        status: '',
        token: localStorage.getItem( 'token' ) || '',
        fms_token: localStorage.getItem( 'fms_token' ) || '',
        fms_refresh_token: localStorage.getItem( 'fms_refresh_token' ) || '',
        current_user: userObject || {},
    },

    getters: {
        users: state => state.users,
        customer_users: state => state.customer_users,
        user: state => state.user,
        current_user: state => state.current_user,
        token: state => state.token,
        fmsToken: state => state.fms_token,
        fmsRefreshToken: state => state.fms_refresh_token,
        userStatus: state => state.status,
        roles: state => state.roles,
        isLoggedIn: state => !!state.token
    },

    actions: {
        saveFmsToken( { commit }, tokenObj ) {
            if ( tokenObj.token && tokenObj.refresh_token ) {
                localStorage.setItem( 'fms_token', tokenObj.token )
                localStorage.setItem( 'fms_refresh_token', tokenObj.refresh_token )

                commit( 'setFMSToken', tokenObj.token )
                commit( 'setFMSRefreshToken', tokenObj.refresh_token )
            }
        },

        resetFMSTokens( { commit } ) {
            localStorage.removeItem( 'fms_token' )
            localStorage.removeItem( 'fms_refresh_token' )
            commit( 'setFMSToken', null )
            commit( 'setFMSRefreshToken', null )
        },

        login( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {

                let resource = authUrl;

                UsersAPI.post( resource, payload ).then( response => {
                    // redirect to dashboard
                    commit( 'setUserStatus', 'success' );
                    const token = response.token
                    const user = response.user

                    localStorage.setItem( 'token', token )
                    localStorage.setItem( 'user', JSON.stringify( user ) )

                    axios.defaults.headers.common[ 'Authorization' ] = token;

                    commit( 'setToken', token )
                    commit( 'setCurrentUser', user )
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        setNewPassword( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {

                let resource = setPasswordUrl;

                UsersAPI.post( resource, payload ).then( response => {
                    // redirect to dashboard
                    commit( 'setUserStatus', 'success' );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        sendInvite( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {

                let resource = sendInviteUrl;

                UsersAPI.post( resource, payload ).then( response => {
                    // redirect to dashboard
                    commit( 'setUserStatus', 'success' );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        getUserFromToken( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {

                let resource = getUserFromTokenUrl;

                UsersAPI.post( resource, payload ).then( response => {
                    // redirect to dashboard
                    commit( 'setUserStatus', 'success' );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        //getUserFromToken

        reloadMe( { commit } ) {
            commit( 'setUserStatus', 'loading' );

            let resource = `${ baseUrl }/${ this.state.usersModule.current_user.id }`;

            UsersAPI.get( resource ).then( response => {
                commit( 'setUserStatus', 'success' );
                commit( 'setCurrentUser', response )
                localStorage.setItem( 'user', JSON.stringify( response ) )
            } ).catch( () => {
                commit( 'setUserStatus', 'error' )
            } );
        },

        logout( { commit } ) {
            // redirect to login screen
            commit( 'setUserStatus', '' )
            commit( 'resetData' )

            this.dispatch( 'resetCustomersData', { root: true } )
            this.dispatch( 'resetProjectsData', { root: true } )
            this.dispatch( 'resetMaterialsData', { root: true } )


            return new Promise( ( resolve ) => {
                localStorage.removeItem( 'token' )
                localStorage.removeItem( 'user' )
                localStorage.removeItem( 'current_user' )
                localStorage.removeItem( 'roles' )

                resolve()
            } )
        },

        resetPassword( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl + '/reset_password';

            return new Promise( ( resolve, reject ) => {
                UsersAPI.post( resource, payload, 'reset_password' ).then( response => {
                    commit( 'setUserStatus', 'success' );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        initiateResetPassword( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            return new Promise( ( resolve, reject ) => {
                UsersAPI.post( initiatePassworsdResetUrl, payload ).then( response => {
                    commit( 'setUserStatus', 'success' );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },


        getUserGroups( { commit } ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl + '/permission_groups';

            return new Promise( ( resolve, reject ) => {
                UsersAPI.get( resource ).then( response => {
                    commit( 'setUserStatus', 'success' );
                    commit( 'setGroups', response );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        addUserPermissionGroups( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl + '/permission_group_users/add_user_groups';

            return new Promise( ( resolve, reject ) => {
                UsersAPI.post( resource, payload ).then( response => {
                    commit( 'setUserStatus', 'success' );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        getUserPermissionGroups( { commit }, user_id ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl + '/permission_group_users';
            let apiData = {
                id: user_id
            }

            return new Promise( ( resolve, reject ) => {
                UsersAPI.get( resource, apiData ).then( response => {
                    commit( 'setUserStatus', 'success' );
                    commit( 'setPermissionGroups', response );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        deleteUserPermissionGroups( { commit }, id ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl + '/permission_group_users';

            return new Promise( ( resolve, reject ) => {
                UsersAPI.delete( resource, id ).then( response => {
                    commit( 'setUserStatus', 'success' );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        createUserGroup( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl + '/permission_groups';

            return new Promise( ( resolve, reject ) => {
                UsersAPI.post( resource, payload ).then( response => {
                    commit( 'setUserStatus', 'success' );
                    this.dispatch( 'getUserGroups' );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        editUserGroup( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            let id = payload.id;

            let resource = baseUrl + '/permission_groups';

            return new Promise( ( resolve, reject ) => {
                UsersAPI.update( resource, id, payload ).then( response => {
                    commit( 'setUserStatus', 'success' );
                    this.dispatch( 'getUserGroups' );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        deleteUserGroup( { commit }, id ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl + '/permission_groups';

            return new Promise( ( resolve, reject ) => {
                UsersAPI.delete( resource, id ).then( response => {
                    commit( 'setUserStatus', 'success' );
                    resolve( response )

                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        changePassword( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl + '/change_password';

            UsersAPI.post( resource, payload ).then( response => {
                console.log( '', response )
                commit( 'setUserStatus', 'success' );
            } ).catch( () => {
                commit( 'setUserStatus', 'error' )
            } );
        },

        refreshToken( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            let resource = authUrl + '/refresh';

            UsersAPI.refreshToken( resource, payload ).then( response => {
                console.log( '', response )
            } ).catch( () => {
                commit( 'setUserStatus', 'error' )
            } );
        },

        createUser( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl;

            UsersAPI.post( resource, payload ).then( () => {
                commit( 'setUserStatus', 'success' );
                this.dispatch( 'getUsers' )
            } ).catch( () => {
                commit( 'setUserStatus', 'error' )
            } );
        },

        getUsers( { commit } ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl;

            return new Promise( ( resolve, reject ) => {
                UsersAPI.get( resource ).then( response => {
                    commit( 'setUserStatus', 'success' );
                    commit( 'setUsers', response )
                    resolve( response )
                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        getCustomerUsers( { commit }, customer_id ) {
            if ( customer_id ) {
                commit( 'setUserStatus', 'loading' );

                let resource = baseUrl;
                let apiData = {
                    customer_id: customer_id
                }

                UsersAPI.get( resource, apiData ).then( response => {
                    commit( 'setUserStatus', 'success' );
                    commit( 'setCustomerUsers', response )
                } ).catch( () => {
                    commit( 'setUserStatus', 'error' )
                } );
            }
        },

        getUser( { commit }, id ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl + `/${ id }`;

            UsersAPI.get( resource ).then( response => {
                commit( 'setUserStatus', 'success' );
                commit( 'setUser', response )
            } ).catch( () => {
                commit( 'setUserStatus', 'error' )
            } );
        },

        getLDAPSettings( { commit } ) {
            commit( 'setUserStatus', 'loading' );
            return UsersAPI.get( ldapURL );
        },

        postLDAPSettings( { commit }, params ) {
            commit( 'setUserStatus', 'loading' );
            UsersAPI.post( ldapURL, params );
        },

        updateSelf( { commit }, payload ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl;

            UsersAPI.patch( resource, this.state.usersModule.current_user.id, payload ).then( () => {
                commit( 'setUserStatus', 'success' );
                this.dispatch( 'reloadMe' )
            } ).catch( () => {
                commit( 'setUserStatus', 'error' )
            } );
        },

        deleteUser( { commit }, id, customer_id = undefined ) {
            commit( 'setUserStatus', 'loading' );

            let resource = baseUrl;

            return new Promise( ( resolve, reject ) => {
                UsersAPI.delete( resource, id ).then( resp => {
                    commit( 'setUserStatus', 'success' );

                    if ( customer_id != undefined ) {
                        this.dispatch( 'getCustomerUsers', customer_id )
                    }
                    else {
                        this.dispatch( 'getUsers' )
                    }

                    resolve( resp )
                } ).catch( err => {
                    commit( 'setUserStatus', 'error' )
                    reject( err )
                } );
            } )
        },

        resetUsersData( { commit } ) {
            commit( 'resetData' )
        }
    },

    mutations: {
        setUserStatus( state, status ) {
            state.status = status;
        },

        setUsers( state, users ) {
            state.users = users;
        },

        setCustomerUsers( state, users ) {
            state.customer_users = users;
        },

        setUser( state, user ) {
            state.user = user;
        },

        setCurrentUser( state, user ) {
            state.current_user = user;
        },

        setToken( state, token ) {
            state.token = token;
        },

        setFMSToken( state, token ) {
            state.fms_token = token;
        },

        setFMSRefreshToken( state, token ) {
            state.fms_refresh_token = token;
        },

        setGroups( state, groups ) {
            state.groups = groups;
        },

        setPermissionGroups( state, groups ) {
            state.user_groups = groups;
        },
        resetData( state ) {
            state.status = ''
            state.users = []
            state.customer_users = []
            state.user = {}
            state.current_user = {}
            state.token = ''
            state.groups = []
            state.user_groups = [];
        }
    }
}