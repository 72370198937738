import {
  FEATURE_MICRO_TUNNEL,
  REDLINE_LINE,
  REDLINE_POLYGON,
  REDLINE_POINT,
  ROAD_CROSSING_HOOK,
  AREA_OF_INTEREST,
  BRIDGE_ATTACHMENT,
  GABION,
} from "../feature_constants";

export default {
  data: () => ({
    buildings: [],
    sites: [],
    poles: [],
    fdps: [],
    cabinets: [],
    splitters: [],
    access_points: [], // a.k.a manholes, handholes
    fiber_cables: [],
    ducts: [],
    odfs: [],
    polygonFeatures: [
      {
        text: "Redline (Polygon)",
        icon: "stacked_line_chart",
        feature_type: REDLINE_POLYGON,
      },
      {
        text: "Area of Interest",
        icon: "emoji_flags",
        feature_type: AREA_OF_INTEREST,
      },
    ],
    pointFeatures: [
      {
        text: "Building",
        icon: "roofing",
        feature_type: "BUILDING",
      },
      {
        text: "Site",
        icon: "settings_input_antenna",
        feature_type: "SITE",
      },
      {
        text: "Cabinet",
        icon: "kitchen",
        feature_type: "CABINET",
      },
      {
        text: "Closure",
        icon: "developer_board",
        feature_type: "SPLICE_CLOSURE",
      },
      {
        text: "Manhole",
        icon: "radio_button_checked",
        feature_type: "MANHOLE",
      },
      {
        text: "Handhole",
        icon: "indeterminate_check_box",
        feature_type: "HANDHOLE",
      },
      {
        text: "Pole",
        icon: "text_fields",
        feature_type: "POLE",
      },
      {
        text: "ODF/ Patch Panel",
        icon: "keyboard",
        feature_type: "ODF",
      },
      {
        text: "Splitter",
        icon: "alt_route",
        feature_type: "SPLITTER",
      },
      {
        text: "FDP",
        icon: "settings_input_svideo",
        feature_type: "FDP",
      },
      {
        text: "Pole Fiber Cable",
        icon: "timeline",
        feature_type: "POLE_FIBER_CABLE",
      },
      {
        text: "Pole Duct",
        icon: "call_split",
        feature_type: "POLE_DUCT",
      },
      {
        text: "Face Plate",
        icon: "album",
        feature_type: "FACE_PLATE",
      },
      {
        text: "ONT",
        icon: "router",
        feature_type: "ONT",
      },
      {
        text: "RTU",
        icon: "power_input",
        feature_type: "RTU",
      },
      {
        text: "Redline (Point)",
        icon: "stacked_line_chart",
        feature_type: REDLINE_POINT,
      },
      {
        text: "Crossing Hook",
        icon: "add_road",
        feature_type: ROAD_CROSSING_HOOK,
      },
    ],
    lineFeatures: [
      {
        text: "Fiber Cable",
        icon: "timeline",
        feature_type: "FIBER_CABLE",
      },
      {
        text: "Duct",
        icon: "call_split",
        feature_type: "DUCT",
      },
      {
        text: "Trench",
        icon: "polymer",
        feature_type: "TRENCH",
      },
      {
        text: "Bridge Attachment",
        icon: "call_split",
        feature_type: BRIDGE_ATTACHMENT,
      },
      {
        text: "Gabion",
        icon: "call_split",
        feature_type: GABION,
      },
      {
        text: "Micro Tunnel",
        icon: "center_focus_strong",
        feature_type: FEATURE_MICRO_TUNNEL,
      },
      {
        text: "Redline (Line)",
        icon: "stacked_line_chart",
        feature_type: REDLINE_LINE,
      },
    ],
    measureFeatures: [
      {
        text: "Measure Length",
        feature_type: "MEASUREMENT_LENGTH",
      },
      {
        text: "Measure Area",
        feature_type: "MEASUREMENT_AREA",
      },
      {
        text: "Clear All",
        action: "clearAll",
        type: "MEASUREMENT_LENGTH",
      },
    ],
    importMenus: [
      {
        text: "Import Features",
        action: "import_features",
      },
    ],
    exportMenus: [
      {
        text: "Export Features",
        action: "export_features",
      },
    ],
    baseMaps: [
      {
        text: "Network Faults",
        action: "faults",
      },
      {
        text: "OSM Background",
        action: "osm",
      },
      {
        text: "Satellite Background", // add Google maps tiles
        action: "satellite",
      },
      {
        text: "Open Layer Manager",
        action: "layer_manager",
      },
      {
        text: "Manage Imported Features",
        action: "imported_layers",
      },
      {
        text: "Clear Blueprints",
        action: "clear_blueprints",
      },
      {
        text: "Hide All Maps",
        action: "hide_all",
      },
    ],
    connectionMenu: [
      {
        text: "Open Connection Manager",
        action: "connection_manager",
      },
      {
        text: "Open Merge Manager",
        action: "merge_manager",
      },
    ],
    reportsMenu: [
      {
        text: "Splice Schematics Layer",
        action: "splice_schematics",
      },
      {
        text: "Duct Plan Layer",
        action: "duct_Plan",
      },
    ],
    splitFeatureMenu: [
      {
        text: "Split Fiber Cable",
        action: "split_fiber_cable",
      },
      {
        text: "Split Duct",
        action: "split_duct",
      },
    ],

    moveFeatureMenu: [
      {
        text: "Activate move tool",
        action: "activate_move_tool",
      },
      {
        text: "Deactivate move tool",
        action: "deactivate_move_tool",
      },
    ],
  }),
};
