<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="success white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-select
                  v-model="inner_duct.color"
                  :rules="rules.required_field"
                  :items="color_set"
                  label="Duct Color"
                ></v-select>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="createOrEdit"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../../utils/mixins";

export default {
  name: "DuctColorDialogComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    feature: {
      type: Object,
      required: true,
    },
    selectedInnerDuct: {
      type: Object,
    },
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "Set Duct Color",
    dialog_button_text: "Set",
    valid: true,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    dialog: null,
    inner_duct: {},
    color_set: ["GREEN", "RED", "BLUE", "ORANGE", "YELLOW", "BLACK"],
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.inner_duct = {};
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    ...mapActions({
      updateInnerDuct: "updateInnerDuct",
    }),
    createOrEdit() {
      if (this.$refs.form.validate()) {
        if (this.feature.get("pk")) {
          this.inner_duct.id = this.selectedInnerDuct.id;
          this.updateInnerDuct(this.inner_duct).then((res) => {
            this.$emit("reload");
            console.log(res);
            this.resetFields();
            this.closeDialog();
          });
        }
      }
    },
    resetFields() {
      this.is_edit_mode = false;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();

      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>