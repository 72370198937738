/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

/*
 * Maximum resolution for labels displayed on the map
 */
export let MAX_LABEL_RESOLUTION = 1.2;

/*
 * Maximum resolution for features displayed on the map
 */
export let MAX_FEATURE_RESOLUTION = 1.2;

/*
 * Scale that we use to resize images as the map gets bigger/smaller
 */
export let RESIZE_ICON_CONSTANT = 0.025;

/*
 * Add comment here
 */
export let METERS_DIVIDER = 10000;

/*
 * Maximum zoom threshold for ...{}
 */
export let ZOOM_THRESHOLD = 17;

/*
 * Initial map zoom
 */
export let INITIAL_ZOOM = 14;

export let INITIAL_ZOOM_NO_FEATURES = 7;

export let iconAnchorUnits = [0.5, 96];

export let iconScale = 0.1020408163;

export let largerIconScale = 0.1320408163;

export let siteScale = 0.1020408163;

export let HIGHLIGHT_FROM_ID = "highlight_from_id";

export let HIGHLIGHT_TO_ID = "highlight_to_id";

export let ROAD_CROSSING_HOOK = "road_crossing_hook";

export let REDLINE_POLYGON = "REDLINE_POLYGON";

export let REDLINE_POINT = "REDLINE_POINT";

export let TRENCH = "TRENCH";

export let REDLINE_LINE = "REDLINE_LINE";

export let FEATURE_BUILDING = "BUILDING";

export let FEATURE_MEASUREMENT_AREA = "MEASUREMENT_AREA";

export let FEATURE_MEASUREMENT_LENGTH = "MEASUREMENT_LENGTH";

export let FEATURE_POLE = "POLE";

export let FAULT = "Fault";

export let POLE_FIBER_CABLE = "POLE_FIBER_CABLE";

export let POLE_DUCT = "POLE_DUCT";

export let FEATURE_ROAD = "ROAD";

export let FEATURE_MICRO_TUNNEL = "MICRO_TUNNEL";

export let FEATURE_DUCT_PLAN = "DUCT_PLAN";

export let FEATURE_SPLICE_SCHEMATIC = "SPLICE_SCHEMATIC";

export let FEATURE_SPLICE_SCHEMATIC_LINESTRING = "SPLICE_SCHEMATIC_LINESTRING";

export let FEATURE_SPLICE_SCHEMATIC_PATH = "SPLICE_SCHEMATIC_PATH";

export let FEATURE_SPLICE_SCHEMATIC_POINT = "SPLICE_SCHEMATIC_POINT";

export let SIGNAL_TRACE = "SIGNAL_TRACE";

export let FEATURE_CLOSURE = "SPLICE_CLOSURE";

export let FEATURE_MANHOLE = "MANHOLE";

export let FEATURE_SITE = "SITE";

export let FEATURE_HANDHOLE = "HANDHOLE";

export let FEATURE_CABINET = "CABINET";

export let FEATURE_ODF = "ODF";

export let FEATURE_OLT = "OLT";

export let FEATURE_SPLITTER = "SPLITTER";

export let FEATURE_FIBER_CABLE = "FIBER_CABLE";

export let FEATURE_DUCT = "DUCT";

export let FEATURE_RTU = "RTU";

export let FEATURE_INNER_DUCT = "INNER_DUCT";

export let FEATURE_FACE_PLATE = "FACE_PLATE";

export let FEATURE_ONT = "ONT";

export let FEATURE_PATCH = "PATCH";

export let FEATURE_SPLICE = "SPLICE";

export let FEATURE_TERMINATION = "TERMINATION";

export let FEATURE_SLACK = "SLACK";

export let FEATURE_FDP = "FDP";

export let FEATURE_IMPORT_LAYER = "IMPORT_LAYER";

export let FEATURE_COMPOSITION = "COMPOSITION";

export let FEATURE_DUCT_CABLES = "DUCT_CABLES";

export let FEATURE_ACCESSORIES = "ACCESSORIES";

export let FEATURE_ATTACHMENTS = "ATTACHMENTS";

export let POINT_FEATURE_ID = "highlight_point_feature_id";

export let FEATURE_POINT_HIGHLIGHT = "POINT_FEATURE_HIGHLIGHT";

export let FEATURE_CABLE_HIGHLIGHT = "CABLE_HIGHLIGHT";

export let FEATURE_DUCT_HIGHLIGHT = "DUCTE_HIGHLIGHT";

export let FIBER_CABLE_FEATURES = "fiber_cable_features";

export let BUILDING_FEATURES = "building_features";

export let CABINET_FEATURES = "cabinet_features";

export let POLE_FEATURES = "pole_features";

export let DUCT_FEATURES = "duct_features";

export let ACCESS_POINT_FEATURES = "access_point_features";

export let SITE_FEATURES = "site_features";

export let BLUE_PRINT_POINT = "blueprint_point";

export let BLUE_PRINT_LINE = "blueprint_line";

export let AREA_OF_INTEREST = "AREA_OF_INTEREST";

export let BRIDGE_ATTACHMENT = "BRIDGE_ATTACHMENT";

export let GABION = "GABION";

export let featureSnapConfig = {
  FIBER_CABLE: [
    FEATURE_POLE,
    FEATURE_BUILDING,
    FEATURE_MANHOLE,
    FEATURE_SITE,
    FEATURE_CABINET,
    FEATURE_DUCT,
    FEATURE_HANDHOLE,
  ],
  DUCT: [
    FEATURE_BUILDING,
    FEATURE_MANHOLE,
    FEATURE_SITE,
    FEATURE_CABINET,
    ROAD_CROSSING_HOOK,
    FEATURE_HANDHOLE,
  ],
  TRENCH: [
    FEATURE_BUILDING,
    FEATURE_MANHOLE,
    FEATURE_SITE,
    FEATURE_CABINET,
    ROAD_CROSSING_HOOK,
    FEATURE_HANDHOLE,
  ],
  ODF: [FEATURE_BUILDING, FEATURE_SITE, FEATURE_POLE, FEATURE_CABINET],
  RTU: [FEATURE_BUILDING, FEATURE_SITE],
  OLT: [FEATURE_BUILDING, FEATURE_SITE],
  SPLITTER: [FEATURE_CABINET, FEATURE_BUILDING, FEATURE_SITE],
  SPLICE_CLOSURE: [FEATURE_POLE, FEATURE_MANHOLE, FEATURE_HANDHOLE],
  FDP: [FEATURE_POLE, FEATURE_MANHOLE, FEATURE_BUILDING, FEATURE_HANDHOLE],
  FACE_PLATE: [FEATURE_BUILDING],
  ONT: [FEATURE_BUILDING],
  POLE: [FEATURE_FIBER_CABLE],
  MANHOLE: [FEATURE_FIBER_CABLE, FEATURE_DUCT, TRENCH],
  HANDHOLE: [FEATURE_FIBER_CABLE, FEATURE_DUCT, TRENCH],
  MICRO_TUNNEL: [
    FEATURE_BUILDING,
    FEATURE_MANHOLE,
    FEATURE_SITE,
    FEATURE_CABINET,
    ROAD_CROSSING_HOOK,
    FEATURE_HANDHOLE,
  ],
};

export let PORT_EQUIPMENT = [
  FEATURE_ODF,
  FEATURE_FDP,
  FEATURE_SPLITTER,
  FEATURE_FACE_PLATE,
  FEATURE_ONT,
  FEATURE_OLT,
  FEATURE_RTU,
];

export let EXCLUDE_SELECTION = [
  FEATURE_SPLICE_SCHEMATIC_PATH,
  FEATURE_SPLICE_SCHEMATIC_LINESTRING,
  FEATURE_SPLICE_SCHEMATIC_POINT,
];

export let contentFeaturesConfig = {
  BUILDING: [
    FEATURE_ODF,
    FEATURE_SPLITTER,
    FEATURE_FACE_PLATE,
    FEATURE_ONT,
    FEATURE_OLT,
    FEATURE_RTU,
  ],
  SITE: [
    FEATURE_ODF,
    FEATURE_SPLITTER,
    FEATURE_FACE_PLATE,
    FEATURE_ONT,
    FEATURE_OLT,
    FEATURE_RTU,
  ],
  MANHOLE: [FEATURE_ODF, FEATURE_FDP, FEATURE_SPLITTER, FEATURE_CLOSURE],
  HANDHOLE: [FEATURE_FDP, FEATURE_SPLITTER, FEATURE_CLOSURE],
  CABINET: [FEATURE_FDP, FEATURE_SPLITTER, FEATURE_CLOSURE],
  POLE: [FEATURE_FDP, FEATURE_SPLITTER, FEATURE_CLOSURE],
  DUCT: [FEATURE_FIBER_CABLE],
};

export let FEATURE_NAME_TYPE_MAP = {
  road_crossing_hook: "Crossing",
};

export let IMPORT_EXPORT_EQUIPMENT = [
  FEATURE_ODF,
  FEATURE_FDP,
  FEATURE_SPLITTER,
  FEATURE_FACE_PLATE,
  FEATURE_ONT,
  FEATURE_OLT,
  FEATURE_POLE,
  TRENCH,
  ROAD_CROSSING_HOOK,
  FEATURE_BUILDING,
  FEATURE_MANHOLE,
  FEATURE_SITE,
  FEATURE_CABINET,
  FEATURE_FIBER_CABLE,
  FEATURE_DUCT,
  FEATURE_HANDHOLE,
  BLUE_PRINT_LINE,
  BLUE_PRINT_POINT,
];

export let IMPORT_EXPORT_FILES = [
  {
    text: "CSV/Excel",
    value: ".xlsx",
  },
  {
    text: "Esri Shapefile",
    value: ".shp",
  },
  {
    text: "KML",
    value: ".kml",
  },
  {
    text: "Map Info",
    value: ".mapinfo",
  },
];

export let IS_SPLICE_CONNECTION = "splice_connection";

export let IS_PORT_CONNECTION = "port_connection";

export let FIBER_COLORS = {
  1: "blue",
  2: "orange",
  3: "green",
  4: "brown",
  5: "slategray",
  6: "white",
  7: "red",
  8: "black",
  9: "yellow",
  10: "violet",
  11: "#FFC0CB",
  12: "aqua",
};
