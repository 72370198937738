<template>
  <v-layout class="pa-0">
    <div v-if="selectedFeaturesVisible" class="pa-0" style="width: 100%">
      <div
        class="
          dialog-title
          pa-2
          success
          white--text
          custom-align-center
          relative
        "
      >
        <h4>Selected Features</h4>

        <v-icon
          color="white"
          class="cursor-item"
          @click="emitHideFeatureWindow"
          title="Hide Feature Window"
          style="position: absolute; top: 8px; right: 10px"
          >clear</v-icon
        >
      </div>

      <v-layout class="tab-item">
        <v-flex xs12>
          <v-expansion-panels class="pa-3">
            <v-expansion-panel
              v-for="(item, i) in selectedFeatureTypes"
              :key="i"
            >
              <v-expansion-panel-header class="pa-0 pl-3"
                >{{ getFeatureNameFromType(item.type) }} ({{
                  item.count
                }})</v-expansion-panel-header
              >
              <v-expansion-panel-content class="pa-0">
                <v-list class="menu-items" dense>
                  <v-list-item
                    v-for="(feature, index) in selectedFeatures.filter(
                      (f) => f.get('type') == item.type
                    )"
                    :key="index"
                    @click="emitOpenFeatureWindow(feature)"
                  >
                    <v-list-item-content class="pa-0">
                      <v-list-item-title :id="feature">{{
                        getFeatureName(feature)
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
      </v-layout>
    </div>

    <div
      v-if="!selectedFeaturesVisible && currentFeature && !isMeasurementFeature"
      class="pa-0"
      style="width: 100%"
    >
      <div
        class="
          dialog-title
          pa-2
          success
          white--text
          custom-align-center
          relative
        "
      >
        <h4 class="feature-name" v-if="currentFeature">
          {{
            featureAlreadyExists()
              ? `${getFeatureName(currentFeature)} - Existing`
              : getFeatureName(currentFeature)
          }}
        </h4>

        <v-icon
          v-if="selectedFeatures.length > 0"
          color="white"
          class="cursor-item"
          @click="emitShowSelectedFeatures"
          title="Back to selected features"
          style="position: absolute; top: 8px; left: 10px"
          >keyboard_backspace</v-icon
        >

        <v-icon
          v-if="tabs == 0"
          color="white"
          class="cursor-item"
          @click="saveFeature"
          title="Save and close feature window"
          style="position: absolute; top: 8px; right: 45px"
          >save_alt</v-icon
        >

        <v-icon
          color="white"
          class="cursor-item"
          @click="emitHideFeatureWindow"
          title="Close feature window"
          style="position: absolute; top: 8px; right: 10px"
          >clear</v-icon
        >
      </div>

      <v-layout>
        <v-flex xs12>
          <v-tabs
            v-model="tabs"
            show-arrows
            color="success"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
          >
            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
            <v-tab v-if="propertiesTab">Properties</v-tab>
            <v-tab v-if="servicesTab && currentFeature.get('pk')"
              >Services</v-tab
            >
            <v-tab v-if="contentsTab">Contents</v-tab>
            <v-tab v-if="attachementsTab">Attachments</v-tab>
            <!-- <v-tab v-if="spliceSchematicsTab">Splice Schematics</v-tab> -->
            <v-tab v-if="splicesTab">Splices</v-tab>
            <v-tab v-if="utilizationTab">Utilization</v-tab>
            <v-tab v-if="trenchesTab">Trenches</v-tab>
            <v-tab v-if="accessoriesTab">Accessories</v-tab>
            <v-tab v-if="cableSlacksTab">Cable Slacks</v-tab>
            <v-tab v-if="cableManagementTab">Inner Duct/ Cables</v-tab>
            <v-tab v-if="monitoringTab">FMS Monitoring Info</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabs">
            <v-tab-item v-if="propertiesTab" class="tab-item">
              <FeatureAttributesTable
                :key="currentFeature.ol_uid"
                :feature_saving="feature_saving"
                :selectedFeature="currentFeature"
                :featureData="featureData"
                :vectorSources="vectorSources"
                :opticalDevices="opticalDevices"
                @deleteFeature="deleteFeature"
              ></FeatureAttributesTable>
            </v-tab-item>

            <v-tab-item
              v-if="servicesTab && currentFeature.get('pk')"
              class="tab-item"
            >
              <ServicesTable :feature="currentFeature"></ServicesTable>
            </v-tab-item>

            <v-tab-item v-if="contentsTab" class="tab-item">
              <FeatureContentsTable
                :feature="currentFeature"
                :vectorSources="vectorSources"
                @saveFeature="saveFeature"
                @deleteFeature="deleteFeature"
              ></FeatureContentsTable>
            </v-tab-item>

            <v-tab-item v-if="attachementsTab" class="tab-item">
              <AttachementsTable :feature="currentFeature"></AttachementsTable>
            </v-tab-item>

            <v-tab-item v-if="splicesTab" class="tab-item">
              <SplicesTable :feature="currentFeature"></SplicesTable>
            </v-tab-item>

            <v-tab-item v-if="accessoriesTab" class="tab-item">
              <PoleAccesoriesTable
                :feature="currentFeature"
              ></PoleAccesoriesTable>
            </v-tab-item>

            <v-tab-item v-if="utilizationTab" class="tab-item">
              <UtilizationTable
                :vectorSources="vectorSources"
                :feature="currentFeature"
              ></UtilizationTable>
            </v-tab-item>

            <v-tab-item v-if="trenchesTab" class="tab-item">
              <TrenchesTable :feature="currentFeature"></TrenchesTable>
            </v-tab-item>

            <v-tab-item v-if="cableSlacksTab" class="tab-item">
              <CableSlacksTable
                :selectedFeatures="selectedFeatures"
                :feature="currentFeature"
                :vectorSources="vectorSources"
                @highlightSelectedFeature="emitHighlightSelectedFeature"
                @deleteAllHighlightFeatures="emitDeleteAllHighlightFeatures"
              ></CableSlacksTable>
            </v-tab-item>

            <v-tab-item v-if="cableManagementTab" class="tab-item">
              <DuctCableManagerTable
                :selectedFeatures="selectedFeatures"
                :vectorSources="vectorSources"
                :feature="currentFeature"
              ></DuctCableManagerTable>
            </v-tab-item>

            <v-tab-item
              v-if="monitoringTab && currentFeature.get('pk')"
              class="tab-item"
            >
              <MonitoringTable :feature="currentFeature"></MonitoringTable>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </div>
  </v-layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import FeatureAttributesTable from "./AttributesTable";
import FeatureContentsTable from "./FeatureContentsTable";
// import RoadCrossingTable from "./RoadCrossingTable";
import TrenchesTable from "./TrenchesTable";
import CableSlacksTable from "./CableSlacksTable";
import UtilizationTable from "./UtilizationTable";
import AttachementsTable from "./AttachementsTable";
import SplicesTable from "./SplicesTable";
import DuctCableManagerTable from "./DuctCablesTable";
import PoleAccesoriesTable from "./PoleAccesories";
import ServicesTable from "./ServicesTable";
import MonitoringTable from "./MonitoringTable.vue";
import Point from "ol/geom/Point";
import messagingMixin from "../../../utils/messagingMixin";
import { Feature } from "ol";

import {
  hasPropertiesTab,
  hasCableSlacksTab,
  hasSpliceSchematicsTab,
  hasRoadCrossingsTab,
  hasUtilizationTab,
  hasSplicesTab,
  hasTrenchSectionsTab,
  hasAttachementsTab,
  hasContentsTab,
  hasCableManagementTab,
  getDerivedName,
  hasAccessoriesTab,
  hasServicesTab,
  isExistingFeature,
  getNameFromType,
  hasMonitoringTab,
} from "../../../utils/map_functions";
import {
  REDLINE_POINT,
  ROAD_CROSSING_HOOK,
} from "../../../utils/feature_constants";

export default {
  components: {
    FeatureAttributesTable,
    FeatureContentsTable,
    // RoadCrossingTable,
    ServicesTable,
    TrenchesTable,
    CableSlacksTable,
    UtilizationTable,
    AttachementsTable,
    DuctCableManagerTable,
    SplicesTable,
    PoleAccesoriesTable,
    MonitoringTable,
  },
  name: "FeatureWindowComponent",
  props: {
    opticalDevices: Array,

    selectedFeaturesVisible: {
      type: Boolean,
    },
    isMeasurementFeature: {
      type: Boolean,
    },
    currentFeature: {
      type: Object,
    },
    selectedFeatures: {
      type: Array,
    },
    selectedFeatureTypes: {
      type: Array,
    },
    vectorSources: {
      type: Array,
    },
    feature_saving: {
      type: Boolean,
    },
  },
  mixins: [messagingMixin],
  data: () => ({
    current_job: 0,
    tabs: 0,
    featureType: null,
    selectOptions: null,
    featureData: {},
    skip_features: [REDLINE_POINT, ROAD_CROSSING_HOOK],
  }),
  mounted() {
    if (this.currentFeature) {
      this.featureType = this.currentFeature.get("type");
    }

    this.current_job = this.$route.params.job_id;
  },
  methods: {
    getFeatureName(feature) {
      return getDerivedName(feature, this.vectorSources);
    },
    getFeatureNameFromType(feature) {
      return getNameFromType(feature);
    },
    emitDeleteAllHighlightFeatures() {
      this.$emit("deleteAllHighlightFeatures");
    },
    emitHighlightSelectedFeature(feature) {
      this.$emit("highlightSelectedFeature", feature);
    },
    emitShowSelectedFeatures() {
      this.$emit("showSelectedFeatures");
    },
    emitHideFeatureWindow() {
      this.$emit("hideFeatureWindow");
    },
    emitOpenFeatureWindow(feature) {
      this.$emit("openFeatureWindow", feature);
    },
    deleteFeature(feature) {
      if (!this.featureAlreadyExists()) {
        this.$emit("deleteFeature", feature);
      } else {
        this.displayErrorAlert(
          "You cannot delete a feature that does not belong to this job"
        );
      }
    },
    featureAlreadyExists() {
      return isExistingFeature(this.currentFeature, this.current_job);
    },
    saveFeature(unsavedFeaure) {
      if (!this.featureAlreadyExists()) {
        if (unsavedFeaure && unsavedFeaure instanceof Feature) {
          // bypass and save feature directly
          this.$emit("addFeatureToMap", unsavedFeaure);
          this.$emit("saveFeature", unsavedFeaure, {
            hide_feature_window: false,
          });
        } else if (this.currentFeature) {
          let geom = this.currentFeature.getGeometry();
          if (
            geom instanceof Point &&
            !this.skip_features.includes(this.currentFeature.get("type"))
          ) {
            geom.setCoordinates([
              parseFloat(this.featureData["longitude"]),
              parseFloat(this.featureData["latitude"]),
            ]);
          }

          this.featureData["geometry"] = geom;
          this.currentFeature.setProperties(this.featureData);
          this.$emit("saveFeature", this.currentFeature);
        }
      } else {
        this.displayErrorAlert(
          "You cannot modify a feature that does not belong to this job."
        );
      }
    },
  },
  computed: {
    propertiesTab: function () {
      return hasPropertiesTab(this.featureType);
    },
    contentsTab: function () {
      return hasContentsTab(this.featureType);
    },
    accessoriesTab: function () {
      return hasAccessoriesTab(this.featureType);
    },
    utilizationTab: function () {
      return hasUtilizationTab(this.featureType);
    },
    attachementsTab: function () {
      return hasAttachementsTab(this.featureType);
    },
    servicesTab: function () {
      return hasServicesTab(this.featureType);
    },
    trenchesTab: function () {
      return hasTrenchSectionsTab(this.featureType);
    },
    roadCrossingTab: function () {
      return hasRoadCrossingsTab(this.featureType);
    },
    splicesTab: function () {
      return hasSplicesTab(this.featureType);
    },
    spliceSchematicsTab: function () {
      return hasSpliceSchematicsTab(this.featureType);
    },
    cableSlacksTab: function () {
      return hasCableSlacksTab(this.featureType);
    },
    cableManagementTab: function () {
      return hasCableManagementTab(this.featureType);
    },
    monitoringTab: function () {
      return hasMonitoringTab(this.featureType);
    },
  },
};
</script>

<style lang="css" scoped>
.tab-item {
  height: 500px;
  overflow-y: scroll;
}

.dialog-title {
  text-align: center;
  border-radius: 3px;
}

.cursor-item {
  cursor: pointer;
}

.feature-dialog {
  position: absolute;
  width: 450px;
  min-height: 300px;
  max-height: 600px;
  background: #fff;
  top: 10em;
  left: 2em;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 3px;
}

h4.feature-name {
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  margin: auto;
}
</style>