<template>
  <v-row align-content="center" justify="center">
    <v-col class="subtitle-1 text-center" cols="12">{{ message }}</v-col>
    <v-col cols="3">
      <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    message: String
  }
};
</script>