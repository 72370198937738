import { render, staticRenderFns } from "./MergeManager.vue?vue&type=template&id=48de768e&scoped=true&"
import script from "./MergeManager.vue?vue&type=script&lang=js&"
export * from "./MergeManager.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48de768e",
  null
  
)

export default component.exports