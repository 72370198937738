/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import { jsPDF } from "jspdf";
import { getPointResolution } from "ol/proj";

export default {
  data: () => ({
    printManagerVisible: false,
    dims: {
      a0: [1189, 841],
      a1: [841, 594],
      a2: [594, 420],
      a3: [420, 297],
      a4: [297, 210],
      a5: [210, 148],
    },
    exportOptions: {
      filter: function(element) {
        var className = element.className || "";
        return (
          className.indexOf("ol-control") === -1 ||
          className.indexOf("ol-scale") > -1 ||
          (className.indexOf("ol-attribution") > -1 &&
            className.indexOf("ol-uncollapsible"))
        );
      },
      width: "",
      height: "",
    },
    mapOptions: null,
  }),

  methods: {
    mapRenderComplete(event) {
      console.log("Received", event);
      console.log("Map Options", this.mapOptions);
      this.exportOptions.height = this.mapOptions.height;
      this.exportOptions.width = this.mapOptions.width;

      var mapCanvas = document.createElement("canvas");
      mapCanvas.width = this.mapOptions.width;
      mapCanvas.height = this.mapOptions.height;
      let selfRef = this;
      var mapContext = mapCanvas.getContext("2d");
      Array.prototype.forEach.call(
        document.querySelectorAll(".ol-layer canvas"),
        function(canvas) {
          if (canvas.width > 0) {
            var opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
            var transform = canvas.style.transform;
            // Get the transform parameters from the style's transform matrix
            var matrix = transform
              .match(/^matrix([^]*)\)$/)[1]
              .split(",")
              .map(Number);

            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );
      var pdf = new jsPDF("landscape", undefined, this.mapOptions.format);
      console.log("Map Canvas", mapCanvas);

      try {
        pdf.addImage(
          mapCanvas.toDataURL("image/jpeg"),
          "JPEG",
          0,
          0,
          this.mapOptions.dim[0],
          this.mapOptions.dim[1]
        );
        pdf.save("map.pdf");
      } catch (e) {
        console.log("Exception:", e);
      }

      // Reset original map size
      this.scaleLine.setDpi();
      this.map.setSize(this.mapOptions.size);
      this.map.getView().setResolution(this.mapOptions.viewResolution);
      document.body.style.cursor = "auto";
    },

    printMapSection(options) {
      document.body.style.cursor = "progress";

      // set render complete event handler
      this.map.once("rendercomplete", this.mapRenderComplete);

      var format = options.format;
      var resolution = options.resolution;
      var scale = options.scale;
      var dim = this.dims[format];
      var width = Math.round((dim[0] * resolution) / 25.4);
      var height = Math.round((dim[1] * resolution) / 25.4);
      var size = this.map.getSize();
      var extent = this.map.getView().calculateExtent(size);
      var viewResolution = this.map.getView().getResolution();
      var scaleResolution =
        scale /
        getPointResolution(
          this.map.getView().getProjection(),
          resolution / 25.4,
          this.map.getView().getCenter()
        );

      let mapOptions = {};
      mapOptions.height = height;
      mapOptions.width = width;
      mapOptions.dim = dim;
      mapOptions.format = format;
      mapOptions.viewResolution = viewResolution;
      mapOptions.size = size;
      this.mapOptions = mapOptions;

      // Set print size
      var printSize = [width, height];
      this.map.setSize(printSize);
      this.map.getView().fit(extent, { size: printSize });
      var scaling = Math.min(width / size[0], height / size[1]);
      this.map.getView().setResolution(viewResolution / scaling);
    },
  },
};
