<template>
  <v-breadcrumbs class="bread-crumbs" :items="items" :divider="breadcrumbs_separator"></v-breadcrumbs>
</template>

<script>
import appConfig from "../../config";
var breadcrumbsSeparator = appConfig.breadcrumbs_separator;
export default {
  name: "",
  props: {
    items: {
      type: Array
    }
  },
  data: () => ({
    breadcrumbs_separator: breadcrumbsSeparator
  })
};
</script>