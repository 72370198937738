<template>
  <!-- App Toolbar |||||  color: #562056 -->
  <div>
    <v-app-bar
      app
      color="white"
      class="secondary--text"
      dark
      :clipped-left="$vuetify.breakpoint.mdAndUp"
      fixed
    >
      <v-toolbar-title class="app-title"></v-toolbar-title>

      <div>
        <p
          class="mb-0 mr-9 ml-9 app-title primary--text darken"
          v-if="customer"
        >
          {{ app_title }} - {{ customer.customer_name }}
        </p>
        <p v-else class="mb-0 mr-9 ml-9 app-title primary--text">
          {{ app_title }}
        </p>
      </div>

      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <p v-if="user.id != undefined" class="mb-0 mr-6 ml-6 app-title">
        {{ user.name }}
      </p>
      <p v-else class="mb-0 mr-6 ml-6 app-title">
        Anonymous {{ selected_name }}
      </p>

      <v-menu left offset-y>
        <template v-slot:activator="{ on }">
          <!-- User avatar -->
          <v-avatar size="32px" tile v-on="on" style="cursor: pointer">
            <img v-if="user" :src="user.user_avatar" alt="Vuetify" />
          </v-avatar>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in toolbar_menu"
            :key="index"
            @click="handleMenuClick"
          >
            <v-list-item-title :id="item.id">{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import appConfig from "../../config";
import { mapState, mapActions } from "vuex";

export default {
  name: "ToolBar",
  props: {},
  data: () => ({
    toolbar_menu: [],
    app_title: appConfig.app_title,
    random_animals: [
      "Armadillo",
      "Pangolin",
      "Peacock",
      "Bear",
      "Lion",
      "Pingu",
    ],
    selected_name: "",
  }),
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      customers: (state) => state.customersModule.customers,
      customer: function () {
        if (this.user.customer_id != undefined) {
          return this.customers.find(
            (customer) => customer.id == this.user.customer_id
          );
        }

        return undefined;
      },
    }),
  },
  methods: {
    ...mapActions({
      logout: "logout",
      getCustomers: "getCustomers",
    }),
    handleMenuClick(evt) {
      if (evt.target.id == "logout") {
        this.logout().then(() => {
          this.$router.push("/login");
        });
      }
    },
  },
  mounted() {
    this.toolbar_menu = appConfig.toolbar_menu;
    let rand = parseInt(Math.random() * this.random_animals.length);
    this.selected_name = this.random_animals[rand];

    if (this.customers.length == 0) {
      this.getCustomers();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,700&display=swap");

.nav-icon {
  color: #fff !important;
}

.logo {
  height: 30px;
}

.app-title {
  font-family: "Ubuntu" !important;
  font-weight: bold;
}

.toolbar-select {
  margin-top: 10px;
  border: none;
}
</style>
