<template>
  <div style="max-width: 100%">
    <v-card flat class="pa-3" style="max-width: 100%">
      <p :class="features_selected_class + ' pl-3 pb-0 pt-0'">
        {{ features_selected }}
      </p>
      <v-layout row wrap class="pa-2 pl- pt-0">
        <v-flex xs6 class="pl-4">
          <v-select
            :disabled="show_signal_tracer"
            dense
            clearable
            v-model="selected_feature"
            :items="filtered_features"
            item-text="text"
            item-value="value"
            label="Select Feature"
          ></v-select>
        </v-flex>

        <v-flex
          xs6
          class="pl-4"
          v-if="!port_equipment_selected && selected_feature"
        >
          <v-select
            dense
            clearable
            v-model="location"
            :items="locations"
            item-text="text"
            item-value="value"
            label="Location"
          ></v-select>
        </v-flex>

        <v-flex v-if="port_equipment_selected" xs4 class="pl-2">
          <v-select
            :disabled="show_signal_tracer"
            dense
            v-if="port_equipment_selected"
            clearable
            v-model="port_type"
            :items="port_types"
            item-text="text"
            item-value="value"
            label="Port Type"
          ></v-select>
        </v-flex>

        <v-flex v-if="!show_signal_tracer" xs4 class="pl-4">
          <v-btn
            :disabled="
              !selected_feature || (port_equipment_selected && !port_type)
            "
            small
            color="success"
            outlined
            @click="loadConnections"
          >
            <v-icon>keyboard_arrow_right</v-icon>Load Connections
          </v-btn>
        </v-flex>

        <v-flex v-if="show_signal_tracer" xs4 class="pl-4">
          <v-btn small color="success" outlined @click="hideSignalTracer">
            <v-icon>keyboard_arrow_left</v-icon>Back to Connections
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex>
          <!-- Fiber Connections Table -->
          <v-data-table
            :loading="loading"
            style="max-width: 100%"
            v-if="!port_equipment_selected && !show_signal_tracer"
            disable-sort
            fixed-header
            height="400"
            :headers="fiber_header"
            :items="feature_connections"
            :items-per-page="600"
            hide-default-footer
          >
            <template v-slot:[`item.termination_A`]="{ item }">{{
              item.termination_A ? `PORT ${item.termination_A}` : ""
            }}</template>

            <template v-slot:[`item.termination_feature_A`]="{ item }">{{
              item.termination_feature_A
                ? getFeatureName(retrieveFeature(item.termination_feature_A))
                : ``
            }}</template>

            <template v-slot:[`item.termination_B`]="{ item }">{{
              item.termination_B ? `PORT ${item.termination_B}` : ""
            }}</template>

            <template v-slot:[`item.termination_feature_B`]="{ item }">{{
              item.termination_feature_B
                ? getFeatureName(retrieveFeature(item.termination_feature_B))
                : ``
            }}</template>

            <template v-slot:[`item.splice_A_fiber`]="{ item }">{{
              item.splice_A_fiber ? `FIBER ${item.splice_A_fiber}` : ``
            }}</template>

            <template v-slot:[`item.splice_A_feature`]="{ item }">{{
              item.splice_A_feature
                ? getFeatureName(retrieveFeature(item.splice_A_feature))
                : ``
            }}</template>

            <template v-slot:[`item.splice_A_location`]="{ item }">{{
              item.splice_A_location
                ? getFeatureName(retrieveFeature(item.splice_A_location))
                : ``
            }}</template>

            <template v-slot:[`item.splice_B_fiber`]="{ item }">{{
              item.splice_B_fiber ? `FIBER ${item.splice_B_fiber}` : ``
            }}</template>

            <template v-slot:[`item.splice_B_feature`]="{ item }">{{
              item.splice_B_feature
                ? getFeatureName(retrieveFeature(item.splice_B_feature))
                : ``
            }}</template>

            <template v-slot:[`item.splice_B_location`]="{ item }">{{
              item.splice_B_location
                ? getFeatureName(retrieveFeature(item.splice_B_location))
                : ``
            }}</template>

            <template v-slot:[`item.trace`]="{ item }">
              <v-btn
                v-if="item.connected"
                @click="execTraceSignal(item)"
                small
                text
              >
                <v-icon color="primary">trending_up</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.connected"
                @click="showDeleteDialog(item)"
                small
                text
              >
                <v-icon color="error">delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <!-- Port Connections Table -->
          <v-data-table
            :loading="loading"
            v-if="port_equipment_selected && !show_signal_tracer"
            disable-sort
            fixed-header
            height="400"
            :items="feature_connections"
            :items-per-page="600"
            hide-default-footer
            :headers="port_header"
          >
            <template v-slot:[`item.to_feature`]="{ item }">{{
              item.to_feature
                ? getFeatureName(retrieveFeature(item.to_feature))
                : ""
            }}</template>

            <template v-slot:[`item.port_fiber`]="{ item }"
              >{{ item.to_fiber ? `FIBER ${item.to_fiber}` : `` }}
              {{ item.to_port ? `PORT ${item.to_port}` : `` }}</template
            >

            <template v-slot:[`item.connection_type`]="{ item }">{{
              item.connection_type
                ? retrieveConnectionType(item.connection_type).text
                : ``
            }}</template>

            <template v-slot:[`item.trace`]="{ item }">
              <v-btn
                v-if="item.connected"
                @click="execTraceSignal(item)"
                small
                text
              >
                <v-icon color="primary">trending_up</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.connected"
                @click="showDeleteDialog(item)"
                small
                text
              >
                <v-icon color="error">delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <!-- Signal Trace Table -->
          <v-data-table
            v-if="show_signal_tracer"
            :loading="loading"
            :items="signal_trace_features"
            :headers="signal_trace_headers"
            :items-per-page="600"
            hide-default-footer
            disable-sort
            fixed-header
            height="400"
          >
            <template v-slot:[`item.feature`]="{ item }">{{
              item.feature ? getFeatureName(retrieveFeature(item.feature)) : ``
            }}</template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <div v-if="delete_dialog"></div>
      <ConfirmDelete
        :deleteMessage="deleteMessage"
        :dialogModel="delete_dialog"
        @closeDialog="closeDialog"
        @deleteConfirmed="deleteConfirmed"
        :model="deleteModel"
      ></ConfirmDelete>
    </v-card>
  </div>
</template>

<script>
import {
  FEATURE_ODF,
  FEATURE_SPLITTER,
  FEATURE_FDP,
  FEATURE_FIBER_CABLE,
  PORT_EQUIPMENT,
  FEATURE_BUILDING,
  FEATURE_POLE,
  FEATURE_SITE,
  FEATURE_MANHOLE,
  FEATURE_CABINET,
  FEATURE_ONT,
  FEATURE_OLT,
  FEATURE_FACE_PLATE,
} from "../../../../utils/feature_constants";

import {
  getFeatureById,
  getDerivedName,
} from "../../../../utils/map_functions";
import ConfirmDelete from "../../../generic/ConfirmDeleteDialog";
import dialogMixins from "../../../../utils/mixins";
import { mapActions } from "vuex";

export default {
  name: "ViewConnectionsComponent",
  components: { ConfirmDelete },
  mixins: [dialogMixins],
  props: {
    features: {
      type: Array,
    },
    vectorSources: {
      type: Array,
    },
  },
  watch: {
    selected_feature: function() {
      this.feature_connections = [];
    },
    port_type: function() {
      this.feature_connections = [];
    },
  },
  data: () => ({
    deleteMessage: "Are you sure you want to remove this connection?",
    location: null,
    loading: false,
    feature_connections: [],
    signal_trace_features: [],
    signal_path: [],
    show_signal_tracer: false,
    current_selected_port: null,
    port_type: null,
    splitter_port_types: [
      {
        text: "INPUT PORT",
        value: 1,
      },
      {
        text: "OUTPUT PORTS",
        value: 2,
      },
    ],
    odf_port_types: [
      {
        text: "PATCH PORTS",
        value: 1,
      },
      {
        text: "PIGTAIL PORTS",
        value: 2,
      },
    ],
    fdp_port_types: [
      {
        text: "CUSTOMER PORTS",
        value: 1,
      },
      {
        text: "INCOMING CABLE PORTS",
        value: 2,
      },
    ],
    ont_port_types: [
      {
        text: "CUSTOMER PORT",
        value: 1,
      },
      {
        text: "DROP CABLE PORTS",
        value: 2,
      },
    ],
    olt_port_types: [
      {
        text: "OLT OUTPUT PORTS",
        value: 2,
      },
    ],
    other_port_types: [
      {
        text: "INPUT PORTS",
        value: 1,
      },
      {
        text: "OUTPUT PORTS",
        value: 2,
      },
    ],
    selected_feature: null,
    items: [],
    fiber_header: [
      {
        text: "Fiber",
        value: "name",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Trace",
        value: "trace",
        sortable: false,
        fixed: true,
        width: "50px",
      },
      {
        text: "Customer",
        value: "customer",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "A Termination",
        value: "termination_A",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "A Feature",
        value: "termination_feature_A",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "B Termination",
        value: "termination_B",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "B Feature",
        value: "termination_feature_B",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice A",
        value: "splice_A_fiber",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice A Feature",
        value: "splice_A_feature",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice A Location",
        value: "splice_A_location",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice B",
        value: "splice_B_fiber",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice B Feature",
        value: "splice_B_feature",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Splice B Location",
        value: "splice_B_location",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      // {
      //   text: "Priority",
      //   value: "priority",
      //   sortable: false,
      //   fixed: true,
      //   width: "150px",
      // },
      // {
      //   text: "Customer",
      //   value: "customer",
      //   sortable: false,
      //   fixed: true,
      //   width: "150px",
      // },
      // {
      //   text: "Trace",
      //   value: "trace",
      //   sortable: false,
      //   fixed: true,
      //   width: "50px",
      // },
      {
        text: "Action",
        value: "action",
        sortable: false,
        fixed: true,
        width: "50px",
      },
    ],
    port_header: [
      {
        text: "Port",
        value: "name",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Trace",
        value: "trace",
        sortable: false,
        fixed: true,
        width: "50px",
      },
      {
        text: "Customers",
        value: "customer",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "To Feature",
        value: "to_feature",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Port/Fiber",
        value: "port_fiber",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Connection Type",
        value: "connection_type",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      // {
      //   text: "Priority",
      //   value: "priority",
      //   sortable: false,
      //   fixed: true,
      //   width: "150px",
      // },
      {
        text: "Action",
        value: "action",
        sortable: false,
        fixed: true,
        width: "50px",
      },
    ],
    signal_trace_headers: [
      {
        text: "Route Object",
        value: "feature",
        fixed: true,
        width: "150px",
      },
      {
        text: "Fiber/Port",
        value: "fiber_port",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Connection Type",
        value: "connection_type",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Location",
        value: "location",
        sortable: false,
        fixed: true,
        width: "150px",
      },
      {
        text: "Length",
        value: "length",
        sortable: false,
        fixed: true,
        width: "150px",
      },
    ],
    is_fiber_cable: false,
    is_port_equipment: true,
    connection_types: [
      {
        text: "Splice",
        value: 1,
      },
      {
        text: "Termination",
        value: 2,
      },
      {
        text: "Patch",
        value: 3,
      },
    ],
  }),
  computed: {
    locations: function() {
      let filteredList = [];
      let validTypes = [
        FEATURE_BUILDING,
        FEATURE_POLE,
        FEATURE_SITE,
        FEATURE_MANHOLE,
        FEATURE_CABINET,
      ];

      this.features.forEach((feature) => {
        if (validTypes.includes(feature.get("type")))
          filteredList.push({
            text: feature.get("name"),
            value: feature.getId(),
          });
      });

      return filteredList;
    },
    features_selected: function() {
      if (this.filtered_features.length == 0) return "No features selected";
      return `${this.filtered_features.length} fearures selected`;
    },
    features_selected_class: function() {
      if (this.filtered_features.length == 0) return "error--text";
      return "primary--text";
    },
    port_types: function() {
      if (this.selected_feature) {
        let feature = getFeatureById(this.selected_feature, this.vectorSources);
        if (feature) {
          if (
            feature.get("type") == FEATURE_ODF ||
            feature.get("type") == FEATURE_FACE_PLATE
          )
            return this.odf_port_types;
          if (feature.get("type") == FEATURE_FDP) return this.fdp_port_types;
          if (feature.get("type") == FEATURE_ONT) return this.ont_port_types;
          if (feature.get("type") == FEATURE_OLT) return this.olt_port_types;
          if (feature.get("type") == FEATURE_SPLITTER)
            return this.splitter_port_types;
        }
      }

      return this.other_port_types;
    },
    port_equipment_selected: function() {
      let feature = getFeatureById(this.selected_feature, this.vectorSources);
      if (feature) {
        if (PORT_EQUIPMENT.includes(feature.get("type"))) return true;
      }

      return false;
    },
    filtered_features: function() {
      let filteredList = [];
      let validTypes = [
        FEATURE_ODF,
        FEATURE_SPLITTER,
        FEATURE_FIBER_CABLE,
        FEATURE_FDP,
        FEATURE_ONT,
        FEATURE_OLT,
        FEATURE_FACE_PLATE,
      ];

      this.features.forEach((feature) => {
        if (validTypes.includes(feature.get("type")))
          filteredList.push({
            text: getDerivedName(feature, this.vectorSources),
            value: feature.getId(),
          });
      });

      return filteredList;
    },
  },
  destroyed() {
    // emit event to hide signal trace
    this.$emit("clearSignalTrace");
  },
  methods: {
    ...mapActions({
      deleteSplice: "deleteSplice",
      deletePortConnection: "deletePortConnection",
      getConnections: "getConnections",
      traceSignal: "getConnections",
      clearPortInfo: "clearPortInfo",
    }),
    getFeatureName(feature) {
      return getDerivedName(feature, this.vectorSources);
    },
    hideSignalTracer() {
      this.signal_trace_features = [];
      this.show_signal_tracer = false;

      // emit event to hide signal trace
      this.$emit("clearSignalTrace");
    },
    deleteConfirmed(item) {
      if (this.port_equipment_selected) this.execDeleteTermination(item);
      else this.execDeleteSplice(item);
    },
    execDeleteSplice(splice) {
      if (this.location) {
        let payload = {};
        payload.location = this.location;
        payload.fiber = splice.id;
        this.deleteSplice(payload).then(() => {
          this.loadConnections();
          this.location = null;
          this.showAlertMessage(
            `Deleted splice at ${getDerivedName(
              getFeatureById(payload.location, this.vectorSources),
              this.vectorSources
            )}`,
            "success"
          );
        });
      } else {
        this.showAlertMessage("You must select a splice location", "error");
      }
    },
    execDeleteTermination(port) {
      let payload = {};
      payload.port = port.id;
      this.clearPortInfo(payload).then(() => {
        this.loadConnections();
        this.showAlertMessage(`Deleted termination successfully`, "success");
      });
    },
    execTraceSignal(item) {
      this.show_signal_tracer = true;
      this.loading = true;
      let payload = {};
      payload.node = item.id;
      payload.node_type = item.fiber_number ? "fiber" : "port";
      payload.signal_trace = true;

      this.traceSignal(payload).then((res) => {
        if (res.features) {
          this.signal_trace_features = res.features;
          this.show_signal_tracer = true;

          // emit event to add signal tracer to map
          this.$emit("traceSignal", res);
        }
        this.loading = false;
      });
    },
    retrieveConnectionType(connection_type) {
      return this.connection_types.find(
        (type) => type.value == connection_type
      );
    },
    retrieveFeature(feature_id) {
      return getFeatureById(feature_id, this.vectorSources);
    },
    loadConnections() {
      this.loading = true;
      let payload = {};
      if (!this.port_equipment_selected) {
        payload.fiber_cable = getFeatureById(
          this.selected_feature,
          this.vectorSources
        ).get("pk");
      } else {
        payload.feature_uuid = this.selected_feature;
        payload.port_type = this.port_type;
      }

      this.getConnections(payload).then((res) => {
        this.feature_connections = res;
        this.loading = false;
      });
    },
    clearSelection() {
      this.$emit("refreshComponents");
    },
  },
};
</script>

<style lang="scss" scoped></style>
