<template>
  <v-dialog v-model="dialog" persistent width="650px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="project.project_name"
                  :rules="rules.required_field"
                  placeholder="Project Name"
                  label="Project Name"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs6 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="project.project_location"
                  :rules="rules.required_field"
                  placeholder="Project Location"
                  label="Project Location"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs6>
              <v-select
                v-model="project.customer_id"
                :items="customers"
                :loading="customerStatus == 'loading'"
                item-text="customer_name"
                item-value="id"
                label="Project Owner"
                placeholder="Project Owner"
                :rules="rules.required_field"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="loading"
          @click="createOrEditProject"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../utils/mixins";

export default {
  name: "ProjectDialogComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true
    },
    editModel: {
      type: Object
    }
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "New Project",
    dialog_button_text: "Create",
    valid: true,
    rules: {
      email_field: [
        v => !!v || "Field is required",
        v =>
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Field should be a valid email"
      ],
      required_field: [v => !!v || "Field is required"]
    },
    dialog: null,
    project: {}
  }),
  computed: {
    ...mapState({
      customers: state => state.customersModule.customers,
      customerStatus: state => state.customersModule.status,
      projectsStatus: state => state.projectsModule.status
    })
  },
  mounted() {
    this.dialog = this.dialogModel;
    this.project = {};

    if (this.customers.length == 0) {
      this.getCustomers();
    }

    if (this.editModel) {
      this.is_edit_mode = true;

      Object.assign(this.project, this.editModel);
      this.dialog_title = "Edit Project";
      this.dialog_button_text = "Update";
    }
  },
  methods: {
    ...mapActions({
      createProject: "createProject",
      updateProject: "updateProject",
      getProject: "getProject"
    }),
    createOrEditProject() {
      if (this.$refs.form.validate()) {
        if (this.is_edit_mode) {
          let projectId = this.project.id;
          this.updateProject(this.project).then(() =>
            this.getProject(projectId)
          );
        } else {
          this.createProject(this.project);
        }

        this.resetFields();
        this.closeDialog();
      }
    },
    resetFields() {
      this.project = {};
      this.is_edit_mode = false;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();

      this.$emit("closeDialog");
    }
  },
  watch: {
    dialogModel: function(val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function(val) {
      if (!val) {
        this.project = {};
        this.closeDialog();
      }
    }
  }
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>