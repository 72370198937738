<template>
  <!-- check main.js for custom component details -->
  <c-submenu-layout>
    <!-- SubMenu Column -->
    <v-flex class="sub-menu" xs2>
      <c-sub-menu :items="sub_menu"></c-sub-menu>
    </v-flex>

    <!-- Main Content Column -->
    <v-flex xs10 class="main-content-border">
      <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

      <h1>Close Account</h1>

      <!-- v-card start -->
      <v-card flat class="content-card">
        <v-flex class="content-card-title" xs12>WARNING</v-flex>
        <v-layout wrap class="form-layout-border">
          <v-flex xs12 class="pa-3">
            <v-card flat class="pb-3">
              <div class="pa-0 custom-info-section">
                <p class="pa-3">
                  <b>NOTE:</b> Some stuff to think through before you go though with this action.
                </p>
              </div>

              <div class="pa-0 custom-error-section">
                <p class="pa-3">
                  Terminating your account will delete all your account data. This includes plans, documents and networks that you have created along with their
                  data. This operation cannot be undone, so proceed with utmost caution!
                </p>
              </div>

              <v-spacer></v-spacer>

              <hr color="#eee" />

              <v-btn class="mt-4 error">CLOSE ACCOUNT</v-btn>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </c-submenu-layout>
</template>

<script>
import appConfig from "../../config";
export default {
  data: () => ({
    sub_menu: [],
    bread_crumbs: [
      {
        text: "Close Account",
        href: "/profile/close-account"
      }
    ]
  }),
  mounted() {
    this.sub_menu = appConfig.profile_page_sub_menus;
  }
};
</script>