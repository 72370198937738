<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="success white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs8 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="customer_name"
                  :rules="rules.required_field"
                  placeholder="Customer Name"
                  label="Customer Name"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs4 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="customer_number"
                  :rules="rules.required_field"
                  placeholder="Customer Number"
                  label="Customer Number"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs6 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="house_number"
                  :rules="rules.required_field"
                  placeholder="House Number"
                  label="House Number"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs6 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="equipment_serial_number"
                  :rules="rules.required_field"
                  placeholder="ONT Serial Number"
                  label="Customer Name"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="setPortInfo"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../../utils/mixins";

export default {
  name: "PortInfoDialogComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    port: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    customer_name: null,
    customer_number: null,
    house_number: null,
    equipment_serial_number: null,
    priority_settings: [
      {
        text: "LOW",
        value: 1,
      },
      {
        text: "MEDIUM",
        value: 2,
      },
      {
        text: "HIGH",
        value: 3,
      },
    ],
    loading: false,
    dialog_title: "Set Port Info",
    dialog_button_text: "Set",
    valid: true,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    dialog: null,
  }),
  mounted() {
    this.dialog = this.dialogModel;
    console.log(this.port);
    this.dialog_title = `${this.port.name} - ${this.dialog_title}`;

    this.customer_name = this.port.customer_name;
    this.equipment_serial_number = this.port.equipment_serial_number;
    this.customer_number = this.port.customer_number;
    this.house_number = this.port.house_number;
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    ...mapActions({
      updatePortInfo: "updatePortInfo",
    }),
    setPortInfo() {
      if (this.$refs.form.validate()) {
        this.port_info = {};

        this.port_info.id = this.port.id;
        this.port_info.customer_name = this.customer_name;
        this.port_info.equipment_serial_number = this.equipment_serial_number;
        this.port_info.customer_number = this.customer_number;
        this.port_info.house_number = this.house_number;

        this.updatePortInfo(this.port_info)
          .then((res) => {
            console.log(res);

            this.$emit("reload");
            this.resetFields();
            this.closeDialog();
          })
          .catch(() => {
            this.resetFields();
            this.closeDialog();
          });
      }
    },
    retrievePriority(priority) {
      return this.priority_settings.find((type) => type.value == priority);
    },
    resetFields() {
      this.customer_name = null;
      this.customer_number = null;
      this.house_number = null;
      this.equipment_serial_number = null;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();
      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.slack = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>