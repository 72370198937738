<template>
  <!-- check main.js for custom component details -->
  <c-submenu-layout>
    <!-- SubMenu Column -->
    <v-flex class="sub-menu" xs2>
      <c-sub-menu :items="sub_menu"></c-sub-menu>
    </v-flex>

    <!-- Main Content Column -->
    <v-flex xs10 class="main-content-border">
      <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

      <v-card>
        <v-card-title>
          <h1>User Groups</h1>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" label="Search user groups"></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :search="search"
            :loading="status == 'loading'"
            :headers="headers"
            :items="groups"
          >
            <template v-slot:item.action="{item}">
              <v-icon small color="primary" @click="loadPermissions(item)" class="pr-3">settings</v-icon>
              <v-icon small color="primary" class="pr-6" @click="editItem(item)">edit</v-icon>
              <v-icon small color="error" @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-btn
        @click="dialog=!dialog"
        absolute
        top
        right
        fab
        rounded
        color="primary"
        class="white--text fab-button"
      >
        <v-icon>add</v-icon>
      </v-btn>

      <div v-if="dialog">
        <UserGroupDialog
          :editModel="editModel"
          @resetEditModel="resetEditModel"
          @closeDialog="closeDialog"
          :dialogModel="dialog"
        ></UserGroupDialog>
      </div>

      <div v-if="delete_dialog">
        <DeleteDialog
          :deleteMessage="delete_message"
          @deleteConfirmed="deleteConfirmed"
          @closeDialog="closeDialog"
          :dialogModel="delete_dialog"
          :model="deleteModel"
        ></DeleteDialog>
      </div>

      <div v-if="manage_group_permissions">
        <GroupPermissionsDialog
          @resetModels="resetModels"
          @closeDialog="closeDialog"
          :group="permissionsGroup"
          :dialogModel="manage_group_permissions"
        ></GroupPermissionsDialog>
      </div>
    </v-flex>
  </c-submenu-layout>
</template>

<script>
import appConfig from "../../config";
import dialogMixins from "../../utils/mixins";
import UserGroupDialog from "./sub-components/UserGroupDialog";
import GroupPermissionsDialog from "./sub-components/UserGroupPermissionsDialog";
import DeleteDialog from "../generic/ConfirmDeleteDialog";
import { mapState, mapActions } from "vuex";

export default {
  mixins: [dialogMixins],
  components: {
    UserGroupDialog,
    DeleteDialog,
    GroupPermissionsDialog
  },
  data: () => ({
    permissionsGroup: null,
    search: "",
    deleteModel: null,
    editModel: null,
    delete_message: "Are you sure you want to delete this user group?",
    sub_menu: [],
    bread_crumbs: [
      {
        text: "User Groups",
        href: "user-groups"
      }
    ],
    headers: [
      {
        text: "Group Name",
        value: "group_name"
      },
      {
        text: "Actions",
        value: "action"
      }
    ]
  }),
  mounted() {
    this.sub_menu = appConfig.settings_page_sub_menus;
    if (this.groups.length == 0) this.getUserGroups();
    if (this.permissions.length == 0) this.getPermissions();

    console.log(this.permissions);
  },
  computed: {
    ...mapState({
      groups: state => state.usersModule.groups,
      status: state => state.usersModule.status,
      permissions: state => state.permissionsModule.permissions
    })
  },
  methods: {
    ...mapActions({
      getUserGroups: "getUserGroups",
      deleteUserGroup: "deleteUserGroup",
      getPermissions: "getPermissions"
    }),
    loadPermissions(item) {
      this.permissionsGroup = item;
      this.manage_group_permissions = !this.manage_group_permissions;
      console.log("Loading permissions for item:", item);
    },
    resetEditModel() {
      this.editModel = undefined;
    },
    editItem(item) {
      this.editModel = item;
      this.dialog = !this.dialog;
    },
    resetModels() {
      this.permissionsGroup = null;
    },
    deleteItem(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    deleteConfirmed(item) {
      this.deleteUserGroup(item.id).then(() => {
        this.$notify({
          group: "success",
          title: "Deleted",
          text: "Deleted permission group"
        });

        this.getUserGroups();
      });
    }
  }
};
</script>