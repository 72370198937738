<template>
  <v-dialog v-model="dialog" persistent width="550px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <!-- Upload single material -->
            <v-layout row wrap v-if="!is_excel_upload || editModel != undefined">
              <v-flex xs12 class="pt-3">
                <v-text-field
                  v-model="material_type.type_name"
                  :rules="rules.required_field"
                  placeholder="Material Name"
                  label="Material Name"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 class="pt-3">
                <v-select
                  :items="uom_types"
                  item-text="text"
                  item-value="value"
                  v-model="material_type.uom"
                  placeholder="UOM"
                  label="UOM"
                ></v-select>
              </v-flex>
            </v-layout>

            <!-- Upload Excel file for materials -->
            <v-layout v-else>
              <v-layout xs12>
                <v-file-input
                  v-model="upload_file"
                  color="primary"
                  class="pt-4"
                  counter
                  label="Material Types"
                  placeholder="Select Excel File"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="primary" dark label small>{{ text }}</v-chip>
                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                    >+{{ files.length - 2 }} File(s)</span>
                  </template>
                </v-file-input>
              </v-layout>
            </v-layout>
          </v-layout>

          <v-flex v-if="editModel == undefined" xs4>
            <v-checkbox
              :label="is_excel_upload ? 'Excel Upload' : 'Single Material'"
              v-model="is_excel_upload"
            ></v-checkbox>
          </v-flex>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!valid || (upload_file == null && is_excel_upload)"
          :loading="loading"
          @click="uploadTypesMaterials"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MaterialsTypesDialog",
  props: {
    dialogModel: {
      type: Boolean,
      required: true
    },
    editModel: {
      type: Object
    }
  },
  data: () => ({
    is_edit_mode: false,
    is_excel_upload: true,
    SINGLE_UPLOAD: 0,
    EXCEL_UPLOAD: 1,
    upload_types: [
      { text: "Single Material", value: 0 },
      { text: "Excel Upload", value: 1 }
    ],
    uom_types: [
      { text: "PIECE", value: "1" },
      { text: "METERS", value: "0" }
    ],
    upload_type: 0,
    loading: false,
    dialog_title: "Upload Material Types",
    dialog_button_text: "Create",
    valid: true,
    dialog: null,
    upload_file: null,
    material_type: {},
    rules: {
      required_field: [v => !!v || "Field is required"]
    }
  }),
  mounted() {
    this.dialog = this.dialogModel;
    if (this.editModel) {
      this.is_excel_upload = false;
      this.is_edit_mode = true;

      this.dialog_title = "Edit Material Type";
      this.dialog_button_text = "Update";

      this.material_type = Object.assign(this.material_type, this.editModel);
    }
  },
  methods: {
    ...mapActions({
      uploadMaterialTypes: "uploadMaterialTypes",
      getMaterialTypes: "getMaterialTypes",
      createMaterialType: "createMaterialType",
      updateMaterialType: "updateMaterialType"
    }),
    uploadTypesMaterials() {
      this.loading = true;

      var formData;

      if (this.is_excel_upload) {
        formData = new FormData();
        formData.append("upload_file", this.upload_file);
      } else {
        formData = this.material_type;
      }

      // check if we're editing
      if (this.is_edit_mode) {
        this.updateMaterialType(formData).then(() => {
          this.getMaterialTypes(this.$route.params.id);
          this.resetFields();
          this.$emit("closeDialog");
        });
      } else {
        if (!this.is_excel_upload) {
          this.createMaterialType(formData).then(() => {
            this.getMaterialTypes(this.$route.params.id);
            this.resetFields();
            this.$emit("closeDialog");
          });
        } else {
          this.uploadMaterialTypes(formData).then(() => {
            this.getMaterialTypes(this.$route.params.id);
            this.resetFields();
            this.$emit("closeDialog");
          });
        }
      }
    },
    resetFields() {
      this.upload_file = null;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();
      this.$emit("resetEditModel");
      this.$emit("closeDialog");
    }
  },
  watch: {
    dialogModel: function(val) {
      this.dialog = val;
    },

    editModel: function(val) {
      if (val.id != undefined) {
        this.material_type = val;
      }
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function(val) {
      if (!val) {
        this.material_type = {};
        this.closeDialog();
      }
    }
  }
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>