<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{
        dialog_title
      }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <!-- Upload single material -->
            <v-layout
              row
              wrap
              v-if="!is_excel_upload || editModel != undefined"
            >
              <v-flex xs12 class="pt-3">
                <v-text-field
                  v-model="material.material_name"
                  :rules="rules.required_field"
                  placeholder="Material Name"
                  label="Material Name"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 class="pt-3">
                <v-text-field
                  v-model="material.material_cost"
                  :rules="rules.required_field"
                  type="number"
                  placeholder="Material Cost"
                  label="Material Cost"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 class="pt-3">
                <v-select
                  :items="material_types"
                  item-text="type_name"
                  item-value="id"
                  v-model="material.material_type"
                  placeholder="Material Type"
                  label="Material Type"
                ></v-select>
              </v-flex>

              <v-flex xs12 class="pt-3" v-if="sizes_list.length > 0">
                <v-select
                  :items="sizes_list"
                  chips
                  multiple
                  v-model="sizes"
                  placeholder="12/24/48 etc"
                  label="Sizes"
                ></v-select>
              </v-flex>

              <v-flex xs12 class="pt-3">
                <v-select
                  :items="material_status"
                  item-text="text"
                  item-value="value"
                  v-model="material.is_active"
                  placeholder="In Use/ Not in Use"
                  label="In Use/ Not in Use"
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout row v-else class="pt-6 full-width">
              <v-flex xs12>
                <v-file-input
                  v-model="upload_file"
                  color="primary"
                  counter
                  label="Materials Excel File"
                  placeholder="Select Excel File"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="primary" dark label small>{{
                      text
                    }}</v-chip>
                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                      >+{{ files.length - 2 }} File(s)</span
                    >
                  </template>
                </v-file-input>
              </v-flex>
            </v-layout>

            <br />

            <v-flex v-if="!is_edit_mode" xs4>
              <v-checkbox
                :label="is_excel_upload ? 'Excel Upload' : 'Single'"
                v-model="is_excel_upload"
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!valid || (upload_file == null && is_excel_upload)"
          :loading="loading"
          @click="uploadMaterials"
          >{{ dialog_button_text }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  FEATURE_CABINET,
  FEATURE_CLOSURE,
  FEATURE_FACE_PLATE,
  FEATURE_FDP,
  FEATURE_FIBER_CABLE,
  FEATURE_ODF,
  FEATURE_OLT,
  FEATURE_ONT,
  FEATURE_SPLITTER,
  TRENCH,
} from "../../../utils/feature_constants";

export default {
  name: "MaterialsDialog",
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    editModel: {
      type: Object,
    },
  },
  data: () => ({
    is_edit_mode: false,
    is_excel_upload: false,
    SINGLE_UPLOAD: 0,
    EXCEL_UPLOAD: 1,
    upload_types: [
      { text: "Single", value: 0 },
      { text: "Excel Upload", value: 1 },
    ],
    material_status: [
      { text: "In Use", value: true },
      { text: "Not In Use", value: false },
    ],
    size_materials: [
      FEATURE_ODF,
      FEATURE_CABINET,
      FEATURE_FIBER_CABLE,
      FEATURE_SPLITTER,
      FEATURE_CLOSURE,
      FEATURE_FDP,
      FEATURE_ONT,
      FEATURE_FACE_PLATE,
      TRENCH,
    ],
    sizes: null,
    material: {},
    upload_type: 0,
    loading: false,
    dialog_title: "Upload Materials",
    dialog_button_text: "Create",
    valid: true,
    dialog: null,
    upload_file: null,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
  }),
  mounted() {
    this.dialog = this.dialogModel;
    if (this.material_types.length == 0) this.getMaterialTypes();

    if (this.editModel) {
      Object.assign(this.material, this.editModel);

      if (this.material.sizes) {
        this.sizes = this.material.sizes.split(",");
      }

      this.is_edit_mode = true;
      this.dialog_title = "Edit Material";
      this.dialog_button_text = "Update";
    }
  },
  computed: {
    ...mapState({
      material_types: (state) => state.materialsModule.material_types,
      status: (state) => state.materialsModule.status,
    }),
    sizes_list: function () {
      let materialType = this.getMaterialType(this.material.material_type);
      if (materialType) {
        let trimmedName = materialType
          .split("_")
          .join(" ")
          .toLowerCase()
          .trim();

        if (
          FEATURE_ODF.split("_")
            .join(" ")
            .toLowerCase()
            .trim()
            .includes(trimmedName) ||
          FEATURE_FDP.split("_")
            .join(" ")
            .toLowerCase()
            .trim()
            .includes(trimmedName)
        ) {
          return ["1", "12", "24", "48", "96", "144", "288"];
        }

        if (
          FEATURE_SPLITTER.split("_")
            .join(" ")
            .toLowerCase()
            .trim()
            .includes(trimmedName)
        ) {
          return ["2", "4", "8", "16", "32", "64"];
        }

        if (
          FEATURE_FIBER_CABLE.split("_")
            .join(" ")
            .toLowerCase()
            .trim()
            .includes(trimmedName) ||
          FEATURE_CABINET.split("_")
            .join(" ")
            .toLowerCase()
            .trim()
            .includes(trimmedName)
        ) {
          return ["1", "12", "24", "48", "96", "144", "288", "576"];
        }

        if (
          FEATURE_CLOSURE.split("_")
            .join(" ")
            .toLowerCase()
            .trim()
            .includes(trimmedName)
        ) {
          return ["12", "24", "48", "60", "72", "84", "96", "144"];
        }

        if (
          TRENCH.split("_").join(" ").toLowerCase().trim().includes(trimmedName)
        ) {
          return ["0.8", "1.2"];
        }

        if (
          FEATURE_ONT.split("_")
            .join(" ")
            .toLowerCase()
            .trim()
            .includes(trimmedName) ||
          FEATURE_OLT.split("_")
            .join(" ")
            .toLowerCase()
            .trim()
            .includes(trimmedName) ||
          FEATURE_FACE_PLATE.split("_")
            .join(" ")
            .toLowerCase()
            .trim()
            .includes(trimmedName)
        ) {
          return ["1", "2", "3", "4"];
        }
      }

      return [];
    },
  },
  methods: {
    ...mapActions({
      uploadCustomerMaterials: "uploadCustomerMaterials",
      updateCustomerMaterial: "updateCustomerMaterial",
      createCustomerMaterial: "createCustomerMaterial",
      getMaterials: "getCustomerMaterials",
      getMaterialTypes: "getMaterialTypes",
    }),
    getMaterialType(material_type_id) {
      if (material_type_id) {
        let materialType = this.material_types.find(
          (material) => material.id == material_type_id
        );

        console.log(materialType);

        if (materialType) return materialType.type_name;

        return "";
      }

      return "";
    },
    uploadMaterials() {
      this.loading = true;

      var formData;
      if (this.is_excel_upload) {
        formData = new FormData();
        formData.append("upload_file", this.upload_file);
        formData.append("customer_id", this.$route.params.id);
      } else {
        formData = this.material;
        formData["customer_id"] = this.$route.params.id;

        if (this.sizes) {
          formData["sizes"] = this.sizes.toString();
        }
      }

      if (this.is_edit_mode) {
        this.updateCustomerMaterial(formData).then(() => {
          this.getMaterials(this.$route.params.id);
          this.resetFields();
          this.closeDialog();
        });
      } else {
        if (this.is_excel_upload) {
          this.uploadCustomerMaterials(formData).then(() => {
            this.getMaterials(this.$route.params.id);
            this.resetFields();
            this.closeDialog();
          });
        } else {
          this.createCustomerMaterial(formData).then(() => {
            this.getMaterials(this.$route.params.id);
            this.resetFields();
            this.closeDialog();
          });
        }
      }
    },
    resetFields() {
      this.upload_file = null;
      this.loading = false;
    },
    closeDialog() {
      this.$emit("resetEditModels");
      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.customer = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}

.full-width {
  width: 100%;
}
</style>