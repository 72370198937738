/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import {
  FEATURE_MEASUREMENT_AREA,
  FEATURE_MEASUREMENT_LENGTH,
  REDLINE_LINE,
  POLE_FIBER_CABLE,
  AREA_OF_INTEREST,
  FEATURE_FIBER_CABLE,
  FEATURE_DUCT,
  FEATURE_ODF,
  FEATURE_CABLE_HIGHLIGHT,
  HIGHLIGHT_FROM_ID,
  HIGHLIGHT_TO_ID,
  FEATURE_FACE_PLATE,
  FEATURE_ONT,
  FEATURE_OLT,
  FEATURE_FDP,
  FEATURE_CLOSURE,
  FEATURE_SPLITTER,
  featureSnapConfig,
  FEATURE_POLE,
  FEATURE_MANHOLE,
  FEATURE_BUILDING,
  FEATURE_SITE,
  FEATURE_CABINET,
  FEATURE_MICRO_TUNNEL,
  FEATURE_HANDHOLE,
  TRENCH,
  POLE_DUCT,
} from "../feature_constants";
import { v4 as uuidv4 } from "uuid";
import { Draw, Snap, Modify } from "ol/interaction";
import {
  getDragSelectedFeatures,
  getCompositionURLKey,
  getSnapFeatureJSON,
  getFeatureAtCoordinates,
  splitLargeDuct,
  getFeatureById,
  getHighlightedLineFeature,
  calculateLengthProperty,
  isCoordinatesEqual,
  splitTrench,
  splitFiberCable,
  splitLineFeature,
  getDerivedName,
  mergeLineFeatures,
  getFeaturesOnLineFeature,
  getFeaturesByType,
  getLocationFeatures,
} from "../../utils/map_functions";
import { getFeatureProps } from "../../utils/feature_properties";
import {
  getFeatureStyle,
  fromSegmentStyle,
  toSegmentStyle,
  thirdPartyFeatureStyle,
} from "../feature_styles";
import { mapActions } from "vuex";
import Collection from "ol/Collection";
import { LineString, Point } from "ol/geom";
import { lineSlice, feature } from "@turf/turf";

export default {
  /*
    1) Get duct
    2) Determine if it has start/end location features

    if 2 is true:
        3) Get all location features along duct
        4) Break duct into smaller similar ducts
        5) Save smaller ducts
        6) Add smaller ducts to map
        7) Remove larger, boken down duct
    else
    8) Save duct without modifications
    9) Add duct to map
    */

  methods: {
    extractDuctData(subDuct) {
      if (subDuct.duct) return this.geoJSON.readFeature(subDuct.duct);

      return subDuct;
    },

    async saveDuct(data) {
      /*
            Save duct(s) function
            */

      let featureCollection = [];
      let feature = data.feature;
      let payload = {};
      let splitter = data.splitter;
      let snapFeatures = data.snapFeatures;
      let featureProps = feature.getProperties();
      delete featureProps.geometry;
      if (featureProps.start) delete featureProps.start;
      if (featureProps.end) delete featureProps.end;

      payload.type = feature.get("type");
      if (feature.get("pk")) payload.pk = feature.get("pk");

      // check if this duct has start/end features
      let locFeatures = getLocationFeatures(feature, this.vectorSources);
      if (locFeatures.length >= 2) {
        /*
                Check if this duct spans multiple location features
                */
        var subDucts = splitLargeDuct(feature, snapFeatures);
        if (subDucts.length == 0 && splitter)
          subDucts = splitLineFeature(feature, splitter);

        if (subDucts.length > 1) {
          subDucts.forEach((subDuct) => {
            let duct = this.extractDuctData(subDuct);
            featureProps.geometry = duct.getGeometry();
            duct.setProperties(featureProps);
            duct.set("calculated_length", calculateLengthProperty(duct));
            duct.setId(uuidv4());

            featureCollection.push(duct);
          });
        }
      }

      if (featureCollection.length > 0) {
        payload.data = JSON.stringify(
          this.geoJSON.writeFeaturesObject(featureCollection)
        );
      } else {
        payload.data = JSON.stringify(this.geoJSON.writeFeatureObject(feature));
      }

      /*
            Create duct/ducts
            */
      try {
        let response = await this.createFeature(payload);
        if (response && response.pk) {
          if (!feature.get("pk")) {
            feature.set("pk", response.pk);
          }

          await this.onSavedDuct(response, feature, featureCollection);
        }
      } finally {
        /*
                Conclude feature saving operations
                */
        this.feature_saving = false;
        this.featureSaved = true;
        this.hideFeatureWindow();
      }
    },

    async mapDucts(featureCollection, response) {
      // add ducts to map with primary keys from the backend
      featureCollection.forEach((duct) => {
        let savedDuct = response.find((obj) => obj.uuid == duct.getId());
        if (savedDuct) duct.set("pk", savedDuct.pk);
        duct.set("calculated_length", calculateLengthProperty(duct));
        this.addFeatureToMap(duct);
      });
    },

    async onSavedDuct(
      response,
      feature = undefined,
      featureCollection = undefined
    ) {
      /*
            Executed after a duct/ducts are saved
            */

      await this.mapDucts(featureCollection, response);

      // remove prev feature if duct was split
      if (featureCollection.length > 0) {
        let deleteSilently = true;
        this.deleteFeature(feature, deleteSilently);

        // redraw ducts
        if (
          parseInt(feature.get("duct_type")) == 1 &&
          parseInt(feature.get("inner_ducts")) > 1
        ) {
          // redraw ducts to pull all the created ducts
          let ignoreZoom = "ignore_zoom";
          this.fetchFeatures(feature.get("type"), ignoreZoom);
        }
      }
    },
  },
};
