<template>
  <div>
    <v-card flat class="pb-3">
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <p :class="'black--text pl-3 pb-0 pt-0'">
            Click a series of ducts to blow a fiber cable
          </p>
          <v-layout row wrap>
            <!-- source -->
            <v-flex xs12>
              <p
                v-if="selected_ducts.length > 0"
                :class="'success--text pl-3 pb-0 pt-0 small-text'"
              >
                {{ getSelectedDucts() }} selected
              </p>
              <p v-else :class="'error--text pl-3 pb-0 pt-0'">
                No duct path selected
              </p>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn class="pl-3 ml-4" @click="clearSelection">Clear Selection</v-btn>
        <v-btn
          class="pl-3 ml-4"
          color="success"
          :disabled="!fiberCable"
          :loading="loading"
          @click="blowFiber"
          >{{ dialog_button_text }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../../utils/mixins";
import GeoJSON from "ol/format/GeoJSON";
import {
  FEATURE_BUILDING,
  FEATURE_SITE,
  FEATURE_MANHOLE,
  FEATURE_CABINET,
  FEATURE_DUCT,
  FEATURE_HANDHOLE,
  FEATURE_FIBER_CABLE,
} from "../../../../utils/feature_constants";
import {
  getDerivedName,
  mergeLineFeatures,
  getFeatureCopy,
} from "../../../../utils/map_functions";

export default {
  name: "BlowFiberManagerComponent",
  mixins: [dialogMixins],
  props: {
    map: {
      type: Object,
    },
    features: {
      type: Array,
    },
    vectorSources: {
      type: Array,
    },
    ductsLayer: {
      type: Object,
    },
    previous_connection: {
      type: Object,
    },
  },
  components: {},
  data: () => ({
    loading: false,
    dialog_button_text: "Blow Fiber Cable",
    valid: true,
    status: "",
    to_feature: null,
    to_feature_uuid: null,
    from_feature: null,
    from_feature_uuid: null,
    geoJSON: null,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    selected_ducts: [],
    selected_inner_ducts: [],
    fiberCable: null,
    f_added: false,
  }),
  mounted() {
    this.geoJSON = new GeoJSON();
    if (this.map) {
      this.map.on("singleclick", this.clickFeatureListener);
    }
  },
  destroyed() {
    this.map.un("singleclick", this.clickFeatureListener);
  },
  watch: {
    selected_ducts: {
      deep: true,
      handler() {
        if (this.selected_ducts.length > 0) {
          // construct highlighted feature
          var feature;
          for (let i = 0; i < this.selected_ducts.length; i++) {
            let duct = this.selected_ducts[i];
            if (!feature) feature = getFeatureCopy(duct);
            else feature = mergeLineFeatures(feature, duct);

            if (!feature) {
              // selected ducts are not adjacent, so display an error alert and break
              this.displayErrorAlert(
                "Select ducts that are adjacent to each other"
              );
              this.$emit("refreshComponents");
            }
          }

          if (feature) {
            this.fiberCable = feature;
            this.fiberCable.set("type", FEATURE_FIBER_CABLE);
            this.fiberCable.set("blown_cable", "1");
            this.$emit("highlightFeature", this.fiberCable);
          }
        }
      },
    },
  },
  computed: {
    features_selected: function () {
      if (this.local_features.length == 0) return "No features selected";
      return `${this.local_features.length} fearures selected`;
    },
    features_selected_class: function () {
      if (this.local_features.length == 0) return "error--text";
      return "primary--text";
    },
    filtered_features: function () {
      let filteredList = [];
      let validTypes = [
        FEATURE_MANHOLE,
        FEATURE_BUILDING,
        FEATURE_CABINET,
        FEATURE_SITE,
        FEATURE_HANDHOLE,
      ];

      this.local_features.forEach((feature) => {
        if (validTypes.includes(feature.get("type"))) {
          filteredList.push({
            text: getDerivedName(feature, this.vectorSources),
            value: feature.getId(),
          });
        }
      });

      return filteredList;
    },
  },
  methods: {
    ...mapActions({
      getRoute: "getDuctRoute",
      getFeature: "getFeature",
    }),

    getSelectedDucts() {
      var ductNames = "";
      this.selected_ducts.forEach((duct) => {
        if (ductNames == "")
          ductNames = getDerivedName(duct, this.vectorSources);
        else ductNames += `, ${getDerivedName(duct, this.vectorSources)}`;
      });

      return ductNames;
    },

    blowFiber() {
      if (this.fiberCable) {
        let payload = {};
        payload.fiberCable = this.fiberCable;
        payload.inner_ducts = this.selected_inner_ducts;
        this.$emit("blowFiberCable", payload);
        this.$emit("refreshComponents");
      } else {
        this.displayErrorAlert("Select a duct path to blow the cable");
      }
    },

    clearSelection() {
      this.selected_ducts = [];
      this.$emit("refreshComponents");
    },

    // get feature at this location and substitute for start/end feature
    async clickFeatureListener(event) {
      let locationFeatures = [FEATURE_DUCT];

      var coordinate = event.coordinate;
      var featureAdded = false;
      let pixel = this.map.getPixelFromCoordinate(coordinate);
      let featuresAtLoc = [];

      this.map.forEachFeatureAtPixel(
        pixel,
        function (feature, layer) {
          if (locationFeatures.includes(feature.get("type"))) {
            featuresAtLoc.push(feature);
          }
        },
        { hitTolerance: 10 } // use a large tolerence to allow a user to easily click ducts
      );

      // examine features at location
      if (featuresAtLoc.length > 0) {
        // check if this feature is not already clicked
        for (let key in featuresAtLoc) {
          let feature = featuresAtLoc[key];

          let exists = this.selected_ducts.find(
            (sFeature) => sFeature.getId() == feature.getId()
          );

          if (!exists && !featureAdded) {
            // check if this duct has under utilized
            let payload = {};
            payload.type = FEATURE_DUCT;
            payload.id = feature.get("pk");

            let duct = await this.getFeature(payload);
            console.log(duct);
            if (duct && parseInt(duct.next_free_inner_duct) > 0) {
              this.selected_inner_ducts.push(duct.next_free_inner_duct);
              this.selected_ducts.push(feature);
              featureAdded = true;
            }
          }
        }
      }

      if (!featureAdded) {
        this.displayErrorAlert(
          "All ducts at this segment are fully utilized, and blowing cannot continue."
        );
        this.selected_ducts = [];
        this.selected_inner_ducts = [];
        this.$emit("refreshComponents");
      }
    },

    displaySuccessAlert(message) {
      this.$notify({
        group: "success",
        title: "",
        text: message,
      });
    },

    displayErrorAlert(message) {
      this.$notify({
        group: "error",
        title: "",
        text: message,
      });
    },
  },
};
</script>

<style lang="css" scoped>
p.small-text {
  font-size: 13.5px !important;
}
</style>