<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent scrollable width="450px">
      <v-card class="flat pb-3" elevation="0">
        <v-card-title
          justify-center
          class="success white--text justify-center"
          >{{ dialog_title }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pa-4" style="height: 550px; overflow: hidden">
          <v-flex class="mt-3">
            <v-select
              dense
              v-model="feature_type"
              label="Feature Type"
              placeholder="Feature Type"
              :items="feature_types"
            ></v-select>
          </v-flex>

          <v-flex
            v-if="feature"
            class="mt-3"
            style="height: 90%; width: 100%; overflow: scroll"
          >
            <AttributesTable
              :selectedFeature="feature"
              :featureData="featureData"
              :vectorSources="vectorSources"
              :hide_delete_button="true"
            ></AttributesTable>
          </v-flex>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn
            color="success"
            :disabled="!feature"
            :loading="status == 'loading'"
            @click="execAddFeature"
            >{{ dialog_button_text }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { contentFeaturesConfig } from "../../../../utils/feature_constants";
import { getFeatureProps } from "../../../../utils/feature_properties";
import AttributesTable from "../AttributesTable";
import { Feature } from "ol";

export default {
  name: "AddFeatureDialogComponent",
  components: {
    AttributesTable,
  },
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    vectorSources: {
      type: Array,
    },
    parentFeature: {
      type: Object,
    },
    createdFeature: {
      type: Object,
    },
  },
  data: () => ({
    id: "",
    dialog: null,
    dialog_title: "Contents Feature",
    dialog_button_text: "Save",
    feature: null,
    featureData: null,
    feature_types: [],
    feature_type: null,
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.feature_types = contentFeaturesConfig[this.parentFeature.get("type")];
    if (this.createdFeature) {
      this.feature = this.createdFeature;
      this.feature_type = this.createdFeature.get("type");
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    execAddFeature() {
      this.feature.setProperties(this.featureData);
      this.$emit("saveFeature", this.feature);
      this.closeDialog();
    },
  },
  watch: {
    feature_type: function (val) {
      if (!this.createdFeature) {
        let geom = this.parentFeature.getGeometry();
        this.feature = new Feature(geom);
        this.feature.setProperties(getFeatureProps(val));
        this.feature.set("job_id", this.parentFeature.get("job_id"));
        this.feature.set("created_by", this.user.id);
      }

      this.featureData = this.feature.getProperties();
    },
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>