<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="success white--text justify-center">{{
        dialog_title
      }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="import_obj.layer_name"
                  :rules="rules.required_field"
                  placeholder="Import Layer Name"
                  label="Import Layer Name"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-select
                  v-model="import_obj.feature_type"
                  :rules="rules.required_field"
                  :items="import_types"
                  item-text="text"
                  item-value="value"
                  label="Import Feature Type"
                ></v-select>
              </v-layout>
            </v-flex>

            <v-flex xs12>
              <v-file-input
                class="pt-3"
                v-model="upload_file"
                :rules="rules.required_field"
                color="primary"
                counter
                label="Import File"
                placeholder="Select file"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip v-if="index < 2" color="success" dark label small>{{
                    text
                  }}</v-chip>
                  <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
                    >+{{ files.length - 2 }} File(s)</span
                  >
                </template>
              </v-file-input>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="execImport"
          >{{ dialog_button_text }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn :href="import_template_url" target="_blank" color="info"
          >Import Template</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-undef */

import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../utils/mixins";
import config from "../../../config";
import {
  IMPORT_EXPORT_EQUIPMENT,
  BLUE_PRINT_POINT,
  BLUE_PRINT_LINE,
} from "../../../utils/feature_constants";
import { getNameFromType } from "../../../utils/map_functions";

export default {
  name: "ImportFeaturesDialogComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "Import Features",
    dialog_button_text: "Import",
    valid: true,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    import_types: [],
    dialog: null,
    import_obj: {},
    upload_file: null,
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.import_obj = {};
    this.import_types = IMPORT_EXPORT_EQUIPMENT.map((item) => {
      return { value: item, text: getNameFromType(item) };
    });
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
    import_template_url: function () {
      return config.import_template_url;
    },
  },
  methods: {
    ...mapActions({
      importFeatures: "importFeatures",
    }),
    execImport() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        for (let field in this.import_obj) {
          if (this.import_obj[field]) {
            formData.append(field, this.import_obj[field]);
          }
        }

        // determine if we are importing blueprint data and just convert it to GEOJson
        if (
          [BLUE_PRINT_POINT, BLUE_PRINT_LINE].includes(
            this.import_obj.feature_type
          )
        ) {
          formData.append("convert_to_geojson", "True");
        }

        if (this.upload_file) {
          formData.append("file", this.upload_file);
          formData.append("upload_file", true);
        }

        formData.append("job", this.$route.params.job_id);
        formData.append("created_by", this.user.id);

        this.importFeatures(formData)
          .then((res) => {
            // reload layer of features that have been imported
            if (res.geojson) {
              // we are importing GEOJSON directly to map
              this.$emit("processBlueprintData", {
                geojson: res.geojson,
                import_type: this.import_obj.feature_type,
              });
            } else {
              this.$emit("resetFeatures", this.import_obj.feature_type);
              this.$emit("resetImportLayers", this.import_obj.feature_type);
            }

            this.$notify({
              group: "success",
              title: "Import",
              text: `Import successful`,
            });

            this.resetFields();
            this.closeDialog();
          })
          .catch(() => {
            this.$notify({
              group: "error",
              title: "Exception",
              text: `An exception occurred. Try again or ontact the system administrator`,
            });
            this.resetFields();
            this.closeDialog();
          });
      }
    },
    resetFields() {
      this.upload_file = null;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();

      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.export = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>