import axios from 'axios'
import appConfig from "../config"

// customer service resource name
let resource = appConfig.base_url + appConfig.customers_service_base_port + '/v1/customers_service/customers';

export default {
    post( payload, has_file = undefined ) {
        if ( has_file ) {
            return axios.post( `${ resource }/`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } )
                .then( response => {
                    return response.data;
                } )
        }
        else {
            return axios.post( `${ resource }/`, payload )
                .then( response => {
                    return response.data;
                } )
        }
    },

    get( requestParams ) {

        return axios.get( `${ resource }/`, {
            params: requestParams
        } )
            .then( response => {
                return response.data;
            } )
    },

    getOne( id ) {

        return axios.get( `${ resource }/${ id }/` )
            .then( response => {
                return response.data;
            } )
    },

    update( id, payload ) {
        return axios.patch( `${ resource }/${ id }/`, payload )
            .then( response => {
                return response.data;
            } )
    },

    patch( id, payload, has_file = undefined ) {
        if ( has_file ) {
            return axios.patch( `${ resource }/${ id }/`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } )
                .then( response => {
                    return response.data;
                } )
        }
        else {
            return axios.patch( `${ resource }/${ id }/`, payload )
                .then( response => {
                    return response.data;
                } )
        }
    },

    put( id, payload, has_file = undefined ) {
        if ( has_file ) {
            return axios.put( `${ resource }/${ id }/`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } )
                .then( response => {
                    return response.data;
                } )
        }
        else {
            return axios.patch( `${ resource }/${ id }/`, payload )
                .then( response => {
                    return response.data;
                } )
        }
    },

    delete( id ) {
        return axios.delete( `${ resource }/${ id }/` )
            .then( response => {
                return response.data;
            } )
    },
}