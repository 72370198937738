const PERMISSION_CAN_ADD_USERS = 1;
// const PERMISSION_CAN_REMOVE_USERS = 2
const PERMISSION_CAN_ADD_MATERIALS = 3;
// const PERMISSION_CAN_REMOVE_MATERIALS = 4
// const PERMISSION_CAN_VIEW_MATERIALS_PRICES = 5
const PERMISSION_CAN_ADD_CUSTOMERS = 6;
// const PERMISSION_CAN_REMOVE_CUSTOMERS = 7
const PERMISSION_IS_SUPER_USER = 8;
const PERMISSION_IS_CUSTOMER_ADMIN = 9;
// const PERMISSION_CAN_APPROVE_DRAWINGS = 10
const PERMISSION_CAN_MANAGE_PROJECTS = 11;

const devURL = "http://localhost";
const prodURL = "https://planning.soliton.co.ke";
const apiGatewayLocation =
  process.env.NODE_ENV == "development" ? ":8095" : "/api";
const base_url = process.env.NODE_ENV == "development" ? devURL : prodURL;
const frontend_service_port =
  process.env.NODE_ENV == "development" ? ":8080" : "";

export default {
  app_title: "Optics360",
  base_url: base_url,
  frontend_service_port: frontend_service_port,
  import_template_url: `${base_url}${frontend_service_port}/templates/point_features_template.xlsx`,

  customers_service_base_port: apiGatewayLocation,
  materials_service_base_port: apiGatewayLocation,
  user_service_base_port: apiGatewayLocation,
  projects_service_base_port: apiGatewayLocation,
  features_service_base_port: apiGatewayLocation,

  two_factor_auth_message:
    "2 factor authentication keeps your account more secure by verifying that it is indeed you logging in.\
     It will require you to provide a security token that is sent to your phone via SMS before granting access to your account.",

  toolbar_menu: [
    { id: "profile", text: "My Profile", url: "/profile" },
    { id: "logout", text: "Logout", url: "" },
  ],

  profile_page_sub_menus: [
    { text: "Account", link: "/profile" },
    { text: "Account Security", link: "/profile/security" },
    // { text: "Billing", link: "/profile/billing" },
    // { text: "Close Account", link: "/profile/close-account" }
  ],

  settings_page_sub_menus: [
    {
      text: "Users",
      link: "/settings/",
      user_roles: [PERMISSION_CAN_ADD_USERS, PERMISSION_IS_CUSTOMER_ADMIN],
    },
    {
      text: "User Groups",
      link: "/settings/user-groups",
      user_roles: [PERMISSION_IS_SUPER_USER],
    },
    {
      text: "LDAP Settings",
      link: "/settings/ldap-settings",
      user_roles: [PERMISSION_IS_SUPER_USER, PERMISSION_IS_CUSTOMER_ADMIN],
    },
    {
      text: "Data Uploads",
      link: "/settings/data-uploads",
      user_roles: [PERMISSION_IS_SUPER_USER],
    },
    {
      text: "Materials Types",
      link: "/settings/materials-types",
      user_roles: [PERMISSION_CAN_ADD_MATERIALS],
    },
    {
      text: "System Activity",
      link: "/settings/system-activity",
      user_roles: [PERMISSION_IS_SUPER_USER],
    },
  ],

  main_menu: [
    {
      action: "dashboard",
      title: "Dashboard",
      href: "/",
    },
    {
      action: "list",
      title: "Projects",
      href: "/customer-projects",
      user_roles: [PERMISSION_CAN_MANAGE_PROJECTS],
    },
    {
      action: "supervisor_account",
      title: "Customers",
      href: "/customers",
      user_roles: [PERMISSION_CAN_ADD_CUSTOMERS],
    },
    // {
    //     action: 'supervisor_account',
    //     title: 'Account Users',
    //     href: '/account_users',
    //     is_customer_route: true,
    //     user_roles: [ PERMISSION_IS_CUSTOMER_ADMIN ]
    // },
    {
      action: "settings",
      title: "System Settings", // user groups [ Super Admin, NOC, Finance, Management, Documentation, Planning and Design ]
      href: "/settings",
      user_roles: [PERMISSION_IS_SUPER_USER, PERMISSION_IS_CUSTOMER_ADMIN],
    },
  ],

  profile_menu: [
    {
      action: "account_circle",
      title: "My Account",
      href: "/profile",
    },
  ],
};
