<template>
  <v-layout class="pa-0 ma-0" style="position: relative">
    <v-btn
      v-if="!hide_delete_button"
      @click="dialog = !dialog"
      small
      color="error"
      absolute
      right
      outlined
      class="mt-1"
    >
      <v-icon small left>delete</v-icon>Delete
    </v-btn>

    <v-data-table
      style="width: 100%"
      class="small-text"
      :headers="properties_header"
      :items="items"
      :items-per-page="100"
      :loading="
        status == 'loading' ||
        feature_saving ||
        usersStatus == 'loading' ||
        !this.customer
      "
      hide-default-footer
    >
      <template v-slot:[`item.value`]="{ item }">
        <v-select
          dense
          v-if="selectOptions && selectOptions[item.key]"
          :items="objectToOptionsArray(selectOptions[item.key])"
          v-model="featureData[item.key]"
          @change="selectValueChanged"
        ></v-select>

        <!-- check if this is an API data item -->
        <v-select
          dense
          v-else-if="api_keys.includes(item.key)"
          :items="opticalDevices"
          v-model="featureData[item.key]"
          item-text="name"
          item-value="id"
          @change="selectValueChanged"
        ></v-select>

        <v-select
          dense
          v-else-if="materials_key.includes(item.key)"
          :items="materialsList"
          item-text="material_name"
          item-value="id"
          v-model="featureData[item.key]"
          @change="selectValueChanged"
        ></v-select>

        <v-text-field
          v-else
          :disabled="shouldDisableField(item.key)"
          dense
          :type="getFieldType(item.key)"
          v-model="featureData[item.key]"
          @change="textValueChanged"
        ></v-text-field>
      </template>
    </v-data-table>

    <DeleteDialog
      :dialogModel="dialog"
      deleteMessage="Are you sure you want to delete this feature?"
      :model="feature"
      @deleteConfirmed="deleteFeature"
      @closeDialog="closeDialog"
    ></DeleteDialog>
  </v-layout>
</template>

<script>
/* eslint-disable no-unused-vars */

import { getFeatureProps } from "../../../utils/feature_properties";
import { mapState, mapActions } from "vuex";
import { getSelectOptions } from "../../../utils/feature_properties";
import DeleteDialog from "../../generic/ConfirmDeleteDialog";
import dialogMixins from "../../../utils/mixins";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import {
  lineSlice,
  lineString as turfLineString,
  point as turfPoint,
} from "@turf/turf";
import GeoJSON from "ol/format/GeoJSON";
import {
  FEATURE_FIBER_CABLE,
  FIBER_CABLE_FEATURES,
  FEATURE_DUCT,
  FEATURE_MANHOLE,
  FEATURE_ODF,
  FEATURE_ONT,
  FEATURE_SPLITTER,
  FEATURE_FACE_PLATE,
  FEATURE_FDP,
  FEATURE_CLOSURE,
  FEATURE_CABINET,
  POLE_DUCT,
  POLE_FIBER_CABLE,
} from "../../../utils/feature_constants";
import {
  getFeatureById,
  getFeatureAtCoordinates,
  splitLargeDuct,
  getDerivedName,
  formatLength,
  calculateLengthProperty,
  getFeaturesByType,
} from "../../../utils/map_functions";

export default {
  name: "FeatureAttriutesTableComponent",
  components: { DeleteDialog },
  props: {
    opticalDevices: Array,

    selectedFeature: {
      type: Object,
    },
    featureData: {
      type: Object,
    },
    vectorSources: {
      type: Array,
    },
    feature_saving: {
      type: Boolean,
    },
    hide_delete_button: {
      type: Boolean,
    },
  },
  mixins: [dialogMixins],
  data: () => ({
    properties_header: [
      {
        text: "Property",
        value: "property",
        sortable: false,
      },
      {
        text: "Value",
        value: "value",
        sortable: false,
      },
    ],
    automated_name_features: ["MANHOLE", "HANDHOLE"],
    feature: null,
    featureProps: {},
    isPointFeature: false,
    items: [],
    input_port_features: [
      FEATURE_ODF,
      FEATURE_ONT,
      FEATURE_FACE_PLATE,
      FEATURE_FDP,
    ],
    output_port_features: [
      FEATURE_ODF,
      FEATURE_ONT,
      FEATURE_SPLITTER,
      FEATURE_FACE_PLATE,
      FEATURE_FDP,
    ],
    capacity_features: [FEATURE_CLOSURE],
    cable_features: [FEATURE_FIBER_CABLE],
    size_features: [FEATURE_CABINET],
    skip_keys: [
      "latitude",
      "longitude",
      "start",
      "end",
      "_name",
      "f_name",
      "job_state",
      "type",
    ],
    line_feature_skip_keys: [
      "latitude",
      "longitude",
      "start",
      "end",
      "_name",
      "f_name",
      "job_state",
      "type",
    ],
    api_keys: ["attached_rtu"],
    point_feature_skip_keys: ["_name", "f_name", "job_state", "type"],
    mandatory_keys: ["customer_material"],
    disabled_keys: [
      "calculated_length",
      "physical_length",
      "creation_user",
      "type",
      "trench_distance",
      "start",
      "end",
      "id",
    ],
    number_keys: ["calculated_length"],
    date_keys: ["install_date"],
    selectOptions: null,
    materials_key: ["customer_material"],
    customer: null,
  }),
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      users: (state) => state.usersModule.users,
      status: (state) => state.featuresModule.status,
      customer_prefixes: (state) => state.featuresModule.customer_prefixes,
      job_prefixes: (state) => state.featuresModule.job_prefixes,
      usersStatus: (state) => state.usersModule.status,
      materialTypes: (state) => state.materialsModule.material_types,
      customerMaterials: (state) => state.materialsModule.customer_materials,
      customers: (state) => state.customersModule.customers,
    }),
    materialsList: function () {
      var featureType = this.convertKeyToName(this.feature.get("type"))
        .toLowerCase()
        .trim();

      // if feature type us pole duct or pole cable, change it to duct and fiber cable
      if (this.feature.get("type") == POLE_DUCT) featureType = "duct";
      if (this.feature.get("type") == POLE_FIBER_CABLE)
        featureType = "fiber cable";

      let materials = this.customerMaterials.filter((material) => {
        if (material.type_name) {
          let materialName = material.type_name.toLowerCase().trim();
          return materialName.includes(featureType);
        }

        return "";
      });

      return materials;
    },
  },

  mounted() {
    if (this.users.length == 0 || this.customers.length == 0) {
      this.getUsers().then(() => {
        this.getCustomers().then((customers) => {
          this.setCustomer(customers);
          this.preRenderFeature();
        });
      });
    } else {
      this.setCustomer(this.customers);
      this.preRenderFeature();
    }
  },

  methods: {
    shouldDisableField(fieldKey) {
      let lengthKey = ["calculated_length"];
      let exceptedFeatures = [POLE_DUCT, POLE_FIBER_CABLE];
      if (
        fieldKey == lengthKey &&
        exceptedFeatures.includes(this.feature.get("type"))
      ) {
        return false;
      }

      return this.disabled_keys.includes(fieldKey);
    },
    setCustomer(customers) {
      this.customer = customers.find(
        (customer) => customer.id == this.$route.params.id
      );
    },
    /**
     * Whenever any data changes on this object, we emit a custom event to display the save
     */
    getFieldType(key) {
      if (this.date_keys.includes(key)) return "date";
      if (this.number_keys.includes(key)) return "number";

      return "text";
    },

    selectValueChanged() {
      this.configMaterial();
      this.$emit("featureDataChange");
    },

    textValueChanged() {
      this.$emit("featureDataChange");
    },

    ...mapActions({
      getFeature: "getFeature",
      getFiberSegmentFeatures: "getFiberCableSegmentFeatures",
      getCompositionFeatures: "getCompositionFeatures",
      getDuctSegmentFeatures: "getDuctSegmentFeatures",
      getUsers: "getUsers",
      getCustomers: "getCustomers",
    }),

    convertKeyToName(key) {
      // rename calculated_length to optical distance in UI
      if (key == "is_3rd_party") return String("3rd party").toUpperCase();
      if (
        key == "calculated_length" &&
        this.feature.get("type") == FEATURE_FIBER_CABLE
      )
        return String("optical length").toUpperCase();

      return key.split("_").join(" ").toUpperCase();
    },

    objectToOptionsArray(object) {
      let options = [];
      for (let key in object) {
        options.push({
          text: object[key],
          value: key,
        });
      }

      return options;
    },

    deleteFeature(feature) {
      this.$emit("deleteFeature", feature);
    },

    preRenderFeature() {
      this.feature = this.selectedFeature;

      if (this.selectedFeature.get("pk")) {
        let payload = {};
        payload.type = this.selectedFeature.get("type");
        payload.id = this.selectedFeature.get("pk");
        this.getFeature(payload).then((feature) => {
          // check for variables of interest:
          // 1: slack_length
          if (feature && feature.slack_length) {
            this.feature.set("slack_length", feature.slack_length);
          }

          this.renderFeature();
        });
      } else {
        this.renderFeature();
      }
    },

    async renderFeature() {
      this.selectOptions = getSelectOptions(this.feature.get("type"));
      let coordinates = this.feature.getGeometry().getCoordinates();
      this.isPointFeature = this.feature.getGeometry() instanceof Point;
      this.isLineFeature = this.feature.getGeometry() instanceof LineString;

      let featureProps = getFeatureProps(this.feature.get("type"));

      for (let key in featureProps) {
        var propValue;
        if (key == "creation_user") {
          if (this.feature.get("created_by")) {
            let creationUser = this.users.find(
              (user) => user.id == parseInt(this.feature.get("created_by"))
            );
            if (creationUser) propValue = creationUser.name;
          } else {
            propValue = this.user.name;
          }
        } else if (key == "name") {
          propValue = getDerivedName(this.feature, this.vectorSources);
          if (
            this.automated_name_features.includes(propValue) &&
            this.automated_name_features.includes(this.feature.get("type"))
          ) {
            // get automated name prefix + number, eg MH1/HH2
            var prefixes;
            if (this.job_prefixes && this.job_prefixes.length > 0)
              prefixes = this.job_prefixes;
            if (
              this.customer_prefixes &&
              this.customer_prefixes.length > 0 &&
              !prefixes
            )
              prefixes = this.customer_prefixes;

            if (prefixes && prefixes.length > 0) {
              let prefix_obj = prefixes.find(
                (prefix) => prefix.feature_type == this.feature.get("type")
              );
              if (prefix_obj && prefix_obj.prefix) {
                // set the name to the prefix
                let totalFeatures = getFeaturesByType(
                  this.feature.get("type"),
                  this.vectorSources
                );

                let count =
                  totalFeatures && totalFeatures.length
                    ? totalFeatures.length
                    : 1;
                propValue = `${prefix_obj.prefix}${count + 1}`;
              }
            }
          }
        } else if (key == "calculated_length" || key == "trench_distance")
          propValue = calculateLengthProperty(this.feature);
        else if (key == "calculated_length" || key == "trench_distance")
          propValue = calculateLengthProperty(this.feature);
        else if (key == "id") {
          if (this.feature.get("pk")) {
            let pk = this.feature.get("pk");
            if (this.feature.get("type") == FEATURE_FIBER_CABLE)
              propValue = `FIB${pk}`;
            else propValue = `DCT${pk}`;
          }
        } else if (this.isPointFeature && key == "latitude")
          propValue = coordinates[1];
        else if (this.isPointFeature && key == "longitude")
          propValue = coordinates[0];
        else if (key == "owner") {
          if (this.feature.get(key) == "") {
            propValue = this.customer.customer_name;
          } else propValue = this.feature.get(key);
        } else {
          propValue =
            this.feature.get(key) && this.feature.get(key) != ""
              ? this.feature.get(key)
              : featureProps[key];
        }

        //only add lat,lon if this is a point feature
        if (
          (this.isLineFeature && !this.line_feature_skip_keys.includes(key)) ||
          (this.isPointFeature && !this.point_feature_skip_keys.includes(key))
        ) {
          let item = this.items.find((item) => item.key == key);
          if (!item) {
            this.items.push({
              property: this.convertKeyToName(key),
              key: key,
              value: propValue,
            });
          } else {
            // only modify the value
            item.value = propValue;
          }

          // add value to main model
          this.featureData[key] = propValue;
        }
      }
    },

    configMaterial() {
      // check/set size/capacity based on customer material if set
      if (this.featureData["customer_material"]) {
        let material = this.customerMaterials.find(
          (material) => material.id == this.featureData["customer_material"]
        );
        if (material && material.sizes) {
          if (this.input_port_features.includes(this.feature.get("type"))) {
            this.featureData["input_ports"] = material.sizes;
            this.feature.set("input_ports", material.sizes);
          }

          if (this.output_port_features.includes(this.feature.get("type"))) {
            this.featureData["output_ports"] = material.sizes;
            this.feature.set("output_ports", material.sizes);
          }

          if (this.capacity_features.includes(this.feature.get("type"))) {
            this.featureData["capacity"] = material.sizes;
            this.feature.set("capacity", material.sizes);
          }

          if (this.size_features.includes(this.feature.get("type"))) {
            this.featureData["size"] = material.sizes;
            this.feature.set("size", material.sizes);
          }

          if (this.cable_features.includes(this.feature.get("type"))) {
            this.featureData["cable_size"] = material.sizes;
            this.feature.set("cable_size", material.sizes);
          }

          // rerender the feature
          this.renderFeature();
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
.v-data-table td * {
  font-size: 13.5px !important;
}
</style>