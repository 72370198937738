<template>
  <v-dialog v-model="dialog" persistent width="850px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="customer.customer_name"
                  :rules="rules.required_field"
                  placeholder="Customer Name"
                  label="Customer Name"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs8 class="mt-3 pr-3">
              <v-text-field
                v-model="customer.email"
                :rules="rules.email_field"
                label="Email Address"
                placeholder="Email Address"
              ></v-text-field>
            </v-flex>

            <v-flex class="pl-3 mt-3" xs4>
              <v-text-field
                v-model="customer.phone_number"
                :rules="rules.phone_field"
                label="Phone Number"
                placeholder="Pohne Number (2547xxxxxxxx)"
              ></v-text-field>
            </v-flex>

            <v-flex xs8 class="mt-3 pr-3">
              <v-text-field
                v-model="customer.location"
                :rules="rules.required_field"
                label="Location"
                placeholder="Location"
              ></v-text-field>
            </v-flex>

            <v-flex class="pl-3 mt-3" xs4>
              <v-text-field v-model="customer.country" label="Country" placeholder="Country"></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-file-input
                v-model="upload_file"
                color="primary"
                counter
                label="Logo"
                placeholder="Select logo file"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip v-if="index < 2" color="primary" dark label small>{{ text }}</v-chip>
                  <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
                  >+{{ files.length - 2 }} File(s)</span>
                </template>
              </v-file-input>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="loading"
          @click="createOrEditCustomer"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import dialogMixins from "../../../utils/mixins";

export default {
  name: "CustomerDialog",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    editModel: {
      type: Object,
    },
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "Add Customer",
    dialog_button_text: "Create",
    valid: true,
    rules: {
      email_field: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Field should be a valid email",
      ],
      required_field: [(v) => !!v || "Field is required"],
      phone_field: [(v) => v.startsWith("2547") || "Add a valid phone number"],
    },
    dialog: null,
    customer: {},
    upload_file: null,
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.customer = {};

    if (this.editModel) {
      this.is_edit_mode = true;

      Object.assign(this.customer, this.editModel);
      this.dialog_title = "Edit Customer";
      this.dialog_button_text = "Update";
    }
  },
  methods: {
    ...mapActions({
      createCustomer: "createCustomer",
      updateCustomer: "updateCustomer",
    }),
    createOrEditCustomer() {
      let formData = new FormData();
      for (let field in this.customer) {
        // remove the logo, just in case we're editing this customer
        if (field != "logo") {
          if (this.customer[field]) {
            formData.append(field, this.customer[field]);
          }
        }
      }

      if (this.upload_file) {
        formData.append("logo", this.upload_file);
        formData.append("upload_file", true);
      }

      if (this.is_edit_mode) {
        this.updateCustomer(formData);
      } else {
        this.createCustomer(formData);
      }

      this.resetFields();
      this.closeDialog();
    },
    resetFields() {
      this.customer = {};
      this.is_edit_mode = false;
      this.upload_file = null;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();

      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.customer = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>