<template>
  <div class="text-center" data-app>
    <v-dialog
      :class="customClasses"
      persistent
      @input="$emit('close-dialog')"
      v-model="dialog"
      :width="default_width"
      :fullscreen="fullscren"
    >
      <slot></slot>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogModel: Boolean,
    width: Number,
    fullScreen: Boolean,
    customClasses: String,
  },
  mounted() {
    if (this.width) this.default_width = this.width;
  },
  data: () => ({
    default_width: 650,
  }),
  computed: {
    dialog: function () {
      if (this.dialogModel) return this.dialogModel;

      return false;
    },
    fullscren: function () {
      if (this.fullScreen) return this.fullScreen;

      return false;
    },
  },
};
</script>

<style scoped>
.force-overflow {
  overflow-y: scroll !important;
}
</style>