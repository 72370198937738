import { mapActions } from "vuex";

export default {
  data: () => ({
    job_prefixes: [],
    customer_prefixes: [],
  }),
  mounted() {
    if (this.$route.params.id) {
      this.getPrefixes({ customer: this.$route.params.id }).then(
        (res) => (this.customer_prefixes = res.results)
      );
    }

    if (this.$route.params.job_id) {
      this.getPrefixes({ job: this.$route.params.job_id }).then(
        (res) => (this.job_prefixes = res.results)
      );
    }
  },
  methods: {
    ...mapActions({
      getPrefixes: "getPrefixes",
    }),
  },
};
