<template>
  <v-dialog fullscreen v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ group.group_name }} - Permissions</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          @click="permissions_dialog = !permissions_dialog"
          color="white"
          class="primary--text"
          absolute
          top
          right
        >
          <v-icon left color="primary">add</v-icon>Add Permissions
        </v-btn>
      </v-toolbar>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-data-table
                  :headers="headers"
                  :items="group_permissions"
                  :loading="status == 'loading'"
                  class="elevation-1"
                  style="margin-top: 15px !important; width: 100%"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.id">
                        <td>{{ item.permission_name }}</td>
                        <td class="clickable error--text" @click="deletePermission(item)">REMOVE</td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>

        <div v-if="permissions_dialog">
          <ConfigPermissionsDialog
            @closePermissionsDialog="closePermissionsDialog"
            :group="group"
            :dialogModel="permissions_dialog"
          ></ConfigPermissionsDialog>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../utils/mixins";
import ConfigPermissionsDialog from "./ConfigPermissionsDialog";

export default {
  name: "UserGroupDialog",
  components: { ConfigPermissionsDialog },
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true
    },
    group: {
      type: Object
    }
  },
  data: () => ({
    attached_permissions: [],
    loading: false,
    headers: [
      {
        text: "Attached Permissions",
        align: "left",
        value: "permission_name",
        sortable: false
      },
      {
        text: "Action",
        align: "right",
        sortable: false
      }
    ],
    id: "",
    is_edit_mode: false,
    dialog_title: "Manage Group Permissions",
    dialog_button_text: "Attach",
    valid: true,
    rules: {
      required_field: [v => !!v || "Field is required"]
    },
    dialog: null
  }),
  computed: {
    ...mapState({
      status: state => state.permissionsModule.status,
      permissions: state => state.permissionsModule.permissions,
      group_permissions: state => state.permissionsModule.group_permissions
    })
  },
  mounted() {
    this.dialog = this.dialogModel;
    this.getGroupPermissions(this.group.id).then(res => console.log(res));
  },
  methods: {
    ...mapActions({
      createUserGroup: "createUserGroup",
      editUserGroup: "editUserGroup",
      getGroupPermissions: "getGroupPermissions",
      deleteGroupPermission: "deleteGroupPermission"
    }),
    deletePermission(item) {
      this.deleteGroupPermission(item.id).then(() =>
        this.getGroupPermissions(this.group.id)
      );
    },
    closeDialog() {
      this.$emit("resetEditModel");
      this.$emit("closeDialog");
    }
  },
  watch: {
    dialogModel: function(val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function(val) {
      if (!val) {
        this.closeDialog();
      }
    }
  }
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>