<template>
  <v-card flat>
    <v-card-title>
      <h4>Materials List</h4>

      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Search materials"></v-text-field>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :search="search"
        :loading="status == 'loading'"
        :headers="headers"
        :items="materials"
      >
        <template v-slot:item.uom="{item}">
          <p class="ma-0 pa-0" v-if="item.uom == 1">PIECE</p>
          <p class="ma-0 pa-0" v-else>METERS</p>
        </template>

        <template v-slot:item.action="{item}" style="width: 100px">
          <v-icon small color="primary" class="pr-3" @click="editItem(item)">edit</v-icon>
          <v-icon small color="error" @click="deleteItem(item)">mdi-delete</v-icon>

          <!-- <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small color="primary" class="pl-3">more_horiz</v-icon>
            </template>
            <v-list dense>
              <v-list-item v-for="(menuItem, index) in items" :key="index">
                <v-list-item-title
                  class="discontinue-item"
                  @click="discontinueItem(item)"
                >{{ menuItem.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>-->
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "MaterialsListComponent",
  props: {
    customer_id: {
      type: Number
    }
  },
  data: () => ({
    search: "",
    items: [{ title: "Discontinue" }],
    headers: [
      {
        text: "Material Name",
        value: "material_name"
      },
      {
        text: "Type",
        value: "type_name"
      },
      {
        text: "UOM",
        value: "uom"
      },
      {
        text: "Price",
        value: "material_cost"
      },
      {
        text: "More",
        value: "action"
      }
    ]
  }),
  mounted() {
    if (this.materials.length == 0)
      this.getCustomerMaterials(this.$route.params.id);
  },
  computed: {
    ...mapState({
      materials: state => state.materialsModule.customer_materials,
      status: state => state.materialsModule.status
    })
  },
  methods: {
    ...mapActions({
      getCustomerMaterials: "getCustomerMaterials"
    }),
    discontinueItem(item) {
      console.log(item, "Discontinued");
    },
    editItem(item) {
      this.$emit("editMaterial", item);
    },
    deleteItem(item) {
      this.$emit("deleteMaterial", item);
    }
  }
};
</script>

<style lang="css" scoped>
.discontinue-item {
  cursor: pointer;
}
</style>