<template>
  <v-card flat>
    <v-card-title>
      <h4 v-if="title != undefined">{{ title }}</h4>

      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Search users"></v-text-field>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :search="search"
        :loading="loading == 'loading'"
        v-if="customer_id != undefined"
        :items="customer_users"
        :headers="headers"
        class="linked-table"
        @clicked:row="handleClick"
      >
        <template v-slot:item.action="{ item }">
          <router-link
            :to="
              '/customers/' +
              customer_id +
              '/details/' +
              item.id +
              '/user_details'
            "
          >
            <v-icon class="primary--text">chevron_right</v-icon>
          </router-link>
        </template>
      </v-data-table>

      <v-data-table
        :loading="loading == 'loading'"
        v-else
        :items="users_list"
        :headers="headers"
        class="linked-table"
        @clicked:row="handleClick"
      >
        <template v-slot:item.action="{ item }">
          <router-link :to="'/settings/users/' + item.id + '/details'">
            <v-icon class="primary--text">chevron_right</v-icon>
          </router-link>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "UsersListComponent",
  props: {
    customer_id: {
      type: Number,
    },
    title: {
      type: String,
    },
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Phone Number",
        value: "phone_number",
      },
      {
        text: "User Type",
        value: "user_type_text",
      },
      {
        text: "More",
        value: "action",
      },
    ],
  }),
  computed: {
    ...mapState({
      customer_users: (state) => state.usersModule.customer_users,
      users: (state) => state.usersModule.users,
      loggedinUser: (state) => state.usersModule.current_user,
      loading: (state) => state.usersModule.status,
      users_list: function () {
        return this.users.filter((user) => user.id != this.loggedinUser.id);
      },
    }),
  },
  mounted() {
    if (this.customer_id) {
      if (this.customer_users.length == 0) this.getCustomerUsers();
    } else {
      if (this.users.length == 0) this.getUsers();
    }
  },
  methods: {
    ...mapActions({
      getCustomerUsers: "getCustomerUsers",
      getUsers: "getUsers",
    }),
    handleClick(item) {
      if (this.customer_id) {
        this.$router.push(
          "/customers/" +
            this.customer_id +
            "/details/" +
            item.id +
            "/user_details"
        );
      } else {
        this.$router.push("/settings/users/" + item.id + "/details");
      }
    },
  },
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none;
}
</style>