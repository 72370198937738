<template>
  <v-layout>
      <v-flex>
        <h1 class="mb-3">{{ page_title }}</h1>
        <!-- v-card start -->
        <v-card flat class="content-card">
        <v-flex class="content-card-title" xs12>Upgrade in progress</v-flex>
        <v-layout wrap class="form-layout-border">
            <v-flex xs12>
            <v-card flat class="pb-3 pl-0">
                <div
                class="grey--text mt-5"
                >
                    <p class="pa-3 black--text">
                        This page is still under construction. Please check back later.
                    </p>
                </div>
            </v-card>
            </v-flex>
        </v-layout>
        </v-card>
      </v-flex>
  </v-layout>
</template>

<script>
    export default {
        props: {
            page_title: {
                type: String
            }
        }
    }
</script>