<template>
  <v-dialog v-model="dialog" persistent width="350px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <div v-if="loading != 'success'">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-layout align-center>
                  <v-text-field
                    v-model="password"
                    :rules="rules.min_characters"
                    label="Password"
                    type="password"
                    placeholder="Password"
                  ></v-text-field>
                </v-layout>
              </v-flex>

              <v-flex xs12 align-center justify-space-between class="mt-3">
                <v-layout align-center>
                  <v-text-field
                    v-model="confirm_password"
                    :rules="rules.required_field"
                    type="password"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                  ></v-text-field>
                </v-layout>
              </v-flex>

              <v-flex v-if="show_error" xs12 align-center justify-space-between>
                <p class="error--text">{{ error_message }}</p>
              </v-flex>
            </v-layout>
          </div>

          <div v-if="loading == 'success'">
            <v-layout row wrap>
              <v-flex
                xs12
                align-center
                justify-center
                class="justify-center"
                style="text-align: center; display: block"
              >
                <v-icon large class="mb-3" color="success">offline_pin</v-icon>

                <p class="success--text">Password reset successful</p>
              </v-flex>
            </v-layout>
          </div>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn v-if="loading != 'success'" @click="closeDialog">Cancel</v-btn>

        <v-btn
          v-if="loading != 'success'"
          color="primary"
          :disabled="!valid"
          :loading="loading == 'loading'"
          @click="execResetPassword"
        >{{ dialog_button_text }}</v-btn>

        <v-btn v-if="loading == 'success'" color="success" @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "ResetPasswordDialog",
  props: {
    user_id: {
      type: Number
    },
    dialogModel: {
      type: Boolean,
      required: true
    },
    editableEntity: {
      type: Object
    }
  },
  data: () => ({
    error_message: "",
    show_error: false,
    show_success: false,
    in_progress: false,
    dialog_title: "Reset Password",
    dialog_button_text: "Reset",
    valid: true,
    rules: {
      required_field: [v => !!v || "This field is required"],
      min_characters: [v => v.length >= 8 || "Min 8 characters"]
    },
    dialog: null,
    password: "",
    confirm_password: ""
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.setUserStatus("");
  },
  computed: {
    ...mapState({
      loading: state => state.usersModule.status
    })
  },
  methods: {
    ...mapActions({
      resetPassword: "resetPassword"
    }),
    ...mapMutations({
      setUserStatus: "setUserStatus"
    }),
    execResetPassword() {
      if (this.$refs.form.validate()) {
        if (this.password == this.confirm_password) {
          let user_id =
            this.$route.params.user_id != undefined
              ? this.$route.params.user_id
              : this.$route.params.company_user_id;
          let apiData = {
            id: user_id,
            password: this.password,
            confirm_password: this.confirm_password
          };

          this.resetPassword(apiData);
        } else {
          this.showError("Passwords must match");
        }
      }
    },
    showError(msg) {
      this.error_message = msg;
      this.show_error = true;

      setTimeout(() => {
        this.error_message = "";
        this.show_error = false;
      }, 3000);
    },
    closeDialog() {
      this.show_error = false;
      this.password = "";
      this.confirm_password = "";
      this.$emit("closeDialog");
    }
  },
  watch: {
    dialogModel: function(val) {
      this.dialog = val;
    },

    loading: function(val) {
      if (val == "error") {
        this.showError(
          "Operation failed. Make sure you have the required permissions for this action."
        );
      }
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function(val) {
      if (!val) {
        this.closeDialog();
      }
    }
  }
};
</script>

<style scoped>
</style>