/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import Overlay from "ol/Overlay";
import { mapActions } from "vuex";
import {
  FAULT,
  FEATURE_BUILDING,
  FEATURE_CABINET,
  FEATURE_CLOSURE,
  FEATURE_DUCT,
  FEATURE_FDP,
  FEATURE_FIBER_CABLE,
  FEATURE_HANDHOLE,
  FEATURE_MANHOLE,
  FEATURE_ODF,
  FEATURE_POLE,
  FEATURE_SITE,
  FEATURE_SPLITTER,
} from "../feature_constants";
import { getCompositionURLKey, getDerivedName } from "../map_functions";

export default {
  data: () => ({
    location_features: [],
    overlay: null,
    overlay_container: null,
    overlay_content: null,
    overlay_closer: null,
  }),

  mounted() {
    this.overlay_container = document.getElementById("popup");
    this.overlay_content = document.getElementById("popup-content");
    this.overlay_closer = document.getElementById("popup-closer");

    /**
     * Create an overlay to anchor the popup to the map.
     */
    this.overlay = new Overlay({
      element: this.overlay_container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    /**
     * Add a click handler to hide the popup.
     * @return {boolean} Don't follow the href.
     */
    let selfRef = this;
    this.overlay_closer.onclick = function() {
      selfRef.overlay.setPosition(undefined);
      selfRef.overlay_closer.blur();
      return false;
    };
  },

  watch: {
    location_features: {
      deep: true,
      handler() {
        this.displayFeatureContents();
      },
    },
  },

  methods: {
    overlayClickListener(event) {
      // only activate overlay if we are not in edit mode
      if (
        !this.is_edit_mode &&
        !this.blowFiberManagerVisible &&
        !this.splice_schematics_visible
      ) {
        this.location_features = [];
        let locationFeatures = [
          FEATURE_BUILDING,
          FEATURE_SITE,
          FEATURE_CABINET,
          FEATURE_MANHOLE,
          FEATURE_HANDHOLE,
          FEATURE_POLE,
          FEATURE_DUCT,
          FEATURE_FIBER_CABLE,
          FAULT,
        ];
        var coordinate = event.coordinate;
        let pixel = this.map.getPixelFromCoordinate(coordinate);

        let selfRef = this;
        this.map.forEachFeatureAtPixel(pixel, function(feature, layer) {
          if (locationFeatures.includes(feature.get("type"))) {
            selfRef.location_features.push(feature);
            selfRef.overlay.setPosition(coordinate);
          }
        });
      }
    },

    ...mapActions({
      getCompositionFeatures: "getCompositionFeatures",
      getFeature: "getFeature",
    }),

    async displayFeatureContents() {
      // init with feature name
      this.overlay_content.innerHTML = "...loading...";

      let displayHTML = `<div class="feature-display-info"><!-- Feature Template -->`;

      for (let i = 0; i < this.location_features.length; i++) {
        const feature = this.location_features[i];
        const featureName = getDerivedName(feature, this.vectorSources);
        const featureType = feature.get("type");
        if (featureType == FAULT) {
          displayHTML += `<div class="feature-template"><p class="feature-name">Network Fault</p>`;
          displayHTML += `<div class="compostion-list"><p>Location</p><ul>`;
          displayHTML += `<li>Longitude: ${
            feature.getGeometry().getCoordinates()[0]
          }</li>`;
          displayHTML += `<li>Latitude: ${
            feature.getGeometry().getCoordinates()[1]
          }</li>`;
          displayHTML += `</ul></div>`;
        } else {
          displayHTML += `<div class="feature-template"><p class="feature-name">${featureType} - ${featureName}</p>`;
          let attached_features = await this.loadCompositionFeatures(feature);
          if (attached_features.length > 0) {
            displayHTML += `<div class="compostion-list"><p>Composition</p><ul>`;

            let ctr = attached_features.length - 1;
            while (ctr >= 0) {
              let feature = attached_features[ctr];

              // get usage info for ODF only
              if (feature.feature_type == FEATURE_ODF) {
                let usageInfo = await this.getUsageInformation(
                  feature.pk,
                  feature.feature_type
                );
                if (usageInfo) {
                  displayHTML += `<li>${feature.feature_name} - ${usageInfo.utilization} used</li>`;
                }
              } else {
                displayHTML += `<li>${feature.feature_name}</li>`;
              }

              ctr--;
            }

            displayHTML += `</ul></div>`;
          }

          // Display usage info for ducts, cables
          if (
            [FEATURE_DUCT, FEATURE_FIBER_CABLE].includes(feature.get("type"))
          ) {
            let usageInfo = await this.getUsageInformation(
              feature.get("pk"),
              feature.get("type")
            );
            if (usageInfo) {
              displayHTML += `<div class="usage-list"><p>Usage</p><ul>`;

              let utilization = usageInfo.duct_utilization
                ? usageInfo.duct_utilization
                : usageInfo.cable_utilization;
              displayHTML += `<li>${utilization} used</li>`;

              displayHTML += `</ul></div>`;
            }
          }
        }

        displayHTML += `</div>`;
      }

      displayHTML += `</div>`;

      this.overlay_content.innerHTML = displayHTML;
    },

    async loadCompositionFeatures(feature) {
      const attached_features = [];
      let urlKey = getCompositionURLKey(feature);
      if (urlKey != "") {
        let payload = {
          id: feature.get("pk"),
          type: urlKey,
        };

        await this.getCompositionFeatures(payload).then((res) => {
          res.results.forEach((feature) => {
            var infoObj;

            if (feature.odf_info) infoObj = feature.odf_info;
            if (feature.splitter_info) infoObj = feature.splitter_info;
            if (feature.fdp_info) infoObj = feature.fdp_info;
            if (feature.closure_info) infoObj = feature.closure_info;
            if (feature.ont_info) infoObj = feature.ont_info;
            if (feature.face_plate_info) infoObj = feature.face_plate_info;

            if (infoObj) {
              attached_features.push({
                id: feature.id,
                feature_name: infoObj.name,
                feature_type: infoObj.type,
                pk: infoObj.pk,
              });
            }
          });
        });
      }

      return attached_features;
    },

    async getUsageInformation(featureId, featureType) {
      let USAGE_FEATURES = [FEATURE_DUCT, FEATURE_FIBER_CABLE, FEATURE_ODF];
      if (USAGE_FEATURES.includes(featureType)) {
        return await this.getFeature({ id: featureId, type: featureType });
      }

      return;
    },
  },
};
