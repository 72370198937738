/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '../config'

import usersModule from "./modules/users"
import customersModule from "./modules/customers"
import materialsModule from "./modules/materials"
import projectsModule from "./modules/projects"
import featuresModule from "./modules/features"
import permissionsModule from "./modules/permissions"

Vue.use( Vuex )

export default new Vuex.Store( {
    modules: {
        usersModule,
        customersModule,
        materialsModule,
        projectsModule,
        permissionsModule,
        featuresModule
    },

    state: {
        layout: 'app-layout'
    },
    mutations: {

    },
    actions: {

    },
    getters: {
        layout: state => state.layout,
    }
} )