<template>
  <!-- check main.js for custom component details -->
  <c-submenu-layout>
    <!-- SubMenu Column -->
    <v-flex class="sub-menu" xs2>
      <c-sub-menu :items="sub_menu"></c-sub-menu>
    </v-flex>

    <!-- Main Content Column -->
    <v-flex xs10 class="main-content-border">
      <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

      <h1>Data Uploads Summary</h1>

      <v-card class="mt-9">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <div v-if="loading">
            <c-loader message="Loading summary. Please wait..."></c-loader>
          </div>

          <div v-else class="row uploads-data pa-5">
            <div class="flex">
              <h3 class="primary--text">Uploaded Counties</h3>
              <p>{{ uploaded_counties }}</p>
            </div>

            <div class="flex">
              <h3 class="primary--text">Uploaded Buildings</h3>
              <p>{{ uploaded_buildings }}</p>
            </div>

            <div class="flex">
              <h3 class="primary--text">Uploaded Roads</h3>
              <p>{{ uploaded_roads }}</p>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-btn
        @click="dialog = !dialog"
        absolute
        top
        right
        fab
        rounded
        color="primary"
        class="white--text fab-button"
      >
        <v-icon>add</v-icon>
      </v-btn>

      <div v-if="dialog">
        <DataUploadDialog
          @closeDialog="closeDialog"
          :editModel="editModel"
          @reloadData="getUploadsDataSummary"
          :dialogModel="dialog"
        ></DataUploadDialog>
      </div>

      <div v-if="delete_dialog">
        <DeleteDialog
          :deleteMessage="delete_message"
          @deleteConfirmed="deleteConfirmed"
          @closeDialog="closeDialog"
          :dialogModel="delete_dialog"
          :model="deleteModel"
        ></DeleteDialog>
      </div>
    </v-flex>
  </c-submenu-layout>
</template>

<script>
import appConfig from "../../config";
import DataUploadDialog from "./sub-components/DataUploadDialog";
import DeleteDialog from "../generic/ConfirmDeleteDialog";
import dialogMixins from "../../utils/mixins";
import { mapState, mapActions } from "vuex";

export default {
  name: "DataUploadsComponent",
  components: { DataUploadDialog, DeleteDialog },
  mixins: [dialogMixins],
  data: () => ({
    search: "",
    deleteModel: null,
    editModel: null,
    delete_message: "Are you sure you want to delete this material type?",
    sub_menu: [],
    tabs: null,
    loading: false,
    uploaded_counties: 0,
    uploaded_roads: 0,
    uploaded_buildings: 0,
    bread_crumbs: [
      {
        text: "Data Uploads",
        href: "data-uploads",
      },
    ],
  }),
  mounted() {
    this.sub_menu = appConfig.settings_page_sub_menus;
    this.getUploadsDataSummary();
  },
  computed: {
    ...mapState({
      material_types: (state) => state.materialsModule.material_types,
      status: (state) => state.materialsModule.status,
      features_status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    ...mapActions({
      getMaterialTypes: "getMaterialTypes",
      deleteMaterialType: "deleteMaterialType",
      dataUploadsSummary: "dataUploadsSummary",
    }),
    getUploadsDataSummary() {
      this.loading = true;
      this.dataUploadsSummary().then((res) => {
        this.loading = false;

        this.uploaded_counties = res.counties;
        this.uploaded_buildings = res.buildings;
        this.uploaded_roads = res.roads;
      });
    },
    reloadData() {
      this.editModel = undefined;
    },
    editItem(item) {
      this.editModel = item;
      this.dialog = !this.dialog;
    },
    deleteItem(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    deleteConfirmed(item) {
      this.deleteMaterialType(item.id).then(() => {
        this.getMaterialTypes();
      });
    },
  },
};
</script>

<style lang="css" scoped>
.uploads-data {
  flex-direction: column;
}
.uploads-data h3 {
  font-size: 24px;
  line-height: 2;
}

.uploads-data p {
  font-size: 18px;
  line-height: 2;
}

.uploads-data .flex {
  border-bottom: solid 1px #eee;
  border-width: 50%;
  margin-bottom: 25px;
}
</style>