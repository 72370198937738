var dialogMixin = {
  created: function() {},

  data: function() {
    return {
      // snackbar component
      dialog: false,
      generic_dialog: false,
      manage_imports_dialog: false,
      delete_dialog: false,
      edit_customer_dialog: false,
      edit_job_dialog: false,
      delete_permission_dialog: false,
      permissions_dialog: false,
      reset_password_dialog: false,
      manage_group_permissions: false,
      alert_text: "",
      alert_type: "watoto",
      notifications_alert: false,
      generate_boq_dialog: false,
      view_job_dialog: false,
      edit_project_dialog: false,
      prefix_dialog: false,
      maintenance_dialog: false,
      add_feature_dialog: false,

      // delete dialog
      deleteModel: null,
      deleteMessage: "Are you sure?",
    };
  },

  methods: {
    showDeleteDialog(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },

    openDialog: function() {
      if (!this.dialog) {
        this.dialog = !this.dialog;
      }
    },
    closeDialog: function() {
      this.dialog = false;
      this.manage_imports_dialog = false;
      this.generic_dialog = false;
      this.delete_dialog = false;
      this.reset_password_dialog = false;
      this.permissions_dialog = false;
      this.delete_permission_dialog = false;
      this.edit_customer_dialog = false;
      this.edit_job_dialog = false;
      this.generate_boq_dialog = false;
      this.view_job_dialog = false;
      this.edit_project_dialog = false;
      this.manage_group_permissions = false;
      this.prefix_dialog = false;
      this.add_feature_dialog = false;
      this.maintenance_dialog = false;
    },
    closePermissionsDialog: function() {
      this.permissions_dialog = false;
    },
    showAlertMessage(message, type) {
      this.$notify({
        group: type,
        title: type,
        text: message,
      });
    },
    closeAlert() {
      this.alert_text = "";
      this.notifications_alert = false;
    },
  },
};

export default dialogMixin;
