import FeaturesAPI from "../../api/FeaturesAPI"
import appConfig from "../../config"
import {
    FEATURE_BUILDING, FEATURE_POLE, FEATURE_CABINET, FEATURE_FIBER_CABLE, FEATURE_ROAD,
    FEATURE_DUCT, FEATURE_INNER_DUCT, FEATURE_ODF, FEATURE_SPLITTER, FEATURE_SITE, FEATURE_FACE_PLATE, FEATURE_SPLICE,
    FEATURE_PATCH, FEATURE_TERMINATION, FEATURE_IMPORT_LAYER, FEATURE_SLACK, FEATURE_FDP, FEATURE_ACCESSORIES, FEATURE_ATTACHMENTS,
    FEATURE_CLOSURE, FIBER_CABLE_FEATURES, BUILDING_FEATURES, SITE_FEATURES, CABINET_FEATURES, POLE_FEATURES, DUCT_FEATURES, FEATURE_MANHOLE,
    FEATURE_MICRO_TUNNEL,
    TRENCH,
    ACCESS_POINT_FEATURES,
    IS_SPLICE_CONNECTION,
    IS_PORT_CONNECTION,
    FEATURE_ONT, FEATURE_OLT, REDLINE_LINE, REDLINE_POINT, REDLINE_POLYGON, ROAD_CROSSING_HOOK, FEATURE_HANDHOLE, POLE_DUCT, POLE_FIBER_CABLE, AREA_OF_INTEREST, BRIDGE_ATTACHMENT, GABION, FEATURE_RTU
} from "../../utils/feature_constants";

/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

var baseUrl = appConfig.base_url + appConfig.features_service_base_port + '/v1/features_service';
/* for reading features only */
let readFeaturesURL = `${ baseUrl }/features`

/* for writing features */
let bridgeAttachementURL = `${ baseUrl }/bridge_attachment`
let gabionURL = `${ baseUrl }/gabion`
let areaOfInterestURL = `${ baseUrl }/area_of_interest`
let areaOfInterestFeaturesURL = `${ baseUrl }/area_of_interest/get_bounded_features`
let buildingsURL = `${ baseUrl }/building`
let polesURL = `${ baseUrl }/pole`
let roadsURL = `${ baseUrl }/roads`
let cabinetsURL = `${ baseUrl }/cabinet`
let odfsURL = `${ baseUrl }/odf`
let splittersURL = `${ baseUrl }/splitter`
let fdpURL = `${ baseUrl }/fdp`
let fiberCablesURL = `${ baseUrl }/fibercable`
let ductsURL = `${ baseUrl }/duct`
let roadCrossingHooksURL = `${ baseUrl }/road_crossing_hook`
let microTunnelURL = `${ baseUrl }/micro_tunnel`
let trenchURL = `${ baseUrl }/trench`
let ductTrenchesUrl = `${ baseUrl }/duct_trenches`
let sitesURL = `${ baseUrl }/site`
let slacksURL = `${ baseUrl }/maintenanceloop`
let closuresURL = `${ baseUrl }/closure`
let faceplateURL = `${ baseUrl }/faceplate`
let ontURL = `${ baseUrl }/ont`
let oltURL = `${ baseUrl }/olt`
let redlineURL = `${ baseUrl }/redlining`
let splicesURL = `${ baseUrl }/fibersplice`
let deleteSplicesURL = `${ baseUrl }/fibersplice/delete_splice`
let checkSplicesURL = `${ baseUrl }/fibersplice/check_existing_connections`
let portConnectionURL = `${ baseUrl }/port_connection`
let checkPortConnectionsURL = `${ baseUrl }/port_connection/check_existing_connections`
let terminationsURL = `${ baseUrl }/portsplice`
let patchesURL = `${ baseUrl }/patch`
let accesspointsURL = `${ baseUrl }/accesspoint`
let accessoryURL = `${ baseUrl }/featureaccessory`
let attachmentsURL = `${ baseUrl }/attachment`
let countyURL = `${ baseUrl }/county`
let ductFeaturesURL = `${ baseUrl }/ductfeatures`
let ductSegmentFeaturesURL = `${ baseUrl }/ductfeatures/get_segment_features`
let ductRouteURL = `${ baseUrl }/duct/get_duct_route`
let innerDuctURL = `${ baseUrl }/innerduct`
let innerDuctCablesURL = `${ baseUrl }/innerductcable`
let ductCablesURL = `${ baseUrl }/ductcable`
let fiberStrandsURL = `${ baseUrl }/fiberstrand`
let odfPortsURL = `${ baseUrl }/odfport`
let splitterPortsURL = `${ baseUrl }/splitterport`
let fdpPortsURL = `${ baseUrl }/fdpport`
let featureCompositionURL = `${ baseUrl }/featurecomposition`
let fiberCableFeaturesURL = `${ baseUrl }/fibercablefeatures`
let fiberCableSegmentFeaturesURL = `${ baseUrl }/fibercablefeatures/get_segment_features`
let importLayerURL = `${ baseUrl }/importlayer`
let buildingFeaturesURL = `${ baseUrl }/building_features`
let cabinetFeaturesURL = `${ baseUrl }/cabinet_features`
let poleFeaturesURL = `${ baseUrl }/pole_features`
let accessPointFeaturesURL = `${ baseUrl }/access_point_features`
let siteFeaturesURL = `${ baseUrl }/site_features`
let connectionsURL = `${ baseUrl }/connections`
let importExportURL = `${ baseUrl }/import_export_features`
let portsURL = `${ baseUrl }/port`
let poleAccessoriesUrl = `${ baseUrl }/poleaccessory`
let poleAccessoryItemsUrl = `${ baseUrl }/poleaccessoryitem`
let undoConnectionsUrl = `${ baseUrl }/undo_connections`
let importedLayersUrl = `${ baseUrl }/importlayer/get_imported_features`
let deleteImportedLayersUrl = `${ baseUrl }/importlayer/delete_imported_features`
let featureServicesUrl = `${ baseUrl }/feature_services`
let boqssUrl = `${ baseUrl }/boqs`
let prefixesUrl = `${ baseUrl }/feature_prefixes`
let dataUploadsSummaryUrl = `${ baseUrl }/data_uploads_summary`
let fmsPortInfoUrl = `${ baseUrl }/fms_port_info`
let rtuUrl = `${ baseUrl }/rtu`
let monitoredRoutesUrl = `${ baseUrl }/monitored_routes`

// splice schematics layer
let schematicsUrl = `${ baseUrl }/fibersplice/get_splice_schematics`

/**
 * Returns the correct API URL for a given feature type
 * The payload object consists of 2 fields: 
 * - @type: The type of feature that we are working with
 * - @data: This is an optional field that is only available for geojson features
 * @param {Object} payload 
 */
function getResourceURL( payload ) {
    let redlineFeatures = [
        REDLINE_LINE,
        REDLINE_POINT,
        REDLINE_POLYGON
    ]

    if ( payload.type == FEATURE_BUILDING ) return buildingsURL;
    if ( payload.type == FEATURE_MANHOLE || payload.type == FEATURE_HANDHOLE ) return accesspointsURL;
    if ( payload.type == FEATURE_POLE ) return polesURL;
    if ( payload.type == FEATURE_ROAD ) return roadsURL;
    if ( payload.type == FEATURE_CABINET ) return cabinetsURL;
    if ( payload.type == FEATURE_FIBER_CABLE || payload.type == POLE_FIBER_CABLE ) return fiberCablesURL;
    if ( payload.type == FEATURE_DUCT || payload.type == POLE_DUCT ) return ductsURL;
    if ( payload.type == FEATURE_INNER_DUCT ) return innerDuctURL;
    if ( payload.type == FEATURE_ODF ) return odfsURL;
    if ( payload.type == FEATURE_SPLITTER ) return splittersURL;
    if ( payload.type == FEATURE_FACE_PLATE ) return faceplateURL;
    if ( payload.type == FEATURE_ONT ) return ontURL;
    if ( payload.type == FEATURE_OLT ) return oltURL;
    if ( payload.type == BRIDGE_ATTACHMENT ) return bridgeAttachementURL;
    if ( payload.type == GABION ) return gabionURL;
    if ( payload.type == FEATURE_RTU ) return rtuUrl;
    if ( redlineFeatures.includes( payload.type ) ) return redlineURL;
    if ( payload.type == FEATURE_SPLICE ) return splicesURL;
    if ( payload.type == FEATURE_PATCH ) return patchesURL;
    if ( payload.type == FEATURE_TERMINATION ) return terminationsURL;
    if ( payload.type == FEATURE_IMPORT_LAYER ) return importLayerURL;
    if ( payload.type == FEATURE_SLACK ) return slacksURL;
    if ( payload.type == FEATURE_FDP ) return fdpURL;
    if ( payload.type == FEATURE_ACCESSORIES ) return accessoryURL;
    if ( payload.type == FEATURE_ATTACHMENTS ) return attachmentsURL;
    if ( payload.type == FEATURE_SITE ) return sitesURL;
    if ( payload.type == FEATURE_CLOSURE ) return closuresURL;
    if ( payload.type == FEATURE_MICRO_TUNNEL ) return microTunnelURL;
    if ( payload.type == TRENCH ) return trenchURL;
    if ( payload.type == ROAD_CROSSING_HOOK ) return roadCrossingHooksURL;

    if ( payload.type == FIBER_CABLE_FEATURES ) return fiberCableFeaturesURL;
    if ( payload.type == DUCT_FEATURES ) return ductFeaturesURL;
    if ( payload.type == BUILDING_FEATURES ) return buildingFeaturesURL;
    if ( payload.type == POLE_FEATURES ) return poleFeaturesURL;
    if ( payload.type == SITE_FEATURES ) return siteFeaturesURL;
    if ( payload.type == CABINET_FEATURES ) return cabinetFeaturesURL;
    if ( payload.type == ACCESS_POINT_FEATURES ) return accessPointFeaturesURL;
    if ( payload.type == AREA_OF_INTEREST ) return areaOfInterestURL;

    return '';
}

let MSG_COMMIT_LOADING = 'loading'
let MSG_COMMIT_SUCCESS = 'success'
let MSG_COMMIT_ERROR = 'error'
let COMMIT_STATUS = 'setFeatureStatus'
let COMMIT_MAP_MODE = 'setMapMode'
let COMMIT_COMPOSITION_STATUS = 'setCompositionStatus'
let COMMIT_CUSTOMER_PREFIXES = 'setCustomerPrefixes'
let COMMIT_JOB_PREFIXES = 'setJobPrefixes'
let DOCUMENTATION_MODE = 'DOCUMENTATION_MODE'
let MAINTENANCE_MODE = 'MAINTENANCE_MODE'

export default {
    state: {
        status: '',
        map_mode: '',
        feature_composition_status: '',
        buildings: [],
        poles: [],
        cabinets: [],
        odfs: [],
        splitters: [],
        fdps: [],
        fiberCables: [],
        ducts: [],
        sites: [],
        closures: [],
        faceplates: [],
        accessPoints: [],
        fiberCableFeatures: [],
        importLayers: [],
        job_prefixes: [],
        customer_prefixes: [],
    },

    actions: {
        // features section
        async createFeature( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = getResourceURL( payload )
                if ( featureURL == '' ) reject( 'No URL found for this resource' )

                if ( payload.pk ) {
                    FeaturesAPI.put( featureURL, payload.pk, payload ).then( response => {
                        commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                        resolve( response )

                    } ).catch( err => {
                        commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                        reject( err )
                    } );
                }
                else {
                    FeaturesAPI.post( featureURL, payload ).then( response => {
                        commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                        resolve( response )

                    } ).catch( err => {
                        commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                        reject( err )
                    } );
                }
            } )
        },

        getFeature( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = getResourceURL( payload )
                if ( featureURL == '' ) reject( 'No URL found for this resource' )
                featureURL = `${ featureURL }/${ payload.id }`

                FeaturesAPI.get( featureURL ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        updateFeature( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = getResourceURL( payload )
                if ( featureURL == '' ) reject( 'No URL found for this resource' )
                featureURL = `${ featureURL }/${ payload.id }`

                FeaturesAPI.update( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getAllFeatures( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = readFeaturesURL;

                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getAreaOfInterestFeatures( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = areaOfInterestFeaturesURL;

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        removeFeature( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = getResourceURL( payload )
                if ( featureURL == '' ) reject( 'No URL found for this resource' )

                FeaturesAPI.delete( featureURL, payload.id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // // accessories
        // createFeatureAccessory( { commit }, payload ) {
        //     commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

        //     return new Promise( ( resolve, reject ) => {
        //         let featureURL = `${ readFeaturesURL }/?job_id=${ job_id }`

        //         FeaturesAPI.get( featureURL, payload ).then( response => {
        //             commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
        //             resolve( response )

        //         } ).catch( err => {
        //             commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
        //             reject( err )
        //         } );
        //     } )
        // },

        // removeFeatureAccessory( { commit }, payload ) {
        //     commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
        //     let featureURL = `${ readFeaturesURL }/?job_id=${ job_id }`

        //     return new Promise( ( resolve, reject ) => {
        //         FeaturesAPI.get( featureURL, payload ).then( response => {
        //             commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
        //             resolve( response )

        //         } ).catch( err => {
        //             commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
        //             reject( err )
        //         } );
        //     } )
        // },

        // getFeatureAccessories( { commit }, payload ) {
        //     commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
        //     let featureURL = `${ readFeaturesURL }/?job_id=${ job_id }`

        //     return new Promise( ( resolve, reject ) => {
        //         FeaturesAPI.get( featureURL, payload ).then( response => {
        //             commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
        //             resolve( response )

        //         } ).catch( err => {
        //             commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
        //             reject( err )
        //         } );
        //     } )
        // },

        // ports
        updatePortInfo( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = portsURL;

                FeaturesAPI.update( featureURL, payload.id, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        clearPortInfo( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = `${ portsURL }/clear_port_connection`;

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // rtu info
        createRTU( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = rtuUrl;

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getRTUs( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = rtuUrl;

                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getMonitoredRoutes( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {

                FeaturesAPI.post( monitoredRoutesUrl, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getRTU( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = rtuUrl;

                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                    // check if customer prefixes
                    if ( payload.customer ) commit( COMMIT_CUSTOMER_PREFIXES, response );
                    if ( payload.job ) commit( COMMIT_JOB_PREFIXES, response );

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        updateRTU( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = rtuUrl;

                FeaturesAPI.update( featureURL, payload.id, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        deleteRTU( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = rtuUrl;

                FeaturesAPI.delete( featureURL, payload.id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // feature prefixes (Manholes, Handholes)
        createPrefix( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = prefixesUrl;

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getPrefixes( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = prefixesUrl;

                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                    // check if customer prefixes
                    if ( payload.customer ) commit( COMMIT_CUSTOMER_PREFIXES, response );
                    if ( payload.job ) commit( COMMIT_JOB_PREFIXES, response );

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        updatePrefix( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = prefixesUrl;

                FeaturesAPI.update( featureURL, payload.id, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        deletePrefix( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = prefixesUrl;

                FeaturesAPI.delete( featureURL, payload.id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // attachments
        createFeatureAttachment( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = attachmentsURL;
            let hasFileUpload = true;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload, hasFileUpload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getFeatureAttachments( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = attachmentsURL;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        removeFeatureAttachment( { commit }, attachment_id ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = attachmentsURL;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.delete( featureURL, attachment_id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // trenches
        createTrench( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = ductTrenchesUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getTrenches( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = ductTrenchesUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        removeTrench( { commit }, id ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = ductTrenchesUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.delete( featureURL, id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // slack loops
        createSlack( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = slacksURL;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getSlacks( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = slacksURL;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getCableSlacks( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = slacksURL;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        removeSlack( { commit }, id ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = slacksURL;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.delete( featureURL, id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // feature composition API
        createCompositionFeature( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            commit( COMMIT_COMPOSITION_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = getResourceURL( payload )
                if ( featureURL == '' ) reject( 'No URL found for this resource' )

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    commit( COMMIT_COMPOSITION_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    commit( COMMIT_COMPOSITION_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )

        },

        getCompositionFeatures( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = getResourceURL( payload )
                if ( featureURL == '' ) reject( 'No URL found for this resource' )

                FeaturesAPI.get( featureURL, { feature: payload.id } ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getFiberCableSegmentFeatures( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = fiberCableSegmentFeaturesURL;

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getDuctSegmentFeatures( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = ductSegmentFeaturesURL;

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        removeCompositionFeature( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = getResourceURL( payload )
                if ( featureURL == '' ) reject( 'No URL found for this resource' )

                FeaturesAPI.delete( featureURL, payload.id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // duct route
        getDuctRoute( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = ductRouteURL;

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // fiber and port connections
        createConnection( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL;
                if ( payload.operation == IS_SPLICE_CONNECTION ) featureURL = splicesURL;
                if ( payload.operation == IS_PORT_CONNECTION ) featureURL = portConnectionURL;
                if ( !featureURL ) reject( 'No URL found for this resource' )

                // remove operation key
                delete payload.operation

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        checkConnections( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL;
                if ( payload.operation == IS_SPLICE_CONNECTION ) featureURL = checkSplicesURL;
                if ( payload.operation == IS_PORT_CONNECTION ) featureURL = checkPortConnectionsURL;
                if ( !featureURL ) reject( 'No URL found for this resource' )

                // remove operation key
                delete payload.operation

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getConnections( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = connectionsURL;

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        undoConnection( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = undoConnectionsUrl;

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        deleteSplice( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = deleteSplicesURL;

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        deletePortConnection( { commit }, id ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                let featureURL = portConnectionURL;

                FeaturesAPI.delete( featureURL, id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // inner ducts
        getInnerDucts( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = innerDuctURL;

                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getInnerDuctCable( { commit }, id ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = `${ innerDuctCablesURL }/${ id }`;

                FeaturesAPI.get( featureURL ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        updateInnerDuct( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = innerDuctURL;

                let id = payload.id;

                FeaturesAPI.update( featureURL, id, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        updateInnerDuctCable( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = innerDuctCablesURL;

                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        clearInnerDuct( { commit }, id ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );

            return new Promise( ( resolve, reject ) => {
                var featureURL = innerDuctCablesURL;

                FeaturesAPI.delete( featureURL, id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // pole accessories
        createPoleAccessory( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = poleAccessoriesUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        updatePoleAccessory( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = poleAccessoriesUrl;
            let id = payload.id;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.update( featureURL, id, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        createPoleAccessoryItem( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = poleAccessoryItemsUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getPoleAccessories( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = poleAccessoriesUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getPoleAccessoryItems( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = poleAccessoryItemsUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        removePoleAccessory( { commit }, id ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = poleAccessoriesUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.delete( featureURL, id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        removePoleAccessoryItem( { commit }, id ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = poleAccessoryItemsUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.delete( featureURL, id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // BOQ export
        exportBOQs( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = boqssUrl;
            let fullResponse = true
            let hasFileUpload = false;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload, hasFileUpload, fullResponse ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        dataUploadsSummary( { commit } ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = dataUploadsSummaryUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.get( featureURL ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // import export features
        exportFeatures( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = importExportURL;
            let fullResponse = true
            let hasFileUpload = false;
            payload.operation = "export";

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload, hasFileUpload, fullResponse ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        importFeatures( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = importExportURL;
            payload.set( 'operation', 'import' )
            let hasFileUpload = true;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload, hasFileUpload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // import layers

        getImportedLayers( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = importedLayersUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        deleteImportedLayers( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = deleteImportedLayersUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // feature services
        createFeatureService( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = featureServicesUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        getFeatureServices( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = featureServicesUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.get( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        deleteFeatureService( { commit }, id ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = featureServicesUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.delete( featureURL, id ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        // get splice schematics
        getSpliceSchematics( { commit }, payload ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            let featureURL = schematicsUrl;

            return new Promise( ( resolve, reject ) => {
                FeaturesAPI.post( featureURL, payload ).then( response => {
                    commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
                    resolve( response )

                } ).catch( err => {
                    commit( COMMIT_STATUS, MSG_COMMIT_ERROR );
                    reject( err )
                } );
            } )
        },

        activatePlanningMode( { commit } ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            commit( COMMIT_MAP_MODE, DOCUMENTATION_MODE );
            commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
        },

        activateMaintenanceMode( { commit } ) {
            commit( COMMIT_STATUS, MSG_COMMIT_LOADING );
            commit( COMMIT_MAP_MODE, MAINTENANCE_MODE );
            commit( COMMIT_STATUS, MSG_COMMIT_SUCCESS );
        },

        // createFeatureComposition() {

        // },

        // removeFeatureComposition() {

        // },

        // getFeatureComposition() {

        // },

        // // feature ports
        // getODFPorts() {

        // },

        // getFDPPorts() {

        // },

        // getSplitterPorts() {

        // },

        // // splices
        // getFeatureSplices() {

        // },

        // getCableSplices() {

        // },

        // createCableSplice() {

        // },

        // removeCableSplice() {

        // },

        // // feature terminations
        // getFeatureTerminations() {

        // },

        // getODFTerminations() {

        // },

        // removeODFTermination() {

        // },

        // // import layers
        // getImportLayers() {

        // },

        // createImportLayer() {

        // },

        // removeImportLayer() {

        // },

        // // duct cables
        // getDuctCables() {

        // },

        // createDuctCable() {

        // },

        // removeDuctCable() {

        // },

        // // duct cables
        // getDuctCables() {

        // },

        // createDuctCable() {

        // },

        // removeDuctCable() {

        // },


    },

    mutations: {
        [ COMMIT_STATUS ]( state, results ) {
            state.status = results;
        },

        [ COMMIT_CUSTOMER_PREFIXES ]( state, results ) {
            state.customer_prefixes = results.results;
        },

        [ COMMIT_MAP_MODE ]( state, mode ) {
            state.map_mode = mode;
        },

        [ COMMIT_JOB_PREFIXES ]( state, results ) {
            state.job_prefixes = results.results;
        },

        [ COMMIT_COMPOSITION_STATUS ]( state, results ) {
            state.feature_composition_status = results;
        },
    }
}