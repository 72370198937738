import axios from 'axios'

export default {
    post( resource, payload, has_file = undefined, full_response = undefined ) {
        if ( has_file ) {
            return axios.post( `${ resource }/`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } )
                .then( response => {
                    if ( full_response ) return response;
                    return response.data;
                } )
        }
        else {
            if ( full_response ) {
                return axios.post( `${ resource }/`, payload, { responseType: 'blob' } )
                    .then( response => {
                        return response;
                    } )
            }
            else
                return axios.post( `${ resource }/`, payload )
                    .then( response => {
                        return response.data;
                    } )
        }
    },

    get( resource, requestParams ) {

        return axios.get( `${ resource }/`, {
            params: requestParams
        } )
            .then( response => {
                return response.data;
            } )
    },

    update( resource, id, payload ) {
        return axios.patch( `${ resource }/${ id }/`, payload )
            .then( response => {
                return response.data;
            } )
    },

    put( resource, id, payload ) {
        return axios.put( `${ resource }/${ id }/`, payload )
            .then( response => {
                return response.data;
            } )
    },

    patch( resource, id, payload, has_file = undefined ) {
        if ( has_file ) {
            return axios.patch( `${ resource }/${ id }/`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } )
                .then( response => {
                    return response.data;
                } )
        }
        else {
            return axios.patch( `${ resource }/${ id }/`, payload )
                .then( response => {
                    return response.data;
                } )
        }
    },

    delete( resource, id ) {
        return axios.delete( `${ resource }/${ id }/` )
            .then( response => {
                return response.data;
            } )
    },
}