<template>
  <!-- check main.js for custom component details -->
  <c-app-layout>
    <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

    <h1 v-if="customer">{{ customer.customer_name }} - Projects</h1>

    <v-card class="mt-9">
      <v-card-title class="ma-0 pa-0">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          class="mr-3"
          label="Search projects"
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :search="search"
          :loading="loading == 'loading'"
          :headers="headers"
          :items="customer_projects"
          class="linked-table"
          @click:row="handleClick"
        >
          <template v-slot:item.project_status="{ item }">
            <v-chip
              v-for="(status, i) in item.project_status"
              :key="i"
              :color="getChipColor(status)"
              class="pa-2 mr-2"
              small
              >{{ status == "Closed" ? "Documented" : status }}</v-chip
            >
          </template>

          <template v-slot:item.action="{ item }">
            <router-link
              :to="
                '/customer-projects/' +
                $route.params.id +
                '/projects/' +
                item.id +
                '/details'
              "
            >
              <v-icon class="primary--text">chevron_right</v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-btn
      @click="dialog = !dialog"
      absolute
      top
      right
      fab
      rounded
      color="primary"
      class="white--text fab-button"
    >
      <v-icon>add</v-icon>
    </v-btn>

    <div v-if="dialog">
      <ProjectDialog
        @closeDialog="closeDialog"
        :dialogModel="dialog"
      ></ProjectDialog>
    </div>
  </c-app-layout>
</template>

<script>
// import CustomerDialog from "./sub-components/CustomerDialog";
import ProjectDialog from "./sub-components/ProjectDialog";
import dialogMixins from "../../utils/mixins";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "CompanyProjectsComponent",
  components: { ProjectDialog },
  mixins: [dialogMixins],
  data: () => ({
    search: "",
    sub_menu: [],
    bread_crumbs: [],
    headers: [
      {
        text: "Project Name",
        value: "project_name",
      },
      {
        text: "Project Location",
        value: "project_location",
      },
      {
        text: "Project Status",
        value: "project_status",
      },
      {
        text: "More",
        value: "action",
      },
    ],
  }),
  computed: {
    ...mapState({
      customers: (state) => state.customersModule.customers,
      customer: function () {
        return this.customers.find(
          (customer) => customer.id == this.$route.params.id
        );
      },
      loading: (state) => state.projectsModule.status,
      projects: (state) => state.projectsModule.projects,
      customer_projects: function () {
        return this.projects.filter(
          (project) => project.customer_id == this.$route.params.id
        );
      },
    }),
  },
  created() {
    if (this.customers.length == 0 || this.projects.length == 0) {
      this.getCustomers().then(() => this.getProjects());
    } else if (this.projects.length == 0) {
      this.getProjects();
    }
  },
  mounted() {
    if (this.customer) {
      this.bread_crumbs.push(
        {
          text: "Customer Projects",
          href: "/customer-projects",
        },
        {
          text: this.customer.customer_name,
          disabled: true,
        }
      );
    }
  },
  watch: {
    customer: function () {
      this.bread_crumbs = [];
      this.bread_crumbs.push(
        {
          text: "Customer Projects",
          href: "/customer-projects",
        },
        {
          text: this.customer.customer_name,
          disabled: true,
        }
      );
    },
  },
  methods: {
    ...mapActions({
      getCustomers: "getCustomers",
      getProjects: "getProjects",
    }),
    ...mapMutations({
      setCustomer: "setCustomer",
      setCustomerUsers: "setCustomerUsers",
    }),
    handleClick(item) {
      this.$router.push(
        "/customer-projects/" +
          this.$route.params.id +
          "/projects/" +
          item.id +
          "/details"
      );
    },
    getChipColor(chipState) {
      if (chipState.toString().toLowerCase() == "proposed") return "warning";
      if (
        chipState.toString().toLowerCase() == "implementation" ||
        chipState.toString().toLowerCase() == "updated"
      )
        return "primary";
      if (chipState.toString().toLowerCase() == "closed") return "success";

      return "secondary";
    },
    loadCustomer(item) {
      this.setCustomer(item);
    },
  },
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none !important;
}
</style>