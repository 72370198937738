<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="success white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-select
                  v-model="selected_feature_type"
                  :rules="rules.required_field"
                  :items="export_types"
                  item-text="type"
                  item-value="type"
                  label="Export Feature Type"
                ></v-select>
              </v-layout>
            </v-flex>

            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-select
                  v-model="export_obj.output_type"
                  :rules="rules.required_field"
                  :items="export_file_types"
                  item-text="text"
                  item-value="value"
                  label="Export File Type"
                ></v-select>
              </v-layout>
            </v-flex>

            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="export_obj.file_name"
                  :rules="rules.required_field"
                  placeholder="Export Filename"
                  label="Export Filename"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="execExport"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-undef */

import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../utils/mixins";
import {
  FEATURE_BUILDING,
  FEATURE_MANHOLE,
  FEATURE_DUCT,
  FEATURE_FIBER_CABLE,
  FEATURE_CABINET,
  FEATURE_SITE,
  FEATURE_POLE,
  IMPORT_EXPORT_EQUIPMENT,
  IMPORT_EXPORT_FILES,
  SITE_FEATURES,
} from "../../../utils/feature_constants";

export default {
  name: "ExportFeaturesDialogComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    selectedFeatures: {
      type: Array,
      required: true,
    },
    selectedFeatureTypes: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "Export Features",
    dialog_button_text: "Export",
    valid: true,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    export_types: [],
    export_file_types: [],
    selected_feature_type: null,
    feature_ids: [],
    dialog: null,
    not_implemented_types: [".mapinfo"],
    csv_exports: [FEATURE_SITE],
    export_obj: {},
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.export_obj = {};
    this.export_types = this.selectedFeatureTypes;
    this.export_file_types = IMPORT_EXPORT_FILES;
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    ...mapActions({
      exportFeatures: "exportFeatures",
    }),
    execExport() {
      if (this.$refs.form.validate()) {
        if (
          (this.export_obj.output_type == ".xlsx" &&
            !this.csv_exports.includes(this.selected_feature_type)) ||
          this.not_implemented_types.includes(this.export_obj.output_type)
        ) {
          this.$notify({
            group: "error",
            title: "Notice",
            text: `This operation is not supported for ${this.export_obj.output_type} file types.`,
          });
        } else {
          this.export_obj.created_by = this.user.id;
          this.export_obj.job = this.$route.params.job_id;
          this.export_obj.feature_ids = this.feature_ids;
          this.export_obj.feature_type = this.selected_feature_type;
          let fileName = `${this.export_obj.file_name}${this.export_obj.output_type}`;
          let blobType =
            this.export_obj.output_type == ".xlsx"
              ? "application/vnd.ms-excel"
              : "application/octet-stream";

          this.exportFeatures(this.export_obj)
            .then((response) => {
              this.$notify({
                group: "success",
                title: "Export",
                text: `Your export file will download automatically in a few.`,
              });

              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // IE variant
                window.navigator.msSaveOrOpenBlob(
                  new Blob([response.data], { type: blobType }),
                  fileName
                );
              } else {
                const url = window.URL.createObjectURL(
                  new Blob([response.data], { type: blobType })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                console.log(link);
                link.click();
              }

              this.resetFields();
              this.closeDialog();
            })
            .catch(() => {
              this.$notify({
                group: "error",
                title: "Exception",
                text: `An exception occurred. Try again or ontact the system administrator`,
              });
              this.resetFields();
              this.closeDialog();
            });
        }
      }
    },
    resetFields() {
      this.export_obj = {};
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();
      this.$emit("closeDialog");
    },
  },
  watch: {
    selected_feature_type: function (val) {
      this.feature_ids = [];
      this.selectedFeatures.forEach((feature) => {
        if (feature.get("type") == val) {
          this.feature_ids.push(feature.get("pk"));
        }
      });
    },

    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.export = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>