<template>
  <v-layout>
    <v-flex xs12 class="mt-9">
      <v-data-table
        :loading="status == 'loading'"
        :items="inner_ducts"
        :headers="header"
        hide-default-footer
      >
        <template v-slot:[`item.color`]="{ item }">{{
          item.color ? item.color : "COLOUR NOT SET"
        }}</template>

        <template v-slot:[`item.fiber_cable`]="{ item }">{{
          getFiberCableName(item)
        }}</template>

        <template v-slot:[`item.status`]="{ item }">{{
          getDuctStatus(item)
        }}</template>

        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small color="primary">more_vert</v-icon>
            </template>
            <v-list dense>
              <v-list-item v-for="(menuItem, index) in menuList" :key="index">
                <v-list-item-title
                  class="clickable"
                  @click="menuClicked(item, menuItem)"
                  >{{ menuItem.text }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-flex>

    <div v-if="dialog">
      <DuctCableDialog
        @reload="loadInnerDucts"
        :selectedInnerDuct="selectedInnerDuct"
        :feature="feature"
        :selectedFeatures="selectedFeatures"
        :vectorSources="vectorSources"
        :dialogModel="dialog"
        @closeDialog="closeDialog"
      ></DuctCableDialog>
    </div>

    <div v-if="generic_dialog">
      <DuctColorDialog
        @reload="loadInnerDucts"
        :selectedInnerDuct="selectedInnerDuct"
        :feature="feature"
        :dialogModel="generic_dialog"
        @closeDialog="closeDialog"
      ></DuctColorDialog>
    </div>

    <div v-if="delete_dialog"></div>
    <ConfirmDelete
      :deleteMessage="deleteMessage"
      :dialogModel="delete_dialog"
      @closeDialog="closeDialog"
      @deleteConfirmed="clearDuct"
      :model="deleteModel"
    ></ConfirmDelete>
  </v-layout>
</template>

<script>
import DuctCableDialog from "./dialogs/DuctCableDialog";
import DuctColorDialog from "./dialogs/DuctColorDialog";
import dialogMixins from "../../../utils/mixins";
import { mapActions, mapState } from "vuex";
import ConfirmDelete from "../../generic/ConfirmDeleteDialog";
import { getFeatureById, getDerivedName } from "../../../utils/map_functions";

export default {
  name: "DuctCablesManagementTableComponent",
  components: { DuctCableDialog, ConfirmDelete, DuctColorDialog },
  props: {
    feature: {
      type: Object,
      required: true,
    },
    selectedFeatures: {
      type: Array,
    },
    vectorSources: {
      type: Array,
    },
  },
  mixins: [dialogMixins],
  data: () => ({
    menuList: [
      {
        text: "Set Fiber Cable",
        value: "set_fiber_cable",
      },
      {
        text: "Set Properties",
        value: "set_properties",
      },
      {
        text: "Clear duct",
        value: "clear_duct",
      },
    ],
    selectedInnerDuct: null,
    deleteModel: null,
    deleteMessage: "Are you sure you want to clear this duct?",
    header: [
      {
        text: "Duct Color",
        value: "color",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      {
        text: "Fiber Cable",
        value: "fiber_cable",
        sortable: false,
      },
      {
        text: "More",
        value: "action",
      },
    ],
    inner_ducts: [],
  }),
  mounted() {
    this.loadInnerDucts();
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    ...mapActions({
      getInnerDucts: "getInnerDucts",
      updateInnerDuct: "updateInnerDuct",
      updateInnerDuctCable: "updateInnerDuctCable",
      clearInnerDuct: "clearInnerDuct",
    }),
    showDeleteDialog(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    clearDuct(innerDuct) {
      this.deleteModel = null;
      if (innerDuct.inner_duct_cable) {
        this.clearInnerDuct(innerDuct.inner_duct_cable).then(() =>
          this.loadInnerDucts()
        );
      } else {
        this.closeDialog();
      }
    },
    loadInnerDucts() {
      let payload = {
        duct: this.feature.get("pk"),
      };

      this.getInnerDucts(payload).then((res) => {
        console.log(res);
        this.inner_ducts = res.results;
      });
    },
    getDuctStatus(innerDuct) {
      if (innerDuct.cable_uuid) return "USED";

      return "FREE";
    },
    getFiberCableName(innerDuct) {
      if (innerDuct.cable_uuid) {
        let fiberCable = getFeatureById(
          innerDuct.cable_uuid,
          this.vectorSources
        );
        if (fiberCable) return getDerivedName(fiberCable, this.vectorSources);
      }

      return "";
    },
    menuClicked(innerDuct, menuItem) {
      this.selectedInnerDuct = innerDuct;

      if (menuItem.value == "set_fiber_cable") {
        this.dialog = !this.dialog;
      }

      if (menuItem.value == "set_properties") {
        this.generic_dialog = !this.generic_dialog;
      }

      if (menuItem.value == "clear_duct") {
        this.showDeleteDialog(innerDuct);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>