<template>
  <v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <div
          class="dialog-title pa-2 success white--text custom-align-center relative"
          style="width: inherit"
        >
          <h4>Connection Manager</h4>

          <v-icon
            color="white"
            class="cursor-item"
            @click="$emit('close-connection-manager')"
            title="Close Connection Manager"
            style="position: absolute; top: 8px; right: 10px"
            >clear</v-icon
          >
        </div>
      </v-flex>

      <v-flex xs12>
        <v-tabs v-model="tabs" color="success">
          <v-tab>New Connection</v-tab>
          <v-tab>View Connections</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs" color="success">
          <v-tab-item>
            <NewConnectionsTab
              :key="new_connections_key"
              @highlightFromSegment="emitHighlightFromSegment"
              @highlightToSegment="emitHighlightToSegment"
              @refreshComponents="refreshComponents"
              @connectionPayload="connectionPayload"
              :vectorSources="vectorSources"
              :features="features"
              :previous_connection="previous_connection"
            ></NewConnectionsTab>
          </v-tab-item>
          <v-tab-item>
            <ViewConnectionsTab
              :key="view_connections_key"
              :vectorSources="vectorSources"
              :features="features"
              @refreshComponents="refreshComponents"
              @traceSignal="traceSignal"
              @clearSignalTrace="clearSignalTrace"
            ></ViewConnectionsTab>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import ViewConnectionsTab from "./connection-tabs/ViewConnections";
import NewConnectionsTab from "./connection-tabs/NewConnection";

export default {
  name: "ConnectionMaganerComponent",
  props: {
    features: {
      type: Array,
    },
    vectorSources: {
      type: Array,
    },
  },
  watch: {
    features: function () {
      this.refreshComponents();
    },
    tabs: function () {
      this.refreshComponents();
    },
  },
  destroyed() {
    this.clearSignalTrace();
  },
  components: {
    NewConnectionsTab,
    ViewConnectionsTab,
  },
  data: () => ({
    tabs: 0,
    new_connections_key: 1,
    view_connections_key: 2,
    previous_connection: {},
  }),
  methods: {
    traceSignal(signalData) {
      this.$emit("traceSignal", signalData);
    },
    clearSignalTrace() {
      this.$emit("clearSignalTrace");
    },
    connectionPayload(payload) {
      this.previous_connection = payload;
    },
    refreshComponents() {
      this.new_connections_key += 1;
      this.view_connections_key += 1;

      this.$emit("clearHighlightedFeatures");
    },
    emitHighlightFromSegment(segment) {
      this.$emit("highlightFromSegment", segment);
    },
    emitHighlightToSegment(segment) {
      this.$emit("highlightToSegment", segment);
    },
  },
};
</script>

<style lang="css" scoped>
.tab-item {
  height: 500px;
  overflow-y: scroll;
}

.dialog-title {
  text-align: center;
  border-radius: 3px;
}

.cursor-item {
  cursor: pointer;
}

.feature-dialog {
  position: absolute;
  width: 450px;
  min-height: 100px !important;
  max-height: 600px;
  background: #fff;
  top: 10em;
  left: 2em;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 3px;
}
</style>
