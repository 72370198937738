<template>
  <div>
    <v-card flat class="pb-3">
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <p :class="features_selected_class + ' pl-3 pb-0 pt-0'">
            {{ features_selected }}
          </p>
          <v-layout row wrap>
            <!-- slack_type -->
            <v-flex xs8>
              <v-select
                class="pl-2 pr-2 pb-3"
                dense
                v-model="location"
                :rules="rules.required_field"
                :items="locations"
                item-text="text"
                item-value="value"
                label="Connection Location"
              ></v-select>
            </v-flex>

            <!-- slack_type -->
            <v-flex xs4>
              <v-select
                class="pl-2 pr-2 pb-3"
                dense
                v-model="connection_type"
                :rules="rules.required_field"
                :items="connection_types"
                item-text="text"
                item-value="value"
                label="Connection Type"
              ></v-select>
            </v-flex>

            <!-- source -->
            <v-layout row wrap>
              <v-flex xs6>
                <v-select
                  v-if="connection_type"
                  class="pl-2 pr-2 pb-3"
                  dense
                  v-model="from_feature"
                  :hint="from_feature_size"
                  persistent-hint
                  :rules="rules.required_field"
                  :items="filtered_features"
                  item-text="text"
                  item-value="value"
                  label="From Feature"
                ></v-select>
              </v-flex>

              <!-- destination -->
              <v-flex xs6>
                <v-select
                  v-if="connection_type"
                  class="pl-2 pr-2 pb-3"
                  dense
                  v-model="to_feature"
                  :hint="to_feature_size"
                  persistent-hint
                  :rules="rules.required_field"
                  :items="filtered_features"
                  item-text="text"
                  item-value="value"
                  label="To Feature"
                ></v-select>
              </v-flex>
            </v-layout>

            <v-flex xs6>
              <v-select
                v-if="connection_type == 1 && !prev_connection_loaded"
                class="pl-2 pr-2 pb-3"
                dense
                v-model="from_segment"
                :items="from_segments"
                item-text="path"
                item-value="path"
                label="From Segment"
              ></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select
                v-if="connection_type == 1 && !prev_connection_loaded"
                class="pl-2 pr-2 pb-3"
                dense
                v-model="to_segment"
                :items="to_segments"
                item-text="path"
                item-value="path"
                label="To Segment"
              ></v-select>
            </v-flex>

            <v-flex xs6>
              <v-text-field
                v-if="connection_type && from_feature"
                class="pl-2 pr-2 pb-3"
                dense
                v-model="from_range"
                :rules="rules.required_field"
                :placeholder="from_range_place_holder"
                :label="from_range_place_holder"
              ></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field
                v-if="connection_type && to_feature"
                class="pl-2 pr-2 pb-3"
                dense
                v-model="to_range"
                :rules="rules.required_field"
                :placeholder="to_range_place_holder"
                :label="to_range_place_holder"
              ></v-text-field>
            </v-flex>

            <!-- port types -->
            <v-flex xs6>
              <div>
                <v-select
                  v-if="show_from_port_type"
                  class="pl-2 pr-2 pb-3"
                  dense
                  :rules="rules.required_field"
                  v-model="from_port_type"
                  :items="from_port_types"
                  item-text="text"
                  item-value="value"
                  label="From Port Type"
                ></v-select>

                <v-select
                  v-if="from_rtu_connection"
                  class="pl-2 pr-2 pb-3"
                  dense
                  :rules="rules.required_field"
                  v-model="optical_route"
                  :items="filteredOpticalRoutes"
                  item-text="name"
                  item-value="id"
                  label="Monitored Optical Route"
                ></v-select>
              </div>
              <div></div>
            </v-flex>

            <v-flex xs6>
              <div v-if="show_to_port_type">
                <v-select
                  class="pl-2 pr-2 pb-3"
                  dense
                  :rules="rules.required_field"
                  v-model="to_port_type"
                  :items="to_port_types"
                  item-text="text"
                  item-value="value"
                  label="To Port Type"
                ></v-select>

                <v-select
                  v-if="to_rtu_connection"
                  class="pl-2 pr-2 pb-3"
                  dense
                  :rules="rules.required_field"
                  v-model="optical_route"
                  :items="filteredOpticalRoutes"
                  item-text="name"
                  item-value="id"
                  label="Monitored Optical Route"
                ></v-select>
              </div>
              <div></div>
            </v-flex>

            <!-- slack_type -->
            <v-flex
              v-if="connection_type == 1 && from_feature && to_feature"
              xs6
            >
              <v-select
                class="pl-2 pr-2 pb-3"
                dense
                v-model="splice_type"
                :rules="rules.required_field"
                :items="splice_types"
                item-text="text"
                item-value="value"
                label="Splice Type"
              ></v-select>
            </v-flex>

            <v-flex
              v-if="connection_type == 1 && from_feature && to_feature"
              xs6
            >
              <v-select
                class="pl-2 pr-2 pb-3"
                dense
                v-model="closure"
                :items="closures"
                :rules="rules.required_field"
                item-text="text"
                item-value="value"
                label="Closure"
              ></v-select>
            </v-flex>

            <v-flex
              v-if="connection_type == 1 && from_feature && to_feature"
              xs6
            >
              <v-text-field
                class="pl-2 pr-2 pb-3"
                dense
                v-model="splice_tray"
                placeholder="Closure Tray"
                label="Closure Tray"
                type="number"
              ></v-text-field>
            </v-flex>

            <v-flex xs6 v-if="connection_type == 3">
              <v-text-field
                class="pl-2 pr-2 pb-3"
                dense
                :rules="rules.required_field"
                v-model="patch_cord_length"
                placeholder="Patch Cord Length"
                label="Patch Cord Length (m)"
                type="number"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions class="pb-8">
        <v-btn class="pl-3 ml-4" @click="clearSelection">Clear Selection</v-btn>
        <v-btn
          v-if="!prev_connection_loaded"
          class="pl-3 ml-4"
          color="success"
          :disabled="!valid"
          :loading="loading"
          @click="makeConnection"
          >{{ dialog_button_text }}</v-btn
        >

        <v-btn
          v-if="prev_connection_loaded"
          class="pl-3 ml-4"
          color="error"
          outlined
          :disabled="!valid"
          :loading="loading"
          @click="showUndoConnectionsWarning"
          >Undo Connection</v-btn
        >

        <v-btn
          absolute
          right
          v-if="prev_connection_exists && !prev_connection_loaded"
          outlined
          class="pl-3 ml-4"
          color="success"
          @click="loadPrevConnection"
          >Back</v-btn
        >
      </v-card-actions>

      <InfoDialog
        :message="connections_message"
        :dialogModel="dialog"
        @closeDialog="closeDialog"
        @actionConfirmed="connectionConfirmed"
      ></InfoDialog>

      <InfoDialog
        :message="undo_connections_message"
        :dialogModel="generic_dialog"
        @closeDialog="closeDialog"
        @actionConfirmed="execUndoConnection"
      ></InfoDialog>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../../utils/mixins";
import fmsMixin from "../../../../utils/featuresMixins/fmsMixin";
import InfoDialog from "../../../generic/InfoDialog";
import GeoJSON from "ol/format/GeoJSON";
import {
  FEATURE_BUILDING,
  FEATURE_CLOSURE,
  FEATURE_POLE,
  FEATURE_SITE,
  FEATURE_MANHOLE,
  FEATURE_ODF,
  FEATURE_SPLITTER,
  FEATURE_FIBER_CABLE,
  FEATURE_CABINET,
  PORT_EQUIPMENT,
  FEATURE_FDP,
  FEATURE_FACE_PLATE,
  IS_SPLICE_CONNECTION,
  IS_PORT_CONNECTION,
  FEATURE_ONT,
  FEATURE_OLT,
  FEATURE_HANDHOLE,
  FEATURE_RTU,
} from "../../../../utils/feature_constants";
import {
  getFeatureById,
  getDerivedName,
  getFeaturesByType,
  getClosuresOnFiberCable,
  getFeatureClosestToCoordinates,
  getFeaturesOnFiberCable,
  getLocationFeatures,
} from "../../../../utils/map_functions";
import Point from "ol/geom/Point";
import {
  lineSlice,
  lineString as turfLineString,
  point as turfPoint,
} from "@turf/turf";

export default {
  name: "NewConnectionComponent",
  mixins: [dialogMixins, fmsMixin],
  props: {
    features: {
      type: Array,
    },
    vectorSources: {
      type: Array,
    },
    previous_connection: {
      type: Object,
    },
  },
  components: { InfoDialog },
  data: () => ({
    optical_route: null,
    prev_connection_exists: false,
    prev_connection_loaded: false,
    connections_message:
      "You will overwrite some already made connections. Are you sure you want to proceed?",
    undo_connections_message:
      "You will delete some already made connections. Are you sure you want to proceed?",
    checked_for_connections: false,
    connections_exist: false,
    cable_segments: {},
    from_segment: null,
    to_segment: null,
    from_segments: [],
    to_segments: [],
    loading: false,
    connection: {},
    closure: null,
    splice_tray: null,
    dialog_button_text: "Connect",
    valid: true,
    status: "",
    to_range: "",
    from_range: "",
    to_feature: null,
    from_port_type: null,
    to_port_type: null,
    from_feature: null,
    patch_cord_length: "",
    location: null,
    connection_types: [
      {
        text: "Splice",
        value: 1,
      },
      {
        text: "Termination",
        value: 2,
      },
      {
        text: "Patch",
        value: 3,
      },
    ],
    splice_types: [
      {
        text: "Fusion Splice",
        value: 1,
      },
      {
        text: "Mechanical Splice",
        value: 2,
      },
    ],
    splitter_port_types: [
      {
        text: "INPUT PORT",
        value: 1,
      },
      {
        text: "OUTPUT PORTS",
        value: 2,
      },
    ],
    odf_port_types: [
      {
        text: "PATCH PORTS",
        value: 1,
      },
      {
        text: "PIGTAIL PORTS",
        value: 2,
      },
    ],
    fdp_port_types: [
      {
        text: "CUSTOMER PORTS",
        value: 1,
      },
      {
        text: "INCOMING CABLE PORTS",
        value: 2,
      },
    ],
    ont_port_types: [
      {
        text: "CUSTOMER PORT",
        value: 1,
      },
      {
        text: "DROP CABLE PORTS",
        value: 2,
      },
    ],
    rtu_port_types: [
      {
        text: "OUTPUT PORTS",
        value: 2,
      },
    ],
    olt_port_types: [
      {
        text: "OLT OUTPUT PORTS",
        value: 2,
      },
    ],
    other_port_types: [
      {
        text: "INPUT PORTS",
        value: 1,
      },
      {
        text: "OUTPUT PORTS",
        value: 2,
      },
    ],
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    splice_type: 1,
    connection_type: null,
    geoJSON: null,
  }),
  async mounted() {
    this.geoJSON = new GeoJSON();
    if (this.previous_connection.connection_uuid) {
      this.prev_connection_exists = true;
    }
  },
  watch: {
    token: function () {
      this.getOpticalRoutes();
    },

    connections_exist: function (val) {
      if (val) this.dialog = !this.dialog;
    },
    from_feature: function (val) {
      let selectedFeature = getFeatureById(val, this.vectorSources);
      if (selectedFeature.get("type") == FEATURE_FIBER_CABLE) {
        // highlight cable initially
        this.$emit("highlightFromSegment", selectedFeature);

        // get all segments from cable
        this.getFromConnectionSegments(selectedFeature);
      }

      if (selectedFeature.get("type") == FEATURE_RTU)
        this.getRTU(selectedFeature.get("attached_rtu"));
    },
    to_feature: function (val) {
      let selectedFeature = getFeatureById(val, this.vectorSources);
      if (selectedFeature.get("type") == FEATURE_FIBER_CABLE) {
        // highlight cable initially
        this.$emit("highlightToSegment", selectedFeature);

        // get all segments from cable
        this.getToConnectionSegments(selectedFeature);
      }

      if (selectedFeature.get("type") == FEATURE_RTU)
        this.getRTU(selectedFeature.get("attached_rtu"));
    },
    to_segment: function (val) {
      let segment = this.to_segments.find((seg) => seg.path == val);
      this.$emit("highlightToSegment", segment);
    },
    from_segment: function (val) {
      let segment = this.from_segments.find((seg) => seg.path == val);
      this.$emit("highlightFromSegment", segment);
    },
  },
  computed: {
    filteredOpticalRoutes: function () {
      if (this.selectedRtu && this.selectedRtu.monitoredAssets) {
        return this.opticalRoutes.filter((route) =>
          this.selectedRtu.monitoredAssets.OpticalRoute.includes(route.id)
        );
      }

      return [];
    },

    from_rtu_connection: function () {
      let feature = getFeatureById(this.from_feature, this.vectorSources);
      return feature && feature.get("type") == FEATURE_RTU;
    },
    to_rtu_connection: function () {
      let feature = getFeatureById(this.to_feature, this.vectorSources);
      return feature && feature.get("type") == FEATURE_RTU;
    },
    from_feature_size: function () {
      if (this.from_feature) {
        let feature = getFeatureById(this.from_feature, this.vectorSources);
        if (feature.get("type") == FEATURE_FIBER_CABLE)
          return `${feature.get("cable_size")} CORE CABLE`;
        if (feature.get("type") == FEATURE_ODF)
          return `${feature.get("output_ports")} PORT ODF`;
        if (feature.get("type") == FEATURE_SPLITTER)
          return `1/${feature.get("output_ports")} SPLITTER`;
      }

      return "";
    },
    to_feature_size: function () {
      if (this.to_feature) {
        let feature = getFeatureById(this.to_feature, this.vectorSources);
        if (feature.get("type") == FEATURE_FIBER_CABLE)
          return `${feature.get("cable_size")} CORE CABLE`;
        if (feature.get("type") == FEATURE_ODF)
          return `${feature.get("output_ports")} PORT ODF`;
        if (feature.get("type") == FEATURE_SPLITTER)
          return `1/${feature.get("output_ports")} SPLITTER`;
      }

      return "";
    },
    from_range_place_holder: function () {
      if (this.from_feature) {
        let feature = getFeatureById(this.from_feature, this.vectorSources);

        if (PORT_EQUIPMENT.includes(feature.get("type")))
          return "Ports Range, eg 1,2 or 1-12";
      }

      return "Fiber Range, eg 1,2 or 1-12";
    },
    to_range_place_holder: function () {
      if (this.to_feature) {
        let feature = getFeatureById(this.to_feature, this.vectorSources);

        if (PORT_EQUIPMENT.includes(feature.get("type")))
          return "Ports Range, eg 1,2 or 1-12";
      }

      return "Fiber Range, eg 1,2 or 1-12";
    },
    from_port_types: function () {
      if (this.from_feature) {
        let feature = getFeatureById(this.from_feature, this.vectorSources);

        if (
          feature.get("type") == FEATURE_ODF ||
          feature.get("type") == FEATURE_FACE_PLATE
        )
          return this.odf_port_types;
        if (feature.get("type") == FEATURE_FDP) return this.fdp_port_types;
        if (feature.get("type") == FEATURE_OLT) return this.ont_port_types;
        if (feature.get("type") == FEATURE_RTU) return this.rtu_port_types;
        if (feature.get("type") == FEATURE_OLT) return this.olt_port_types;
        if (feature.get("type") == FEATURE_SPLITTER)
          return this.splitter_port_types;
      }

      return this.other_port_types;
    },
    to_port_types: function () {
      if (this.to_feature) {
        let feature = getFeatureById(this.to_feature, this.vectorSources);

        if (
          feature.get("type") == FEATURE_ODF ||
          feature.get("type") == FEATURE_FACE_PLATE
        )
          return this.odf_port_types;
        if (feature.get("type") == FEATURE_FDP) return this.fdp_port_types;
        if (feature.get("type") == FEATURE_ONT) return this.ont_port_types;
        if (feature.get("type") == FEATURE_OLT) return this.olt_port_types;
        if (feature.get("type") == FEATURE_RTU) return this.rtu_port_types;
        if (feature.get("type") == FEATURE_SPLITTER)
          return this.splitter_port_types;
      }

      return this.other_port_types;
    },
    features_selected: function () {
      if (this.features.length == 0) return "No features selected";
      return `${this.features.length} fearures selected`;
    },
    features_selected_class: function () {
      if (this.features.length == 0) return "error--text";
      return "primary--text";
    },
    filtered_features: function () {
      let filteredList = [];
      let validTypes = [
        FEATURE_ODF,
        FEATURE_SPLITTER,
        FEATURE_FIBER_CABLE,
        FEATURE_FDP,
        FEATURE_FACE_PLATE,
        FEATURE_ONT,
        FEATURE_OLT,
        FEATURE_RTU,
      ];

      let spliceFeatures = [FEATURE_FIBER_CABLE];
      let patchFeatures = [FEATURE_ODF, FEATURE_RTU];
      var filterTypes = validTypes;
      if (this.connection_type == 1) filterTypes = spliceFeatures;
      if (this.connection_type == 3) filterTypes = patchFeatures;

      this.features.forEach((feature) => {
        if (filterTypes.includes(feature.get("type"))) {
          filteredList.push({
            text: getDerivedName(feature, this.vectorSources),
            value: feature.getId(),
          });
        }
      });

      return filteredList;
    },
    closures: function () {
      let filteredList = [];
      let validTypes = [FEATURE_CLOSURE];

      this.features.forEach((feature) => {
        if (validTypes.includes(feature.get("type")))
          filteredList.push({
            text: feature.get("name"),
            value: feature.getId(),
          });
      });

      return filteredList;
    },
    locations: function () {
      let filteredList = [];
      let validTypes = [
        FEATURE_BUILDING,
        FEATURE_POLE,
        FEATURE_SITE,
        FEATURE_MANHOLE,
        FEATURE_CABINET,
      ];

      this.features.forEach((feature) => {
        if (validTypes.includes(feature.get("type")))
          filteredList.push({
            text: feature.get("name"),
            value: feature.getId(),
          });
      });

      return filteredList;
    },
    show_from_port_type: function () {
      return (
        this.from_feature &&
        getFeatureById(this.from_feature, this.vectorSources).get("type") !=
          FEATURE_FIBER_CABLE
      );
    },
    show_to_port_type: function () {
      return (
        this.to_feature &&
        getFeatureById(this.to_feature, this.vectorSources).get("type") !=
          FEATURE_FIBER_CABLE
      );
    },
  },
  methods: {
    ...mapActions({
      getFiberSegmentFeatures: "getFiberCableSegmentFeatures",
      createConnection: "createConnection",
      checkConnections: "checkConnections",
      undoConnection: "undoConnection",
      getMonitoredRoutes: "getMonitoredRoutes",
    }),

    async connectionConfirmed() {
      this.closeDialog();
      this.checked_for_connections = true;
      await this.makeConnection();
    },

    loadPrevConnection() {
      let connectionPayload = this.previous_connection;
      this.prev_connection_loaded = true;
      this.connection_type = connectionPayload.connection_type;
      this.to_feature = connectionPayload.to_feature;
      this.from_feature = connectionPayload.from_feature;
      this.location = connectionPayload.location;
      this.from_range = connectionPayload.from_range;
      this.to_range = connectionPayload.to_range;

      if (connectionPayload.splice_tray)
        this.splice_tray = connectionPayload.splice_tray;
      if (connectionPayload.closure_obj)
        this.closure = connectionPayload.closure_obj;
      if (connectionPayload.patch_cord_length)
        this.patch_cord_length = connectionPayload.patch_cord_length;
      if (connectionPayload.from_port_type)
        this.from_port_type = connectionPayload.from_port_type;
      if (connectionPayload.to_port_type)
        this.to_port_type = connectionPayload.to_port_type;
      if (connectionPayload.from_segment)
        this.from_segment = JSON.parse(connectionPayload.from_segment);
      if (connectionPayload.to_segment)
        this.from_segment = JSON.parse(connectionPayload.to_segment);
      if (connectionPayload.optical_route_id)
        this.optical_route = connectionPayload.optical_route_id;
    },

    getFromConnectionSegments(fiberCable) {
      this.from_segments = [];

      let referencePoint = getFeatureById(this.location, this.vectorSources);
      let endPoint = referencePoint.getGeometry().getCoordinates();
      let lineStringCoordinates = fiberCable.getGeometry().getCoordinates();

      // check for network features that lie of the line
      let closures = getFeaturesByType(FEATURE_CLOSURE, this.vectorSources);
      let odfs = getFeaturesByType(FEATURE_ODF, this.vectorSources);
      let networkFeatures = closures.concat(odfs);

      let featuresOnCable = getFeaturesOnFiberCable(
        fiberCable,
        networkFeatures
      );

      if (featuresOnCable) {
        featuresOnCable.forEach((feature, index) => {
          let startPoint = feature.getGeometry().getCoordinates();

          // check if the coords are the same
          if (endPoint[0] != startPoint[0] && endPoint[1] != startPoint[1]) {
            let accessPoints = getFeatureClosestToCoordinates(
              startPoint,
              this.vectorSources,
              [
                FEATURE_MANHOLE,
                FEATURE_HANDHOLE,
                FEATURE_SITE,
                FEATURE_BUILDING,
              ]
            );
            if (accessPoints) feature = accessPoints[0];

            let currentIndex = this.from_segments.length;
            let pathObj = {
              path: `Seg ${currentIndex + 1} ${feature.get(
                "name"
              )} - ${referencePoint.get("name")}`,
              segment_feature: feature,
              segment: lineSlice(
                turfPoint(startPoint),
                turfPoint(endPoint),
                turfLineString(lineStringCoordinates)
              ),
            };

            this.from_segments.push(pathObj);
          }
        });
      }
    },

    getToConnectionSegments(fiberCable) {
      this.to_segments = [];

      let referencePoint = getFeatureById(this.location, this.vectorSources);
      let endPoint = referencePoint.getGeometry().getCoordinates();
      let lineStringCoordinates = fiberCable.getGeometry().getCoordinates();

      // check for network features that lie of the line
      let closures = getFeaturesByType(FEATURE_CLOSURE, this.vectorSources);
      let odfs = getFeaturesByType(FEATURE_ODF, this.vectorSources);
      let networkFeatures = closures.concat(odfs);

      let featuresOnCable = getFeaturesOnFiberCable(
        fiberCable,
        networkFeatures
      );

      if (featuresOnCable) {
        featuresOnCable.forEach((feature, index) => {
          let startPoint = feature.getGeometry().getCoordinates();

          // check if the coords are the same
          if (endPoint[0] != startPoint[0] && endPoint[1] != startPoint[1]) {
            let accessPoints = getFeatureClosestToCoordinates(
              startPoint,
              this.vectorSources,
              [
                FEATURE_MANHOLE,
                FEATURE_HANDHOLE,
                FEATURE_SITE,
                FEATURE_BUILDING,
              ]
            );

            if (accessPoints) feature = accessPoints[0];

            let currentIndex = this.to_segments.length;
            let pathObj = {
              path: `Seg ${referencePoint.get("name")} - ${
                currentIndex + 1
              } ${feature.get("name")}`,
              segment_feature: feature,
              segment: lineSlice(
                turfPoint(startPoint),
                turfPoint(endPoint),
                turfLineString(lineStringCoordinates)
              ),
            };

            this.to_segments.push(pathObj);
          }
        });
      }
    },

    showUndoConnectionsWarning() {
      this.generic_dialog = !this.generic_dialog;
    },

    async execUndoConnection() {
      if (this.previous_connection.connection_uuid) {
        let payload = {};
        payload.connection_uuid = this.previous_connection.connection_uuid;
        try {
          await this.undoConnection(payload);
          this.$emit("connectionPayload", {});
          this.displaySuccessAlert("Connections undone successfully");
          this.$emit("refreshComponents");
        } catch (e) {
          this.this.displayErrorAlert("Connection could not be undone.");
        }
      }
    },

    async makeConnection() {
      this.closeDialog();

      if (this.$refs.form.validate()) {
        this.loading = true;

        let fromFeature = getFeatureById(this.from_feature, this.vectorSources);
        let toFeature = getFeatureById(this.to_feature, this.vectorSources);

        let connectionPayload = {};
        connectionPayload.to_feature = this.to_feature;
        connectionPayload.from_feature = this.from_feature;
        connectionPayload.connection_type = this.connection_type;
        connectionPayload.operation = IS_PORT_CONNECTION;

        /**
         * Connection process proceeds as follows:
         * 1) Check for SPLICE, or if we are splicing (both features are fiber cables)
         * 2) Check for PATCH, or if both features are port equipment (OR not fiber cables)
         * 3) Check for TERMINATION. We only get here if we are connecting a fiber cable and a port
         */

        if (
          fromFeature.get("type") == FEATURE_FIBER_CABLE &&
          toFeature.get("type") == FEATURE_FIBER_CABLE
        ) {
          connectionPayload.operation = IS_SPLICE_CONNECTION;

          connectionPayload.splice_type = this.splice_type;
          connectionPayload.splice_tray = this.splice_tray;
          connectionPayload.closure_obj = this.closure;
          connectionPayload.closure = getFeatureById(
            this.closure,
            this.vectorSources
          ).get("pk");
          connectionPayload.from_cable = fromFeature.get("pk");
          connectionPayload.to_cable = toFeature.get("pk");
        } else if (
          fromFeature.get("type") != FEATURE_FIBER_CABLE &&
          toFeature.get("type") != FEATURE_FIBER_CABLE
        ) {
          connectionPayload.from_port_type = this.from_port_type;
          connectionPayload.to_port_type = this.to_port_type;
          connectionPayload.from_equipment_uuid = fromFeature.getId();
          connectionPayload.to_equipment_uuid = toFeature.getId();
        } else {
          if (fromFeature.get("type") == FEATURE_FIBER_CABLE) {
            connectionPayload.from_equipment_uuid = toFeature.getId();
            connectionPayload.from_port_type = this.to_port_type;
            connectionPayload.to_cable = fromFeature.get("pk");
          } else {
            connectionPayload.from_port_type = this.from_port_type;
            connectionPayload.from_equipment_uuid = fromFeature.getId();
            connectionPayload.to_cable = toFeature.get("pk");
          }
        }

        if (this.from_segment) {
          let segment = this.from_segments.find(
            (seg) => seg.path == this.from_segment
          );
          if (segment) {
            connectionPayload.from_path = JSON.stringify(segment.segment);
          }
        }

        if (this.to_segment) {
          let segment = this.to_segments.find(
            (seg) => seg.path == this.to_segment
          );
          if (segment) {
            connectionPayload.to_path = JSON.stringify(segment.segment);
          }
        }

        connectionPayload.location = this.location;
        connectionPayload.from_range = this.from_range;
        connectionPayload.to_range = this.to_range;

        if (this.patch_cord_length)
          connectionPayload.patch_cord_length = this.patch_cord_length;

        if (this.splice_tray) connectionPayload.splice_tray = this.splice_tray;

        if (this.optical_route)
          connectionPayload.optical_route_id = this.optical_route;

        console.log("Connection", connectionPayload);

        if (!this.checked_for_connections) {
          const res = await this.checkConnections(connectionPayload);
          if (res.exists) {
            /**
             * Connection exists, so display a dialog for user to double check connections
             */
            this.connections_exist = res.exists;
            this.loading = false;
          } else {
            /**
             * No connections exist, so proceed with making the connection
             */
            await this.connectionConfirmed();
          }
        } else {
          try {
            const res = await this.createConnection(connectionPayload);
            if (res.uuid) {
              connectionPayload.connection_uuid = res.uuid;

              // remove from_path, to_path segments
              delete connectionPayload.from_path;
              delete connectionPayload.to_path;

              this.$emit("connectionPayload", connectionPayload);
            }

            this.checked_for_connections = false;
            this.loading = false;
            this.displaySuccessAlert("Success!");
            this.$emit("refreshComponents");
          } catch (e) {
            this.loading = false;
            this.displayErrorAlert(
              "The connection was not completed! Make sure that your connection data is correct."
            );
          }
        }
      } else {
        this.displayErrorAlert(
          "The selected ranges must be equal, must not contain any colons, and there should not be any duplicate fibers/ports"
        );
      }
    },

    onBeforeSplice() {
      // checks that both from and to features are fiber cables
      let isConnectionRange = this.isConnectionRange(
        this.from_range,
        this.to_range
      );
      let isConnectionRangeEqual = this.isConnectionRangeEqual(
        this.from_range,
        this.to_range
      );

      return !isConnectionRange || isConnectionRangeEqual;
    },

    isConnectionRange(from_range, to_range) {
      return (
        from_range.toString().includes("-") ||
        to_range.toString().includes("-") ||
        from_range.toString().includes(",") ||
        to_range.toString().includes(",") ||
        from_range.toString().includes(":") ||
        to_range.toString().includes(":")
      );
    },

    isConnectionRangeEqual(from_range, to_range) {
      var is_range_equal = true;
      if (
        from_range.split("-").length == 2 &&
        to_range.split("-").length == 2
      ) {
        if (
          from_range.split("-")[1] - from_range.split("-")[0] !=
          to_range.split("-")[1] - to_range.split("-")[0]
        ) {
          is_range_equal = false;
        }
      }

      if (from_range.split(",").length > 0 && to_range.split(",").length > 0) {
        if (
          this.rangeContainsDuplicates(from_range) ||
          this.rangeContainsDuplicates(to_range)
        ) {
          is_range_equal = false;
        }
      }

      if (from_range.indexOf(":") > -1 || to_range.indexOf(":") > -1) {
        is_range_equal = false;
      }

      if (from_range.indexOf(";") > -1 || to_range.indexOf(";") > -1) {
        is_range_equal = false;
      }

      return is_range_equal;
    },

    rangeContainsDuplicates(range_string) {
      let rangeArray = range_string.split(",");
      let rangeSet = new Set();
      rangeArray.forEach((item) => rangeSet.add(item));

      return rangeArray.length != rangeSet.size;
    },

    clearSelection() {
      this.$emit("refreshComponents");
    },

    displaySuccessAlert(message) {
      this.$notify({
        group: "success",
        title: "",
        text: message,
      });
    },

    displayErrorAlert(message) {
      this.$notify({
        group: "error",
        title: "",
        text: message,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>