const {
  FEATURE_BUILDING,
  FEATURE_POLE,
  FEATURE_MANHOLE,
  FEATURE_HANDHOLE,
  FEATURE_CABINET,
  FEATURE_ODF,
  FEATURE_SPLITTER,
  FEATURE_CLOSURE,
  FEATURE_SITE,
  FEATURE_FDP,
  FEATURE_FACE_PLATE,
  FEATURE_ONT,
  FEATURE_OLT,
  REDLINE_POINT,
  ROAD_CROSSING_HOOK,
  FEATURE_SPLICE_SCHEMATIC_POINT,
  POLE_FIBER_CABLE,
  POLE_DUCT,
  BLUE_PRINT_POINT,
  FEATURE_POINT_HIGHLIGHT,
  FEATURE_RTU,
  FAULT,
} = require("./feature_constants");

import {
  cabinetIcon,
  odfIcon,
  proposedAltManholeIcon,
  altManholeIcon,
  proposedCabinetIcon,
  proposedOdfIcon,
} from "./feature_icons";

const _featureStylesConfig = {};

_featureStylesConfig[BLUE_PRINT_POINT] = {
  color: "red",
  points: 12,
  radius: 3,
};

_featureStylesConfig[FEATURE_RTU] = {
  color: "green",
  points: 12,
  radius: 4,
};

_featureStylesConfig[FEATURE_BUILDING] = {
  color: "blue",
  points: 6,
  radius: 8,
};

_featureStylesConfig[REDLINE_POINT] = {
  color: "red",
  points: 12,
  radius: 6,
};

_featureStylesConfig[FEATURE_POINT_HIGHLIGHT] = {
  color: "rgba(0,0,0,0)",
  strokeColor: "red",
  points: 4,
  radius: 16,
};

_featureStylesConfig[FAULT] = {
  color: "red",
  strokeColor: "red",
  points: 12,
  radius: 6,
};

_featureStylesConfig[FEATURE_FDP] = {
  color: "yellow",
  points: 8,
  radius: 1,
};

_featureStylesConfig[FEATURE_SITE] = {
  color: "red",
  points: 3,
  radius: 8,
};

_featureStylesConfig[FEATURE_POLE] = {
  color: "black",
  points: 4,
  radius: 8,
  radius2: 0,
  angle: Math.PI / 4,
};

_featureStylesConfig[ROAD_CROSSING_HOOK] = {
  color: "orange",
  points: 10,
  radius: 4,
  radius2: 8,
  // angle: Math.PI / 4
};

_featureStylesConfig[FEATURE_MANHOLE] = {
  color: "red",
  points: 4,
  radius: 8,
  angle: Math.PI / 4,
};

_featureStylesConfig[FEATURE_FACE_PLATE] = {
  color: "yellow",
  points: 4,
  radius: 1,
  angle: Math.PI / 4,
};

_featureStylesConfig[FEATURE_ONT] = {
  color: "orange",
  points: 6,
  radius: 1,
  angle: Math.PI / 4,
};

_featureStylesConfig[FEATURE_OLT] = {
  color: "orange",
  points: 6,
  radius: 1,
  angle: Math.PI / 4,
};

_featureStylesConfig[FEATURE_HANDHOLE] = {
  color: "blue",
  points: 4,
  radius: 8,
  angle: Math.PI / 4,
};

_featureStylesConfig[FEATURE_CABINET] = {
  color: "green",
  points: 3,
  radius: 8,
  rotation: Math.PI / 4,
  angle: 0,
  icon: cabinetIcon,
};

_featureStylesConfig[FEATURE_ODF] = {
  color: "yellow",
  points: 4,
  radius: 1,
  angle: Math.PI / 4,
};

_featureStylesConfig[POLE_DUCT] = {
  color: "black",
  points: 4,
  radius: 1,
  angle: Math.PI / 4,
};

_featureStylesConfig[POLE_FIBER_CABLE] = {
  color: "black",
  points: 4,
  radius: 1,
  angle: Math.PI / 4,
};

_featureStylesConfig[FEATURE_CLOSURE] = {
  color: "teal",
  points: 4,
  radius: 1,
  angle: Math.PI / 4,
};

_featureStylesConfig[FEATURE_SPLITTER] = {
  color: "teal",
  points: 4,
  sides: 8,
  radius: 1,
  angle: Math.PI / 4,
};

const _proposedStylesConfig = {};

_proposedStylesConfig[FEATURE_MANHOLE] = {
  icon: proposedAltManholeIcon,
};

_proposedStylesConfig[FEATURE_CABINET] = {
  icon: proposedCabinetIcon,
};

_proposedStylesConfig[FEATURE_ODF] = {
  icon: proposedOdfIcon,
};

const _implStylesConfig = {};

_implStylesConfig[FEATURE_MANHOLE] = {
  icon: altManholeIcon,
};

_implStylesConfig[FEATURE_CABINET] = {
  icon: proposedCabinetIcon,
};

_implStylesConfig[FEATURE_ODF] = {
  icon: proposedOdfIcon,
};

const _schematicsStylesConfig = {};

_schematicsStylesConfig[FEATURE_CLOSURE] = {
  color: "#07266b",
  points: 20,
  radius: 3,
  angle: Math.PI / 4,
};

_schematicsStylesConfig[FEATURE_CABINET] = {
  color: "green",
  points: 3,
  radius: 10,
  rotation: Math.PI / 4,
  angle: 0,
  icon: cabinetIcon,
};

_schematicsStylesConfig[FEATURE_ODF] = {
  color: "black",
  points: 4,
  radius: 10,
  scale: 0.2,
  angle: Math.PI / 4,
  icon: odfIcon,
};

_schematicsStylesConfig[FEATURE_SPLICE_SCHEMATIC_POINT] = {
  color: "#f8334e",
  strokeColor: "#f8334e",
  points: 10,
  radius: 3,
};

const _ductPlanStylesConfig = {};
_ductPlanStylesConfig[FEATURE_MANHOLE] = {
  color: "#FF0000",
  strokeColor: "black",
  points: 4,
  radius: 6,
};

_ductPlanStylesConfig[FEATURE_SITE] = {
  color: "#FF0000",
  strokeColor: "black",
  points: 3,
  radius: 6,
};

_ductPlanStylesConfig[FEATURE_HANDHOLE] = {
  color: "#FF1493",
  strokeColor: "black",
  points: 4,
  radius: 6,
};

export var featureStylesConfig = _featureStylesConfig;

export var proposedStylesConfig = _proposedStylesConfig;

export var implStylesConfig = _implStylesConfig;

export var schematicsStylesConfig = _schematicsStylesConfig;

export var ductPlanStylesConfig = _ductPlanStylesConfig;
