<template>
  <v-dialog v-model="dialog" persistent width="650px">
    <v-card class="pb-3">
      <v-card-title justify-center class="primary white--text justify-center">{{
        dialog_title
      }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="user.name"
                  :rules="rules.required_field"
                  placeholder="User Name"
                  label="User Name"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs8 class="mt-3 pr-3">
              <v-text-field
                v-model="user.email"
                :rules="rules.email_field"
                label="Email Address"
                placeholder="Email Address"
              ></v-text-field>
            </v-flex>

            <v-flex class="pl-3 mt-3" xs4>
              <v-text-field
                v-model="user.phone_number"
                :rules="rules.phone_field"
                label="Phone Number"
                placeholder="Pohne Number (2547xxxxxxxx)"
              ></v-text-field>
            </v-flex>

            <v-flex
              class="mt-3"
              v-if="loggedinUser.customer_id == undefined"
              xs8
            >
              <v-select
                item-text="name"
                item-value="value"
                dense
                v-model="user.user_type"
                label="User Type"
                placeholder="User Type"
                chips
                :items="user_groups"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="loading"
          @click="createOrEditUser"
          >{{ dialog_button_text }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../utils/mixins";

export default {
  name: "UserDialogComponent",
  mixins: [dialogMixins],
  props: {
    customer_id: {
      type: Number,
    },
    dialogModel: {
      type: Boolean,
      required: true,
    },
    editableEntity: {
      type: Object,
    },
  },
  data: () => ({
    id: "",
    is_edit_mode: false,
    loading: false,
    user_groups: [
      { name: "NOC", value: 2 },
      { name: "FINANCE", value: 3 },
      { name: "ADMINISTRATOR", value: 4 },
      { name: "DOCUMENTATION", value: 5 },
      { name: "PLANNING", value: 6 },
    ],
    dialog_title: "Add User",
    dialog_button_text: "Create",
    valid: true,
    rules: {
      email_field: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Field should be a valid email",
      ],
      required_field: [(v) => !!v || "Field is required"],
      phone_field: [(v) => v.startsWith("2547") || "Add a valid phone number"],
    },
    dialog: null,
    user: {},
  }),
  computed: {
    ...mapState({
      loggedinUser: (state) => state.usersModule.current_user,
    }),
  },
  mounted() {
    this.dialog = this.dialogModel;
    this.user = {};
  },
  methods: {
    ...mapActions({
      createUser: "createUser",
    }),
    createOrEditUser() {
      if (this.$refs.form.validate()) {
        if (this.$route.params.id) {
          this.user["customer_id"] = this.$route.params.id;
        } else {
          if (this.loggedinUser.customer_id != undefined) {
            this.user["customer_id"] = this.loggedinUser.customer_id;
          }
        }

        // set password
        this.user["password"] = "password";

        this.createUser(this.user);
        this.resetFields();
        this.closeDialog();
      }
    },
    resetFields() {
      this.user = {};
      this.is_edit_mode = false;
      this.upload_file = null;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();

      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    editableEntity: function (val) {
      this.id = val.id;
      this.user = val;

      // remove id
      delete this.user.id;

      this.dialog_title = "Edit Customer";
      this.is_edit_mode = true;
      this.dialog_button_text = "Update";
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.user = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>