<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="success white--text justify-center">{{ dialog_title }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="trench.trench_distance"
                  :rules="rules.required_field"
                  placeholder="Trench Distance"
                  label="Length in meters"
                  type="number"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="trench.trench_depth"
                  :rules="rules.required_field"
                  placeholder="Trench Depth"
                  label="Depth in meters"
                  type="number"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <!-- soil_profile_type -->
            <v-flex>
              <v-select
                v-model="trench.soil_profile"
                :rules="rules.required_field"
                :items="soil_profiles"
                item-text="text"
                item-value="value"
                label="Soil Profile"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="createOrEdit"
        >{{ dialog_button_text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../../utils/mixins";

export default {
  name: "TrenchDialogComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    selectedFeatures: {
      type: Array,
    },
    vectorSources: {
      type: Array,
    },
    feature: {
      type: Object,
      required: true,
    },
    editModel: {
      type: Object,
    },
  },
  data: () => ({
    soil_profiles: [
      {
        text: "Rock",
        value: 1,
      },
      {
        text: "Sand",
        value: 2,
      },
      {
        text: "Soft",
        value: 3,
      },
      {
        text: "Unknown",
        value: 4,
      },
    ],
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "Add Trench",
    dialog_button_text: "Create",
    valid: true,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    dialog: null,
    trench: {},
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.trench = {};

    if (this.editModel) {
      this.is_edit_mode = true;

      Object.assign(this.trench, this.editModel);
      this.dialog_title = "Edit Trench";
      this.dialog_button_text = "Update";
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    ...mapActions({
      createTrench: "createTrench",
    }),
    createOrEdit() {
      if (this.$refs.form.validate()) {
        if (this.is_edit_mode) {
          console.log("To be implemented");
        } else {
          this.trench.duct = this.feature.get("pk");
          this.createTrench(this.trench)
            .then(() => {
              this.$emit("reloadTrenches");
              this.resetFields();
              this.closeDialog();
            })
            .catch(() => {
              this.resetFields();
              this.closeDialog();
            });
        }
      }
    },
    resetFields() {
      this.trench = {};
      this.is_edit_mode = false;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();
      this.$emit("closeDialog");
    },
  },
  watch: {
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.trench = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>