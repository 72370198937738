/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import {
  FEATURE_BUILDING,
  FEATURE_FIBER_CABLE,
  FEATURE_MANHOLE,
  FEATURE_SPLICE_SCHEMATIC_LINESTRING,
  FEATURE_SPLICE_SCHEMATIC_PATH,
  FEATURE_DUCT,
  FEATURE_MEASUREMENT_AREA,
  FEATURE_MEASUREMENT_LENGTH,
  FEATURE_SPLICE_SCHEMATIC,
  FEATURE_POLE,
  FEATURE_CABINET,
  FEATURE_SPLITTER,
  FEATURE_SITE,
  REDLINE_LINE,
  REDLINE_POLYGON,
  FEATURE_ROAD,
  AREA_OF_INTEREST,
  FEATURE_HANDHOLE,
  FEATURE_ODF,
  FEATURE_CLOSURE,
  FEATURE_FDP,
  FEATURE_CABLE_HIGHLIGHT,
  FEATURE_MICRO_TUNNEL,
  FEATURE_FACE_PLATE,
  FEATURE_ONT,
  FEATURE_OLT,
  REDLINE_POINT,
  ROAD_CROSSING_HOOK,
  TRENCH,
  FEATURE_SPLICE_SCHEMATIC_POINT,
  SIGNAL_TRACE,
  FEATURE_DUCT_PLAN,
  POLE_DUCT,
  POLE_FIBER_CABLE,
  BLUE_PRINT_LINE,
  BLUE_PRINT_POINT,
  GABION,
  BRIDGE_ATTACHMENT,
  FEATURE_POINT_HIGHLIGHT,
  FEATURE_RTU,
  FAULT,
} from "../feature_constants";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, XYZ, Vector as VectorSource } from "ol/source";
import {
  pointLayersStyleFunction,
  redliningStyleFunction,
  roadsStyleFunction,
  schematicsStyleFunction,
  ductPlanStyleFunction,
  areaOfInterestStyleFunction,
  namedRoadsStyleFunction,
} from "../feature_styles";

var alt_key =
  "pk.eyJ1IjoiZW11ZGRlIiwiYSI6ImNqbGJvbjI3eDNjbHkzanF5a2d5ank0cWgifQ.6wK8LAOaea5Z7FkxN9fquA";
var key =
  "pk.eyJ1IjoiZW5vY2ttdWRkZSIsImEiOiJjbGptcDU4bTYwM2F4M3FxbW9jdmY1emU2In0.tNsKSmVQK_1S2lWossgpuw";

export default {
  data: () => ({
    editableLayers: [],
    drawer: null,
    items: [
      {
        text: "Building",
        icon: "roofing",
        feature_type: "BUILDING",
      },
      {
        text: "Site",
        icon: "settings_input_antenna",
        feature_type: "SITE",
      },
      {
        text: "Cabinet",
        icon: "kitchen",
        feature_type: "CABINET",
      },
      {
        text: "Closure",
        icon: "developer_board",
        feature_type: "SPLICE_CLOSURE",
      },
      {
        text: "Road",
        icon: "stacked_line_chart",
        feature_type: "ROAD",
      },
      {
        text: "Manhole",
        icon: "radio_button_checked",
        feature_type: "MANHOLE",
      },
      {
        text: "Pole",
        icon: "text_fields",
        feature_type: "POLE",
      },
      {
        text: "ODF",
        icon: "keyboard",
        feature_type: "ODF",
      },
      {
        text: "Splitter",
        icon: "alt_route",
        feature_type: "SPLITTER",
      },
      {
        text: "FDP",
        icon: "settings_input_svideo",
        feature_type: "FDP",
      },
      {
        text: "Face Plate",
        icon: "album",
        feature_type: "FACE_PLATE",
      },
      {
        text: "ONT",
        icon: "router",
        feature_type: "ONT",
      },
      {
        text: "OLT",
        icon: "power_input",
        feature_type: "OLT",
      },
      {
        text: "Fiber Cable",
        icon: "timeline",
        feature_type: "FIBER_CABLE",
      },
      {
        text: "Duct",
        icon: "call_split",
        feature_type: "DUCT",
      },
      {
        text: "Trench",
        icon: "call_split",
        feature_type: "TRENCH",
      },
      {
        text: "Bridge Attachment",
        icon: "call_split",
        feature_type: BRIDGE_ATTACHMENT,
      },
      {
        text: "Gabion",
        icon: "call_split",
        feature_type: GABION,
      },

      {
        text: "Micro Tunnel",
        icon: "center_focus_strong",
        feature_type: FEATURE_MICRO_TUNNEL,
      },
      {
        text: "Red Line",
        icon: "stacked_line_chart",
        feature_type: REDLINE_LINE,
      },
      {
        text: "Area of Interest",
        icon: "emoji_flags",
        feature_type: AREA_OF_INTEREST,
      },
    ],

    roads_names_visible: true,

    // track which feature was modified
    modifiedFeature: null,

    // layers
    satelliteLayer: null,
    rasterLayer: null,
    osmLayer: null,
    measurementsLayer: null,
    buildingsLayer: null, // icon
    sitesLayer: null, // icon
    cabinetsLayer: null, // green triangle
    polesLayer: null, // black cross
    manholesLayer: null, // red square
    handholesLayer: null, // blue square
    closuresLayer: null, // white circle
    ductsLayer: null, // black dotted line
    fiberCablesLayer: null, // multi colored line
    highlightLayer: null,
    odfLayer: null,
    fdpLayer: null,
    facePlateLayer: null,
    onuLayer: null,
    oltLayer: null,
    splitterLayer: null,
    microTunnelLayer: null,
    redliningLayer: null,
    roadCrossingLayer: null,
    roadsLayer: null,
    trenchLayer: null,
    splicesLayer: null,
    ductPlanLayer: null,
    signalTracerLayer: null,
    blueprintLayer: null,
    areaOfInterestLayer: null,
    bridgeAttachementLayer: null,
    gabionLayer: null,
    rtuLayer: null,
    faultsLayer: null,

    // vector sources
    satelliteVectorSource: null,
    rasterVectorSource: null,
    measurementsVectorSource: null,
    buildingsVectorSource: null,
    sitesVectorSource: null,
    cabinetsVectorSource: null,
    polesVectorSource: null,
    closuresVectorSource: null,
    manholesVectorSource: null,
    handholesVectorSource: null,
    ductsVectorSource: null,
    fiberCablesVectorSource: null,
    highlightVectorSource: null,
    odfVectorSource: null,
    fdpVectorSource: null,
    facePlateVectorSource: null,
    onuVectorSource: null,
    oltVectorSource: null,
    splitterVectorSource: null,
    microTunnelVectorSource: null,
    redliningVectorSource: null,
    roadCrossingVectorSource: null,
    roadsVectorSource: null,
    trenchVectorSource: null,
    splicesVectorSource: null,
    ductPlanVectorSource: null,
    signalTracerVectorSource: null,
    blueprintVectorSource: null,
    areaOfInterestVectorSource: null,
    bridgeAttachementVectorSource: null,
    gabionVectorSource: null,
    rtuVectorSource: null,
    faultsVectorSource: null,
  }),
  computed: {
    ductPlanLayers: function() {
      return [
        this.ductsLayer,
        this.manholesLayer,
        this.handholesLayer,
        this.sitesLayer,
        this.rasterLayer,
      ];
    },

    schematicsLayers: function() {
      return [
        this.fiberCablesLayer,
        this.cabinetsLayer,
        this.closuresLayer,
        this.odfLayer,
        this.roadsLayer,
        this.splicesLayer,
        this.rasterLayer,
      ];
    },

    schematicsOnlyLayers: function() {
      return [
        this.fiberCablesLayer,
        this.cabinetsLayer,
        this.closuresLayer,
        this.odfLayer,
        this.splicesLayer,
      ];
    },

    mapLayers: function() {
      return [
        this.satelliteLayer,
        this.rasterLayer,
        this.measurementsLayer,
        this.microTunnelLayer,
        this.trenchLayer,
        this.ductsLayer,
        this.fiberCablesLayer,
        this.buildingsLayer,
        this.sitesLayer,
        this.cabinetsLayer,
        this.manholesLayer,
        this.handholesLayer,
        this.polesLayer,
        this.closuresLayer,
        this.highlightLayer,
        this.odfLayer,
        this.fdpLayer,
        this.facePlateLayer,
        this.onuLayer,
        this.oltLayer,
        this.splitterLayer,
        this.redliningLayer,
        this.roadCrossingLayer,
        this.roadsLayer,
        this.splicesLayer,
        this.ductPlanLayer,
        this.signalTracerLayer,
        this.blueprintLayer,
        this.areaOfInterestLayer,
        this.gabionLayer,
        this.bridgeAttachementLayer,
        this.rtuLayer,
        this.faultsLayer,
      ];
    },
    vectorSources: function() {
      return [
        this.measurementsVectorSource,
        this.buildingsVectorSource,
        this.sitesVectorSource,
        this.cabinetsVectorSource,
        this.polesVectorSource,
        this.closuresVectorSource,
        this.microTunnelVectorSource,
        this.trenchVectorSource,
        this.ductsVectorSource,
        this.fiberCablesVectorSource,
        this.highlightVectorSource,
        this.odfVectorSource,
        this.fdpVectorSource,
        this.facePlateVectorSource,
        this.onuVectorSource,
        this.oltVectorSource,
        this.manholesVectorSource,
        this.handholesVectorSource,
        this.splitterVectorSource,
        this.redliningVectorSource,
        this.roadCrossingVectorSource,
        this.roadsVectorSource,
        this.splicesVectorSource,
        this.ductPlanVectorSource,
        this.signalTracerVectorSource,
        this.blueprintVectorSource,
        this.areaOfInterestVectorSource,
        this.gabionVectorSource,
        this.bridgeAttachementVectorSource,
        this.rtuVectorSource,
        this.faultsVectorSource,
      ];
    },
  },
  methods: {
    async hideSchematicsLayer() {
      this.hideLayer(this.splicesLayer);
      this.hideLayer(this.satelliteLayer);
      await this.mapLayers.forEach((layer) => this.showLayer(layer));
    },

    async showMapLayer() {
      await this.mapLayers.forEach((layer) => this.showLayer(layer));
    },

    async hideDuctsPlanLayer() {
      await this.mapLayers.forEach((layer) => this.showLayer(layer));
      this.hideLayer(this.splicesLayer);
      this.hideLayer(this.satelliteLayer);
    },

    async showDuctsPlanLayer() {
      await this.mapLayers.forEach((layer) => this.hideLayer(layer));
      await this.ductPlanLayers.forEach((layer) => this.showLayer(layer));
    },

    async showSchematicsLayer() {
      await this.mapLayers.forEach((layer) => this.hideLayer(layer));
      await this.schematicsLayers.forEach((layer) => this.showLayer(layer));
      this.showLayer(this.splicesLayer);
    },

    async setDuctPlanStyle() {
      await this.ductPlanLayers.forEach((layer) =>
        this.setLayerStyle(layer, ductPlanStyleFunction)
      );
    },

    async setSchematicsStyle() {
      await this.schematicsLayers.forEach((layer) =>
        this.setLayerStyle(layer, schematicsStyleFunction)
      );
    },

    async setDefaultStyle() {
      await this.schematicsOnlyLayers.forEach((layer) =>
        this.setLayerStyle(layer, pointLayersStyleFunction)
      );
      await this.ductPlanLayers.forEach((layer) =>
        this.setLayerStyle(layer, pointLayersStyleFunction)
      );
    },

    setLayerStyle(layer, styleFunction) {
      if (typeof layer.setStyle === "function") {
        layer.setStyle(styleFunction);
      }
    },

    initLayers() {
      let self = this;

      this.satelliteVectorSource = new XYZ({
        url:
          "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}?access_token=" +
          key,
        crossOrigin: "anonymous",
        wrapX: false,
      });
      this.satelliteLayer = new TileLayer({
        source: this.satelliteVectorSource,
      });

      let customStyle = "emudde/cjlboul3b5ngs2sm2wjbhzyoh";
      let mapboxStyle = "mapbox/streets-v12";
      this.rasterVectorSource = new XYZ({
        url: `https://api.mapbox.com/styles/v1/${mapboxStyle}/tiles/256/{z}/{x}/{y}?access_token=${key}`,
        crossOrigin: "anonymous",
        wrapX: false,
      });

      this.rasterLayer = new TileLayer({
        source: this.rasterVectorSource,
      });

      this.osmSource = new OSM({ crossOrigin: "anonymous" });
      this.osmLayer = new TileLayer({
        source: this.osmSource,
      });

      this.measurementsVectorSource = new VectorSource();
      this.measurementsLayer = new VectorLayer({
        source: this.measurementsVectorSource,
        style: pointLayersStyleFunction,
      });

      this.redliningVectorSource = new VectorSource();
      this.redliningLayer = new VectorLayer({
        source: this.redliningVectorSource,
        style: redliningStyleFunction,
      });

      this.roadsVectorSource = new VectorSource();
      this.roadsLayer = new VectorLayer({
        source: this.roadsVectorSource,
        style: namedRoadsStyleFunction,
      });

      this.areaOfInterestVectorSource = new VectorSource();
      this.areaOfInterestLayer = new VectorLayer({
        source: this.areaOfInterestVectorSource,
        style: areaOfInterestStyleFunction,
      });

      this.roadCrossingVectorSource = new VectorSource();
      this.roadCrossingLayer = new VectorLayer({
        source: this.roadCrossingVectorSource,
        style: pointLayersStyleFunction,
      });

      this.faultsVectorSource = new VectorSource();
      this.faultsLayer = new VectorLayer({
        source: this.faultsVectorSource,
        style: pointLayersStyleFunction,
      });

      this.rtuVectorSource = new VectorSource();
      this.rtuLayer = new VectorLayer({
        source: this.rtuVectorSource,
        style: pointLayersStyleFunction,
      });

      this.blueprintVectorSource = new VectorSource();
      this.blueprintLayer = new VectorLayer({
        source: this.blueprintVectorSource,
        style: pointLayersStyleFunction,
      });

      this.microTunnelVectorSource = new VectorSource();
      this.microTunnelLayer = new VectorLayer({
        source: this.microTunnelVectorSource,
        style: pointLayersStyleFunction,
      });

      this.trenchVectorSource = new VectorSource();
      this.trenchLayer = new VectorLayer({
        source: this.trenchVectorSource,
        style: pointLayersStyleFunction,
      });

      this.bridgeAttachementVectorSource = new VectorSource();
      this.bridgeAttachementLayer = new VectorLayer({
        source: this.bridgeAttachementVectorSource,
        style: pointLayersStyleFunction,
      });

      this.gabionVectorSource = new VectorSource();
      this.gabionLayer = new VectorLayer({
        source: this.gabionVectorSource,
        style: pointLayersStyleFunction,
      });

      this.odfVectorSource = new VectorSource();
      this.odfLayer = new VectorLayer({
        source: this.odfVectorSource,
        style: pointLayersStyleFunction,
      });

      this.fdpVectorSource = new VectorSource();
      this.fdpLayer = new VectorLayer({
        source: this.fdpVectorSource,
        style: pointLayersStyleFunction,
      });

      this.splitterVectorSource = new VectorSource();
      this.splitterLayer = new VectorLayer({
        source: this.splitterVectorSource,
        style: pointLayersStyleFunction,
      });

      this.facePlateVectorSource = new VectorSource();
      this.facePlateLayer = new VectorLayer({
        source: this.facePlateVectorSource,
        style: pointLayersStyleFunction,
      });

      this.onuVectorSource = new VectorSource();
      this.onuLayer = new VectorLayer({
        source: this.onuVectorSource,
        style: pointLayersStyleFunction,
      });

      this.oltVectorSource = new VectorSource();
      this.oltLayer = new VectorLayer({
        source: this.oltVectorSource,
        style: pointLayersStyleFunction,
      });

      this.buildingsVectorSource = new VectorSource();
      this.buildingsVectorSource.on("changefeature", function(evt) {
        self.modifiedFeature = evt.feature;
      });

      //changefeature
      this.buildingsLayer = new VectorLayer({
        source: this.buildingsVectorSource,
        style: pointLayersStyleFunction,
        renderMode: "image",
      });

      this.sitesVectorSource = new VectorSource();
      this.sitesLayer = new VectorLayer({
        source: this.sitesVectorSource,
        style: pointLayersStyleFunction,
      });

      this.splicesVectorSource = new VectorSource();
      this.splicesLayer = new VectorLayer({
        source: this.splicesVectorSource,
        style: schematicsStyleFunction,
      });

      this.ductPlanVectorSource = new VectorSource();
      this.ductPlanLayer = new VectorLayer({
        source: this.ductPlanVectorSource,
        style: ductPlanStyleFunction,
      });

      this.signalTracerVectorSource = new VectorSource();
      this.signalTracerLayer = new VectorLayer({
        source: this.signalTracerVectorSource,
        style: pointLayersStyleFunction,
      });

      this.cabinetsVectorSource = new VectorSource();
      this.cabinetsLayer = new VectorLayer({
        source: this.cabinetsVectorSource,
        style: pointLayersStyleFunction,
      });

      this.polesVectorSource = new VectorSource();
      this.polesLayer = new VectorLayer({
        source: this.polesVectorSource,
        style: pointLayersStyleFunction,
      });

      this.handholesVectorSource = new VectorSource();
      this.handholesLayer = new VectorLayer({
        source: this.handholesVectorSource,
        style: pointLayersStyleFunction,
      });

      this.manholesVectorSource = new VectorSource();
      this.manholesLayer = new VectorLayer({
        source: this.manholesVectorSource,
        style: pointLayersStyleFunction,
      });

      this.ductsVectorSource = new VectorSource();
      this.ductsLayer = new VectorLayer({
        source: this.ductsVectorSource,
        style: pointLayersStyleFunction,
      });

      this.fiberCablesVectorSource = new VectorSource();
      this.fiberCablesLayer = new VectorLayer({
        source: this.fiberCablesVectorSource,
        style: pointLayersStyleFunction,
      });

      this.highlightVectorSource = new VectorSource();
      this.highlightLayer = new VectorLayer({
        source: this.highlightVectorSource,
      });

      this.closuresVectorSource = new VectorSource();
      this.closuresLayer = new VectorLayer({
        source: this.closuresVectorSource,
        style: pointLayersStyleFunction,
      });

      // set satellite layer to invisible
      this.hideLayer(this.satelliteLayer);
    },

    showLayer(layer) {
      layer.setVisible(true);
    },

    hideLayer(layer) {
      layer.setVisible(false);
    },

    toggleLayerFeatureNames(layerItem) {
      if (layerItem.feature_type == FEATURE_ROAD) {
        this.roads_names_visible = !this.roads_names_visible;
        // change road layer style
        let feature = undefined;
        let layer = this.getFeatureLayer(feature, layerItem.feature_type);
        if (this.roads_names_visible) layer.setStyle(namedRoadsStyleFunction);
        else layer.setStyle(roadsStyleFunction);
      }
    },

    toggleVisibility(layerItem) {
      let feature = undefined;
      let layer = this.getFeatureLayer(feature, layerItem.feature_type);
      if (layer) {
        if (layer.getVisible()) this.hideLayer(layer);
        else this.showLayer(layer);
      }
    },

    toggleEditability(layerItem) {
      if (!this.editableLayers.includes(layerItem.feature_type)) {
        this.editableLayers.push(layerItem.feature_type);
      } else {
        //get position
        let searchFunc = (item) => item == layerItem.feature_type;
        let itemIndex = this.editableLayers.findIndex(searchFunc);

        // remove layer index
        this.editableLayers.splice(itemIndex, 1);
      }
    },

    getLayerNamesVisibility(layerItem) {
      if (layerItem.feature_type == FEATURE_ROAD)
        return this.roads_names_visible;
    },

    getVisibility(layerItem) {
      let feature = undefined;
      let layer = this.getFeatureLayer(feature, layerItem.feature_type);
      if (!layer) return false;
      return layer.getVisible();
    },

    getEditability(layerItem) {
      return this.editableLayers.includes(layerItem.feature_type);
    },

    hideAllBaseLayers() {
      this.satelliteLayer.setVisible(false);
      this.rasterLayer.setVisible(false);
    },

    getFeatureVectorSource(type) {
      let spliceSchematicTypes = [
        FEATURE_SPLICE_SCHEMATIC_LINESTRING,
        FEATURE_SPLICE_SCHEMATIC,
        FEATURE_SPLICE_SCHEMATIC_POINT,
        FEATURE_SPLICE_SCHEMATIC_PATH,
      ];

      if (
        type == FEATURE_MEASUREMENT_AREA ||
        type == FEATURE_MEASUREMENT_LENGTH
      )
        return this.measurementsVectorSource;
      if (type == FEATURE_BUILDING) return this.buildingsVectorSource;
      if (type == FEATURE_POLE) return this.polesVectorSource;
      if (type == FAULT) return this.faultsVectorSource;
      if (type == FEATURE_CABINET) return this.cabinetsVectorSource;
      if (type == FEATURE_SITE) return this.sitesVectorSource;
      if (type == FEATURE_MANHOLE) return this.manholesVectorSource;
      if (type == FEATURE_HANDHOLE) return this.handholesVectorSource;
      if (type == FEATURE_DUCT || type == POLE_DUCT)
        return this.ductsVectorSource;
      if (type == FEATURE_ODF) return this.odfVectorSource;
      if (type == FEATURE_CLOSURE) return this.closuresVectorSource;
      if (type == FEATURE_FIBER_CABLE || type == POLE_FIBER_CABLE)
        return this.fiberCablesVectorSource;
      if (type == FEATURE_FDP) return this.fdpVectorSource;
      if (type == FEATURE_SPLITTER) return this.splitterVectorSource;
      if (type == FEATURE_CABLE_HIGHLIGHT) return this.highlightVectorSource;
      if (type == FEATURE_POINT_HIGHLIGHT) return this.highlightVectorSource;
      if (type == FEATURE_MICRO_TUNNEL) return this.microTunnelVectorSource;
      if (type == BRIDGE_ATTACHMENT) return this.bridgeAttachementVectorSource;
      if (type == GABION) return this.gabionVectorSource;
      if (type == FEATURE_RTU) return this.rtuVectorSource;
      if (type == FEATURE_FACE_PLATE) return this.facePlateVectorSource;
      if (type == FEATURE_ONT) return this.onuVectorSource;
      if (type == FEATURE_OLT) return this.oltVectorSource;
      if (type == ROAD_CROSSING_HOOK) return this.roadCrossingVectorSource;
      if (type == FEATURE_ROAD) return this.roadsVectorSource;
      if (type == TRENCH) return this.trenchVectorSource;
      if (type == SIGNAL_TRACE) return this.signalTracerVectorSource;
      if (type == FEATURE_DUCT_PLAN) return this.ductPlanVectorSource;
      if (type == BLUE_PRINT_LINE || type == BLUE_PRINT_POINT)
        return this.blueprintVectorSource;
      if (type == AREA_OF_INTEREST) return this.areaOfInterestVectorSource;
      if (spliceSchematicTypes.includes(type)) return this.splicesVectorSource;

      // redline layer
      if (
        type == REDLINE_LINE ||
        type == REDLINE_POLYGON ||
        type == REDLINE_POINT
      )
        return this.redliningVectorSource;

      return null;
    },

    getFeatureLayer(feature, feature_type = undefined) {
      if (!feature && !feature_type) return;

      if (feature || feature_type) {
        let type = feature ? feature.get("type") : feature_type;
        let spliceSchematicTypes = [
          FEATURE_SPLICE_SCHEMATIC_LINESTRING,
          FEATURE_SPLICE_SCHEMATIC,
          FEATURE_SPLICE_SCHEMATIC_POINT,
          FEATURE_SPLICE_SCHEMATIC_PATH,
        ];

        if (
          type == FEATURE_MEASUREMENT_AREA ||
          type == FEATURE_MEASUREMENT_LENGTH
        )
          return this.measurementsLayer;
        if (type == FEATURE_BUILDING) return this.buildingsLayer;
        if (type == FEATURE_POLE) return this.polesLayer;
        if (type == FAULT) return this.faultsLayer;
        if (type == FEATURE_CABINET) return this.cabinetsLayer;
        if (type == FEATURE_SITE) return this.sitesLayer;
        if (type == FEATURE_ODF) return this.odfLayer;
        if (type == FEATURE_MANHOLE) return this.manholesLayer;
        if (type == FEATURE_HANDHOLE) return this.handholesLayer;
        if (type == FEATURE_CLOSURE) return this.closuresLayer;
        if (type == FEATURE_DUCT || type == POLE_DUCT) return this.ductsLayer;
        if (type == FEATURE_FIBER_CABLE || type == POLE_FIBER_CABLE)
          return this.fiberCablesLayer;
        if (type == FEATURE_FDP) return this.fdpLayer;
        if (type == GABION) return this.gabionLayer;
        if (type == FEATURE_RTU) return this.rtuLayer;
        if (type == BRIDGE_ATTACHMENT) return this.bridgeAttachementLayer;
        if (type == FEATURE_SPLITTER) return this.splitterLayer;
        if (type == FEATURE_CABLE_HIGHLIGHT) return this.highlightLayer;
        if (type == FEATURE_POINT_HIGHLIGHT) return this.highlightLayer;
        if (type == FEATURE_MICRO_TUNNEL) return this.microTunnelLayer;
        if (type == FEATURE_FACE_PLATE) return this.facePlateLayer;
        if (type == FEATURE_ONT) return this.onuLayer;
        if (type == FEATURE_OLT) return this.oltLayer;
        if (type == ROAD_CROSSING_HOOK) return this.roadCrossingLayer;
        if (type == FEATURE_ROAD) return this.roadsLayer;
        if (type == SIGNAL_TRACE) return this.signalTracerLayer;
        if (type == FEATURE_DUCT_PLAN) return this.ductPlanLayer;
        if (type == TRENCH) return this.trenchLayer;
        if (type == AREA_OF_INTEREST) return this.areaOfInterestLayer;
        if (spliceSchematicTypes.includes(type)) return this.splicesLayer;
        if (
          type == REDLINE_LINE ||
          type == REDLINE_POLYGON ||
          type == REDLINE_POINT
        )
          return this.redliningLayer;
      }

      return null;
    },

    deleteAllFeatures(type) {
      let vectorSource = this.getFeatureVectorSource(type);
      vectorSource.clear();
    },
  },
};
