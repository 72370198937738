<template>
  <v-dialog v-model="dialog" persistent width="450px">
    <v-card class="pb-3">
      <v-card-title justify-center class="success white--text justify-center">{{
        dialog_title
      }}</v-card-title>
      <v-container grid-list-sm class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <!-- slack_type -->
            <v-flex>
              <v-select
                v-model="slack.fiber_cable"
                :rules="rules.required_field"
                :items="filteredFeatures"
                item-text="text"
                item-value="value"
                label="Fiber Cable"
              ></v-select>
            </v-flex>

            <v-flex xs12 class="pr-3" align-center justify-space-between>
              <v-layout align-center>
                <v-text-field
                  v-model="slack.length"
                  :rules="rules.required_field"
                  placeholder="Slack Length"
                  label="Length in meters"
                  type="number"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <!-- slack_type -->
            <v-flex>
              <v-select
                v-model="slack.slack_type"
                :rules="rules.required_field"
                :items="slack_types"
                item-text="text"
                item-value="value"
                label="Slack Type"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="success"
          :disabled="!valid"
          :loading="status == 'loading'"
          @click="createOrEdit"
          >{{ dialog_button_text }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../../utils/mixins";
import { FEATURE_FIBER_CABLE } from "../../../../utils/feature_constants";
import { getFeatureById } from "../../../../utils/map_functions";

export default {
  name: "SlackDialogComponent",
  mixins: [dialogMixins],
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    selectedFeatures: {
      type: Array,
    },
    vectorSources: {
      type: Array,
    },
    feature: {
      type: Object,
      required: true,
    },
    editModel: {
      type: Object,
    },
  },
  data: () => ({
    slack_types: [
      {
        text: "Generic Slack",
        value: 1,
      },
      {
        text: "Before Closure",
        value: 2,
      },
      {
        text: "After Closure",
        value: 3,
      },
    ],
    id: "",
    is_edit_mode: false,
    loading: false,
    dialog_title: "Add Slack Cable",
    dialog_button_text: "Create",
    valid: true,
    rules: {
      required_field: [(v) => !!v || "Field is required"],
    },
    dialog: null,
    slack: {},
    upload_file: null,
  }),
  mounted() {
    this.dialog = this.dialogModel;
    this.slack = {};

    if (this.editModel) {
      this.is_edit_mode = true;

      Object.assign(this.slack, this.editModel);
      this.dialog_title = "Edit Slack";
      this.dialog_button_text = "Update";
    }
  },
  destroyed() {
    this.$emit("deleteAllHighlightFeatures");
  },
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
    filteredFeatures: function () {
      let fiberCablesArray = [];
      this.selectedFeatures.forEach((feature) => {
        if (feature.get("type") == FEATURE_FIBER_CABLE) {
          fiberCablesArray.push({
            text: feature.get("name"),
            value: feature.get("pk"),
            id: feature.getId(),
          });
        }
      });
      return fiberCablesArray;
    },
  },
  methods: {
    ...mapActions({
      createSlack: "createSlack",
    }),
    createOrEdit() {
      if (this.$refs.form.validate()) {
        if (this.is_edit_mode) {
          console.log("To be implemented");
        } else {
          this.slack.location = this.feature.getId();
          this.slack.created_by = this.user.id;

          this.createSlack(this.slack)
            .then(() => {
              this.$emit("reloadSlacks");
              this.resetFields();
              this.closeDialog();
            })
            .catch(() => {
              this.resetFields();
              this.closeDialog();
            });
        }
      }
    },
    resetFields() {
      this.slack = {};
      this.is_edit_mode = false;
      this.upload_file = null;
      this.loading = false;
    },
    closeDialog() {
      this.resetFields();

      this.$emit("closeDialog");
    },
  },
  watch: {
    "slack.fiber_cable": function (val) {
      let fiberCable = this.filteredFeatures.find(
        (cable) => cable.value == val
      );
      if (fiberCable) {
        let cableFeature = getFeatureById(fiberCable.id, this.vectorSources);
        if (cableFeature) {
          this.$emit("highlightSelectedFeature", cableFeature);
        }
      }
    },
    dialogModel: function (val) {
      this.dialog = val;
    },

    /*
     * Hack to close dialog when user clicks outside the dialog box.
     * It works by watching the model passed to the dialog
     */
    dialog: function (val) {
      if (!val) {
        this.slack = {};
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>