<template>
  <!-- check main.js for custom component details -->
  <c-submenu-layout>
    <!-- SubMenu Column -->
    <v-flex class="sub-menu" xs2>
      <c-sub-menu :items="sub_menu"></c-sub-menu>
    </v-flex>

    <!-- Main Content Column -->
    <v-flex xs10 class="main-content-border">
      <c-bread-crumbs :items="bread_crumbs"></c-bread-crumbs>

      <h1>Pole Accessories</h1>

      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" label="Search pole accessories"></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :search="search"
            :loading="status == 'loading'"
            :headers="headers"
            :items="accessories"
          >
            <template v-slot:item.action="{item}">
              <v-icon small color="primary" class="pr-3" @click="editItem(item)">edit</v-icon>
              <v-icon small color="error" @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-btn
        @click="dialog=!dialog"
        absolute
        top
        right
        fab
        rounded
        color="primary"
        class="white--text fab-button"
      >
        <v-icon>add</v-icon>
      </v-btn>

      <div v-if="dialog">
        <PoleAccessoryDialog
          @closeDialog="closeDialog"
          @reload="getAccessories"
          :editModel="editModel"
          @resetEditModel="resetEditModel"
          :dialogModel="dialog"
        ></PoleAccessoryDialog>
      </div>

      <div v-if="delete_dialog">
        <DeleteDialog
          :deleteMessage="delete_message"
          @deleteConfirmed="deleteConfirmed"
          @closeDialog="closeDialog"
          :dialogModel="delete_dialog"
          :model="deleteModel"
        ></DeleteDialog>
      </div>
    </v-flex>
  </c-submenu-layout>
</template>

<script>
import appConfig from "../../config";
import PoleAccessoryDialog from "./sub-components/PoleAccessoryDialog";
import DeleteDialog from "../generic/ConfirmDeleteDialog";
import dialogMixins from "../../utils/mixins";
import { mapState, mapActions } from "vuex";

export default {
  name: "PoleAccessoriesComponent",
  components: { PoleAccessoryDialog, DeleteDialog },
  mixins: [dialogMixins],
  data: () => ({
    search: "",
    deleteModel: null,
    editModel: null,
    delete_message: "Are you sure you want to delete this accessory?",
    sub_menu: [],
    bread_crumbs: [
      {
        text: "Pole Accessories",
        href: "pole-accessories",
      },
    ],
    headers: [
      {
        text: "Pole Accessory",
        value: "accessory_name",
      },
      {
        text: "Action",
        value: "action",
      },
    ],
    accessories: [],
  }),
  mounted() {
    this.sub_menu = appConfig.settings_page_sub_menus;
    if (this.accessories.length == 0) this.getAccessories();
  },
  computed: {
    ...mapState({
      status: (state) => state.featuresModule.status,
    }),
  },
  methods: {
    ...mapActions({
      createPoleAccessory: "createPoleAccessory",
      getPoleAccessories: "getPoleAccessories",
      updatePoleAccessory: "updatePoleAccessory",
      removePoleAccessory: "removePoleAccessory",
    }),
    getAccessories() {
      this.getPoleAccessories().then((res) => {
        this.accessories = res.results;
      });
    },
    resetEditModel() {
      this.editModel = undefined;
    },
    editItem(item) {
      this.editModel = item;
      this.dialog = !this.dialog;
    },
    deleteItem(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },
    deleteConfirmed(item) {
      this.removePoleAccessory(item.id).then(() => {
        this.getAccessories();
      });
    },
  },
};
</script>