<template>
  <v-layout>
    <v-btn
      @click="dialog = !dialog"
      small
      color="success"
      absolute
      right
      outlined
      class="mt-5"
    >
      <v-icon>add</v-icon>Add
    </v-btn>

    <v-flex xs12 class="mt-9">
      <v-data-table
        :items="items"
        :loading="status == 'loading'"
        :headers="header"
        hide-default-footer
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn @click="showDeleteDialog(item)" small text>
            <v-icon small color="error">delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-flex>

    <div v-if="dialog">
      <DialogComponent
        :selectedFeatures="selectedFeatures"
        :feature="feature"
        :dialogModel="dialog"
        @reload="loadFMSInfo"
        @closeDialog="closeDialog"
      ></DialogComponent>
    </div>

    <ConfirmDelete
      :deleteMessage="deleteMessage"
      :dialogModel="delete_dialog"
      @closeDialog="closeDialog"
      @deleteConfirmed="deleteItem"
      :model="deleteModel"
    ></ConfirmDelete>
  </v-layout>
</template>

<script>
import DialogComponent from "./dialogs/MonitoringDialog.vue";
import { mapActions, mapState } from "vuex";
import dialogMixins from "../../../utils/mixins";
import ConfirmDelete from "../../generic/ConfirmDeleteDialog";

export default {
  name: "MonitoringTableComponent",
  components: { DialogComponent, ConfirmDelete },
  mixins: [dialogMixins],
  props: {
    feature: {
      type: Object,
      required: true,
    },
    selectedFeatures: {
      type: Array,
    },
  },
  data: () => ({
    deleteModel: null,
    deleteMessage: "Are you sure you want to delete this entry?",
    header: [
      {
        text: "Port Number",
        value: "port_number",
        sortable: false,
      },
      {
        text: "Optical Route",
        value: "optical_route_name",
        sortable: false,
      },
      {
        text: "More",
        value: "action",
      },
    ],
    items: [],
  }),
  computed: {
    ...mapState({
      user: (state) => state.usersModule.current_user,
      status: (state) => state.featuresModule.status,
    }),
  },
  mounted() {
    this.loadFMSInfo();
  },
  methods: {
    ...mapActions({
      getItems: "getFeatureServices",
      removeItem: "deleteFeatureService",
      createFMSPortInfo: "createFMSPortInfo",
      getFMSPortInfo: "getFMSPortInfo",
      updateFMSPortInfo: "updateFMSPortInfo",
      deleteFMSPortInfo: "deleteFMSPortInfo",
    }),
    showDeleteDialog(item) {
      this.deleteModel = item;
      this.delete_dialog = !this.delete_dialog;
    },

    async deleteItem(item) {
      await this.deleteFMSPortInfo(item);
      await this.loadFMSInfo();
      this.deleteModel = null;
    },

    async loadFMSInfo() {
      let payload = {
        odf_id: this.feature.getId().toString(),
      };

      let res = await this.getFMSPortInfo(payload);
      this.items = res.results;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>