<template>
  <v-row justify="center">
    <v-dialog v-model="dialogModel" persistent max-width="350">
      <v-card>
        <v-card-title justify-center class="info darken-2 white--text justify-center">Notice</v-card-title>
        <v-card-text class="pt-5 body-1 black--text">{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" @click="hideDialog">Cancel</v-btn>
          <v-btn color="info darken-1" @click="actionConfirmed">Yes I am!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
    },
    dialogModel: {
      type: Boolean,
    },
    model: {
      type: Object,
    },
  },
  methods: {
    hideDialog() {
      this.$emit("closeDialog");
    },
    actionConfirmed() {
      this.$emit("actionConfirmed", this.model);
      this.hideDialog();
    },
  },
};
</script>